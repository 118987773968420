import {Observable} from 'rxjs';
import {airportStore, AirportStore} from './airport.store';
import {airportQuery} from './airport.query';
import {AirportState} from './airport.model';

export class AirportService {
  constructor(private airportStore: AirportStore) {}

  /**
   * Méthode permettant de démarrer le service
   */
  start(): void {
    console.log('Airport service started');
  }

  /**
   * Méthode permettant d'arrêter le service
   */
  destroy(): void {
    console.log('Airport service stopped');
  }

  /**
   * Méthode permettant de définir le nouvel aeroport contenu par le modèle
   * @param airport nouvel aeroport à insérer dans le store
   */
  updateAirport(airport: AirportState): void {
    airportStore.update(airport);
  }

  /**
   * Méthode permettant de s'abonner aux changements d'aeroport (code OACI)
   */
  selectIcaoAirport(): Observable<string> {
    return airportQuery.selectIcaoAirport();
  }

  /**
   * Méthode permettant de s'abonner aux changement d'aeroport (nom de l'aeroport)
   */
  selectAirportName(): Observable<string> {
    return airportQuery.selectAirportName();
  }

  /**
   * Méthode permettant de récupérer le code OACI de l'aeroport
   */
  getIcaoAirport(): string {
    return airportQuery.getIcaoAirport();
  }

  /**
   * Méthode permettant de récupérer le nom de l'aeroport
   */
  getAirportName(): string {
    return airportQuery.getAirportName();
  }
}

export const airportService = new AirportService(airportStore);
