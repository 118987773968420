import {flowStore, FlowStore} from './flow.store';
import {Flow} from './flow.model';
import {flowQuery} from './flow.query';

export class FlowService {
  constructor(private flowStore: FlowStore) {}

  /**
   * Méthode permettant de charger les informations des flux et de les insérer dans le store au démarrage
   */
  async start() {
    // On s'assure que le store est vide
    flowStore.remove();
    flowStore.setLoading(true);

    // On récupère les données
    let response: Response = await fetch('configLFPO/flows_LFPO.geojson');
    let result: any = await response.json();
    let dataset: any[] = result.features;

    // Et on les ajoute au store
    const flows: Flow[] = [];
    dataset.forEach((data) => {
      if (data.geometry.type === 'LineString') {
        if (data.properties.type !== 'sector') {
          const flow: Flow = {
            name: data.properties.name,
            firstBeacon: data.properties.firstBeacon,
            lastBeacon: data.properties.lastBeacon,
            coordinates: data.geometry.coordinates,
            forAirport: 'LFPO',
            forConfig: data.properties.forConfig,
          };

          flows.push(flow);
        }
      }
    });

    // On récupère les données
    response = await fetch('configLFPG/flows_LFPG.geojson');
    result = await response.json();
    dataset = result.features;

    // Et on les ajoute au store
    dataset.forEach((data) => {
      if (data.geometry.type === 'LineString') {
        if (data.properties.type !== 'sector') {
          const flow: Flow = {
            name: data.properties.name,
            firstBeacon: data.properties.firstBeacon,
            lastBeacon: data.properties.lastBeacon,
            coordinates: data.geometry.coordinates,
            forAirport: 'LFPG',
            forConfig: data.properties.forConfig,
          };

          flows.push(flow);
        }
      }
    });

    flowStore.add(flows);
    flowStore.setLoading(false);
  }

  /**
   * Méthode permettant de vider le store lorsqu'il n'est plus utilisé
   */
  destroy(): void {
    // On vide le store
    flowStore.remove();
  }

  /**
   * Méthode permettant d'ajouter un flux au store
   * @param flow Flux à ajouter aus store
   */
  addFlow(flow: Flow) {
    flowStore.add(flow);
  }

  /**
   * Méthode permettant de s'abonner aux changements des flux
   */
  selectFlows() {
    return flowQuery.selectFlows();
  }

  /**
   * Méthode permettant de s'abonner aux changements des flux pour un aeroport et une configuration précisées
   * @param airport Aeroport dont on veut récupérer les flux
   * @param config Configuration E ou W pour récupérer les bons flux
   */
  selectFlowsForAirportAndConfig(airport: string, config: string) {
    return flowQuery.selectFlowsForAirportAndConfig(airport, config);
  }

  /**
   * Méthode permettant d'obtenir ponctuellement l'ensemble des flux
   */
  getFlows() {
    return flowQuery.getFlows();
  }

  /**
   * Méthode permettant d'obtenir ponctuellement l'ensemble des flux pour un aeroport et une configuration précisée
   * @param airport aeroport dont on veut récupérer les flux
   * @param config configuration E ou W pour récupérer les bons flux
   */
  getFlowsForAirportAndConfig(airport: string, config: string) {
    return flowQuery.getFlowsForAirportAndConfig(airport, config);
  }
}

export const flowService = new FlowService(flowStore);
