import React, {useEffect, useState, useReducer, useRef} from 'react';

import './beacons-styles.css';

import Wheel from './wheel.js';

import {flightService} from '../../models/flight/flight.service.ts';
import {dateService} from '../../models/date/date.service.ts';
import {beaconService} from '../../models/beacon/beacon.service.ts';
import {airportService} from '../../models/airport/airport.service.ts';

function BeaconCop({name, defaultCapacity, x, y, isHovered, isSelected}) {
  // State
  const [currentDate, setCurrentDate] = useReducer(
    (state, newValue) => dateService.getDate(),
    dateService.getDate(),
  );
  const [airport, setAirport] = useState('');
  const [beaconLoad, setBeaconLoad] = useState([]);
  const [beaconLoadWithCapacities, setBeaconLoadWithCapacities] = useState([]);
  const [wheelDisplayed, setWheelDisplayed] = useState(false);

  // Refs des subscriptions
  const dateSubscription = useRef(null);
  const airportSubscription = useRef(null);
  const loadSubscription = useRef(null);

  // Au mount du composant, on s'abonne aux modèles
  useEffect(() => {
    dateSubscription.current = dateService.selectMinute().subscribe(setCurrentDate);
    airportSubscription.current = airportService
      .selectIcaoAirport(setAirport)
      .subscribe(setAirport);

    // Au unmount du composant, on se désabonne des modèles
    return () => {
      if (dateSubscription.current) {
        dateSubscription.current.unsubscribe();
      }
      if (airportSubscription.current) {
        airportSubscription.current.unsubscribe();
      }
      if (loadSubscription.current) {
        loadSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Lors du changement d'aéroport ou de nom de balise, on s'abonne aux vols de la balise
  useEffect(() => {
    // On se désabonne des précédents vols
    if (loadSubscription.current) {
      loadSubscription.current.unsubscribe();
    }

    if (airport && name) {
      loadSubscription.current = flightService
        .selectLoadForCopAndAirport(name, airport)
        .subscribe((newLoad) => setBeaconLoad(newLoad.loadArray));
    } else {
      setBeaconLoad([]);
    }
  }, [airport, name]);

  // Lors de la mise à jour du load et/ou des capacités (particulières ou par défaut), on merge les données
  useEffect(() => {
    if (beaconLoad && defaultCapacity) {
      setBeaconLoadWithCapacities(beaconLoad.map((load) => ({...load, capacity: defaultCapacity})));
    } else if (beaconLoad) {
      setBeaconLoadWithCapacities(beaconLoad.map((load) => ({...load, capacity: 999})));
    } else {
      setBeaconLoadWithCapacities([]);
    }
  }, [currentDate, beaconLoad, defaultCapacity]);

  // Lors de la mise à jour du load avec capacité, on determine s'il faut afficher la roue crantée
  useEffect(() => {
    const appears = beaconLoadWithCapacities.reduce(
      (accumulator, currentValue) =>
        accumulator || currentValue.load > Math.floor(currentValue.capacity / 3),
      false,
    );
    setWheelDisplayed(appears);
  }, [beaconLoadWithCapacities]);

  // Event handlers
  const handleClick = (event) => {
    if (isSelected) {
      beaconService.updateSelectedBeacon('');
    } else {
      beaconService.updateSelectedBeacon(name);
    }
  };

  const handleMouseEnter = (event) => {
    if (event.buttons % 2 !== 1) {
      beaconService.updateHoveredBeacon(name);
    }
  };

  const handleMouseLeave = (event) => {
    if (event.buttons % 2 !== 1) {
      beaconService.updateHoveredBeacon('');
    }
  };

  return (
    <g
      className={`beaconCop${isSelected ? ' selected' : isHovered ? ' hovered' : ''}`}
      transform={`translate (${x} ${y})`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {wheelDisplayed ? (
        <g>
          <Wheel
            beaconRadius={11}
            beaconLoadWithCapacities={beaconLoadWithCapacities}
            currentDateTime={currentDate}
          />
          <rect className="nameArea" x="-23.5" y="34" rx="5" ry="5" width="46" height="14" />
          <text className="name" x="0" y="45" textAnchor="middle">
            {name}
          </text>
        </g>
      ) : (
        <g>
          <rect className="nameArea" x="-23.5" y="-26" rx="5" ry="5" width="46" height="14" />
          <text className="name" x="0" y="-15" textAnchor="middle">
            {name}
          </text>
        </g>
      )}
      <circle className="beaconCircle" cx="0" cy="0" r="10" />
    </g>
  );
}

export default BeaconCop;
