import {Query} from '@datorama/akita';
import {AirportState} from './airport.model';
import {airportStore, AirportStore} from './airport.store';
import {Observable} from 'rxjs';

export class AirportQuery extends Query<AirportState> {
  constructor(protected store: AirportStore) {
    super(store);
  }

  selectIcaoAirport(): Observable<string> {
    return this.select((state) => state.icaoName);
  }

  selectAirportName(): Observable<string> {
    return this.select((state) => state.name);
  }

  getIcaoAirport(): string {
    return this.getValue().icaoName;
  }

  getAirportName(): string {
    return this.getValue().name;
  }
}

export const airportQuery = new AirportQuery(airportStore);
