import {webSocket} from '../../utils/serverWebSocket';
import {Socket} from 'socket.io-client';

import {PlanificationStore} from './planification.store';
import {Planifications} from './planification.model';
import {planificationQuery} from './planification.query';
import {planificationStore} from './planification.store';

import {dateService} from '../date/date.service';

export class PlanificationService {
  private socket?: Socket;

  /**
   * Constructeur
   * @param planificationStore Store
   */
  constructor(private planificationStore: PlanificationStore) {}

  /**
   * Méthode permettant de s'abonner aux mises à jour du serveur pour ce qui concerne les planifications
   */
  start(): void {
    // On s'abonne au WS
    this.socket = webSocket().getSocket();
    this.onMessageReceived = this.onMessageReceived.bind(this);
    this.socket.on('message', this.onMessageReceived);
  }

  /**
   * Méthode permettant d'arrêter la mise à jour automatique de la date du modèle
   */
  destroy(): void {
    // Déconnexion du serveur
    if (this.socket) {
      this.socket.off('message', this.onMessageReceived);
      this.socket = undefined;
    }
  }

  /**
   * Listener de message reçu du serveur
   * @param message Message reçu
   */
  onMessageReceived(message: any) {
    const json = JSON.parse(message);

    if (json.typeMessage === 'planificationUpdated') {
      json.data.LFPO.configurations = json.data.LFPO.configurations.map((config: any) => ({
        configuration: config.configuration,
        from: config.from ? new Date(config.from) : null,
        to: config.to ? new Date(config.to) : null,
        next: config.next ? new Date(config.next) : null,
      }));

      json.data.LFPG.configurations = json.data.LFPG.configurations.map((config: any) => ({
        configuration: config.configuration,
        from: config.from ? new Date(config.from) : null,
        to: config.to ? new Date(config.to) : null,
        next: config.next ? new Date(config.next) : null,
      }));

      for (const [key, value] of Object.entries(json.data.LFPO.cadencies)) {
        json.data.LFPO.cadencies[key] = (value as any[]).map((cadency: any) => ({
          cadency: cadency.cadency,
          from: cadency.from ? new Date(cadency.from) : null,
          to: cadency.to ? new Date(cadency.to) : null,
        }));
      }

      for (const [key, value] of Object.entries(json.data.LFPG.cadencies)) {
        json.data.LFPG.cadencies[key] = (value as any[]).map((cadency: any) => ({
          cadency: cadency.cadency,
          from: cadency.from ? new Date(cadency.from) : null,
          to: cadency.to ? new Date(cadency.to) : null,
        }));
      }

      for (const [key, value] of Object.entries(json.data.LFPO.closures)) {
        json.data.LFPO.closures[key] = (value as any[]).map((closure: any) => ({
          type: closure.type,
          from: closure.from ? new Date(closure.from) : null,
          to: closure.to ? new Date(closure.to) : null,
        }));
      }

      for (const [key, value] of Object.entries(json.data.LFPG.closures)) {
        json.data.LFPG.closures[key] = (value as any[]).map((closure: any) => ({
          type: closure.type,
          from: closure.from ? new Date(closure.from) : null,
          to: closure.to ? new Date(closure.to) : null,
        }));
      }

      this.onPlanificationChanged(json.data);
    }
  }

  /**
   * Event listener lorsque l'on reçoit un message de type 'planificationChanged'.
   * Cette méthode met à jour le store avec les informations reçues
   * @param planification Nouvelle planification à sauvegarder
   */
  onPlanificationChanged(planification: Planifications) {
    this.planificationStore.update(planification);
  }

  /**
   * Méthode permettant de récupérer le QFU en fonction de l'aéroport et de la configuration de cet aéroport
   * @param airport aeroport pour lequel on veut récupérer le QFU
   * @param runway piste pour laquel on veut récupérer le QFU
   * @param config configuration de l'aeroport pour laquel on veut récupérer le QFU
   */
  getQfuForAirportAndConfig(
    airport: 'LFPO' | 'LFPG',
    runway: 'LFPO' | 'LFPGARN' | 'LFPGARS',
    config: string,
  ) {
    return planificationQuery.getQfuForAirportAndConfig(airport, runway, config);
  }

  /**
   * Méthode permettant de s'abonner aux modifications de planification
   * @param airport aeroport dont on veut récupérer les planifications
   */
  selectPlanificationForAirport(airport: 'LFPO' | 'LFPG') {
    return planificationQuery.selectPlanificationForAirport(airport);
  }

  /**
   * Méthode permettant de s'abonner à la cadence courante d'un QFU pour un aeroport
   * @param airport aeroport dont on veut récupérer la cadence courante
   * @param qfu qfu pour lequel on veut récupérer la cadence courante
   */
  selectCurrentCadencyForAirportAndQfu(airport: 'LFPO' | 'LFPG', qfu: string) {
    return planificationQuery.selectCurrentCadencyForAirportAndQfu(airport, qfu);
  }

  /**
   * Méthode permettant de s'abonner aux modifications de planifications pour le graphe de planification
   * @param airport Aeroport dont on veut récupérer les planifications
   */
  selectPlanificationForGraphForAirport(
    airport: 'LFPO' | 'LFPG',
    subairport: 'LFPO' | 'LFPGARS' | 'LFPGARN',
  ) {
    return planificationQuery.selectPlanificationForGraphForAirport(airport, subairport);
  }

  /**
   * Méthode permettant de récupérer le QFU ouvert à une heure donnée
   * @param time heure pour laquelle on veut récupérer le QFU
   * @param airport aeroport dont on veut récupérer le QFU
   */
  getQfuAtTimeForAirport(
    time: Date,
    airport: 'LFPO' | 'LFPG',
    subairport: 'LFPO' | 'LFPGARN' | 'LFPGARS',
  ) {
    return planificationQuery.getQfuAtTimeForAirport(time, airport, subairport);
  }

  /**
   * Méthode permettant de récupérer les configurations possibles pour un aéroport
   * @param airport aeroport pour lequel on veut récupérer les configurations possibles
   */
  getPossibleConfigForAirport(airport: 'LFPO' | 'LFPG') {
    return planificationQuery.getPossibleConfigForAirport(airport);
  }

  /**
   * Méthode permettant de récupérer la liste des QFU possible pour un aeroport + sous aeroport
   * @param airport Aeroport pour lequel on veut récupérer les QFU possibles
   * @param subairport Sous aeroport pour lequel on veut récupérer les QFU possibles (pour LFPG -> LFPGARN ou LFPGARS)
   */
  getPossibleQfuForAirport(airport: 'LFPO' | 'LFPG', subairport: 'LFPO' | 'LFPGARN' | 'LFPGARS') {
    return planificationQuery.getPossibleQfuForAirport(airport, subairport);
  }

  /**
   * Méthode permettant de récupérer la cadence pour un qfu donné, à une heure donnée
   * @param qfu qfu
   * @param time heure pour laquelle on veut récupérer la cadence
   * @param airport aeroport dont on veut récupérer la cadence
   */
  getCadencyForQfuAtTimeForAirport(qfu: string, time: Date, airport: 'LFPO' | 'LFPG') {
    return planificationQuery.getCadencyForQfuAtTimeForAirport(qfu, time, airport);
  }

  /**
   * Méthode permettant de s'abonner à la configuration courante pour un aeroport
   * @param airport aeroport pour lequel on veut avoir la configuration courante
   */
  selectCurrentConfigurationForAirport(airport: 'LFPO' | 'LFPG') {
    return planificationQuery.selectCurrentConfigurationForAirport(airport);
  }

  /**
   * Méthode permettant de récupérer le nom de la piste (pour LFPG -> LFPGARN ou LFPGARS)
   * @param airport Aeroport pour lequel on veut le nom de la piste
   * @param qfu Qfu de la piste dont on veut le nom
   */
  getRunwayForAirportAndQfu(airport: 'LFPO' | 'LFPG', qfu: string) {
    return planificationQuery.getRunwayForAirportAndQfu(airport, qfu);
  }

  /**
   * Méthode permetant de récupérer l'ensemble des pistes pour un aéroport (LFPO -> [LFPO], LFPG -> [LFPGARN, LFPGARS])
   * @param airport Aéroport pour lequel on veut récupérer les pistes
   */
  getAllRunwaysForAirport(airport: 'LFPO' | 'LFPG') {
    return planificationQuery.getAllRunwaysForAirport(airport);
  }

  /**
   * Méthode permettant de définir une nouvelle configuration pour un aeroport
   * @param configuration nouvelle configuration
   * @param airport aeroport pour lequel on veut définir une nouvelle configuration
   */
  setNewConfigurationForAirport(configuration: string, airport: 'LFPO' | 'LFPG') {
    const time = dateService.getDate();

    const message = JSON.stringify({
      typeMessage: 'newCurrentConfiguration',
      airport: airport,
      configuration: configuration,
      time: time,
    });

    this.socket?.emit('order', message);
  }

  /**
   * Méthode permettant de définir une nouvelle cadence pour un aeroport + qfu
   * @param cadency nouvelle cadence
   * @param qfu qfu pour lequel on veut définir une nouvelle cadence
   * @param airport aeroport pour lequel on veut définir une nouvelle cadence
   */
  setNewCadencyForAirport(cadency: number, qfu: string, airport: 'LFPO' | 'LFPG') {
    const time = dateService.getDate();

    const message = JSON.stringify({
      airport: airport,
      typeMessage: 'newCurrentCadency',
      qfu: qfu,
      cadency: cadency,
      time: time,
    });

    this.socket?.emit('order', message);
  }

  /**
   * Méthode permettant de planifier un nouveau changement de configuration
   * @param configuration nouvelle configuration planifiée
   * @param closureFrom Début de la fermeture associée au changement de configuration
   * @param closureTo Fin de la fermeture associée au changement de configuration
   * @param airport Aeroport pour lequel on veut prévoir un changement de configuration
   */
  setNewPlannedConfigurationForAirport(
    configuration: string,
    closureFrom: Date,
    closureTo: Date,
    airport: 'LFPO' | 'LFPG',
  ) {
    const message = JSON.stringify({
      typeMessage: 'newPlannedConfiguration',
      airport: airport,
      configuration: configuration,
      closureFrom: closureFrom,
      closureTo: closureTo,
    });

    this.socket?.emit('order', message);

    if (closureFrom.getTime() !== closureTo.getTime()) {
      const runways = this.getAllRunwaysForAirport(airport);
      runways.forEach((runway) => {
        const oldQfu = this.getQfuAtTimeForAirport(closureFrom, airport, runway);
        const newQfu = this.getQfuForAirportAndConfig(airport, runway, configuration);

        const messageClosure1 = JSON.stringify({
          typeMessage: 'newPlannedClosureConfig',
          airport: airport,
          qfu: oldQfu,
          from: closureFrom,
          to: closureTo,
        });
        this.socket?.emit('order', messageClosure1);

        const messageClosure2 = JSON.stringify({
          typeMessage: 'newPlannedClosureConfig',
          airport: airport,
          qfu: newQfu,
          from: closureFrom,
          to: closureTo,
        });
        this.socket?.emit('order', messageClosure2);
      });
    }
  }

  /**
   * Methode permettant de planifier un nouveau changement de cadence
   * @param cadency nouvelle cadence planifiée
   * @param qfu QFU sur lequel on veut appliquer la nouvelle cadence
   * @param at Heure prévue du changement de cadence
   * @param airport Aeroport pour lequel on veut prévoir un changement de cadence
   */
  setNewPlannedCadencyForQfuForAirport(
    cadency: number,
    qfu: string,
    at: Date,
    airport: 'LFPO' | 'LFPG',
  ) {
    const message = JSON.stringify({
      typeMessage: 'newPlannedCadency',
      airport: airport,
      qfu: qfu,
      cadency: cadency,
      time: at,
    });

    this.socket?.emit('order', message);
  }

  /**
   * Méthode permettant de planifier une fermeture pour un aeroport + sous aeroport (Pour LFPG -> LFPGARN ou LFPGARS)
   * @param from début de la fermeture planifiée
   * @param to fin de la fermeture planifiée
   * @param airport aeroport pour lequel on veut planifier une fermeture
   * @param runway piste pour laquelle on veut planifier une fermeture
   */
  setNewPlannedClosureForAirport(
    from: Date,
    to: Date,
    airport: 'LFPO' | 'LFPG',
    runway: 'LFPO' | 'LFPGARS' | 'LFPGARN',
  ) {
    const qfuAtTime = this.getQfuAtTimeForAirport(from, airport, runway);

    const message = JSON.stringify({
      typeMessage: 'newPlannedClosureReal',
      airport: airport,
      qfu: qfuAtTime,
      from: from,
      to: to,
    });

    this.socket?.emit('order', message);
  }

  /**
   * Méthode permettant de planifier un nouveau GAP pour un aeroport + sous aeroport (Pour LFPG -> LFPGARN ou LFPGARS)
   * @param from Début du gap planifié
   * @param to fin du gap planifié
   * @param airport Aeroport pour lequel on veut planifier un gap
   * @param runway piste pour laquelle on veut planifier un gap
   */
  setNewPlannedGapForAirport(
    from: Date,
    to: Date,
    airport: 'LFPO' | 'LFPG',
    runway: 'LFPO' | 'LFPGARS' | 'LFPGARN',
  ) {
    const qfuAtTime = this.getQfuAtTimeForAirport(from, airport, runway);

    const message = JSON.stringify({
      typeMessage: 'newPlannedClosureGap',
      airport: airport,
      qfu: qfuAtTime,
      from: from,
      to: to,
    });

    this.socket?.emit('order', message);
  }

  /**
   * Méthode permettant de supprimer un changement de configuration planifié pour un aeroport
   * @param planification planification à supprimer
   * @param airport Aeroport pour lequel on veut supprimer un changement de configuration planifié
   */
  deleteConfigurationChangeForAirport(planification: any, airport: 'LFPO' | 'LFPG') {
    if (planification.config && planification.closureTo) {
      if (planification.closureFrom.getTime() !== planification.closureTo.getTime()) {
        const runways = this.getAllRunwaysForAirport(airport);
        runways.forEach((runway) => {
          const oldQfu = this.getQfuAtTimeForAirport(planification.closureFrom, airport, runway);
          const newQfu = this.getQfuForAirportAndConfig(airport, runway, planification.config);

          const messageClosure1 = JSON.stringify({
            typeMessage: 'deletePlannedClosure',
            airport: airport,
            qfu: oldQfu,
            start: planification.closureFrom,
            end: planification.closureTo,
          });

          this.socket?.emit('order', messageClosure1);

          const messageClosure2 = JSON.stringify({
            typeMessage: 'deletePlannedClosure',
            airport: airport,
            qfu: newQfu,
            start: planification.closureFrom,
            end: planification.closureTo,
          });

          this.socket?.emit('order', messageClosure2);
        });
      }

      const message = JSON.stringify({
        typeMessage: 'deletePlannedConfiguration',
        airport: airport,
        configuration: planification.config,
        time: planification.closureTo,
      });

      this.socket?.emit('order', message);
    }
  }

  /**
   * Méthode permettant de supprimer une fermeture planifiée (pour un aéroport + sous aeroport)
   * @param planification planification à supprimer
   * @param airport Aeroport pour lequel on veut supprimer une fermeture planifiée
   * @param runway Piste pour laquelle on veut supprimer une fermeture (pour LFPG -> LFPGARN ou LFPGARS)
   */
  deleteClosureForAirport(
    planification: any,
    airport: 'LFPO' | 'LFPG',
    runway: 'LFPO' | 'LFPGARS' | 'LFPGARN',
  ) {
    const qfuAtStart = planificationQuery.getQfuAtTimeForAirport(
      planification.from,
      airport,
      runway,
    );

    if (planification.from && planification.to && qfuAtStart) {
      const message = JSON.stringify({
        typeMessage: 'deletePlannedClosure',
        airport: airport,
        start: planification.from,
        end: planification.to,
        qfu: qfuAtStart,
      });

      this.socket?.emit('order', message);
    }
  }

  /**
   * Méthode permettant de supprimer un changement de CVF (CVF <-> non CVF)
   * @param planification planification à supprimer
   * @param airport Aeroport pour lequel on veut supprimer un changement CVF
   */
  deleteCvfChangeForAirport(planification: any, airport: 'LFPO' | 'LFPG') {
    const currentConfig = planificationQuery.getCurrentConfigForAirport(airport);

    if (currentConfig && currentConfig !== '--') {
      const message = JSON.stringify({
        typeMessage: 'deletePlannedConfiguration',
        airport: airport,
        configuration: planification.notCvfToCvf
          ? `${currentConfig}_CVF`
          : currentConfig.replace('_CVF', ''),
        time: planification.at,
      });

      this.socket?.emit('order', message);
    }
  }

  /**
   * Méthode permettant de supprimer un changement de cadence planifié
   * @param planification planification à supprimer
   * @param airport Aeroport pour lequel on veut supprimer un changement de cadence planifié
   * @param runway Piste pour laquelle on veut supprimer un changement de cadence planifié (Pour LFPG -> LFPGARN ou LFPGARS)
   */
  deleteCadencyChangeForAirport(
    planification: any,
    airport: 'LFPO' | 'LFPG',
    runway: 'LFPO' | 'LFPGARS' | 'LFPGARN',
  ) {
    if (planification.at) {
      const qfuAtTime = planificationQuery.getQfuAtTimeForAirport(
        planification.at,
        airport,
        runway,
      );

      if (qfuAtTime) {
        const message = JSON.stringify({
          typeMessage: 'deletePlannedCadency',
          airport: airport,
          qfu: qfuAtTime,
          time: planification.at,
        });

        this.socket?.emit('order', message);
      }
    }
  }
}

export const planificationService = new PlanificationService(planificationStore);
