import {sectorStore, SectorStore} from './sector.store';
import {Sector} from './sector.model';
import {sectorQuery} from './sector.query';

import * as d3 from 'd3';

export class SectorService {
  constructor(private sectorStore: SectorStore) {}

  /**
   * Méthode permettant de charger les informations des secteurs et de les insérer dans le store au démarrage
   */
  async start() {
    // On s'assure que le store est vide
    sectorStore.remove();
    sectorStore.setLoading(true);

    // On récupère les données
    const response: Response = await fetch('sectors_LFPO_LFPG.geojson');
    const result: any = await response.json();
    const dataset: any[] = result.features;

    // Et on les ajoute au store
    const sectors: Sector[] = [];
    dataset.forEach((data) => {
      if (data.geometry.type === 'LineString') {
        if (data.properties.type === 'sector') {
          const sector: Sector = {
            name: data.properties.name,
            coordinates: data.geometry.coordinates,
            belong: data.properties.belong,
          };

          sectors.push(sector);
        }
      }
    });

    sectorStore.add(sectors);
    sectorStore.setLoading(false);
  }

  /**
   * Méthode permettant de vider le store lorsqu'il n'est plus utilisé
   */
  destroy(): void {
    // On vide le store
    sectorStore.remove();
  }

  /**
   * Méthode permettant d'ajouter un secteur au store
   * @param sector secteur à ajouter
   */
  addSector(sector: Sector) {
    sectorStore.add(sector);
  }

  /**
   * Méthode permettant de s'abonner aux changements des secteurs
   */
  selectSectors() {
    return sectorQuery.selectSectors();
  }

  /**
   * Méthode permettant de s'abonner au secteur EGA (utilisé pour masquer les vols proche de l'aeroport)
   */
  selectSectorEga() {
    return sectorQuery.selectSectorEga();
  }

  /**
   * Donne la projection sur la carte
   */
  getProjection() {
    return d3
      .geoMercator()
      .translate([800, 1600])
      .scale(8000 / (2 * Math.PI));
  }
}

export const sectorService = new SectorService(sectorStore);
