import React, {useState, useEffect, useRef, useReducer} from 'react';

import './beacons-styles.css';

import Wheel from './wheel.js';

import {dateService} from '../../models/date/date.service.ts';
import {beaconService} from '../../models/beacon/beacon.service.ts';
import {airportService} from '../../models/airport/airport.service.ts';
import {flightService} from '../../models/flight/flight.service.ts';
import {planificationService} from '../../models/planification/planification.service.ts';
import {capacityService} from '../../models/capacity/capacity.service.ts';

function BeaconAirport({name, defaultCapacity, x, y, isSelected, isHovered}) {
  // State
  const [currentDate, setCurrentDate] = useReducer(
    (state, newValue) => dateService.getDate(),
    dateService.getDate(),
  );
  const [airport, setAirport] = useState('');
  const [beaconLoad, setBeaconLoad] = useState(null);
  const [capacities, setCapacities] = useState(null);
  const [beaconLoadWithCapacities, setBeaconLoadWithCapacities] = useState(null);
  const [wheelDisplayed, setWheelDisplayed] = useState(false);
  const [configuration, setConfiguration] = useState(null);
  const [cadency, setCadency] = useState(null);

  // Refs des subscriptions
  const dateSubscription = useRef(null);
  const airportSubscription = useRef(null);
  const loadSubscription = useRef(null);
  const configurationSubscription = useRef(null);
  const cadencySubscription = useRef(null);
  const capacitySubscription = useRef(null);

  // Au mount du composant, on s'abonne aux modèles
  useEffect(() => {
    dateSubscription.current = dateService.selectMinute().subscribe(setCurrentDate);
    airportSubscription.current = airportService
      .selectIcaoAirport(setAirport)
      .subscribe(setAirport);

    // Au unmount du composant, on se désabonne des modèles
    return () => {
      if (dateSubscription.current) {
        dateSubscription.current.unsubscribe();
      }
      if (airportSubscription.current) {
        airportSubscription.current.unsubscribe();
      }
      if (loadSubscription.current) {
        loadSubscription.current.unsubscribe();
      }
      if (configurationSubscription.current) {
        configurationSubscription.current.unsubscribe();
      }
      if (cadencySubscription.current) {
        cadencySubscription.current.unsubscribe();
      }
      if (capacitySubscription.current) {
        capacitySubscription.current.unsubscribe();
      }
    };
  }, []);

  // Lors du changement d'aéroport, on s'abonne à la configuration courante
  useEffect(() => {
    if (configurationSubscription.current) {
      configurationSubscription.current.unsubscribe();
    }

    if (airport) {
      configurationSubscription.current = planificationService
        .selectCurrentConfigurationForAirport(airport)
        .subscribe(setConfiguration);
    } else {
      setConfiguration(null);
    }
  }, [airport]);

  // Lors du changement d'aéroport, de piste ou de configuration, on met à jour la subscription à la cadence
  useEffect(() => {
    if (cadencySubscription.current) {
      cadencySubscription.current.unsubscribe();
    }

    if (airport && name && configuration) {
      const qfu = planificationService.getQfuForAirportAndConfig(airport, name, configuration);
      cadencySubscription.current = planificationService
        .selectCurrentCadencyForAirportAndQfu(airport, qfu)
        .subscribe(setCadency);
    } else {
      setCadency(null);
    }
  }, [airport, name, configuration]);

  // Lors du changement d'aéroport ou de nom de balise, on s'abonne au load de la balise et aux capacités
  useEffect(() => {
    // On se désabonne des précédents vols
    if (loadSubscription.current) {
      loadSubscription.current.unsubscribe();
    }
    if (capacitySubscription.current) {
      capacitySubscription.current.unsubscribe();
    }

    if (airport && name) {
      loadSubscription.current = flightService
        .selectLoadForAirportAndRunway(airport, name)
        .subscribe((newLoad) => setBeaconLoad(newLoad.loadArray));
      capacitySubscription.current = capacityService
        .selectCapacityForTV(name)
        .subscribe(setCapacities);
    } else {
      setBeaconLoad(null);
      setCapacities(null);
    }
  }, [airport, name]);

  // Lors de la mise à jour du load et/ou des capacités (particulières ou par défaut), on merge les données
  useEffect(() => {
    if (beaconLoad && capacities && capacities.capacities && defaultCapacity) {
      setBeaconLoadWithCapacities(
        beaconLoad.map((loadItem) => {
          let loadItemCapacity = 999;
          const beginCapacity = capacities.capacities.find(
            (capa) =>
              capa.start.getTime() <= loadItem.from.getTime() &&
              capa.end.getTime() > loadItem.from.getTime(),
          );
          const endCapacity = capacities.capacities.find(
            (capa) =>
              capa.start.getTime() <= loadItem.to.getTime() &&
              capa.end.getTime() > loadItem.to.getTime(),
          );
          if (beginCapacity && endCapacity) {
            // Si la capacité change pendant le créneau (beginCapacity !== endCapacity), on prend la plus contraignante
            loadItemCapacity = Math.min(beginCapacity.capacity, endCapacity.capacity);
          } else if (beginCapacity) {
            loadItemCapacity = beginCapacity.capacity;
          } else if (endCapacity) {
            loadItemCapacity = endCapacity.capacity;
          } else {
            loadItemCapacity = defaultCapacity;
          }

          return {
            ...loadItem,
            capacity: loadItemCapacity,
          };
        }),
      );
    } else if (beaconLoad && defaultCapacity) {
      setBeaconLoadWithCapacities(
        beaconLoad.map((loadItem) => ({
          ...loadItem,
          capacity: defaultCapacity,
        })),
      );
    } else if (beaconLoad) {
      setBeaconLoadWithCapacities(beaconLoad.map((loadItem) => ({...loadItem, capacity: 999})));
    } else {
      setBeaconLoadWithCapacities(null);
    }
  }, [currentDate, beaconLoad, capacities, defaultCapacity]);

  // Lors de la mise à jour du load avec capacité, on determine s'il faut afficher la roue crantée
  useEffect(() => {
    const appears =
      beaconLoadWithCapacities &&
      beaconLoadWithCapacities.reduce(
        (accumulator, currentValue) =>
          accumulator || currentValue.load > Math.round(currentValue.capacity / 3),
        false,
      );
    setWheelDisplayed(appears);
  }, [beaconLoadWithCapacities]);

  // Event handlers
  const handleClick = (event) => {
    if (isSelected) {
      beaconService.updateSelectedBeacon('');
    } else {
      beaconService.updateSelectedBeacon(name);
    }
  };

  const handleMouseEnter = (event) => {
    if (event.buttons % 2 !== 1) {
      beaconService.updateHoveredBeacon(name);
    }
  };

  const handleMouseLeave = (event) => {
    if (event.buttons % 2 !== 1) {
      beaconService.updateHoveredBeacon('');
    }
  };

  return (
    <g
      className={`beaconAirport${isSelected ? ' selected' : isHovered ? ' hovered' : ''}`}
      transform={`translate (${x} ${y})`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      {wheelDisplayed ? (
        <g>
          <Wheel
            beaconRadius={18}
            beaconLoadWithCapacities={beaconLoadWithCapacities}
            currentDateTime={currentDate}
          />
          {name === 'LFPGARN' ? (
            <>
              <rect className="nameArea" x="-35" y="-52" rx="5" ry="5" width="70" height="18" />
              <text className="name" x="0" y="-38" textAnchor="middle">
                {name}
              </text>
            </>
          ) : (
            <>
              <rect className="nameArea" x="-35" y="34" rx="5" ry="5" width="70" height="18" />
              <text className="name" x="0" y="48" textAnchor="middle">
                {name}
              </text>
            </>
          )}
        </g>
      ) : name === 'LFPGARS' ? (
        <g>
          <rect className="nameArea" x="-35" y="18.5" rx="5" ry="5" width="70" height="18" />
          <text className="name" x="0" y="32" textAnchor="middle">
            {name}
          </text>
        </g>
      ) : (
        <g>
          <rect className="nameArea" x="-35" y="-36.5" rx="5" ry="5" width="70" height="18" />
          <text className="name" x="0" y="-23" textAnchor="middle">
            {name}
          </text>
        </g>
      )}
      <g>
        <circle className="beaconCircle" cx="0" cy="0" r="17" />
        <text className="cadency" textAnchor="middle" dominantBaseline="central" x="0" y="0">
          {cadency ? `${cadency}s` : '--'}
        </text>
      </g>
    </g>
  );
}

export default BeaconAirport;
