import {QueryEntity} from '@datorama/akita';
import {MilitaryAreaState, MilitaryAreaStore, militaryAreaStore} from './military-area.store';

export class MilitaryAreaQuery extends QueryEntity<MilitaryAreaState> {
  constructor(protected store: MilitaryAreaStore) {
    super(store);
  }

  selectMilitaryAreas() {
    return this.selectAll();
  }

  getMilitaryAreas() {
    return this.getAll();
  }
}

export const militaryAreaQuery = new MilitaryAreaQuery(militaryAreaStore);
