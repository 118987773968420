import React, {useEffect, useRef, useState} from 'react';
import {IIntervalsBarCharts, Margins, MilitaryArea} from './IntervalsBarChart.type';
import * as d3 from 'd3';

import './graphs-styles.css';

import './graphs-styles.css';
import {
  adjustHeightBasedOnMaxLoad,
  appendXTicks,
  appendYTicks,
  computeAreaStartAndEndTime,
  computeStartingBarWidth,
  createAbscissaElement,
  createCapacityLines,
  createOrdinateElement,
  createSvg,
  createTooltip,
  createXAxis,
  createYAxis,
  drawMilitaryAreas,
  generateScales,
  getActiveAreas,
  injectDataAndBuildBars,
  removeFirstXTick,
} from './utils';

import {Capacity} from '../../../types/capacity';

const styles = {
  display: 'grid',
  justifyItems: 'center',
};

const WIDTH = 320;
const BAR_WIDTH = 30;
const SPACING = 1;
const MARGINS: Margins = {
  top: 20,
  right: 20,
  bottom: 20,
  left: 20,
};

function IntervalsBarChart({
  beaconSelected,
  loadWithCapacities,
  minutesLeft,
  max,
  militaryAreas,
}: IIntervalsBarCharts) {
  const adjustedHeight = useRef(0);
  const startingBarWidth = useRef(0);
  const scales = useRef(null);
  const xAxis = useRef(null);
  const yAxis = useRef(null);
  const chartRef = useRef(null);

  function initChart(data: Capacity[], defaultMax: number[], minLeft: number) {
    startingBarWidth.current = computeStartingBarWidth(minLeft);
    adjustedHeight.current = adjustHeightBasedOnMaxLoad(data, defaultMax);
    scales.current = generateScales(MARGINS, WIDTH, adjustedHeight.current, data, defaultMax);
    xAxis.current = createXAxis(data, scales.current.xScale);
    yAxis.current = createYAxis(data, scales.current.yScale);

    return createSvg(chartRef, WIDTH, adjustedHeight.current);
  }

  useEffect(() => {
    if (loadWithCapacities) {
      const svg = initChart(loadWithCapacities, max, minutesLeft);
      const tip = createTooltip();

      if (svg && tip) {
        injectDataAndBuildBars(
          svg,
          loadWithCapacities,
          adjustedHeight.current,
          MARGINS,
          SPACING,
          BAR_WIDTH,
          startingBarWidth.current,
          scales.current.yScale,
          tip,
        );
        createCapacityLines(
          svg,
          loadWithCapacities,
          MARGINS,
          startingBarWidth.current,
          SPACING,
          BAR_WIDTH,
          scales.current.yScale,
        );
        createOrdinateElement(svg, MARGINS, adjustedHeight.current, yAxis.current);
        svg.call(tip);
        createAbscissaElement(
          svg,
          xAxis.current,
          minutesLeft,
          BAR_WIDTH,
          WIDTH,
          SPACING,
          adjustedHeight.current,
        );
        appendXTicks(
          svg,
          xAxis.current,
          minutesLeft,
          BAR_WIDTH,
          startingBarWidth.current,
          SPACING,
          adjustedHeight.current,
        );
        appendYTicks(svg, yAxis.current, MARGINS);
        removeFirstXTick(svg);

        if (militaryAreas?.length) {
          let activeAreas: MilitaryArea[] = getActiveAreas(militaryAreas);

          if (activeAreas) {
            activeAreas = computeAreaStartAndEndTime(activeAreas);
            drawMilitaryAreas(
              activeAreas,
              svg,
              startingBarWidth.current,
              SPACING,
              BAR_WIDTH,
              MARGINS,
              adjustedHeight.current,
              loadWithCapacities,
            );
          }
        }
      }
    }

    return () => {
      while (chartRef.current?.hasChildNodes()) {
        chartRef.current.removeChild(chartRef.current.lastChild);
      }
      d3.selectAll('.d3-tip').remove();
      d3.selectAll('.d3-tip-big').remove();
    };
  }, [loadWithCapacities, max, minutesLeft, militaryAreas]);

  return <div ref={chartRef} style={styles}></div>;
}

export default IntervalsBarChart;
