import {QueryEntity} from '@datorama/akita';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {airportService} from '../airport/airport.service';
import {BeaconState, BeaconStore, beaconStore} from './beacon.store';

export class BeaconQuery extends QueryEntity<BeaconState> {
  constructor(protected store: BeaconStore) {
    super(store);
  }

  selectHovered() {
    return this.select('hovered');
  }

  selectSelected() {
    return this.select('selected');
  }

  selectAllBeacons() {
    return this.selectLoading().pipe(
      switchMap((loading) => {
        return loading ? of([]) : this.selectAll();
      }),
    );
  }

  selectBeaconsGeoForAirport(airport: string) {
    return this.selectLoading().pipe(
      switchMap((loading) => {
        return loading
          ? of([])
          : this.selectAll({
              filterBy: (entity) => entity.typeBeacon === 'geo' && entity.forAirport === airport,
            });
      }),
    );
  }

  selectBeaconsMfForAirport(airport: string) {
    return this.selectLoading().pipe(
      switchMap((loading) => {
        return loading
          ? of([])
          : this.selectAll({
              filterBy: (entity) => entity.typeBeacon === 'mf' && entity.forAirport === airport,
            });
      }),
    );
  }

  selectBeaconsCopForAirport(airport: string) {
    return this.selectAll({
      filterBy: (entity) => entity.typeBeacon === 'cop' && entity.forAirport === airport,
    });
  }

  selectBeaconsIafForAirport(airport: string) {
    return this.selectLoading().pipe(
      switchMap((loading) => {
        return loading
          ? of([])
          : this.selectAll({
              filterBy: (entity) => entity.typeBeacon === 'iaf' && entity.forAirport === airport,
            });
      }),
    );
  }

  selectBeaconsAirportForAirport(airport: string) {
    return this.selectLoading().pipe(
      switchMap((loading) => {
        return loading
          ? of([])
          : this.selectAll({
              filterBy: (entity) =>
                entity.typeBeacon === 'airport' && entity.forAirport === airport,
            });
      }),
    );
  }

  selectBeaconsOtherAirportForAirport(airport: string) {
    return this.selectLoading().pipe(
      switchMap((loading) => {
        return loading
          ? of([])
          : this.selectAll({
              filterBy: (entity) =>
                entity.typeBeacon === 'otherAirport' && entity.forAirport === airport,
            });
      }),
    );
  }

  selectBeaconsLimForAirport(airport: string) {
    return this.selectLoading().pipe(
      switchMap((loading) => {
        return loading
          ? of([])
          : this.selectAll({
              filterBy: (entity) => entity.typeBeacon === 'lim' && entity.forAirport === airport,
            });
      }),
    );
  }

  selectBeaconsRtgForAirport(airport: string) {
    return this.selectLoading().pipe(
      switchMap((loading) => {
        return loading
          ? of([])
          : this.selectAll({
              filterBy: (entity) => entity.typeBeacon === 'rtg' && entity.forAirport === airport,
            });
      }),
    );
  }

  selectHoveredBeacon() {
    return this.selectHovered().pipe(
      switchMap((hovered) =>
        airportService
          .selectIcaoAirport()
          .pipe(
            switchMap((airport) =>
              hovered && airport
                ? this.selectEntity(
                    (entity) => entity.name === hovered && entity.forAirport === airport,
                  )
                : of([]),
            ),
          ),
      ),
    );
  }

  selectSelectedBeacon() {
    return this.selectSelected().pipe(
      switchMap((selected) =>
        airportService
          .selectIcaoAirport()
          .pipe(
            switchMap((airport) =>
              selected && airport
                ? this.selectEntity(
                    (entity) => entity.name === selected && entity.forAirport === airport,
                  )
                : of([]),
            ),
          ),
      ),
    );
  }

  selectBeaconForAirport(beaconName: string, airport: 'LFPO' | 'LFPG') {
    return this.selectLoading().pipe(
      switchMap((loading) => {
        return loading
          ? of([])
          : this.selectEntity(
              (entity) => entity.name === beaconName && entity.forAirport === airport,
            );
      }),
    );
  }

  getIafForMfAndAirport(airport: 'LFPO' | 'LFPG', mf: string) {
    const correspondingBeacons = this.getAll({
      filterBy: (entity) =>
        entity.typeBeacon === 'mf' && entity.forAirport === airport && entity.name === mf,
    });
    if (correspondingBeacons && correspondingBeacons.length === 1) {
      return correspondingBeacons[0].iaf;
    } else {
      return null;
    }
  }

  getIafForAirport(airport: 'LFPO' | 'LFPG') {
    return this.getAll({
      filterBy: (entity) => entity.typeBeacon === 'iaf' && entity.forAirport === airport,
    }).map((entity) => entity.name);
  }

  getBeaconForAirport(beaconName: string, airport: 'LFPO' | 'LFPG') {
    const beacons = this.getAll({
      filterBy: (entity) => entity.name === beaconName && entity.forAirport === airport,
    });
    return beacons.length > 0 ? beacons[0] : null;
  }
}

export const beaconQuery = new BeaconQuery(beaconStore);
