import {webSocket} from '../../utils/serverWebSocket';
import {Socket} from 'socket.io-client';

import {regulationStore, RegulationStore} from './regulation.store';
import {Regulation} from './regulation.model';
import {regulationQuery} from './regulation.query';

export class RegulationService {
  private socket?: Socket;

  constructor(private regulationStore: RegulationStore) {}

  /**
   * Méthode permettant d'ouvrir la connexion avec le serveur via une webSocket et de s'abonner aux différents types de messages émis par celui ci
   */
  async start() {
    // On s'assure que le store est vide
    regulationStore.remove();

    // On s'abonne au WS
    this.socket = webSocket().getSocket();
    this.onMessageReceived = this.onMessageReceived.bind(this);
    this.socket.on('message', this.onMessageReceived);
  }

  /**
   * Méthode permettant se désabonner du serveur et de vider le store, lorsque celui ci n'est plus utilisé
   * La socket n'est pas fermée car elle peut être utilisée par un autre modèle
   */
  destroy(): void {
    // Déconnexion du serveur
    if (this.socket) {
      this.socket.off('message', this.onMessageReceived);
      this.socket = undefined;
    }

    // On vide le store
    regulationStore.remove();
  }

  /**
   * Listener pour les messages reçus du serveur
   * @param message message reçu
   */
  onMessageReceived(message: any) {
    const json = JSON.parse(message);

    if (json.typeMessage === 'nmDataRegulationsRvrUpdated') {
      this.updateRvrRegulations(
        json.data.map((regulation: any) => ({
          ...regulation,
          rvrs: regulation.rvrs.map((rvr: any) => ({
            ...rvr,
            start: new Date(rvr.start),
            end: new Date(rvr.end),
          })),
        })),
      );
    }
  }

  /**
   * Méthode permettant de mettre à jour les régulations ayant des RVR
   * @param regulations régulations à jour
   */
  updateRvrRegulations(regulations: Regulation[]) {
    // Suppression des régulations qui ne sont pas renvoyées par le serveur
    regulationStore.remove(
      (entity) =>
        !regulations.some((regulation) => regulation.regulationId === entity.regulationId),
    );

    // Mise à jour / Ajout des régulations reçues
    regulationStore.upsertMany(regulations);
  }

  /**
   * Méthode permettant de s'abonner à une régulation
   * @param regulationId Id de la régulation à laquelle on veut s'abonner
   */
  selectRegulation(regulationId: string) {
    return regulationQuery.selectRegulation(regulationId);
  }
}

export const regulationService = new RegulationService(regulationStore);
