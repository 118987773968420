import React, {useRef, useState, useEffect, useReducer} from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {makeStyles} from '@material-ui/core/styles';
import RtgGraphsCard from './cards/rtg-graphs-card.js';
import FlightListTEUJCard from './cards/flight-list-te-uj-card.js';

import {beaconService} from '../models/beacon/beacon.service.ts';
import {dateService} from '../models/date/date.service.ts';
import {flightService} from '../models/flight/flight.service.ts';
import {militaryAreaService} from '../models/military-area/military-area.service.ts';
import {capacityService} from '../models/capacity/capacity.service.ts';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawerContainer: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 48,
  },
  drawerTitle: {
    flexGrow: 1,
  },
  name: {
    textAlign: 'center',
    fontSize: '26px',
    fontWeight: 'bold',
  },
  scrollableContent: {
    height: 'calc(100% - 48px)',
    overflow: 'auto',
  },
}));

function RtgTEUJDrawer({name}) {
  const classes = useStyles();

  // Subscriptions
  const minutesLeftSubscription = useRef(null);

  const loadLFFTE3Subscription = useRef(null);
  const loadLFFUJSubscription = useRef(null);

  const beaconRTGTEUJSubscription = useRef(null);

  const capacityLFFTE3Subscription = useRef(null);
  const capacityLFFUJSubscription = useRef(null);

  const TEReroutableFlightsSubscription = useRef(null);
  const UJReroutableFlightsSubscription = useRef(null);

  const militaryAreasSubscription = useRef(null);

  // State
  const [minutesLeft, setMinutesLeft] = useState(null);

  const [loadLFFTE3, setLoadLFFTE3] = useState(null);
  const [loadLFFUJ, setLoadLFFUJ] = useState(null);

  const [beaconRTGTEUJ, setBeaconRTGTEUJ] = useReducer(
    (currentBeacon, beacon) =>
      beacon
        ? {
            ...beacon,
            defaultCapacity: beacon.defaultCapacity || 999,
            defaultDepartureCapacity: beacon.defaultDepartureCapacity || 999,
          }
        : null,
    null,
  );

  const [capacitiesLFFTE3, setCapacitiesLFFTE3] = useState(null);
  const [capacitiesLFFUJ, setCapacitiesLFFUJ] = useState(null);

  const [TEReroutableFlights, setTEReroutableFlights] = useState(null);
  const [UJReroutableFlights, setUJReroutableFlights] = useState(null);

  const [militaryAreas, setMilitaryAreas] = useState(null);

  // Au mount du composant
  useEffect(() => {
    minutesLeftSubscription.current = dateService.selectMinutesLeft().subscribe(setMinutesLeft);

    // Subscription aux load
    loadLFFTE3Subscription.current = flightService
      .selectLoadForTv('LFFTE3')
      .subscribe((load) => setLoadLFFTE3(load.loadArray));
    loadLFFUJSubscription.current = flightService
      .selectLoadForTv('LFFUJ')
      .subscribe((load) => setLoadLFFUJ(load.loadArray));

    // Subscription au beacon (pour les capacités par défaut)
    beaconRTGTEUJSubscription.current = beaconService
      .selectBeaconForAirport('RTG TE-UJ', 'LFPG')
      .subscribe(setBeaconRTGTEUJ);

    // subscription aux capacités
    capacityLFFTE3Subscription.current = capacityService
      .selectCapacityForTV('LFFTE3')
      .subscribe((capacities) =>
        capacities && capacities.capacities
          ? setCapacitiesLFFTE3(capacities.capacities)
          : setCapacitiesLFFTE3(null),
      );
    capacityLFFUJSubscription.current = capacityService
      .selectCapacityForTV('LFFUJ')
      .subscribe((capacities) =>
        capacities && capacities.capacities
          ? setCapacitiesLFFUJ(capacities.capacities)
          : setCapacitiesLFFUJ(null),
      );

    // Subscriptions aux listes de vols
    TEReroutableFlightsSubscription.current = flightService
      .selectTEReroutableFlights()
      .subscribe(setTEReroutableFlights);
    UJReroutableFlightsSubscription.current = flightService
      .selectUJReroutableFlights()
      .subscribe(setUJReroutableFlights);

    // Subscription aux zones militaires
    militaryAreasSubscription.current = militaryAreaService
      .selectMilitaryAreas()
      .subscribe(setMilitaryAreas);

    // Au unmount du composant
    return () => {
      if (minutesLeftSubscription.current) {
        minutesLeftSubscription.current.unsubscribe();
      }
      if (loadLFFTE3Subscription.current) {
        loadLFFTE3Subscription.current.unsubscribe();
      }
      if (loadLFFUJSubscription.current) {
        loadLFFUJSubscription.current.unsubscribe();
      }
      if (beaconRTGTEUJSubscription.current) {
        beaconRTGTEUJSubscription.current.unsubscribe();
      }
      if (capacityLFFTE3Subscription.current) {
        capacityLFFTE3Subscription.current.unsubscribe();
      }
      if (capacityLFFUJSubscription.current) {
        capacityLFFUJSubscription.current.unsubscribe();
      }
      if (TEReroutableFlightsSubscription.current) {
        TEReroutableFlightsSubscription.current.unsubscribe();
      }
      if (UJReroutableFlightsSubscription.current) {
        UJReroutableFlightsSubscription.current.unsubscribe();
      }
      if (militaryAreasSubscription.current) {
        militaryAreasSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Event handlers
  const handleClick = () => {
    beaconService.updateSelectedBeacon('');
  };

  return (
    <div className={classes.drawerContainer}>
      <div className={classes.drawerHeader}>
        <div className={classes.drawerTitle}>
          <Typography className={classes.name}>{name}</Typography>
        </div>
        <IconButton onClick={handleClick}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <div className={classes.scrollableContent}>
        <RtgGraphsCard
          beaconSelected={name}
          loadName1="LFFTE3"
          load1={loadLFFTE3}
          defaultCapacity1={beaconRTGTEUJ ? beaconRTGTEUJ.defaultCapacity : null}
          capacities1={capacitiesLFFTE3}
          loadName2="LFFUJ"
          load2={loadLFFUJ}
          defaultCapacity2={beaconRTGTEUJ ? beaconRTGTEUJ.defaultCapacity : null}
          capacities2={capacitiesLFFUJ}
          minutesLeft={minutesLeft}
          militaryAreas2={militaryAreas}
        />
        <FlightListTEUJCard name="TE &rarr; UJ" flights={TEReroutableFlights} />
        <FlightListTEUJCard name="UJ &rarr; TE" flights={UJReroutableFlights} />
      </div>
    </div>
  );
}

export default RtgTEUJDrawer;
