import {map} from 'rxjs/operators';
import {QueryEntity} from '@datorama/akita';
import {CapacityState, CapacityStore, capacityStore} from './capacity.store';

export class CapacityQuery extends QueryEntity<CapacityState> {
  constructor(protected store: CapacityStore) {
    super(store);
  }

  selectCapacityForTV(tv: string) {
    // return this.selectEntity(entity => entity.tv === tv);
    return this.selectAll({filterBy: (entity) => entity.tv === tv}).pipe(
      map((capacities) => (capacities.length > 0 ? capacities[0] : null)),
    );
  }

  getCapacityForTV(tv: string) {
    return this.getEntity(tv);
  }
}

export const capacityQuery = new CapacityQuery(capacityStore);
