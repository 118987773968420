import * as d3 from 'd3';
import {tip as d3Tip} from 'd3-v6-tip';
import {Capacity} from '../../../types/capacity';
import {Margins, MilitaryArea, MilitaryChartProperties} from './IntervalsBarChart.type';

export function adjustHeightBasedOnMaxLoad(data: Capacity[], defaultMax: number[]) {
  const maxValue: number = parseInt(d3.max(data.map((el: any) => el.load)));
  return maxValue > defaultMax[0] ? 170 + maxValue * 1.5 - defaultMax[0] : 170;
}

export function computeStartingBarWidth(minLeft: number) {
  return (minLeft * 3) / 2;
}

function createXScale(margins: Margins, width: number) {
  const range: number[] = [margins.left, width - margins.right];
  return d3.scaleLinear().domain([0, 9]).range(range);
}

function computeDomain(data: Capacity[], defaultMax: number[]) {
  const maxValue: number = parseInt(d3.max(data.map((el: any) => el.load)));
  return maxValue > defaultMax[0] ? d3.max(data.map((el: any) => el.load)) + 2 : defaultMax[1];
}

function createYScale(margins: Margins, height: number, data: Capacity[], defaultMax: number[]) {
  const range: number[] = [height - margins.bottom, 0];
  const domain: number[] = [0, computeDomain(data, defaultMax)];
  return d3.scaleLinear().domain(domain).range(range);
}

export function generateScales(
  margins: Margins,
  width: number,
  height: number,
  data: Capacity[],
  defaultMax: number[],
) {
  return {
    xScale: createXScale(margins, width),
    yScale: createYScale(margins, height, data, defaultMax),
  };
}

export function createXAxis(data: Capacity[], xScale: any) {
  return d3.axisBottom(xScale).tickFormat((d, i) =>
    data[i]?.from?.getUTCMinutes() === 0
      ? data[i].from.toLocaleTimeString('utc', {
          hour12: false,
          timeZone: 'UTC',
          timeStyle: 'short',
        })
      : '',
  );
}

export function createYAxis(data: Capacity[], yScale: any) {
  return (
    d3
      // @ts-ignore
      .axisLeft()
      .scale(yScale)
      .tickValues(
        data
          ? data
              .map((elem) => Math.floor(elem.capacity / 3))
              .filter((elem, index, array) => array.indexOf(elem) === index)
          : null,
      )
      .tickSizeOuter(0)
  );
}

export function createSvg(ref, width: number, height: number) {
  return d3
    .select(ref.current)
    .append('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('class', 'bar')
    .attr('marginLeft', 5);
}

export function createTooltip() {
  return d3Tip()
    .attr('class', 'd3-tip')
    .offset([-10, 0])
    .html(function (event, d, i) {
      // Description du tooltip
      let text = '';
      const vols = d && d.load > 1 ? 'vols' : 'vol';
      const nbVols =
        d && d.load
          ? `<style>.nbVols{justify-content:center; display:flex;}</style><div class='nbVols'>${d.load} ${vols}</div>`
          : '';

      if (d && d.flights) {
        d.flights.forEach((flight) => {
          text += `<div class='nbVols'>${flight.callsign}</div>`;
        });
      }
      return `${nbVols}<hr>${text}`;
    });
}

export function injectDataAndBuildBars(
  svg: any,
  data: Capacity[],
  height: number,
  margins: Margins,
  spacing: number,
  barWidth: number,
  startingBarWidth: number,
  yScale: any,
  tip: any,
) {
  return (
    svg
      .selectAll('bars')
      .data(data)
      .enter()
      .append('rect')
      .attr('fill', function (d) {
        // Couleur des barres
        if (d.load > Math.floor(1.2 * Math.floor(d.capacity / 3))) {
          return '#CA5256';
        } else if (d.load <= Math.floor(d.capacity / 3)) {
          return '#406279';
        }
        return '#D9A62F';
      })
      .attr('class', 'sBar')
      .attr('x', function (d, i) {
        // Valeure x des barres
        if (i === 0) {
          return margins.left;
        }
        return margins.left + startingBarWidth + spacing * i + barWidth * (i - 1);
      })
      .attr('width', function (d, i) {
        // Largeur des barres
        if (i === 0) {
          return startingBarWidth;
        }
        if (i === data.length - 1) {
          return barWidth - startingBarWidth;
        }
        return barWidth;
      })
      .attr('y', yScale(0))
      .on('mouseenter', (...params) => tip.show(...params))
      .on('mousemove', tip.show)
      .on('mouseout', tip.hide)
      // .conditionalTransition(changeType !== 'timeChange', 1000, (function (d, i) { // Si on est sur un changement de balise cliquée, on a une animation
      //     return i * 100;
      // }))
      .attr('y', (value, i) => (value ? yScale(value.load) : 0))
      .attr('height', (value) => {
        return value ? height - yScale(value.load) - margins.bottom : 0;
      })
  );
}

export function createCapacityLines(
  svg: any,
  data: Capacity[],
  margins: Margins,
  startingBarWidth: number,
  spacing: number,
  barWidth: number,
  yScale: any,
) {
  return svg
    .selectAll('lines')
    .data(data)
    .enter()
    .append('line')
    .attr('class', 'capacityLine')
    .attr('x1', function (d, i) {
      // X du départ de la ligne de capacité
      return margins.left + startingBarWidth + spacing * i + barWidth * (i - 1);
    })
    .attr('y1', function (d, i) {
      // Y du départ de la ligne de capacité
      return yScale(Math.floor(d.capacity / 3));
    })
    .attr('x2', function (d, i, array) {
      // X de la fin de la ligne de capacité
      return i < array.length - 1
        ? margins.left + startingBarWidth + spacing * i + barWidth * i
        : margins.left + spacing * i + barWidth * i;
    })
    .attr('y2', function (d, i) {
      // Y de la fin de la ligne de capacité
      return yScale(Math.floor(d.capacity / 3));
    });
}

export function createOrdinateElement(svg: any, margins: Margins, height: number, yAxis: any) {
  return svg
    .append('rect')
    .attr('x', 0)
    .attr('y', 0)
    .attr('width', margins.left)
    .attr('height', height - margins.bottom)
    .style('fill', 'grey');
}

export function createAbscissaElement(
  svg: any,
  xAxis: any,
  minLeft: any,
  barWidth: number,
  width: number,
  spacing: number,
  height: number,
) {
  return svg
    .append('line')
    .attr('x1', 0)
    .attr('x2', 300)
    .attr('y1', height - 20)
    .attr('y2', height - 20)
    .style('stroke', 'black')
    .style('strokeWidth', '1');
}

export function appendXTicks(
  svg: any,
  xAxis: any,
  minutesLeft: number,
  barWidth: number,
  startingBarWidth: number,
  spacing: number,
  height: number,
) {
  return svg
    .append('g')
    .call(xAxis)
    .attr(
      'transform',
      `translate(${minutesLeft !== 0 ? -(barWidth - startingBarWidth + spacing) : 0},${
        height - 20
      })`,
    )
    .attr('class', 'axisX');
}

export function appendYTicks(svg: any, yAxis: any, margins: Margins) {
  return svg
    .append('g')
    .call(yAxis)
    .attr('class', 'axisWhite')
    .attr('transform', `translate(${margins.right},0)`);
}

export function removeFirstXTick(svg: any) {
  return svg.selectAll('.tick').each(function (d) {
    if (d === 0) {
      this.remove();
    }
  });
}

// HANDLE MILITARY ZONES

export function getActiveAreas(areasList: MilitaryArea[]) {
  return areasList.filter((area: MilitaryArea) => area.isActive);
}

export function computeAreaStartAndEndTime(areas: MilitaryArea[]): MilitaryArea[] {
  const result: MilitaryArea[] = areas.map((area: MilitaryArea) => {
    // Recalcul start time
    const newStartTime: Date = new Date(area.start.getTime() - (area.start.getTime() % 1200000));

    const newEndTime: Date =
      area.end.getTime() % 1200000 === 0
        ? new Date(area.end.getTime())
        : new Date(area.end.getTime() + 1200000 - (area.end.getTime() % 1200000));

    return {
      ...area,
      start: newStartTime,
      end: newEndTime,
    };
  });

  return result;
}

export function drawMilitaryAreas(
  areas: MilitaryArea[],
  svg: any,
  startingBarWidth: number,
  spacing: number,
  barWidth: number,
  margins: Margins,
  adjustedHeight: number,
  data: Capacity[],
) {
  const positionsAndDimensionsList: MilitaryChartProperties[] | null =
    computeAreaPositionAndDimensions(
      areas,
      data,
      margins,
      startingBarWidth,
      spacing,
      barWidth,
      adjustedHeight,
    );

  if (positionsAndDimensionsList?.length > 0) {
    return createMilitaryAreasSVGs(positionsAndDimensionsList, svg);
  }
  return;
}

function computeAreaPositionAndDimensions(
  areas: MilitaryArea[],
  data: Capacity[],
  margins: Margins,
  startingBarWidth: number,
  spacing: number,
  barWidth: number,
  adjustedHeight: number,
) {
  return areas
    .map((area: MilitaryArea) => {
      const startIndex: number = data.findIndex(
        (data: Capacity) => data.from.getTime() === area.start.getTime(),
      );
      const endIndex: number = data.findIndex(
        (data: Capacity) => data.to.getTime() === area.end.getTime(),
      );
      let x: number, y: number, width: number, height: number;

      if (startIndex === -1 && endIndex === -1) return null;

      if (startIndex > -1 && endIndex > -1) {
        x =
          startIndex === 0
            ? margins.left
            : margins.left + startingBarWidth + spacing * startIndex + barWidth * (startIndex - 1);
        y = 1;

        if (startIndex === 0 && endIndex === 0) {
          width = startingBarWidth;
        } else if (startIndex === 0 && endIndex === data.length - 1) {
          width = startingBarWidth + endIndex * (barWidth + spacing) - startingBarWidth;
        } else if (startIndex === 0) {
          width = startingBarWidth + spacing + (endIndex - startIndex) * (barWidth + spacing);
        } else if (endIndex === data.length - 1) {
          width = (endIndex - startIndex + 1) * (barWidth + spacing) - startingBarWidth;
        } else {
          width = (endIndex - startIndex + 1) * (barWidth + spacing);
        }
        height = adjustedHeight - 1 - margins.bottom;
      } else if (startIndex === -1 && endIndex > -1) {
        x = margins.left;
        y = 1;

        if (endIndex === 0) {
          width = startingBarWidth;
        } else if (endIndex === data.length - 1) {
          width = startingBarWidth + endIndex * (barWidth + spacing) - startingBarWidth;
        } else {
          width = startingBarWidth + spacing + endIndex * (barWidth + spacing);
        }
        height = adjustedHeight - 1 - margins.bottom;
      } else if (startIndex > -1 && endIndex === -1) {
        x =
          startIndex === 0
            ? margins.left
            : margins.left + startingBarWidth + spacing * startIndex + barWidth * (startIndex - 1);
        y = 1;
        width =
          startIndex === 0
            ? spacing + (data.length - 1 - startIndex) * (barWidth + spacing)
            : (data.length - startIndex) * (barWidth + spacing) - startingBarWidth;
        height = adjustedHeight - 1 - margins.bottom;
      }

      return {
        name: area.name,
        x,
        y,
        width,
        height,
        lowerLimit: area.lowerLimit,
        upperLimit: area.upperLimit,
      };
    })
    .filter((el) => el !== null);
}

function createMilitaryAreasSVGs(areas: MilitaryChartProperties[], svg: any) {
  if (areas) {
    return areas.forEach((area: MilitaryChartProperties) => {
      svg
        .append('rect')
        .attr('stroke-width', '1')
        .attr('stroke', '#dbb14c')
        .attr('fill', 'none')
        .attr('x', area.x)
        .attr('y', area.y)
        .attr('width', area.width)
        .attr('height', area.height);
      // tag
      svg
        .append('rect')
        .attr('fill', '#dbb14c')
        .attr('stroke', 'none')
        .attr('rx', 5)
        .attr('ry', 5)
        .attr('x', area.x + area.width / 2 - 25)
        .attr('y', area.y + 3)
        .attr('width', 50)
        .attr('height', 15);

      // Area name
      const militaryAreaName = svg
        .append('text')
        .attr('style', 'fill: white; font-size: 12px; font-weight: bold;')
        .text(area.name.substring(2))
        .attr('x', area.x + area.width / 2)
        .attr('y', area.y + 15)
        .attr('text-anchor', 'middle');

      militaryAreaName.append('title').text(`${area.lowerLimit} - ${area.upperLimit}`);
    });
  }
  return;
}
