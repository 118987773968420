import React, {useEffect, useState, useRef} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Popover from '@material-ui/core/Popover';
import SvgIcon from '@material-ui/core/SvgIcon';

import {ReactComponent as SvgLayers} from '../svg-icons/layers.svg';

import {displayService} from '../models/display/display.service.ts';

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'flex',
    padding: '20px',
  },
  typographyFirst: {
    color: '#5b637b',
    fontSize: '20px',
    fontWeight: 'bold',
    paddingBottom: '10px',
  },
  typography: {
    color: '#5b637b',
    fontSize: '20px',
    fontWeight: 'bold',
    paddingBottom: '10px',
    paddingTop: '5px',
  },
  checks: {
    color: '#5b637b',
  },
  divider: {
    width: 200,
  },
}));

function LayersMenu(props) {
  const classes = useStyles();

  // On crée un state pour chaque valeur des checkboxes
  const [geoDisplayed, setGeoDisplayed] = useState(false);
  const [otherAirportDisplayed, setOtherAirportDisplayed] = useState(false);
  const [iafDisplayed, setIafDisplayed] = useState(false);
  const [mfDisplayed, setMfDisplayed] = useState(false);
  const [lfbbDisplayed, setLfbbDisplayed] = useState(false);
  const [egaDisplayed, setEgaDisplayed] = useState(false);
  const [uirDisplayed, setUirDisplayed] = useState(false);
  const [nwDisplayed, setNwDisplayed] = useState(false);
  const [muacKuacDisplayed, setMuacKuacDisplayed] = useState(false);
  const [seDisplayed, setSeDisplayed] = useState(false);
  const [distanceMarksDisplayed, setDistanceMarksDisplayed] = useState(false);

  // State indiquant si le menu est ouvert
  const [menuOpen, setMenuOpen] = useState(false);

  // Mémorisation des subscription
  const beaconGeoSubscription = useRef(null);
  const beaconOtherAirportSubscription = useRef(null);
  const beaconIafSubscription = useRef(null);
  const beaconMfSubscription = useRef(null);
  const sectorLfbbSubscription = useRef(null);
  const sectorEgaSubscription = useRef(null);
  const sectorUirSubscription = useRef(null);
  const sectorSectoNwSubscription = useRef(null);
  const sectorMuacKuacSubscription = useRef(null);
  const sectorSectoSeSubscription = useRef(null);
  const distanceMarksDisplayedSubscription = useRef(null);

  // Ref du bouton d'ouverture du menu
  const button = useRef(null);

  useEffect(() => {
    // Abonnement au model au 'mount' du composant
    beaconGeoSubscription.current = displayService
      .areBeaconsGeoDisplayed()
      .subscribe(setGeoDisplayed);
    beaconOtherAirportSubscription.current = displayService
      .areBeaconsOtherAirportDisplayed()
      .subscribe(setOtherAirportDisplayed);
    beaconIafSubscription.current = displayService
      .areBeaconsIafDisplayed()
      .subscribe(setIafDisplayed);
    beaconMfSubscription.current = displayService.areBeaconsMfDisplayed().subscribe(setMfDisplayed);
    sectorLfbbSubscription.current = displayService
      .areSectorLfbbDisplayed()
      .subscribe(setLfbbDisplayed);
    sectorEgaSubscription.current = displayService
      .areSectorEgaDisplayed()
      .subscribe(setEgaDisplayed);
    sectorUirSubscription.current = displayService
      .areSectorUirDisplayed()
      .subscribe(setUirDisplayed);
    sectorSectoNwSubscription.current = displayService
      .areSectorSectoNwDisplayed()
      .subscribe(setNwDisplayed);
    sectorMuacKuacSubscription.current = displayService
      .areSectorMuacKuacDisplayed()
      .subscribe(setMuacKuacDisplayed);
    sectorSectoSeSubscription.current = displayService
      .areSectorSectoSeDisplayed()
      .subscribe(setSeDisplayed);
    distanceMarksDisplayedSubscription.current = displayService
      .areDistanceMarksDisplayed()
      .subscribe(setDistanceMarksDisplayed);

    return () => {
      // Desabonnement au model au 'unmount' du composant
      if (beaconGeoSubscription.current) {
        beaconGeoSubscription.current.unsubscribe();
      }
      if (beaconOtherAirportSubscription.current) {
        beaconOtherAirportSubscription.current.unsubscribe();
      }
      if (beaconIafSubscription.current) {
        beaconIafSubscription.current.unsubscribe();
      }
      if (beaconMfSubscription.current) {
        beaconMfSubscription.current.unsubscribe();
      }
      if (sectorLfbbSubscription.current) {
        sectorLfbbSubscription.current.unsubscribe();
      }
      if (sectorEgaSubscription.current) {
        sectorEgaSubscription.current.unsubscribe();
      }
      if (sectorUirSubscription.current) {
        sectorUirSubscription.current.unsubscribe();
      }
      if (sectorSectoNwSubscription.current) {
        sectorSectoNwSubscription.current.unsubscribe();
      }
      if (sectorMuacKuacSubscription.current) {
        sectorMuacKuacSubscription.current.unsubscribe();
      }
      if (sectorSectoSeSubscription.current) {
        sectorSectoSeSubscription.current.unsubscribe();
      }
      if (distanceMarksDisplayedSubscription.current) {
        distanceMarksDisplayedSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Gère un clic sur la case des balises géo
  const handleClickGeo = () => {
    displayService.updateBeaconsGeoDisplayed(!geoDisplayed);
  };

  // Gère un clic sur la case des autres aéroports
  const handleClickOtherAirport = () => {
    displayService.updateBeaconsOtherAirportDisplayed(!otherAirportDisplayed);
  };

  //  Gère un clic sur la case des balises IAF
  const handleClickIaf = () => {
    displayService.updateBeaconsIafDisplayed(!iafDisplayed);
  };

  // Gère un clic sur la case des balises MF
  const handleClickMf = () => {
    displayService.updateBeaconsMfDisplayed(!mfDisplayed);
  };

  // Gère un clic sur la case du secteur LFBB
  const handleClickLfbb = () => {
    displayService.updateSectorLfbbDisplayed(!lfbbDisplayed);
  };

  // Gère un clic sur la case des balises EGA
  const handleClickEga = () => {
    displayService.updateSectorEgaDisplayed(!egaDisplayed);
  };

  // Gère un clic sur la case des balises UIR
  const handleClickUir = () => {
    displayService.updateSectorUirDisplayed(!uirDisplayed);
  };

  // Gère un clic sur la case des secto NW
  const handleClickSectoNw = () => {
    displayService.updateSectorSectoNwDisplayed(!nwDisplayed);
  };

  // Gère un clic sur la case des secteurs MUAC KUAC
  const handleClicMuacKuac = () => {
    displayService.updateSectorMuacKuacDisplayed(!muacKuacDisplayed);
  };

  // Gère un clic sur la case des secteurs SE
  const handleClickSectoSe = () => {
    displayService.updateSectorSectoSeDisplayed(!seDisplayed);
  };

  // Gère un clic sur la case des marqueurs de distance
  const handleClickDistanceMarks = () => {
    displayService.updateDistanceMarksDisplayed(!distanceMarksDisplayed);
  };

  // Ouverture du menu
  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  // Fermeture du menu
  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <Fab aria-label="layers" className={props.className} onClick={handleMenuOpen} ref={button}>
        <SvgIcon component={SvgLayers} viewBox="10 10 25 25" />
      </Fab>

      <Popover
        open={menuOpen}
        anchorEl={button.current}
        onClose={handleMenuClose}
        anchorOrigin={{vertical: -10, horizontal: 'left'}}
        transformOrigin={{vertical: 'bottom', horizontal: 'left'}}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <Typography className={classes.typographyFirst}>Points</Typography>

            <Divider className={classes.divider} />

            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checks}
                  color="default"
                  checked={geoDisplayed}
                  onClick={handleClickGeo}
                  name="geo"
                />
              }
              label="Points d'intérêts"
            />
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checks}
                  color="default"
                  checked={otherAirportDisplayed}
                  onClick={handleClickOtherAirport}
                  name="otherAirport"
                />
              }
              label="Aéroports"
            />
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checks}
                  color="default"
                  checked={iafDisplayed}
                  onClick={handleClickIaf}
                  name="iaf"
                />
              }
              label="IAF"
            />
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checks}
                  color="default"
                  checked={mfDisplayed}
                  onClick={handleClickMf}
                  name="mf"
                />
              }
              label="MF"
            />

            <Typography className={classes.typography}>Secteurs</Typography>

            <Divider className={classes.divider} />

            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checks}
                  color="default"
                  checked={lfbbDisplayed}
                  onClick={handleClickLfbb}
                  name="lfbb"
                />
              }
              label="LFBB-LFRR"
            />
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checks}
                  color="default"
                  checked={egaDisplayed}
                  onClick={handleClickEga}
                  name="ega"
                />
              }
              label="EGA"
            />
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checks}
                  color="default"
                  checked={uirDisplayed}
                  onClick={handleClickUir}
                  name="uir"
                />
              }
              label="UIR"
            />
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checks}
                  color="default"
                  checked={nwDisplayed}
                  onClick={handleClickSectoNw}
                  name="sectoNw"
                />
              }
              label="Secto NW"
            />
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checks}
                  color="default"
                  checked={muacKuacDisplayed}
                  onClick={handleClicMuacKuac}
                  name="muacKuac"
                />
              }
              label="MUAC-KUAK"
            />
            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checks}
                  color="default"
                  checked={seDisplayed}
                  onClick={handleClickSectoSe}
                  name="sectoSe"
                />
              }
              label="Secto SE"
            />

            <Typography className={classes.typography}>Distances</Typography>

            <Divider className={classes.divider} />

            <FormControlLabel
              control={
                <Checkbox
                  className={classes.checks}
                  color="default"
                  checked={distanceMarksDisplayed}
                  onClick={handleClickDistanceMarks}
                  name="distanceMarks"
                />
              }
              label="Marqueurs distance"
            />
          </FormGroup>
        </FormControl>
      </Popover>
    </>
  );
}

export default LayersMenu;
