import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {useStyles} from './SpeedSection.style';

const SpeedSection = ({
  // mach,
  // machRange,
  tocMach,
  tocMinMach,
  tocMaxMach,
  xmanMach,
  xmanMinMach,
  xmanMaxMach,
  todMach,
  todIas,
}) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center">
      <Grid item>
        <Typography className={classes.subtitle}>Vitesses</Typography>
      </Grid>

      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Grid item>
          <Typography className={classes.flightParameter}>
            Vitesse initiale de croisière :{' '}
          </Typography>
        </Grid>
        <Grid item>
          {tocMach && tocMinMach && tocMaxMach ? (
            <Typography
              className={
                classes.flightData
              }>{`.${tocMach} [${tocMinMach} - ${tocMaxMach}]`}</Typography>
          ) : (
            <Typography className={classes.flightData}>&nbsp;</Typography>
          )}
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Grid item>
          <Typography className={classes.flightParameter}>
            Vitesse finale de croisière :{' '}
          </Typography>
        </Grid>
        <Grid item>
          {xmanMach && xmanMinMach && xmanMaxMach ? (
            <Typography
              className={
                classes.flightData
              }>{`.${xmanMach} [${xmanMinMach} - ${xmanMaxMach}]`}</Typography>
          ) : (
            <Typography className={classes.flightData}>&nbsp;</Typography>
          )}
        </Grid>
      </Grid>

      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Grid item>
          <Typography className={classes.flightParameter}>Vitesse en descente : </Typography>
        </Grid>
        <Grid item>
          {todMach && todIas ? (
            <Typography className={classes.flightData}>{`.${tocMach} / ${todIas}kts`}</Typography>
          ) : (
            <Typography className={classes.flightData}>&nbsp;</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SpeedSection;
