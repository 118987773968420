import React from 'react';
import * as d3 from 'd3';
import {tip as d3Tip} from 'd3-v6-tip';

import './graphs-styles.css';

class RepartitionGraph extends React.Component {
  // Dessin du graphe au démarrage
  componentDidMount() {
    this.chartRef = React.createRef();
    this.drawChart();
  }

  // Fonction permettant de supprimer le graphe
  removePreviousChart() {
    while (this.chartRef.current.hasChildNodes()) {
      this.chartRef.current.removeChild(this.chartRef.current.lastChild);
    }

    d3.selectAll('.d3-tip-repartition').remove();
  }

  /**
   * Méthode qui dessine le graphique, prend en argument le type de changement: si le changement est un changement d'heure, le graphique est redessiné directement,
   * si on change de balise, il y a une animation sur les barres
   * @param {String} changeType
   */
  drawChart(changeType) {
    if (this.chartRef.current) {
      d3.selection.prototype.conditionalTransition = function (cond1, duration, delay) {
        return cond1 ? d3.transition().duration(duration).delay(delay).ease(d3.easeBounce) : this;
      };

      const repartition = this.props.loadWithCapacities
        ? this.props.loadWithCapacities.map((el) => el.flightRepartition)
        : null; // Repartition par intervalle, par iaf
      const capacities = this.props.loadWithCapacities
        ? this.props.loadWithCapacities.map((el) => el.capacity)
        : null;
      const iafs =
        this.props.loadWithCapacities &&
        this.props.loadWithCapacities[0] &&
        this.props.loadWithCapacities[0].flightRepartition
          ? Object.keys(this.props.loadWithCapacities[0].flightRepartition)
          : []; // IAFs pour l'aeroport sélectionné
      const tickLabels = this.props.loadWithCapacities
        ? this.props.loadWithCapacities.map((el) =>
            el.from.toLocaleTimeString('utc', {
              hour12: false,
              timeZone: 'UTC',
              timeStyle: 'short',
            }),
          )
        : null;
      const {max} = this.props;
      const h =
        this.props.loadWithCapacities &&
        d3.max(this.props.loadWithCapacities.map((el) => el.load)) > max[0]
          ? 170 + (d3.max(this.props.loadWithCapacities.map((el) => el.load)) - max[0])
          : 170;
      const barWidth = 30; // Largeur de barre
      const spacing = 1;
      const width0 = (this.props.minutesLeft * 3) / 2; // Largeur de la première barre
      const w = 320; // Width
      const margins = {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      };

      const dataset = repartition ? d3.stack().keys(iafs)(repartition) : null; // Récupère les données pour faire une stacked bar chart

      const xScale = d3
        .scaleLinear()
        .domain([0, 9])
        .range([margins.left, w - margins.right]);
      const xAxis = d3
        .axisBottom()
        .scale(xScale)
        .tickFormat((d, i) =>
          tickLabels && tickLabels[i] && tickLabels[i].endsWith(':00') ? tickLabels[i] : '',
        );

      const yScale = d3
        .scaleLinear()
        .domain([
          0,
          this.props.loadWithCapacities &&
          d3.max(this.props.loadWithCapacities.map((el) => el.load)) > max[0]
            ? d3.max(this.props.loadWithCapacities.map((el) => el.load)) + 2
            : max[1],
        ])
        .range([h - margins.bottom, 0]);
      const yAxis = d3
        .axisLeft()
        .scale(yScale)
        .tickValues(
          capacities
            ? capacities
                .filter((elem, index, array) => array.indexOf(elem) === index)
                .map((elem) => Math.floor(elem / 3))
            : null,
        )
        .tickSizeOuter(0);

      const colors = [
        'RGB(128,107,107)',
        'RGB(159,129,129)',
        'RGB(204,164,164)',
        'RGB(227,206,206)',
      ]; // Couleurs des IAF

      const tip = d3Tip()
        .attr('class', 'd3-tip-repartition')
        .offset([-10, 0])
        .html((event, d) => {
          const nbVols = d.data
            ? Object.values(d.data).reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0,
              )
            : null;
          const volsLabel = nbVols > 1 ? 'vols' : 'vol';
          const textTitle = `<style>.nbVols{justify-content:center; display:flex;}</style><div class="nbVols">${nbVols} ${volsLabel}</div><hr/>`;
          let text = '';

          const currentHoveredIaf = dataset.reduce(
            (accumulator, currentValue) =>
              d.index === currentValue.index ? currentValue.key : accumulator,
            null,
          );

          iafs.forEach((iaf, index) => {
            if (dataset && dataset[index] && dataset[index][d.index])
              text = `${iaf === currentHoveredIaf ? '<text style="color: #8cb8f2">' : ''}${iaf}: ${
                d.data[iaf]
              }${iaf === currentHoveredIaf ? '</text>' : ''}<br/>${text}`;
          });

          return textTitle + text;
        });

      const svg = d3
        .select(this.chartRef.current)
        .append('svg')
        .attr('width', w)
        .attr('height', h)
        .attr('class', 'bar')
        .attr('marginLeft', 5);

      if (dataset) {
        const groups = svg
          .selectAll('g.cost')
          .data(dataset)
          .enter()
          .append('g')
          .attr('class', 'cost')
          .style('fill', (d, i) => colors[i]);

        groups
          .selectAll('rect')
          .data((d, i) =>
            d.map((elem) => {
              elem.index = i;
              return elem;
            }),
          )
          .enter()
          .append('rect')
          .attr('class', 'sBar')
          .attr('x', (d, i) =>
            i === 0 ? margins.left : margins.left + width0 + spacing * i + barWidth * (i - 1),
          )
          .attr('width', (d, i) => {
            // Largeur des barres
            if (i === 0) {
              return width0;
            }
            if (i === 9) {
              return barWidth - width0;
            }
            return barWidth;
          })
          .attr('y', yScale(0))
          .on('mouseenter', tip.show)
          .on('mousemove', tip.show)
          .on('mouseout', tip.hide)
          .conditionalTransition(changeType !== 'timeChange', 1000, (d, i) => i * 100)
          .attr('y', (d) => (d ? yScale(d[1]) : 0))
          .attr('height', (d) => (d ? yScale(d[0]) - yScale(d[1]) : 0));
      }

      svg.call(tip);

      // Ajout des lignes affichant les valeurs de capacité
      if (capacities) {
        svg
          .selectAll('lines')
          .data(capacities)
          .enter()
          .append('line')
          .attr('class', 'capacityLine')
          .attr(
            'x1',
            (d, i) =>
              // X du départ de la ligne de capacité
              margins.left + width0 + spacing * i + barWidth * (i - 1),
          )
          .attr('y1', (d, i) =>
            // Y du départ de la ligne de capacité
            yScale(Math.floor(d / 3)),
          )
          .attr('x2', (d, i, array) =>
            // X de la fin de la ligne de capacité
            i < array.length - 1
              ? margins.left + width0 + spacing * i + barWidth * i
              : margins.left + spacing * i + barWidth * i,
          )
          .attr('y2', (d, i) =>
            // Y de la fin de la ligne de capacité
            yScale(Math.floor(d / 3)),
          );
      }

      // Ajout du rectangle tout à gauche du graphique
      svg
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', margins.left)
        .attr('height', h - margins.bottom)
        .style('fill', 'grey');

      // Ajout de l'axe des x que l'on décale pour faire décaler en fonction des minutes
      svg
        .append('g')
        .call(xAxis)
        .attr(
          'transform',
          `translate(${this.props.minutesLeft !== 0 ? -(barWidth - width0 + spacing) : 0},${
            h - 20
          })`,
        )
        .attr('class', 'axisX');

      // Ajout d'une ligne sur l'axe des abscisses
      svg
        .append('line')
        .attr('x1', 0)
        .attr('x2', 300)
        .attr('y1', h - 20)
        .attr('y2', h - 20)
        .style('stroke', 'black')
        .style('strokeWidth', '1');

      // Ajout de l'axe des ordonnées
      svg
        .append('g')
        .call(yAxis)
        .attr('class', 'axisWhite')
        .attr('transform', `translate(${margins.right},0)`);

      // Remove du premier tick de l'axe x
      svg.selectAll('.tick').each(function (d) {
        if (d === 0) {
          this.remove();
        }
      });
    }
  }

  /**
   * Mise à jour du composant si les minutes restantes changent ou que l'on sélectionne une autre balise
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (prevProps.beaconSelected !== this.props.beaconSelected) {
      this.removePreviousChart();
      this.drawChart('otherChange');
    } else {
      this.removePreviousChart();
      this.drawChart('timeChange');
    }
  }

  componentWillUnmount() {
    this.removePreviousChart();
  }

  render() {
    const styles = {
      display: 'grid',
      justifyItems: 'center',
    };

    return <div ref={this.chartRef} style={styles} />;
  }
}

export default RepartitionGraph;
