import {Store, StoreConfig} from '@datorama/akita';
import {DisplayState} from './display.model';

export function createInitialState(): DisplayState {
  return {
    areBeaconsGeoDisplayed: true,
    areBeaconsOtherAirportDisplayed: false,
    areBeaconsIafDisplayed: true,
    areBeaconsMfDisplayed: true,
    areSectorLfbbDisplayed: false,
    areSectorEgaDisplayed: false,
    areSectorUirDisplayed: false,
    areLabelsDisplayed: true,
    areSectorSectoNwDisplayed: false,
    areSectorMuacKuacDisplayed: false,
    areSectorSectoSeDisplayed: false,
    areMeteoTurbulencesDisplayed: false,
    areMeteoConvectionsDisplayed: false,
    areMeteoLightningsDisplayed: false,
    areMeteoRVRsDisplayed: false,
    meteoTimeFilter: 0,
    areDistanceMarksDisplayed: false,
  };
}

@StoreConfig({name: 'display'})
export class DisplayStore extends Store<DisplayState> {
  constructor() {
    super(createInitialState());
  }
}

export const displayStore = new DisplayStore();
