import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  flightParameter: {
    fontSize: '13px',
  },
  flightData: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  subtitle: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'grey',
    height: 25,
    lineHeight: '25px',
    marginBottom: 5,
  },
}));
