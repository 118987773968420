import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  cardTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#565656',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  card: {
    minHeight: '400',
    backgroundColor: 'RGB(247,247,247)',
    margin: theme.spacing(1),
  },
  paper: {
    backgroundColor: 'RGB(234,234,234)',
  },
}));
