import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {makeStyles} from '@material-ui/styles';

import AirportSwitch from './airport-switch.js';
import FlightSearch from './flight-search.js';
import Clock from './clock.js';
import NotificationCenter from './notification-center.js';

import {airportService} from '../models/airport/airport.service.ts';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  airportSwitchGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    right: theme.spacing(4),
  },
  timelineConfigurations: {
    marginLeft: theme.spacing(1),
    width: 250,
  },
  flightSearch: {
    marginRight: theme.spacing(4),
    marginLeft: 'auto',
  },
  notificationCenter: {
    marginLeft: theme.spacing(2),
  },
  timelineSwitch: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: theme.spacing(2),
  },
  timelineIcon: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: 20,
    width: 20,
  },
  clock: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    marginLeft: 'calc(50vw - 210px)',
  },
}));

function HeaderAppBar({
  handleTimelineConfigurationChange,
  timelinesConfigurations,
  timelineConfiguration,
  selectedAirport,
}) {
  const classes = useStyles();

  const setAirport = (icaoAirport, airportName) => {
    airportService.updateAirport({
      icaoName: icaoAirport,
      airportName,
    });
  };

  return (
    <AppBar className={classes.appBar} elevation={1}>
      <Toolbar>
        <FormControl variant="outlined" className={classes.timelineConfigurations} size="small">
          <InputLabel id="timeline-configuration-select-label">Timelines</InputLabel>
          <Select
            labelId="timeline-configuration-select-label"
            id="timeline-configuration-select"
            value={timelineConfiguration || ''}
            onChange={handleTimelineConfigurationChange}
            label="Timelines">
            {timelinesConfigurations
              ? timelinesConfigurations.map((config) => (
                  <MenuItem value={config} key={config.name}>
                    {config.name}
                  </MenuItem>
                ))
              : ''}
          </Select>
        </FormControl>
        <div className={classes.clock}>
          <Clock />
          <NotificationCenter className={classes.notificationCenter} />
        </div>
        <FlightSearch airport={selectedAirport} className={classes.flightSearch} />
        <div className={classes.airportSwitchGroup}>
          <Typography>ORY</Typography>
          <AirportSwitch
            checked={selectedAirport === 'LFPG'}
            onChange={(event) =>
              event.target.checked ? setAirport('LFPG', 'CDG') : setAirport('LFPO', 'Orly')
            }
          />
          <Typography>CDG</Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default HeaderAppBar;
