import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {makeStyles} from '@material-ui/core/styles';

import {ReactComponent as SvgCvf} from '../../svg-icons/tag_cvf.svg';
import {ReactComponent as SvgMoved} from '../../svg-icons/tag_moved.svg';
import {ReactComponent as SvgForced} from '../../svg-icons/tag_forced.svg';
import {ReactComponent as SvgLowPerf} from '../../svg-icons/tag_low_perf.svg';
import {ReactComponent as SvgSwapped} from '../../svg-icons/tag_swapped.svg';
import {ReactComponent as SvgForcedChart} from '../../svg-icons/tag_forced_chart.svg';
import {ReactComponent as SvgProgress} from '../../svg-icons/state_inprogress.svg';
import {ReactComponent as SvgOk} from '../../svg-icons/state_ok.svg';
import {ReactComponent as SvgNok} from '../../svg-icons/state_nok.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  card: {
    minHeight: '400',
    minWidth: '400',
    maxWidth: '400',
    backgroundColor: 'RGB(247,247,247)',
    margin: theme.spacing(1),
  },
  cardTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#565656',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  flightParameter: {
    fontSize: '13px',
  },
  flightData: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  flightDataScratch: {
    fontSize: '15px',
    fontWeight: 'bold',
    textDecoration: 'line-through',
  },
  tableauHaut: {
    fontSize: '13px',
    textAlign: 'center',
    height: 25,
    lineHeight: '25px',
  },
  tableauData: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    height: 25,
    lineHeight: '25px',
  },
  tableauDataFF: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'grey',
    height: 25,
    lineHeight: '25px',
  },
  paper: {
    backgroundColor: 'RGB(234,234,234)',
  },
  skipLine: {
    opacity: '0.0001',
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  flightTags: {
    marginLeft: 'auto',
    marginRight: theme.spacing(1),
  },
  flightTag: {
    marginLeft: theme.spacing(1),
  },
  speedConstaintIcon: {
    height: 16,
    width: 16,
  },
  spaceBefore: {
    marginTop: 10,
  },
}));

function AmanCard({flight}) {
  const classes = useStyles();

  return flight ? (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <div className={classes.cardHeader}>
          <Typography className={classes.cardTitle}>MAESTRO AXP</Typography>
          <div className={classes.flightTags}>
            {flight.trajectory === 'CVF' ? (
              <SvgIcon component={SvgCvf} viewBox="0 0 24 24" className={classes.flightTag} />
            ) : (
              ''
            )}
            {flight.moveStatus === 'MOVED' ? (
              <SvgIcon component={SvgMoved} viewBox="0 0 24 24" className={classes.flightTag} />
            ) : flight.moveStatus === 'STA_FORCED' || flight.moveStatus === 'STA_MF_FORCED' ? (
              <SvgIcon component={SvgForced} viewBox="0 0 24 24" className={classes.flightTag} />
            ) : flight.moveStatus === 'SWAPPED' ? (
              <SvgIcon component={SvgSwapped} viewBox="0 0 24 24" className={classes.flightTag} />
            ) : (
              ''
            )}
            {flight.lowPerf ? (
              <SvgIcon component={SvgLowPerf} viewBox="0 0 24 24" className={classes.flightTag} />
            ) : (
              ''
            )}
          </div>
        </div>

        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauHaut}>&nbsp;</Typography>
          </Grid>
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauHaut}>MF</Typography>
          </Grid>
          <Grid item xs={1} className={classes.paper}>
            <Typography className={classes.tableauHaut}>&nbsp;</Typography>
          </Grid>
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauHaut}>FF</Typography>
          </Grid>
          <Grid item xs={1} className={classes.paper}>
            <Typography className={classes.tableauHaut}>&nbsp;</Typography>
          </Grid>
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauHaut}>RWY</Typography>
          </Grid>
          <Grid item xs={1} className={classes.paper}>
            <Typography className={classes.tableauHaut}>&nbsp;</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauData}>&nbsp;</Typography>
          </Grid>
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauData}>{flight.mf}</Typography>
          </Grid>
          <Grid item xs={1} className={classes.paper}>
            <Typography className={classes.tableauData}>&nbsp;</Typography>
          </Grid>
          <Grid item xs={2} className={classes.paper}>
            {flight.iaf ? (
              <Typography className={classes.tableauData}>{flight.iaf}</Typography>
            ) : (
              <Typography className={classes.tableauData}>&nbsp;</Typography>
            )}
          </Grid>
          <Grid item xs={1} className={classes.paper}>
            <Typography className={classes.tableauData}>&nbsp;</Typography>
          </Grid>
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauData}>{flight.arr}</Typography>
          </Grid>
          <Grid item xs={1} className={classes.paper}>
            <Typography className={classes.tableauData}>&nbsp;</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauHaut}>ETA</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.tableauData}>
              {flight.etaMF
                ? flight.etaMF.toLocaleTimeString('utc', {
                    hour12: false,
                    timeZone: 'UTC',
                    timeStyle: 'medium',
                  })
                : ''}
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={2}>
            <Typography className={classes.tableauDataFF}>
              {flight.etaFF
                ? flight.etaFF.toLocaleTimeString('utc', {
                    hour12: false,
                    timeZone: 'UTC',
                    timeStyle: 'medium',
                  })
                : ''}
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={2}>
            <Typography className={classes.tableauData}>
              {flight.eta
                ? flight.eta.toLocaleTimeString('utc', {
                    hour12: false,
                    timeZone: 'UTC',
                    timeStyle: 'medium',
                  })
                : ''}
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauHaut}>STA</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.tableauData}>
              {flight.staMF
                ? flight.staMF.toLocaleTimeString('utc', {
                    hour12: false,
                    timeZone: 'UTC',
                    timeStyle: 'medium',
                  })
                : ''}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {flight.moveStatus === 'STA_MF_FORCED' ? (
              <div className={classes.tableauData}>
                <SvgIcon component={SvgForcedChart} viewBox="0 1 24 24" />
              </div>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.tableauDataFF}>
              {flight.staFF
                ? flight.staFF.toLocaleTimeString('utc', {
                    hour12: false,
                    timeZone: 'UTC',
                    timeStyle: 'medium',
                  })
                : ''}
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={2}>
            <Typography className={classes.tableauData}>
              {flight.sta
                ? flight.sta.toLocaleTimeString('utc', {
                    hour12: false,
                    timeZone: 'UTC',
                    timeStyle: 'medium',
                  })
                : ''}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            {flight.moveStatus === 'STA_FORCED' ? (
              <div className={classes.tableauData}>
                <SvgIcon component={SvgForcedChart} viewBox="0 4 24 24" />
              </div>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauHaut}>Délai</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography
              className={classes.tableauData}
              style={{
                color:
                  flight.delayMF < 0
                    ? 'green'
                    : flight.delayMF < 2
                    ? 'black'
                    : flight.delayMF < 6
                    ? 'RGB(247,147,30)'
                    : 'RGB(203,83,87)',
              }}>
              {flight.delayMF}
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={2}>
            <Typography
              className={classes.tableauDataFF}
              style={{
                color:
                  flight.delayFF < 0
                    ? 'green'
                    : flight.delayFF < 2
                    ? 'black'
                    : flight.delayFF < 6
                    ? 'RGB(247,147,30)'
                    : 'RGB(203,83,87)',
              }}>
              {flight.delayFF}
            </Typography>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={2}>
            <Typography
              className={classes.tableauData}
              style={{
                color:
                  flight.delayRNW < 0
                    ? 'green'
                    : flight.delayRNW < 2
                    ? 'black'
                    : flight.delayRNW < 6
                    ? 'RGB(247,147,30)'
                    : 'RGB(203,83,87)',
              }}>
              {flight.delayRNW}
            </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          className={classes.spaceBefore}>
          <Grid item>
            <Typography className={classes.flightParameter}>Etat Slow :</Typography>
          </Grid>
          <Grid item>
            {flight.speedConstraint === 'SLW' ? (
              <Typography className={classes.flightData}>SLW</Typography>
            ) : flight.speedConstraint === 'DELETE' ? (
              <Typography className={classes.flightDataScratch}>SLW</Typography>
            ) : (
              ''
            )}
          </Grid>
          <Grid item>
            {flight.speedConstraintStatus === 'PROPOSED' ? (
              <SvgIcon
                component={SvgProgress}
                viewBox="0 0 11.036 13.159"
                className={classes.speedConstaintIcon}
              />
            ) : flight.speedConstraintStatus === 'IMPLEMENTED' ? (
              <SvgIcon
                component={SvgOk}
                viewBox="0 0 20 20"
                className={classes.speedConstaintIcon}
              />
            ) : flight.speedConstraintStatus === 'REFUSED' ? (
              <SvgIcon
                component={SvgNok}
                viewBox="0 0 14.414 14.414"
                className={classes.speedConstaintIcon}
              />
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ) : (
    ''
  );
}

export default AmanCard;
