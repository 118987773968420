import React from 'react';

import './beacons-styles.css';

function BeaconLim({x, y}) {
  return (
    <g className="beaconLim" transform={`translate (${x} ${y})`}>
      <circle className="beaconCircle" cx="0" cy="0" r="9" />
      <text className="nbVolsLabel" textAnchor="middle" dominantBaseline="central">
        -
      </text>
    </g>
  );
}

export default BeaconLim;
