import React, {useEffect, useState, useRef} from 'react';

import {makeStyles} from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import SvgIcon from '@material-ui/core/SvgIcon';
import Fab from '@material-ui/core/Fab';
import Popover from '@material-ui/core/Popover';
import RadioGroup from '@material-ui/core/RadioGroup';

import clsx from 'clsx';

import {ReactComponent as SvgLightning} from '../svg-icons/lightning.svg';
import {ReactComponent as SvgLabels} from '../svg-icons/labels.svg';

import {displayService} from '../models/display/display.service';

const useStyles = makeStyles((theme) => ({
  formControl: {
    padding: '20px 20px 0 20px',
  },
  timeSelector: {
    paddingBottom: '20px',
  },
  typographyMeteo: {
    color: '#5b637b',
    fontSize: '20px',
    fontWeight: 'bold',
    paddingBottom: '10px',
  },
  checks: {
    color: '#5b637b',
  },
  divider: {
    width: 240,
  },
  rectangle: {
    width: 17,
    height: 17,
    marginLeft: '3px',
    right: 0,
  },
  lightningIcon: {
    right: 0,
    marginLeft: 'auto',
    height: '20px',
    width: '20px',
  },
  rvrIcon: {
    right: 0,
    marginLeft: 'auto',
    height: 25,
    width: 25,
  },
  radioRoot: {
    display: 'flex',
    marginLeft: 0,
    marginRight: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 10,
    height: 10,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    'input:hover ~ &': {
      backgroundColor: 'white',
    },
    'input:disabled ~ &': {
      background: 'white',
    },
  },
  checkedIcon: {
    backgroundColor: '#5b637b',
    '&:before': {
      display: 'block',
      width: 10,
      height: 10,
    },
    'input:hover ~ &': {
      backgroundColor: '#5b637b',
    },
  },
  withinRadioButtons: {
    backgroundColor: '#5b637b',
  },
  legend: {
    fontSize: '11px',
    textAlign: 'center',
  },
  radioFormControlLabel: {
    marginRight: 0,
    paddingRight: 0,
    paddingLeft: 0,
    marginLeft: 0,
  },
}));

function WeatherIcon(props) {
  return (
    <SvgIcon {...props} viewBox="9 9 27 27">
      <g id="Groupe_455" data-name="Groupe 455" transform="translate(-129 -1023)" fill="#5b637b">
        <g id="Bouton_météo" data-name="Bouton météo" transform="translate(129 1023)">
          <g id="Groupe_454" data-name="Groupe 454">
            <g id="cloud-24px" transform="translate(11 6)">
              <path id="Tracé_3" data-name="Tracé 3" fill="none" d="M0,0H25V25H0Z" />
              <g id="cloud-coloring">
                <path
                  id="Tracé_4"
                  data-name="Tracé 4"
                  d="M20.156,10.292A7.8,7.8,0,0,0,5.573,8.208,6.247,6.247,0,0,0,6.25,20.667H19.792a5.193,5.193,0,0,0,.365-10.375Z"
                  transform="translate(0 0.167)"
                />
                <path
                  id="Tracé_5"
                  data-name="Tracé 5"
                  stroke="#fff"
                  d="M91.9,1043.486h4.923l-2.227,4.806h4.22l-8.791,14.3,2.7-11.019H88.852Z"
                  transform="translate(-81 -1029)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

function WeatherMenu(props) {
  const classes = useStyles();

  // On crée un state pour chaque valeur
  const [turbulences, setTurbulencesDisplayed] = useState(false);
  const [convections, setConvectionsDisplayed] = useState(false);
  const [lightnings, setLightningsDisplayed] = useState(false);
  const [rvrs, setRVRsDisplayed] = useState(false);
  const [selectedValue, setSelectedValue] = useState('0');

  // State d'ouverture du menu
  const [menuOpen, setMenuOpen] = useState(false);

  // Mémorisation des subscription
  const meteoTurbulencesSubscription = useRef(null);
  const meteoConvectionSubscription = useRef(null);
  const meteoLightningsSubscription = useRef(null);
  const meteoRvrSubscription = useRef(null);
  const timeFilterSubscription = useRef(null);

  // Ref du bouton
  const button = useRef(null);

  useEffect(() => {
    // Abonnement au model au 'mount' du composant
    meteoTurbulencesSubscription.current = displayService
      .areMeteoTurbulencesDisplayed()
      .subscribe(setTurbulencesDisplayed);
    meteoConvectionSubscription.current = displayService
      .areMeteoConvectionsDisplayed()
      .subscribe(setConvectionsDisplayed);
    meteoLightningsSubscription.current = displayService
      .areMeteoLightningsDisplayed()
      .subscribe(setLightningsDisplayed);
    meteoRvrSubscription.current = displayService
      .areMeteoRVRsDisplayed()
      .subscribe(setRVRsDisplayed);
    timeFilterSubscription.current = displayService
      .whatIsMeteoTimeFilter()
      .subscribe(setSelectedValue);

    return () => {
      // Desabonnement au model au 'unmount' du composant
      if (meteoTurbulencesSubscription.current) {
        meteoTurbulencesSubscription.current.unsubscribe();
      }
      if (meteoConvectionSubscription.current) {
        meteoConvectionSubscription.current.unsubscribe();
      }
      if (meteoLightningsSubscription.current) {
        meteoLightningsSubscription.current.unsubscribe();
      }
      if (meteoRvrSubscription.current) {
        meteoRvrSubscription.current.unsubscribe();
      }
      if (timeFilterSubscription.current) {
        timeFilterSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Gère un clic sur la case des turbulences
  const handleClickTurbulences = () => {
    displayService.updateMeteoTurbulencesDisplayed(!turbulences);
  };

  // Gère un clic sur la case des convections
  const handleClickConvections = () => {
    displayService.updateMeteoConvectionsDisplayed(!convections);
  };

  // Gère un clic sur la case de foudre
  const handleClickLightnings = () => {
    displayService.updateMeteoLightningsDisplayed(!lightnings);
  };

  // Gère un clic sur la case de RVR
  const handleClickRVRs = () => {
    displayService.updateMeteoRVRsDisplayed(!rvrs);
  };

  // Gère le changement de case choisie sur l'heure de prévision
  const handleTimeChange = (event) => {
    if (event.target.value !== 0) {
      displayService.updateMeteoLightningsDisplayed(false);
    }
    displayService.updateMeteoTimeFilter(parseInt(event.target.value));
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <Fab aria-label="weather" className={props.className} onClick={handleMenuOpen} ref={button}>
        <WeatherIcon />
      </Fab>

      <Popover
        open={menuOpen}
        anchorEl={button.current}
        onClose={handleMenuClose}
        anchorOrigin={{vertical: -10, horizontal: 'left'}}
        transformOrigin={{vertical: 'bottom', horizontal: 'left'}}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <Typography className={classes.typographyMeteo}>Météo</Typography>
            <Divider className={classes.divider} />
            <div style={{display: 'flex', alignItems: 'center'}}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checks}
                    color="default"
                    checked={turbulences}
                    onClick={handleClickTurbulences}
                    name="turbulences"
                  />
                }
                label="Turbulences"
              />
              <div
                className={classes.rectangle}
                style={{
                  backgroundColor: 'RGB(144,177,128)',
                  marginLeft: 'auto',
                }}
              />
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checks}
                    color="default"
                    checked={convections}
                    onClick={handleClickConvections}
                    name="convections"
                  />
                }
                label="Convections"
              />
              <div
                className={classes.rectangle}
                style={{
                  backgroundColor: 'RGB(249,166,47)',
                  marginLeft: 'auto',
                }}
              />
              <div className={classes.rectangle} style={{backgroundColor: 'RGB(249,87,47)'}} />
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checks}
                    color="default"
                    checked={lightnings}
                    onClick={handleClickLightnings}
                    name="foudre"
                    disabled={selectedValue !== 0} // Si on est sur une heure de prévision différente du temps réel, la case des impacts de foudre sera disabled
                  />
                }
                label="Impacts de foudre"
              />
              <SvgIcon
                component={SvgLightning}
                viewBox="0 0 1000 1000"
                className={classes.lightningIcon}
              />
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checks}
                    color="default"
                    checked={rvrs}
                    onClick={handleClickRVRs}
                    name="rvrs"
                  />
                }
                label="RVR"
              />
              <SvgIcon
                component={SvgLabels}
                viewBox="0 0 31.598 25.118"
                className={classes.rvrIcon}
              />
            </div>
          </FormGroup>
        </FormControl>

        <div className={classes.timeSelector}>
          <RadioGroup row onChange={handleTimeChange} value={selectedValue} spacing="0">
            <Grid container direction="row" alignItems="center" justifyContent="center" flex="0">
              <Grid item xs={1} justifyContent="center" container>
                <FormControlLabel
                  value={0}
                  control={
                    <Radio
                      className={classes.radioRoot}
                      disableRipple
                      color="default"
                      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                      icon={<span className={classes.icon} />}
                    />
                  }
                  className={classes.radioFormControlLabel}
                />
              </Grid>
              <Grid item xs={1}>
                <Divider className={classes.withinRadioButtons} />
              </Grid>
              <Grid item xs={1} justifyContent="center" container>
                <FormControlLabel
                  value={15}
                  control={
                    <Radio
                      className={classes.radioRoot}
                      disableRipple
                      color="default"
                      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                      icon={<span className={classes.icon} />}
                    />
                  }
                  className={classes.radioFormControlLabel}
                />
              </Grid>
              <Grid item xs={1}>
                <Divider className={classes.withinRadioButtons} />
              </Grid>
              <Grid item xs={1} justifyContent="center" container>
                <FormControlLabel
                  value={30}
                  control={
                    <Radio
                      className={classes.radioRoot}
                      disableRipple
                      color="default"
                      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                      icon={<span className={classes.icon} />}
                    />
                  }
                  className={classes.radioFormControlLabel}
                />
              </Grid>
              <Grid item xs={1}>
                <Divider className={classes.withinRadioButtons} />
              </Grid>
              <Grid item xs={1} justifyContent="center" container>
                <FormControlLabel
                  value={45}
                  control={
                    <Radio
                      className={classes.radioRoot}
                      disableRipple
                      color="default"
                      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                      icon={<span className={classes.icon} />}
                    />
                  }
                  className={classes.radioFormControlLabel}
                />
              </Grid>
              <Grid item xs={1}>
                <Divider className={classes.withinRadioButtons} />
              </Grid>
              <Grid item xs={1} justifyContent="center" container>
                <FormControlLabel
                  value={60}
                  control={
                    <Radio
                      className={classes.radioRoot}
                      disableRipple
                      color="default"
                      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                      icon={<span className={classes.icon} />}
                    />
                  }
                  className={classes.radioFormControlLabel}
                />
              </Grid>
            </Grid>
          </RadioGroup>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            flex="0"
            wrap="nowrap"
            alignContent="center">
            <Grid item xs={1} justifyContent="center" container>
              <Typography className={classes.legend}>Temps réel</Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1} justifyContent="center" container>
              <Typography className={classes.legend}>15mn</Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1} justifyContent="center" container>
              <Typography className={classes.legend}>30mn</Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1} justifyContent="center" container>
              <Typography className={classes.legend}>45mn</Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1} justifyContent="center" container>
              <Typography className={classes.legend} align="center">
                60mn
              </Typography>
              <Grid item xs />
            </Grid>
          </Grid>
        </div>
      </Popover>
    </>
  );
}

export default WeatherMenu;
