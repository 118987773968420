import React from 'react';

import './beacons-styles.css';

function BeaconGeo({name, x, y}) {
  return (
    <g className="beaconGeo" transform={`translate(${x} ${y})`}>
      <text className="name" textAnchor="middle" x="0" y="-10">
        {name}
      </text>
      <circle className="beaconCircle" cx="0" cy="0" r="4" />
    </g>
  );
}

export default BeaconGeo;
