import {beaconStore, BeaconStore} from './beacon.store';
import {Beacon} from './beacon.model';
import {beaconQuery} from './beacon.query';

import * as d3 from 'd3';

export class BeaconService {
  constructor(private beaconStore: BeaconStore) {}

  /**
   * Méthode permettant de charger les données depuis le fichier json
   */
  async start() {
    // On s'assure que le store est vide
    beaconStore.remove();
    beaconStore.setLoading(true);

    // On récupère les données pour LFPO
    let response: Response = await fetch('configLFPO/beacons_LFPO.geojson');
    let result: any = await response.json();
    let dataset: any[] = result.features;

    // Et on les ajoute au store
    const beacons: Beacon[] = [];
    dataset.forEach((data) => {
      if (data.geometry.type === 'Point') {
        const beacon: Beacon = {
          name: data.properties.name,
          coordinates: [data.geometry.coordinates[0], data.geometry.coordinates[1]],
          typeBeacon: data.properties.typeBeacon,
          forAirport: 'LFPO',
        };

        switch (beacon.typeBeacon) {
          case 'airport':
            beacon.cadency = 90;
            beacon.defaultCapacity = data.properties.defaultCapacity;
            beacon.defaultDepartureCapacity = data.properties.defaultDepartureCapacity;
            beacon.tvForCapacity = data.properties.tvForCapacity;
            beacon.tvForDepartureCapacity = data.properties.tvForDepartureCapacity;
            break;
          case 'otherAirport':
            break;
          case 'lim':
            break;
          case 'mf':
            beacon.iaf = data.properties.iaf;
            beacon.shortName = data.properties.shortName;
            beacon.defaultCapacity = data.properties.defaultCapacity;
            break;
          case 'iaf':
            beacon.defaultCapacity = data.properties.defaultCapacity;
            break;
          case 'cop':
            beacon.defaultCapacity = data.properties.defaultCapacity;
            break;
          case 'rtg':
            beacon.defaultCapacity = data.properties.defaultCapacity;
            break;
        }

        beacons.push(beacon);
      }
    });

    // On récupère les données pour LFPG
    response = await fetch('configLFPG/beacons_LFPG.geojson');
    result = await response.json();
    dataset = result.features;

    // Et on les ajoute au store
    dataset.forEach((data) => {
      if (data.geometry.type === 'Point') {
        const beacon: Beacon = {
          name: data.properties.name,
          coordinates: [data.geometry.coordinates[0], data.geometry.coordinates[1]],
          typeBeacon: data.properties.typeBeacon,
          forAirport: 'LFPG',
        };

        switch (beacon.typeBeacon) {
          case 'airport':
            beacon.cadency = 90;
            beacon.defaultCapacity = data.properties.defaultCapacity;
            beacon.defaultDepartureCapacity = data.properties.defaultDepartureCapacity;
            beacon.tvForCapacity = data.properties.tvForCapacity;
            beacon.tvForDepartureCapacity = data.properties.tvForDepartureCapacity;
            break;
          case 'otherAirport':
            break;
          case 'lim':
            break;
          case 'mf':
            beacon.iaf = data.properties.iaf;
            beacon.shortName = data.properties.shortName;
            beacon.defaultCapacity = data.properties.defaultCapacity;
            break;
          case 'iaf':
            beacon.defaultCapacity = data.properties.defaultCapacity;
            break;
          case 'cop':
            beacon.defaultCapacity = data.properties.defaultCapacity;
            break;
          case 'rtg':
            beacon.defaultCapacity = data.properties.defaultCapacity;
            break;
        }

        beacons.push(beacon);
      }
    });

    beaconStore.add(beacons);
    beaconStore.setLoading(false);
  }

  /**
   * Méthode qui vide le store lorsqu'il n'est plus utilisé
   */
  destroy(): void {
    // On vide le store
    beaconStore.remove();
  }

  /**
   * Méthode permettant d'ajouter une balise au store
   * @param beacon Balise a ajouter au store
   */
  addBeacon(beacon: Beacon) {
    beaconStore.add(beacon);
  }

  /**
   * Méthode permettant de définir la balise survolée
   * @param hoveredBeacon nom de la balise survolée
   */
  updateHoveredBeacon(hoveredBeacon: string): void {
    beaconStore.update({
      hovered: hoveredBeacon,
    });
  }

  /**
   * Méthode permettant de définir la balise sélectionnée
   * @param selectedBeacon nom de la balise sélectionnée
   */
  updateSelectedBeacon(selectedBeacon: string): void {
    beaconStore.update({
      selected: selectedBeacon,
    });
  }

  /**
   * Méthode permettant de s'abonner au changement de nom de la balise survolée
   */
  selectHovered() {
    return beaconQuery.selectHovered();
  }

  /**
   * Méthode permettant de s'abonner au changement de nom de la balise sélectionnée
   */
  selectSelected() {
    return beaconQuery.selectSelected();
  }

  /**
   * Méthode permettant de s'abonner au changement de l'une des balises
   */
  selectAllBeacons() {
    return beaconQuery.selectAllBeacons();
  }

  /**
   * Méthode permettant de s'abonner au changement de l'une des balises Geo pour un aéroport précisé
   * @param airport code OACI de l'aeroport dont on veut les beacons Geo
   */
  selectBeaconsGeoForAirport(airport: string) {
    return beaconQuery.selectBeaconsGeoForAirport(airport);
  }

  /**
   * Méthode permettant de s'abonner au changement de l'une des balises Mf pour un aéroport précisé
   * @param airport code OACI de l'aeroport dont on veut les beacons MF
   */
  selectBeaconsMfForAirport(airport: string) {
    return beaconQuery.selectBeaconsMfForAirport(airport);
  }

  /**
   * Méthode permettant de s'abonner au changement de l'une des balises Cop pour un aéroport précisé
   * @param airport code OACI de l'aeroport dont on veut les beacons Cop
   */
  selectBeaconsCopForAirport(airport: string) {
    return beaconQuery.selectBeaconsCopForAirport(airport);
  }

  /**
   * Méthode permettant de s'abonner au changement de l'une des balises Iaf pour un aéroport précisé
   * @param airport code OACI de l'aéroport dont on veut récupérer les beacons IAF
   */
  selectBeaconsIafForAirport(airport: string) {
    return beaconQuery.selectBeaconsIafForAirport(airport);
  }

  /**
   * Méthode permettant de s'abonner au changement de l'une des balises Airport pour un aéroport précisé
   * @param airport code OACI de l'aéroport  dont on veut récupérer les balises Airport
   */
  selectBeaconsAirportForAirport(airport: string) {
    return beaconQuery.selectBeaconsAirportForAirport(airport);
  }

  /**
   * Méthode permettant de s'abonner au changement de l'une des balises Other Airport pour un aéroport précisé
   * @param airport code OACI de l'aéroport dont on veut récupérer les balises Other Airport
   */
  selectBeaconsOtherAirportForAirport(airport: string) {
    return beaconQuery.selectBeaconsOtherAirportForAirport(airport);
  }

  /**
   * Méthode permettant de s'abonner au changement de l'une des balises Limite pour un aéroport précisé
   * @param airport code OACI de l'aéroport dont on veut récupérer les balises Limite
   */
  selectBeaconsLimForAirport(airport: string) {
    return beaconQuery.selectBeaconsLimForAirport(airport);
  }

  /**
   * Méthode permettant de s'abonner au changement de l'une des balises rerouting pour un aéroport précisé
   * @param airport code OACI de l'aéroport dont on veut récupérer les balises rerouting
   */
  selectBeaconsRtgForAirport(airport: string) {
    return beaconQuery.selectBeaconsRtgForAirport(airport);
  }

  /**
   * Méthode permettant de s'abonner au changement de balise survolée
   */
  selectHoveredBeacon() {
    return beaconQuery.selectHoveredBeacon();
  }

  /**
   * Méthode permettant de s'abonner au changement de balise sélectionnée
   */
  selectSelectedBeacon() {
    return beaconQuery.selectSelectedBeacon();
  }

  /**
   * Méthode permettant de récupérer les informations d'une balise pour un aeroport précisé
   * @param beaconName nom de la balise à récupérer
   * @param airport Aeroport pour lequel on veut récupérer la balise
   */
  selectBeaconForAirport(beaconName: string, airport: 'LFPO' | 'LFPG') {
    return beaconQuery.selectBeaconForAirport(beaconName, airport);
  }

  /**
   * Donne la projection sur la carte
   */
  getProjection() {
    return d3
      .geoMercator()
      .translate([800, 1600])
      .scale(8000 / (2 * Math.PI));
  }

  /**
   * Méthode permettant de récupérer le IAF associé au MF en paramètre, pour l'aeroport sélectionné
   * @param airport code OACI de l'aéroport pour lequel on veut récupérer le IAF
   * @param mf MF pour lequel on veut récupérer le IAF
   */
  getIafForMfAndAirport(airport: 'LFPO' | 'LFPG', mf: string) {
    return beaconQuery.getIafForMfAndAirport(airport, mf);
  }

  /**
   * Méthode permettant de récupérer la liste des IAF pour un aeroport
   * @param airport code OACI de l'aéroport dont on veut récupérer la liste des IAF
   */
  getIafForAirport(airport: 'LFPO' | 'LFPG') {
    return beaconQuery.getIafForAirport(airport);
  }

  /**
   * Méthode permettant de récupérer les informations d'une balise pour un aeroport précisé
   * @param beaconName nom de la balise à récupérer
   * @param airport Aeroport pour lequel on veut récupérer la balise
   */
  getBeaconForAirport(beaconName: string, airport: 'LFPO' | 'LFPG') {
    return beaconQuery.getBeaconForAirport(beaconName, airport);
  }
}

export const beaconService = new BeaconService(beaconStore);
