import {Store, StoreConfig} from '@datorama/akita';
import {AirportState} from './airport.model';

export function createInitialState(): AirportState {
  return {
    name: 'Orly',
    icaoName: 'LFPO',
  };
}

@StoreConfig({name: 'airport'})
export class AirportStore extends Store<AirportState> {
  constructor() {
    super(createInitialState());
  }
}

export const airportStore = new AirportStore();
