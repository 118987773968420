import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {MeteoEvent} from './meteo.model';

export interface MeteoState extends EntityState<MeteoEvent, string> {
  hovered: string;
}

@StoreConfig({name: 'meteo', idKey: 'id'})
export class MeteoStore extends EntityStore<MeteoState, MeteoEvent> {}

export const meteoStore = new MeteoStore();
