import React, {useReducer, useRef, useEffect} from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import {makeStyles} from '@material-ui/core/styles';

import {flightService} from '../../models/flight/flight.service';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  tableauHaut: {
    fontSize: '13px',
    textAlign: 'center',
    pointerEvents: 'none',
  },
  bold: {
    fontWeight: 'bold',
  },
  tableauHautHovered: {
    fontSize: '13px',
    textAlign: 'center',
    backgroundColor: '#3277d1',
    color: 'white',
    pointerEvents: 'none',
  },
  tableauHautSelected: {
    fontSize: '13px',
    textAlign: 'center',
    backgroundColor: '#1150a1',
    color: 'white',
    pointerEvents: 'none',
  },
  localRegulation: {
    fontSize: '13px',
    color: 'RGB(224, 78, 178)',
    background: 'white',
    boxShadow: '0px 0px 0px 1px RGB(224, 78, 178) inset',
    width: '28px',
  },
  externalRegulation: {
    maxHeight: 'calc(100% - 4px)',
    fontSize: '13px',
    color: 'white',
    background: 'RGB(224, 78, 178)',
    boxShadow: '0px 0px 0px 1px white inset',
    width: '28px',
  },
}));

function FlightListCardItem({callsign, dep, regulation, sta, atfcmDelay, regulationStatus}) {
  const classes = useStyles();

  // Subscriptions
  const hoveredSubscription = useRef(null);
  const selectedSubscription = useRef(null);

  // State
  const [selected, setSelected] = useReducer(
    (state, action) => !!action.flight && action.flight.callsign === callsign,
    false,
  );
  const [hovered, setHovered] = useReducer(
    (state, action) => !!action.flight && action.flight.callsign === callsign,
    false,
  );

  // Au mount du composant, on s'abonne aux vols survolés et sélectionnés
  useEffect(() => {
    hoveredSubscription.current = flightService
      .selectHoveredFlight()
      .subscribe((flight) => setHovered({flight}));
    selectedSubscription.current = flightService
      .selectSelectedFlight()
      .subscribe((flight) => setSelected({flight}));

    // Au unmount, on se désabonne des modèles
    return () => {
      if (hoveredSubscription.current) {
        hoveredSubscription.current.unsubscribe();
      }
      if (selectedSubscription.current) {
        selectedSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Event handlers
  const handleMouseEnter = () => {
    flightService.setHovered(callsign);
  };

  const handleMouseLeave = () => {
    flightService.setHovered('');
  };

  const handleClickFlightOpenDrawer = () => {
    flightService.setSelected(callsign);
  };

  const handleClickFlightCloseDrawer = () => {
    flightService.setSelected();
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      key={callsign}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={selected ? handleClickFlightCloseDrawer : handleClickFlightOpenDrawer}>
      <Grid item xs={3}>
        <Typography
          className={
            selected
              ? classes.tableauHautSelected
              : hovered
              ? classes.tableauHautHovered
              : classes.tableauHaut
          }>
          {callsign}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          className={
            selected
              ? classes.tableauHautSelected
              : hovered
              ? classes.tableauHautHovered
              : classes.tableauHaut
          }>
          {dep}
        </Typography>
      </Grid>
      <Grid
        item
        xs={2}
        style={{
          backgroundColor: selected ? '#1150a1' : hovered ? '#3277d1' : '',
        }}
        align="center">
        {atfcmDelay > 0 ? (
          <Box
            className={
              regulationStatus === 'MPR_LOCAL_REGULATION'
                ? classes.localRegulation
                : regulationStatus === 'MPR_EXTERNAL_REGULATION'
                ? classes.externalRegulation
                : ''
            }>
            <Typography className={`${classes.tableauHaut} ${classes.bold}`}>
              {atfcmDelay}
            </Typography>
          </Box>
        ) : (
          <Typography
            className={
              selected
                ? classes.tableauHautSelected
                : hovered
                ? classes.tableauHautHovered
                : classes.tableauHaut
            }>
            0
          </Typography>
        )}
      </Grid>
      <Grid item xs={3}>
        <Typography
          className={
            selected
              ? classes.tableauHautSelected
              : hovered
              ? classes.tableauHautHovered
              : classes.tableauHaut
          }>
          {regulation || '-'}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography
          className={
            selected
              ? classes.tableauHautSelected
              : hovered
              ? classes.tableauHautHovered
              : classes.tableauHaut
          }>
          {sta
            ? sta.toLocaleTimeString('utc', {
                hour12: false,
                timeZone: 'UTC',
                timeStyle: 'short',
              })
            : ''}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default FlightListCardItem;
