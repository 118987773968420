import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {makeStyles} from '@material-ui/core/styles';

import {ReactComponent as SvgOk} from '../../svg-icons/state_ok.svg';
import {ReactComponent as SvgDepAirport} from '../../svg-icons/dep_airport.svg';
import {ReactComponent as SvgArrAirport} from '../../svg-icons/arr_airport.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  card: {
    minHeight: '400',
    minWidth: '400',
    maxWidth: '400',
    backgroundColor: 'RGB(247,247,247)',
    margin: theme.spacing(1),
  },
  cardTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#565656',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  airportName: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  flightParameter: {
    fontSize: '13px',
  },
  flightData: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  localRegulation: {
    fontSize: '13px',
    color: 'RGB(224, 78, 178)',
    background: 'white',
    boxShadow: '0px 0px 0px 1px RGB(224, 78, 178) inset',
  },
  externalRegulation: {
    maxHeight: 'calc(100% - 4px)',
    fontSize: '13px',
    color: 'white',
    background: 'RGB(224, 78, 178)',
    boxShadow: '0px 0px 0px 1px white inset',
  },
  delaiAtfcm: {
    fontSize: '13px',
    textAlign: 'center',
    pointerEvents: 'none',
    fontWeight: 'bold',
  },
  paper: {
    backgroundColor: 'RGB(234,234,234)',
  },
  spaceAfter: {
    marginBottom: '10px',
  },
  doubleSpaceAfter: {
    marginBottom: '24px',
  },
  speedConstaintIcon: {
    height: 16,
    width: 16,
  },
  airportIcon: {
    height: '40px',
    width: '40px',
  },
}));

function FlightPlanCard({flight}) {
  const classes = useStyles();

  return flight ? (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <Typography className={classes.cardTitle}>Plan de vol</Typography>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.doubleSpaceAfter}>
          <Grid item xs={1} />
          <Grid item xs={2}>
            <SvgIcon
              component={SvgDepAirport}
              viewBox="0 0 35.689 32.93"
              className={classes.airportIcon}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.airportName}>{flight.dep} </Typography>
          </Grid>
          <Grid item xs={2}>
            <SvgIcon
              component={SvgArrAirport}
              viewBox="0 0 31.822 28.143"
              className={classes.airportIcon}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.airportName}>{flight.arr} </Typography>
          </Grid>
          <Grid item xs={1} />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          className={classes.spaceAfter}>
          <Grid item xs={3}>
            <Typography className={classes.flightParameter}>Mini-PLN :</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography className={classes.flightData}>
              {flight && flight.icaoRoute ? flight.icaoRoute : ''}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.spaceAfter}>
          <Grid item xs={3}>
            <Typography className={classes.flightParameter}>CTOT :</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography className={classes.flightData}>
              {flight.ctot
                ? flight.ctot.toLocaleTimeString('utc', {
                    hour12: false,
                    timeZone: 'UTC',
                    timeStyle: 'short',
                  })
                : ''}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.flightParameter}>RFL :</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.flightData}>
              {flight.rfl < 10
                ? `00${flight.rfl}`
                : flight.rfl < 100
                ? `0${flight.rfl}`
                : flight.rfl}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.spaceAfter}>
          <Grid item xs={3}>
            <Typography className={classes.flightParameter}>Régulation :&nbsp;&nbsp;</Typography>
          </Grid>
          <Grid item xs={4}>
            {flight.regulationStatus !== 'NOT_REGULATED' ? (
              <Typography className={classes.flightData}>{flight.regulation}</Typography>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={3}>
            <Typography className={classes.flightParameter}>Délai ATFCM :</Typography>
          </Grid>
          <Grid item xs={1}>
            {flight.atfcmDelay > 0 ? (
              <Box
                className={
                  flight.regulationStatus === 'MPR_LOCAL_REGULATION'
                    ? classes.localRegulation
                    : flight.regulationStatus === 'MPR_EXTERNAL_REGULATION'
                    ? classes.externalRegulation
                    : ''
                }>
                <Typography className={classes.delaiAtfcm}>{flight.atfcmDelay}</Typography>
              </Box>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={3}>
            <Typography className={classes.flightParameter}>Target Time :</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.flightData}>
              {flight && flight.targetTime ? flight.targetTime : ''}
            </Typography>
          </Grid>
          <Grid item container xs={1} justifyContent="center">
            {flight && flight.targetTime ? (
              <SvgIcon
                component={SvgOk}
                viewBox="0 0 20 20"
                className={classes.speedConstaintIcon}
              />
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={6}>
            {flight && flight.targetTime && flight.targetTimePoint ? (
              <Typography className={classes.flightData}>@{flight.targetTimePoint}</Typography>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ) : (
    ''
  );
}

export default FlightPlanCard;
