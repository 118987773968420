import React, {useEffect, useState, useRef} from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import SvgIcon from '@material-ui/core/SvgIcon';

import {makeStyles} from '@material-ui/core/styles';

import HeaderAppBar from './header-app-bar/header-app-bar.js';
import Map from './map/map.js';

import LayersMenu from './menus/layers-menu.js';
import SettingsMenu from './menus/settings-menu.js';
import WeatherMenu from './menus/weather-menu.js';
import FlightDrawer from './drawers/flight-drawer.js';
import BeaconMfDrawer from './drawers/beacon-mf-drawer.js';
import BeaconIafDrawer from './drawers/beacon-iaf-drawer.js';
import BeaconCopDrawer from './drawers/beacon-cop-drawer.js';
import AirportDrawer from './drawers/airport-drawer.js';
import RtgLATGODrawer from './drawers/rtg-latgo-drawer.js';
import RtgTEUJDrawer from './drawers/rtg-te-uj-drawer.js';

import SmartTimeline from './timelines/smart-timeline.js';

import {dateService} from './models/date/date.service.ts';
import {displayService} from './models/display/display.service.ts';
import {beaconService} from './models/beacon/beacon.service.ts';
import {sectorService} from './models/sector/sector.service.ts';
import {flowService} from './models/flow/flow.service.ts';
import {flightService} from './models/flight/flight.service.ts';
import {planificationService} from './models/planification/planification.service.ts';
import {airportService} from './models/airport/airport.service.ts';
import {meteoService} from './models/meteo/meteo.service.ts';
import {notificationService} from './models/notification/notification.service.ts';
import {militaryAreaService} from './models/military-area/military-area.service.ts';
import {capacityService} from './models/capacity/capacity.service.ts';
import {departureCountService} from './models/departure-count/departure-count.service.ts';
import {regulationService} from './models/regulation/regulation.service.ts';

import {ReactComponent as SvgIoda} from './svg-icons/logo_IODA.svg';

const leftDrawerWidth = 420;
const rightDrawerWidth = 400;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    height: '100vh',
    width: '100vw',
  },
  drawerContainer: {
    overflow: 'auto',
    height: '100%',
    display: 'flex',
  },
  leftDrawer: {
    width: leftDrawerWidth,
    flexShrink: 0,
  },
  leftDrawerPaper1: {
    width: leftDrawerWidth,
  },
  leftDrawerPaper2: {
    width: leftDrawerWidth,
    left: leftDrawerWidth,
  },
  rightDrawerBeacon: {
    width: rightDrawerWidth,
    flexShrink: 0,
  },
  rightDrawerFlight: {
    width: rightDrawerWidth,
    flexShrink: 0,
  },
  rightDrawerPaper: {
    width: rightDrawerWidth,
  },
  rightDrawerBeaconOpenedPaper: {
    width: rightDrawerWidth,
    right: rightDrawerWidth,
  },
  buttonGroup: {
    position: 'fixed',
    left: 15,
    bottom: 15,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menu: {
    marginLeft: '10px',
  },
  iodaIcon: {
    position: 'fixed',
    right: '20px',
    bottom: '10px',
    fontSize: '70px',
  },
  content: {
    marginLeft: -(2 * leftDrawerWidth) - 3 * rightDrawerWidth,
    width: '100%',
    height: '100%',
  },
}));

const timelineConfigs = {
  LFPO: [
    {
      name: '--',
      timeline1: null,
      timeline2: null,
    },
    {
      name: 'LFPO',
      timeline1: {type: 'airport', beaconName: 'LFPO', shift: 0},
      timeline2: null,
    },
  ],
  LFPG: [
    {
      name: '--',
      timeline1: null,
      timeline2: null,
    },
    {
      name: 'LFPGARN',
      timeline1: {type: 'airport', beaconName: 'LFPGARN', shift: 0},
      timeline2: null,
    },
    {
      name: 'LFPGARS',
      timeline1: {type: 'airport', beaconName: 'LFPGARS', shift: 0},
      timeline2: null,
    },
    {
      name: 'LFPGARN + LFPGARS',
      timeline1: {type: 'airport', beaconName: 'LFPGARN', shift: 0},
      timeline2: {type: 'airport', beaconName: 'LFPGARS', shift: 0},
    },
    {
      name: 'COP UJ',
      timeline1: {type: 'cop', beaconName: 'COP UJ', shift: 0},
      timeline2: null,
    },
    {
      name: 'COP TE',
      timeline1: {type: 'cop', beaconName: 'COP TE', shift: 0},
      timeline2: null,
    },
    {
      name: 'LFPGARN + COP TE',
      timeline1: {type: 'airport', beaconName: 'LFPGARN', shift: 0},
      timeline2: {type: 'cop', beaconName: 'COP TE', shift: 23},
    },
    {
      name: 'LFPGARS + COP UJ',
      timeline1: {type: 'airport', beaconName: 'LFPGARS', shift: 0},
      timeline2: {type: 'cop', beaconName: 'COP UJ', shift: 37},
    },
  ],
};

function App() {
  const classes = useStyles();

  // State
  const [rightFlightOpen, setRightFlightOpen] = useState(false);
  const [rightBeaconOpen, setRightBeaconOpen] = useState(false);
  const [selectedName, setSelectedName] = useState('');
  const [selectedAirport, setSelectedAirport] = useState('');
  const [airportList, setAirportList] = useState([]);
  const [iafList, setIafList] = useState([]);
  const [copList, setCopList] = useState([]);
  const [mfList, setMfList] = useState([]);

  const [timelineConfigurations, setTimelineConfigurations] = useState(null);
  const [timelineConfiguration, setTimelineConfiguration] = useState(null);

  // Heure sélectionnée pour les timelines
  const currentDate = dateService.getDate();
  const [selectedTime, setSelectedTime] = useState(
    new Date(currentDate.getTime() - (currentDate.getTime() % 60000)),
  );

  // Refs des subscriptions
  const selectedFlightSubscription = useRef(null);
  const selectedBeaconSubscription = useRef(null);
  const selectedAirportSubscription = useRef(null);
  const beaconsAirportSubscription = useRef(null);
  const beaconsIafSubscription = useRef(null);
  const beaconsCopSubscription = useRef(null);
  const beaconsMfSubscription = useRef(null);

  useEffect(() => {
    // On lance les services à l'initialisation
    dateService.start();
    displayService.start();
    beaconService.start();
    sectorService.start();
    flowService.start();
    airportService.start();
    notificationService.start();
    meteoService.start();
    planificationService.start();
    flightService.start();
    militaryAreaService.start();
    capacityService.start();
    departureCountService.start();
    regulationService.start();
    // Abonnement aux modeles
    selectedFlightSubscription.current = flightService
      .selectSelected()
      .subscribe((callsign) => setRightFlightOpen(!!callsign));
    selectedBeaconSubscription.current = beaconService
      .selectSelectedBeacon()
      .subscribe((beacon) => setSelectedName(beacon && beacon.name ? beacon.name : ''));
    selectedAirportSubscription.current = airportService
      .selectIcaoAirport()
      .subscribe(setSelectedAirport);

    return () => {
      // On se déabonne de tous les modèles
      if (selectedFlightSubscription.current) {
        selectedFlightSubscription.current.unsubscribe();
      }
      if (selectedBeaconSubscription.current) {
        selectedBeaconSubscription.current.unsubscribe();
      }
      if (selectedAirportSubscription.current) {
        selectedAirportSubscription.current.unsubscribe();
      }
      if (beaconsAirportSubscription.current) {
        beaconsAirportSubscription.current.unsubscribe();
      }
      if (beaconsIafSubscription.current) {
        beaconsIafSubscription.current.unsubscribe();
      }
      if (beaconsCopSubscription.current) {
        beaconsCopSubscription.current.unsubscribe();
      }
      if (beaconsMfSubscription.current) {
        beaconsMfSubscription.current.unsubscribe();
      }

      // Lorsque le composant est détruit, on arrête les services
      dateService.destroy();
      displayService.destroy();
      beaconService.destroy();
      sectorService.destroy();
      flowService.destroy();
      airportService.destroy();
      notificationService.destroy();
      meteoService.destroy();
      planificationService.destroy();
      flightService.destroy();
      militaryAreaService.destroy();
      capacityService.destroy();
      departureCountService.destroy();
      regulationService.destroy();
    };
  }, []);

  useEffect(() => {
    setRightBeaconOpen(!!selectedName);
  }, [selectedName]);

  useEffect(() => {
    setTimelineConfigurations(timelineConfigs[selectedAirport]);
    setTimelineConfiguration(
      timelineConfigs[selectedAirport] && timelineConfigs[selectedAirport][0]
        ? timelineConfigs[selectedAirport][0]
        : null,
    );

    if (beaconsAirportSubscription.current) {
      beaconsAirportSubscription.current.unsubscribe();
    }
    if (beaconsIafSubscription.current) {
      beaconsIafSubscription.current.unsubscribe();
    }
    if (beaconsCopSubscription.current) {
      beaconsCopSubscription.current.unsubscribe();
    }
    if (beaconsMfSubscription.current) {
      beaconsMfSubscription.current.unsubscribe();
    }

    beaconsAirportSubscription.current = beaconService
      .selectBeaconsAirportForAirport(selectedAirport)
      .subscribe((airportList) => setAirportList(airportList.map((airport) => airport.name)));
    beaconsIafSubscription.current = beaconService
      .selectBeaconsIafForAirport(selectedAirport)
      .subscribe((iafList) => setIafList(iafList.map((iaf) => iaf.name)));
    beaconsCopSubscription.current = beaconService
      .selectBeaconsCopForAirport(selectedAirport)
      .subscribe((copList) => setCopList(copList.map((cop) => cop.name)));
    beaconsMfSubscription.current = beaconService
      .selectBeaconsMfForAirport(selectedAirport)
      .subscribe((mfList) => setMfList(mfList.map((mf) => mf.name)));
  }, [selectedAirport]);

  const handleTimelineConfigurationChange = (event) => {
    setTimelineConfiguration(event.target.value);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />

      {/* AppBar */}
      <HeaderAppBar
        timelinesConfigurations={timelineConfigurations}
        timelineConfiguration={timelineConfiguration}
        selectedAirport={selectedAirport}
        handleTimelineConfigurationChange={handleTimelineConfigurationChange}
      />

      {/* Drawer gauche 2 (si 2 timelines sont ouvertes) */}
      <Drawer
        className={classes.leftDrawer}
        variant="persistent"
        anchor="left"
        open={!!(timelineConfiguration && timelineConfiguration.timeline2)}
        classes={{paper: classes.leftDrawerPaper2}}>
        <Toolbar />
        <div className={classes.drawerContainer}>
          <SmartTimeline
            type={
              timelineConfiguration &&
              timelineConfiguration.timeline2 &&
              timelineConfiguration.timeline2.type
                ? timelineConfiguration.timeline2.type
                : null
            }
            airport={selectedAirport}
            beaconName={
              timelineConfiguration &&
              timelineConfiguration.timeline2 &&
              timelineConfiguration.timeline2.beaconName
                ? timelineConfiguration.timeline2.beaconName
                : null
            }
            selectedTime={selectedTime}
            shift={
              timelineConfiguration &&
              timelineConfiguration.timeline2 &&
              timelineConfiguration.timeline2.shift
                ? timelineConfiguration.timeline2.shift
                : null
            }
            setSelectedTime={setSelectedTime}
          />
        </div>
      </Drawer>

      {/* Drawer gauche 1 (timeline) */}
      <Drawer
        className={classes.leftDrawer}
        variant="persistent"
        anchor="left"
        open={!!(timelineConfiguration && timelineConfiguration.timeline1)}
        classes={{paper: classes.leftDrawerPaper1}}>
        <Toolbar />
        <div className={classes.drawerContainer}>
          <SmartTimeline
            type={
              timelineConfiguration &&
              timelineConfiguration.timeline1 &&
              timelineConfiguration.timeline1.type
                ? timelineConfiguration.timeline1.type
                : null
            }
            airport={selectedAirport}
            beaconName={
              timelineConfiguration &&
              timelineConfiguration.timeline1 &&
              timelineConfiguration.timeline1.beaconName
                ? timelineConfiguration.timeline1.beaconName
                : null
            }
            selectedTime={selectedTime}
            shift={
              timelineConfiguration &&
              timelineConfiguration.timeline1 &&
              timelineConfiguration.timeline1.shift
                ? timelineConfiguration.timeline1.shift
                : null
            }
            setSelectedTime={setSelectedTime}
          />
        </div>
      </Drawer>

      {/* Menus */}
      <div
        className={classes.buttonGroup}
        style={{
          left:
            (!timelineConfiguration || !timelineConfiguration.timeline1
              ? 0
              : timelineConfiguration.timeline1 && timelineConfiguration.timeline2
              ? 2
              : timelineConfiguration.timeline1
              ? 1
              : 0) * leftDrawerWidth,
        }}>
        <SettingsMenu className={classes.menu} />
        <LayersMenu className={classes.menu} />
        <WeatherMenu className={classes.menu} />
      </div>

      {/* Drawer droit : vol (si drawer beacon ouvert) */}
      <Drawer
        className={classes.rightDrawerFlight}
        variant="persistent"
        anchor="right"
        open={rightFlightOpen && rightBeaconOpen}
        classes={{paper: classes.rightDrawerBeaconOpenedPaper}}>
        <Toolbar />
        <div className={classes.drawerContainer}>
          <FlightDrawer />
        </div>
      </Drawer>

      {/* Drawer droit : vol (si drawer beacon fermé) */}
      <Drawer
        className={classes.rightDrawerFlight}
        variant="persistent"
        anchor="right"
        open={rightFlightOpen && !rightBeaconOpen}
        classes={{paper: classes.rightDrawerPaper}}>
        <Toolbar />
        <div className={classes.drawerContainer}>
          <FlightDrawer />
        </div>
      </Drawer>

      {/* Drawer droit : beacon */}
      <Drawer
        className={classes.rightDrawerBeacon}
        variant="persistent"
        anchor="right"
        open={rightBeaconOpen}
        classes={{paper: classes.rightDrawerPaper}}>
        <Toolbar />
        <div className={classes.drawerContainer}>
          {airportList.includes(selectedName) ? (
            <AirportDrawer name={selectedName} />
          ) : iafList.includes(selectedName) ? (
            <BeaconIafDrawer name={selectedName} />
          ) : copList.includes(selectedName) ? (
            <BeaconCopDrawer name={selectedName} />
          ) : mfList.includes(selectedName) ? (
            <BeaconMfDrawer name={selectedName} />
          ) : selectedName === 'RTG LATGO' ? ( // Balise particulière : Rerouting LATGO
            <RtgLATGODrawer name={selectedName} />
          ) : selectedName === 'RTG TE-UJ' ? ( // Balise particulière : Rerouting TE - UJ
            <RtgTEUJDrawer name={selectedName} />
          ) : (
            ''
          )}
        </div>
      </Drawer>

      {/* Carte */}
      <main className={classes.content}>
        <Map className={classes.content} />
      </main>

      {/* Logo IODA */}
      <SvgIcon
        className={classes.iodaIcon}
        component={SvgIoda}
        height={70}
        width={70}
        viewBox="0 0 64 63"
      />
    </div>
  );
}

export default App;
