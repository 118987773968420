import {Store, StoreConfig} from '@datorama/akita';
import {DateState} from './date.model';

export function createInitialState(): DateState {
  const date = new Date();

  return {
    date: date,
    day: date.getUTCDay(),
    hour: date.getUTCHours(),
    minute: date.getUTCMinutes(),
    tenSecond: Math.trunc(date.getUTCSeconds() / 10),
    updatePeriod: Math.trunc(date.getUTCSeconds() / 4),
    second: date.getUTCSeconds(),
  };
}

@StoreConfig({name: 'date'})
export class DateStore extends Store<DateState> {
  constructor() {
    super(createInitialState());
  }
}

export const dateStore = new DateStore();
