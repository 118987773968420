import {switchMap} from 'rxjs/operators';
import {QueryEntity} from '@datorama/akita';
import {MeteoState, MeteoStore, meteoStore} from './meteo.store';

export class MeteoQuery extends QueryEntity<MeteoState> {
  constructor(protected store: MeteoStore) {
    super(store);
  }

  selectHovered() {
    return this.select('hovered');
  }

  selectHoveredMeteoEvent() {
    return this.selectHovered().pipe(
      switchMap((hovered) =>
        hovered ? this.selectEntity((entity) => entity.id === hovered) : this.selectMany([]),
      ),
    );
  }

  selectAllTurbulences() {
    return this.selectAll({filterBy: (entity) => entity.type === 'Turbulence'});
  }

  selectAllConvections() {
    return this.selectAll({filterBy: (entity) => entity.type === 'ConvectionCell'});
  }

  selectAllLightnings() {
    return this.selectAll({filterBy: (entity) => entity.type === 'LightningCell'});
  }
}

export const meteoQuery = new MeteoQuery(meteoStore);
