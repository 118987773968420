import React, {useEffect, useState, useRef} from 'react';

import {makeStyles} from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import Popover from '@material-ui/core/Popover';
import SvgIcon from '@material-ui/core/SvgIcon';

import {ReactComponent as SvgSettings} from '../svg-icons/settings.svg';
import {ReactComponent as SvgLabels} from '../svg-icons/labels.svg';

import {displayService} from '../models/display/display.service.ts';

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'flex',
    padding: '20px',
  },
  typographyEtiquettes: {
    color: '#5b637b',
    fontSize: '20px',
    fontWeight: 'bold',
    paddingBottom: '10px',
  },
  checks: {
    color: '#5b637b',
  },
  divider: {
    width: 230,
  },
  labelsIcon: {
    height: 30,
    width: 30,
  },
  checkLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '10px',
  },
}));

function SettingsMenu(props) {
  const classes = useStyles();

  // States
  const [labelsDisplayed, setLabelsDisplayed] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);

  // Ref du bouton d'ouverture du menu
  const button = useRef(null);

  // Mémorisation des subscription
  const labelsSubscription = useRef(null);

  useEffect(() => {
    // Abonnement au model au 'mount' du composant
    labelsSubscription.current = displayService.areLabelsDisplayed().subscribe(setLabelsDisplayed);

    return () => {
      // Desabonnement au model au 'unmount' du composant
      if (labelsSubscription.current) {
        labelsSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Ouverture du menu
  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  // Fermeture du menu
  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  // Clic sur la checkbox d'affichage des labels
  const handleClick = () => {
    displayService.updateLabelsDisplayed(!labelsDisplayed);
  };

  return (
    <>
      <Fab aria-label="settings" className={props.className} onClick={handleMenuOpen} ref={button}>
        <SvgIcon component={SvgSettings} viewBox="10 10 25 25" />
      </Fab>

      <Popover
        open={menuOpen}
        anchorEl={button.current}
        onClose={handleMenuClose}
        anchorOrigin={{vertical: -10, horizontal: 'left'}}
        transformOrigin={{vertical: 'bottom', horizontal: 'left'}}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            <Typography className={classes.typographyEtiquettes}>Etiquettes des vols</Typography>
            <Divider className={classes.divider} />
            <div className={classes.checkLine}>
              <FormControlLabel
                control={
                  <Checkbox
                    className={classes.checks}
                    color="default"
                    checked={labelsDisplayed}
                    onClick={handleClick}
                    name="affichage"
                  />
                }
                label="Affichage permanent"
              />
              <SvgIcon component={SvgLabels} viewBox="0 0 31.598 25.118" height="32" width="32" />
            </div>
          </FormGroup>
        </FormControl>
      </Popover>
    </>
  );
}

export default SettingsMenu;
