import React from 'react';

import './beacons-styles.css';

function BeaconOtherAirport({x, y, name, nbFlights}) {
  return (
    <g className="beaconOtherAirport" transform={`translate (${x} ${y})`}>
      <circle className="beaconCircle" cx="0" cy="0" r="9" />
      <rect x="-2" y="10" width="4" height="7" />
      <rect x="-2" y="-17" width="4" height="7" />
      <rect x="-17" y="-2" width="7" height="4" />
      <rect x="10" y="-2" width="7" height="4" />
      <text className="name" x="0" y="-22" textAnchor="middle">
        {name}
      </text>
      <text className="nbVolsLabel" textAnchor="middle" dominantBaseline="central">
        {nbFlights}
      </text>
    </g>
  );
}

export default BeaconOtherAirport;
