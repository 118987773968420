import {webSocket} from '../../utils/serverWebSocket';
import {Socket} from 'socket.io-client';

import {meteoStore, MeteoStore} from './meteo.store';
import {MeteoEventsList, MeteoEvent} from './meteo.model';
import {meteoQuery} from './meteo.query';

import * as d3 from 'd3';

export class MeteoService {
  private socket?: Socket;

  constructor(private meteoStore: MeteoStore) {}

  start() {
    // on s'assure que le store est vide
    meteoStore.remove();

    // On s'abonne au WS
    this.socket = webSocket().getSocket();
    this.onMessageReceived = this.onMessageReceived.bind(this);
    this.socket.on('message', this.onMessageReceived);
  }

  destroy(): void {
    // Déconnexion du serveur
    if (this.socket) {
      this.socket.off('message', this.onMessageReceived);
      this.socket = undefined;
    }

    // On vide le store
    meteoStore.remove();
  }

  onMessageReceived(message: any) {
    // Formattage des données reçues
    const receivedObject: any = JSON.parse(message);

    // Reception uniquement des messages de type 'meteoEventsUpdated'
    if (receivedObject.typeMessage === 'meteoEventsUpdated') {
      const meteoEventsList: MeteoEventsList = {
        type: receivedObject.data.type,
        meteoEvents: receivedObject.data.meteoEvents.map((meteoEvent: any) => ({
          ...meteoEvent,
          properties: {
            ...meteoEvent.properties,
            start: new Date(meteoEvent.properties.start), // Instanciation de la date start
            end: new Date(meteoEvent.properties.end), // Instanciation de la date end
            ...(!!meteoEvent.properties.analysisTime && {
              analysisTime: new Date(meteoEvent.properties.analysisTime),
            }), // Instanciation de la date analysisTime, si présente dans le message
          },
        })),
      };

      // Mise à jour des événements météo stockés
      this.updateMeteoEventsOfType(meteoEventsList.type, meteoEventsList.meteoEvents);
    }
  }

  updateMeteoEventsOfType(
    type: 'LightningCell' | 'ConvectionCell' | 'Turbulence',
    meteoEvents: MeteoEvent[],
  ) {
    // on vide le store des événements meteo dont le type correspond aux nouveaux reçus
    meteoStore.remove((meteoEvent) => meteoEvent.type === type);

    // on ajoute au store les nouveaux événements que l'on a reçu
    meteoStore.add(meteoEvents);
  }

  /**
   * Méthode permettant de s'abonner aux changements des turbulences
   */
  selectAllTurbulences() {
    return meteoQuery.selectAllTurbulences();
  }

  /**
   * Méthode permettant de s'abonner aux changements des convections
   */
  selectAllConvections() {
    return meteoQuery.selectAllConvections();
  }

  /**
   * Méthode permettant de s'abonner aux changements des lightnings
   */
  selectAllLightnings() {
    return meteoQuery.selectAllLightnings();
  }

  /**
   * Donne la projection sur la carte
   */
  getProjection() {
    return d3
      .geoMercator()
      .translate([800, 1600])
      .scale(8000 / (2 * Math.PI));
  }
}

export const meteoService = new MeteoService(meteoStore);
