import React, {useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import '../graphs-styles.css';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import DeleteIcon from '@material-ui/icons/Delete';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import PlanificationsGraphEditCurrent from './planifications-graph-edit-current.js';
import PlanificationsGraphAdd from './planifications-graph-add.js';
import {planificationService} from '../../../models/planification/planification.service.ts';
import {dateService} from '../../../models/date/date.service.ts';

function PlanificationsGraph({airport, runway, planifications}) {
  // Ref du chart container
  const chartRef = useRef(null);

  // Passage du graphe en mode suppression de planification
  const [deleteMode, setDeleteMode] = useState(false);

  // Gestion de la Dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  // Selection d'une planification à supprimer (celle ci sera supprimée si l'utilisateur confirme dans la Dialog)
  const [selectedPlanification, setSelectedPlanification] = useState({
    type: '',
    planification: {},
  });

  const selectPlanificationForDeletion = (type, planification) => {
    if (deleteMode) {
      setSelectedPlanification({type, planification});
      setDialogOpen(true);
    }
  };

  const startDeletion = () => {
    if (deleteMode) {
      setDeleteMode(false);
      setDeletePlanificationEnabled(false);
    } else {
      setDeleteMode(true);
      setDeletePlanificationEnabled(true);
    }
  };

  const quitDeletion = () => {
    if (deleteMode) {
      setDeleteMode(false);
      setDeletePlanificationEnabled(false);
    }
  };

  const validateDeletion = () => {
    switch (selectedPlanification.type) {
      case 'configurationChange':
        planificationService.deleteConfigurationChangeForAirport(
          selectedPlanification.planification,
          airport,
        );
        break;
      case 'closure':
        planificationService.deleteClosureForAirport(
          selectedPlanification.planification,
          airport,
          runway,
        );
        break;
      case 'cvfChange':
        planificationService.deleteCvfChangeForAirport(
          selectedPlanification.planification,
          airport,
        );
        break;
      case 'cadencyChange':
        planificationService.deleteCadencyChangeForAirport(
          selectedPlanification.planification,
          airport,
          runway,
        );
        break;
      default:
        break;
    }
    setDialogOpen(false);
    setDeleteMode(false);
    setDeletePlanificationEnabled(false);
  };

  // Si un menu d'edition est ouvert, on disable les autres
  const [editCurrentConfigurationEnabled, setEditCurrentConfigurationEnabled] = useState(false);
  const [addPlanificationEnabled, setAddPlanificationEnabled] = useState(false);
  const [deletePlanificationEnabled, setDeletePlanificationEnabled] = useState(false);

  // Dessin du chart

  // Height et Width du graphe
  const h = 90;
  const w = 320;

  // Marges
  const margins = {
    top: 20,
    right: 20,
    bottom: 20,
    left: 32,
  };

  const removePreviousChart = () => {
    while (chartRef.current && chartRef.current.firstChild) {
      chartRef.current.removeChild(chartRef.current.firstChild);
    }
  };

  const drawChart = (planifications) => {
    // Paramètres des marqueurs cercles (changements de CVF et cadence)
    const circleRadius = 14;
    const circleStrokeWidth = 1;
    const lineStrokeColor = '#000000';

    const cvfCircleY = 0.28;
    const cvfColor = '#E3B963';

    const cadencyCircleY = 0.8;
    const cadencyColor = '#000000';

    // Taille maximum de l'icone de fermeture
    const closureIconMaxSize = 20;

    // Echelle et axe X
    const now = dateService.getDate();
    const xScale = d3
      .scaleTime()
      .domain([now, new Date(now.getTime() + 3 * 60 * 60000)])
      .range([margins.left, w - margins.right]);
    const xAxis = d3
      .axisBottom()
      .scale(xScale)
      .tickArguments([d3.timeMinute.every(20)])
      .tickFormat((domainValue, index) => {
        const tick = d3.utcFormat('%H:%M')(domainValue, index);
        return tick.endsWith(':00') ? tick : '';
      });

    // Echelle et axe Y
    const yScale = d3
      .scaleLinear()
      .domain([0, 1])
      .range([h - margins.bottom, 0]);
    const yAxis = d3.axisLeft().scale(yScale).tickValues([]).tickSize(0);

    // Création du graphe
    const svg = d3
      .select(chartRef.current)
      .append('svg')
      .attr('width', w)
      .attr('height', h)
      .attr('class', 'bar')
      .attr('marginLeft', 5);

    // Changements de configurations
    if (planifications && planifications.configChangesClosures) {
      const configChangeGroup = svg
        .selectAll('bars')
        .data(planifications.configChangesClosures)
        .enter()
        .append('g')
        .attr('cursor', 'default')
        .attr('class', () => (deleteMode ? 'deleting' : ''))
        .on('click', (event, el) => selectPlanificationForDeletion('configurationChange', el));

      // Rectangle
      configChangeGroup
        .append('rect')
        .attr('fill', '#E3B963')
        .attr('x', (d) => xScale(d.closureFrom)) // valeur x des barres
        .attr('y', yScale(1))
        .attr('width', (d) => xScale(d.closureTo) - xScale(d.closureFrom)) // largeur des barres
        .attr('height', h - margins.bottom);

      // Label QFU
      configChangeGroup
        .append('text')
        .text((d) => {
          const orientation = d.config
            .replace('PO', '')
            .replace('PG_', '')
            .replace('_CVF', '')
            .replace(d.qfu, '');
          return d.qfu && orientation ? orientation + d.qfu : '';
        })
        .attr('font-size', 11)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('x', (d) => (xScale(d.closureFrom) + xScale(d.closureTo)) / 2)
        .attr('y', (h - margins.bottom) / 2)
        .attr('fill', '#000000');

      // CVF icon
      if (cvfIconBlack) {
        configChangeGroup
          .filter((d) => d.isCvfConfig)
          .append(() => cvfIconBlack.cloneNode(true))
          .attr('height', 10)
          .attr('width', 10)
          .attr('x', (d) => (xScale(d.closureFrom) + xScale(d.closureTo)) / 2 - 5)
          .attr('y', (h - margins.bottom) / 2 + 10);
      }
    }

    // Fermetures
    if (planifications && planifications.closures) {
      const closureGroup = svg
        .selectAll('bars')
        .data(planifications.closures)
        .enter()
        .append('g')
        .attr('cursor', 'default')
        .attr('class', () => (deleteMode ? 'deleting' : ''))
        .on('click', (event, el) => selectPlanificationForDeletion('closure', el));

      // Rectangle
      closureGroup
        .append('rect')
        .attr('fill', (clos) => {
          // couleur des barres
          switch (clos.type) {
            case 'REAL':
              return '#C9623D';
            case 'GAP':
              return '#000000';
            default:
              return '#000000';
          }
        })
        .attr('x', (d) => xScale(d.from)) // valeur x des barres
        .attr('y', yScale(1))
        .attr('width', (d) => xScale(d.to) - xScale(d.from)) // largeur des barres
        .attr('height', h - margins.bottom)
        .attr('class', 'closure');

      // Icone
      if (closureIcon) {
        closureGroup
          .append(() => closureIcon.cloneNode(true))
          .attr('height', (d) =>
            Math.min(closureIconMaxSize, 0.8 * (xScale(d.to) - xScale(d.from))),
          )
          .attr('width', (d) => Math.min(closureIconMaxSize, 0.8 * (xScale(d.to) - xScale(d.from))))
          .attr('x', (d) => {
            const xCenter = (xScale(d.from) + xScale(d.to)) / 2;
            const iconWidth = Math.min(closureIconMaxSize, 0.8 * (xScale(d.to) - xScale(d.from)));
            return xCenter - iconWidth / 2;
          })
          .attr(
            'y',
            (d) =>
              (h - margins.bottom) / 2 -
              Math.min(closureIconMaxSize, 0.8 * (xScale(d.to) - xScale(d.from))) / 2,
          );
      }
    }

    // Changements CVF <-> non CVF
    if (planifications && planifications.cvfChanges) {
      const grp = svg
        .selectAll('bars')
        .data(planifications.cvfChanges)
        .enter()
        .append('g')
        .attr('cursor', 'default')
        .attr('class', () => (deleteMode ? 'deleting' : ''))
        .on('click', (event, el) => selectPlanificationForDeletion('cvfChange', el));

      // Cercle
      grp
        .append('circle')
        .attr('cx', (d) => xScale(d.at))
        .attr('cy', yScale(cvfCircleY))
        .attr('r', circleRadius)
        .attr('fill', cvfColor)
        .attr('stroke', '#FFFFFF')
        .attr('stroke-width', circleStrokeWidth);

      // Icone
      // (d.notCvfToCvf ? 0.6 : 0.75) -> Dimensionnement de l'icone en fonction de la taille du cercle. Si icone de lune, 60% de la taille du cercle, sinon 75%
      if (cvfIcon && endCvfIcon) {
        grp
          .append((d) => (d.notCvfToCvf ? cvfIcon.cloneNode(true) : endCvfIcon.cloneNode(true)))
          .attr('x', (d) => xScale(d.at) - (d.notCvfToCvf ? 0.6 : 0.75) * circleRadius)
          .attr('y', (d) => yScale(cvfCircleY) - (d.notCvfToCvf ? 0.6 : 0.75) * circleRadius)
          .attr('height', (d) => (d.notCvfToCvf ? 0.6 : 0.75) * 2 * circleRadius)
          .attr('width', (d) => (d.notCvfToCvf ? 0.6 : 0.75) * 2 * circleRadius);
      }

      // Ligne
      grp
        .append('line')
        .attr('x1', (d) => xScale(d.at))
        .attr('y1', yScale(0))
        .attr('x2', (d) => xScale(d.at))
        .attr('y2', yScale(cvfCircleY) + circleRadius)
        .attr('stroke', lineStrokeColor)
        .attr('stroke-width', circleStrokeWidth);
    }

    // Changements de cadence
    if (planifications && planifications.cadencyChanges) {
      const grp = svg
        .selectAll('bars')
        .data(planifications.cadencyChanges)
        .enter()
        .append('g')
        .attr('cursor', 'default')
        .attr('class', () => (deleteMode ? 'deleting' : ''))
        .on('click', (event, el) => selectPlanificationForDeletion('cadencyChange', el));

      // Cercle
      grp
        .append('circle')
        .attr('cx', (d) => xScale(d.at))
        .attr('cy', yScale(cadencyCircleY))
        .attr('r', circleRadius)
        .attr('fill', cadencyColor)
        .attr('stroke', '#FFFFFF')
        .attr('stroke-width', circleStrokeWidth);

      // Valeur de la nouvelle cadence
      grp
        .append('text')
        .text((d) => d.newCadency)
        .attr('font-size', 11)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('x', (d) => xScale(d.at))
        .attr('y', yScale(cadencyCircleY - 0.01))
        .attr('fill', '#ffffff');

      // Ligne
      grp
        .append('line')
        .attr('x1', (d) => xScale(d.at))
        .attr('y1', yScale(0))
        .attr('x2', (d) => xScale(d.at))
        .attr('y2', yScale(cadencyCircleY) + circleRadius)
        .attr('stroke', lineStrokeColor)
        .attr('stroke-width', circleStrokeWidth);
    }

    // Ajout du rectangle tout à gauche du graphique
    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', margins.left)
      .attr('height', h - margins.bottom)
      .style('fill', 'grey');

    // Ajout du QFU ouvert
    const qfuGroup = svg
      .append('g')
      .attr('transform', `translate(0 ${(h - margins.bottom) * 0.08})`);

    // Insertion de l'icone si elle a bien été pré chargée
    if (airportIcon) {
      const icon = airportIcon.cloneNode(true);
      icon.setAttribute('width', margins.left * 0.4);
      icon.setAttribute('height', margins.left * 0.4);
      icon.setAttribute('x', margins.left * 0.58);
      icon.setAttribute('y', 0);
      icon.setAttribute('dominant-baseline', 'text-before-edge');
      qfuGroup.node().append(icon);
    }

    qfuGroup
      .append('text')
      .text(() => (planifications && planifications.currentQFU ? planifications.currentQFU : '--'))
      .attr('font-size', 12)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'text-before-edge')
      .attr('x', margins.left * 0.28)
      .attr('y', 0)
      .attr('fill', '#ffffff');

    // Ajout de la cadence courante
    const cadencyGroup = svg.append('g');

    cadencyGroup
      .append('circle')
      .attr('cx', margins.left / 2)
      .attr('cy', (h - margins.bottom) * 0.65)
      .attr('r', circleRadius)
      .attr('fill', cadencyColor)
      .attr('stroke', '#FFFFFF')
      .attr('stroke-width', circleStrokeWidth);

    cadencyGroup
      .append('text')
      .text(() =>
        planifications && planifications.currentCadency
          ? `${planifications.currentCadency}s`
          : '--',
      )
      .attr('font-size', 11)
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'central')
      .attr('x', margins.left / 2)
      .attr('y', (h - margins.bottom) * 0.65)
      .attr('fill', '#ffffff');

    // Ajout de l'axe des x que l'on décale pour faire décaler en fonction des minutes
    svg
      .append('g')
      .call(xAxis)
      .attr('transform', `translate(0,${h - 20})`)
      .attr('class', 'axisX');

    // Ajout d'une ligne sur l'axe des abscisses
    svg
      .append('line')
      .attr('x1', 0)
      .attr('x2', 300)
      .attr('y1', h - 20)
      .attr('y2', h - 20)
      .style('stroke', 'black')
      .style('strokeWidth', '1');

    // Ajout de l'axe des ordonnées
    svg
      .append('g')
      .call(yAxis)
      .attr('class', 'axisWhite')
      .attr('transform', `translate(${margins.left},0)`);
  };

  // Icones gardées en mémoire
  const [airportIcon, setAirportIcon] = useState(null);
  const [closureIcon, setClosureIcon] = useState(null);
  const [cvfIcon, setCvfIcon] = useState(null);
  const [cvfIconBlack, setCvfIconBlack] = useState(null);
  const [endCvfIcon, setEndCvfIcon] = useState(null);

  // On mount
  useEffect(() => {
    // Chargement de l'icone d'aeroport
    d3.xml('svg/arr_airport.svg').then((data) => setAirportIcon(data.documentElement));

    // Chargement de l'icone de fermeture
    d3.xml('svg/fermeture_blanc.svg').then((data) => setClosureIcon(data.documentElement));

    // Chargement de l'icone de CVF
    d3.xml('svg/CVF2.svg').then((data) => setCvfIcon(data.documentElement));

    // Chargement de l'icone noire de CVF
    d3.xml('svg/CVF.svg').then((data) => setCvfIconBlack(data.documentElement));

    // Chargement de l'icone de fin de CVF
    d3.xml('svg/No_CVF2.svg').then((data) => setEndCvfIcon(data.documentElement));
  }, []);

  // On update
  useEffect(() => {
    removePreviousChart();
    drawChart(planifications);
  });

  const styles = {
    container: {
      display: 'grid',
      justifyItems: 'center',
    },
    edition: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginLeft: '25px',
      marginRight: '25px',
    },
    right: {
      marginLeft: 'auto',
      right: 0,
      display: 'flex',
      flexDirection: 'row',
    },
    message: {
      height: 12,
      textAlign: 'center',
      marginTop: 5,
      fontWeight: 600,
      color: '#607d8b',
    },
  };

  return (
    <div>
      <ClickAwayListener onClickAway={quitDeletion}>
        <div>
          <div ref={chartRef} style={styles.container} />
          <div style={styles.edition}>
            <PlanificationsGraphEditCurrent
              airport={airport}
              runway={runway}
              currentConfiguration={planifications ? planifications.currentConfiguration : null}
              currentCadency={planifications ? planifications.currentCadency : null}
              enabled={editCurrentConfigurationEnabled}
              disabled={addPlanificationEnabled || deletePlanificationEnabled}
              changeConfigurationDisabled={
                planifications &&
                (planifications.configChangesClosures.length > 0 ||
                  planifications.cvfChanges.length > 0)
              }
              onMenuOpen={() => setEditCurrentConfigurationEnabled(true)}
              onMenuClose={() => setEditCurrentConfigurationEnabled(false)}
            />
            <div style={styles.right}>
              <PlanificationsGraphAdd
                airport={airport}
                runway={runway}
                style={styles.addButton}
                planifications={planifications}
                currentConfiguration={planifications ? planifications.currentConfiguration : null}
                enabled={addPlanificationEnabled}
                disabled={editCurrentConfigurationEnabled || deletePlanificationEnabled}
                addConfigurationDisabled={
                  planifications &&
                  (planifications.configChangesClosures.length > 0 ||
                    planifications.cvfChanges.length > 0)
                }
                onMenuOpen={() => setAddPlanificationEnabled(true)}
                onMenuClose={() => setAddPlanificationEnabled(false)}
              />
              <button
                className={`edition-button delete-button ${
                  deletePlanificationEnabled ? 'enabled-button' : ''
                }`}
                onClick={startDeletion}
                disabled={addPlanificationEnabled || editCurrentConfigurationEnabled}>
                <DeleteIcon fontSize="small" />
              </button>
            </div>
          </div>
          <div style={styles.message}>
            {deleteMode ? 'Cliquez sur la planification à supprimer' : ''}
          </div>
        </div>
      </ClickAwayListener>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>Confirmer la suppression ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {selectedPlanification.type === 'configurationChange' ? (
              <span>
                Suppression du changement de configuration{' '}
                {selectedPlanification.planification.config
                  .replace('PO', '')
                  .replace('PG_', '')
                  .replace('_CVF', '')}
                {selectedPlanification.planification.config.endsWith('_CVF') ? (
                  <Brightness2Icon fontSize="inherit" />
                ) : (
                  ''
                )}{' '}
                à{' '}
                {selectedPlanification.planification.closureTo
                  .toLocaleTimeString('fr-FR', {timeZone: 'UTC'})
                  .slice(0, 5)}{' '}
                (avec fermeture de{' '}
                {selectedPlanification.planification.closureFrom
                  .toLocaleTimeString('fr-FR', {timeZone: 'UTC'})
                  .slice(0, 5)}{' '}
                à{' '}
                {selectedPlanification.planification.closureTo
                  .toLocaleTimeString('fr-FR', {timeZone: 'UTC'})
                  .slice(0, 5)}
                )
              </span>
            ) : selectedPlanification.type === 'cvfChange' ? (
              selectedPlanification.planification.notCvfToCvf ? (
                `Supression du début de CVF à ${selectedPlanification.planification.at
                  .toLocaleTimeString('fr-FR', {timeZone: 'UTC'})
                  .slice(0, 5)}`
              ) : (
                `Supression de la fin de CVF à ${selectedPlanification.planification.at
                  .toLocaleTimeString('fr-FR', {timeZone: 'UTC'})
                  .slice(0, 5)}`
              )
            ) : selectedPlanification.type === 'cadencyChange' ? (
              `Suppression du changement de cadence ${
                selectedPlanification.planification.newCadency
              } à ${selectedPlanification.planification.at
                .toLocaleTimeString('fr-FR', {timeZone: 'UTC'})
                .slice(0, 5)}`
            ) : selectedPlanification.type === 'closure' ? (
              `Suppression de la fermeture de type ${
                selectedPlanification.planification.type
              } de ${selectedPlanification.planification.from
                .toLocaleTimeString('fr-FR', {timeZone: 'UTC'})
                .slice(0, 5)} à ${selectedPlanification.planification.to
                .toLocaleTimeString('fr-FR', {timeZone: 'UTC'})
                .slice(0, 5)}`
            ) : (
              ''
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={validateDeletion} color="secondary" autoFocus>
            Confirmer
          </Button>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default PlanificationsGraph;
