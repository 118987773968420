import React, {useState, useRef, useEffect} from 'react';

import Timeline from './timeline.js';
import Flight from './flight.js';

import {beaconService} from '../models/beacon/beacon.service.ts';
import {dateService} from '../models/date/date.service.ts';
import {flightService} from '../models/flight/flight.service.ts';
import {planificationService} from '../models/planification/planification.service.ts';
import {capacityService} from '../models/capacity/capacity.service.ts';

function SmartTimeline({type, airport, beaconName, selectedTime, setSelectedTime, shift}) {
  // Abonnements aux modèles
  const currentTimeSubscription = useRef(null);
  const currentConfigurationSubscription = useRef(null);
  const currentCadencySubscription = useRef(null);
  const currentLoadSubscription = useRef(null);
  const flightSubscription = useRef(null);
  const hoveredCallsignSubscription = useRef(null);
  const selectedCallsignSubscription = useRef(null);
  const planificationSubscription = useRef(null);
  const beaconSubscription = useRef(null);

  // State
  const currentDate = dateService.getDate(); // Utilisé pour initialiser l'etat, en arrondissant à la minute
  const [currentTime, setCurrentTime] = useState(
    new Date(currentDate.getTime() - (currentDate.getTime() % 60000)),
  );
  const [currentConfiguration, setCurrentConfiguration] = useState(null);
  const [currentCadency, setCurrentCadency] = useState(null);
  const [currentLoad, setCurrentLoad] = useState([]);
  const [flights, setFlights] = useState([]);
  const [hoveredCallsign, setHoveredCallsign] = useState('');
  const [selectedCallsign, setSelectedCallsign] = useState('');
  const [planifications, setPlanifications] = useState(null);
  const [beacon, setBeacon] = useState(null);

  // IAFs situés à l'ouest
  const westIafs = useRef(['ODILO', 'MOPAR', 'BANOX']);

  // Au lancement, on s'abonne au modèle de Date et au modele beacon
  useEffect(() => {
    // Abonnement aux modèles
    currentTimeSubscription.current = dateService.selectMinute().subscribe((minute) => {
      const date = dateService.getDate();
      if (date) {
        setCurrentTime(new Date(date.getTime() - (date.getTime() % 60000)));
      }
    });
    hoveredCallsignSubscription.current = flightService
      .selectHovered()
      .subscribe(setHoveredCallsign);
    selectedCallsignSubscription.current = flightService
      .selectSelected()
      .subscribe(setSelectedCallsign);

    return () => {
      // Désabonnement aux modèles
      if (currentTimeSubscription.current) {
        currentTimeSubscription.current.unsubscribe();
      }
      if (currentConfigurationSubscription.current) {
        currentConfigurationSubscription.current.unsubscribe();
      }
      if (currentCadencySubscription.current) {
        currentCadencySubscription.current.unsubscribe();
      }
      if (currentLoadSubscription.current) {
        currentLoadSubscription.current.unsubscribe();
      }
      if (flightSubscription.current) {
        flightSubscription.current.unsubscribe();
      }
      if (hoveredCallsignSubscription.current) {
        hoveredCallsignSubscription.current.unsubscribe();
      }
      if (selectedCallsignSubscription.current) {
        selectedCallsignSubscription.current.unsubscribe();
      }
      if (planificationSubscription.current) {
        planificationSubscription.current.unsubscribe();
      }
      if (beaconSubscription.current) {
        beaconSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Lors du changement de airport on met à jour l'abonnement à la configuration courante
  useEffect(() => {
    if (currentConfigurationSubscription.current) {
      currentConfigurationSubscription.current.unsubscribe();
    }

    if (type === 'airport' && airport) {
      currentConfigurationSubscription.current = planificationService
        .selectCurrentConfigurationForAirport(airport)
        .subscribe(setCurrentConfiguration);
    } else {
      setCurrentConfiguration(null);
    }
  }, [type, airport]);

  // Lors du changement de airport et/ou beaconName et/ou currentConfiguration, on met à jour l'abonnement à la cadence
  useEffect(() => {
    if (currentCadencySubscription.current) {
      currentCadencySubscription.current.unsubscribe();
    }

    if (type === 'airport' && airport && beaconName && currentConfiguration) {
      const qfu = planificationService.getQfuForAirportAndConfig(
        airport,
        beaconName,
        currentConfiguration,
      );
      if (qfu) {
        currentCadencySubscription.current = planificationService
          .selectCurrentCadencyForAirportAndQfu(airport, qfu)
          .subscribe(setCurrentCadency);
      }
    } else {
      setCurrentCadency(null);
    }
  }, [type, airport, beaconName, currentConfiguration]);

  // Lors du changement d'airport et/ou beaconName, on met à jour la balise
  useEffect(() => {
    if (beaconSubscription.current) {
      beaconSubscription.current.unsubscribe();
    }

    if (airport && beaconName) {
      beaconSubscription.current = beaconService
        .selectBeaconForAirport(beaconName, airport)
        .subscribe(setBeacon);
    }
  }, [airport, beaconName]);

  // Lors du changement de airport et/ou beaconName et/ou beacon, on met à jour l'abonnement au load
  useEffect(() => {
    if (currentLoadSubscription.current) {
      currentLoadSubscription.current.unsubscribe();
    }

    if (airport && beaconName) {
      // Callback au changement de load
      const loadChangedCallback = (load) => {
        setCurrentLoad(
          load.loadArray.map((elem) => {
            // On prend les capacités de la balise
            const capacities = capacityService.getCapacityForTV(beaconName);

            // On on trouve la capacité à utiliser pour le créneau
            let periodCapacity =
              capacities && capacities.capacities
                ? capacities.capacities.reduce((accumulator, capacity) => {
                    const isCurrentCapacity =
                      (elem.from.getTime() >= capacity.start.getTime() &&
                        elem.from.getTime() < capacity.end.getTime()) ||
                      (elem.to.getTime() >= capacity.start.getTime() &&
                        elem.to.getTime() < capacity.end.getTime());
                    if (isCurrentCapacity && accumulator) {
                      return Math.min(capacity.capacity, accumulator);
                    } else if (isCurrentCapacity) {
                      return capacity.capacity;
                    } else {
                      return accumulator;
                    }
                  }, null)
                : null;
            if (!periodCapacity) {
              periodCapacity = beacon && beacon.defaultCapacity ? beacon.defaultCapacity : 999;
            }

            return {
              from: elem.from,
              to: elem.to,
              alertLevel:
                elem.load && periodCapacity
                  ? elem.load > Math.floor(1.2 * Math.floor(periodCapacity / 3))
                    ? 2
                    : elem.load > Math.floor(periodCapacity / 3)
                    ? 1
                    : 0
                  : 0,
            };
          }),
        );
      };

      // Abonnement au load
      if (type === 'airport') {
        currentLoadSubscription.current = flightService
          .selectLoadForAirportAndRunway(airport, beaconName)
          .subscribe(loadChangedCallback);
      } else if (type === 'cop') {
        currentLoadSubscription.current = flightService
          .selectLoadForCopAndAirport(beaconName, airport)
          .subscribe(loadChangedCallback);
      }
    }
  }, [type, airport, beaconName, beacon]);

  // Lors du changement de airport et/ou beaconName, on met à jour l'abonnement à la liste de vols
  useEffect(() => {
    if (flightSubscription.current) {
      flightSubscription.current.unsubscribe();
    }

    if (airport && beaconName) {
      // Abonnement à la liste de vols
      if (type === 'airport') {
        flightSubscription.current = flightService
          .selectFlightsForAirportAndRunway(airport, beaconName)
          .subscribe((flights) => {
            setFlights(
              flights
                .filter((flight) => !!(flight && flight.sta && flight.airborneStatus))
                .map((flight) => {
                  return {
                    at: flight.sta,
                    delay: flight && flight.delayRNW ? flight.delayRNW : null,
                    callsign: flight.callsign,
                    orientation: westIafs.current.includes(flight.iaf) ? 'w' : 'e',
                    properties: {
                      ...flight,
                      mfShortName: flight.mf
                        ? beaconService.getBeaconForAirport(flight.mf, airport).shortName
                        : null,
                    },
                  };
                }),
            );
          });
      } else if (type === 'cop') {
        flightSubscription.current = flightService
          .selectFlightsForAirportAndCop(airport, beaconName)
          .subscribe((flights) => {
            setFlights(
              flights
                .filter((flight) => !!(flight && flight.staMF && flight.airborneStatus))
                .map((flight) => {
                  return {
                    at: flight.staMF,
                    delay: flight && flight.delayMF ? flight.delayMF : null,
                    callsign: flight.callsign,
                    orientation: westIafs.current.includes(flight.iaf) ? 'w' : 'e',
                    properties: {
                      ...flight,
                      displayedTimeProperty: 'staMF',
                      mfShortName: flight.mf
                        ? beaconService.getBeaconForAirport(flight.mf, airport).shortName
                        : null,
                    },
                  };
                }),
            );
          });
      }
    }
  }, [type, airport, beaconName]);

  // Lors du changement de airport et/ou beaconName, on met à jour l'abonnement aux planifications
  useEffect(() => {
    if (planificationSubscription.current) {
      planificationSubscription.current.unsubscribe();
    }

    if (type === 'airport' && airport && beaconName) {
      planificationSubscription.current = planificationService
        .selectPlanificationForGraphForAirport(airport, beaconName)
        .subscribe((planifs) => {
          setPlanifications({
            ...planifs,
            cadencyChanges: planifs.cadencyChanges.filter((change) => {
              // On n'affiche pas les changements de cadence s'ils tombent à la fin d'un changement de config
              return !planifs.configChangesClosures.find(
                (closure) => closure.closureTo.getTime() === change.at.getTime(),
              );
            }),
          });
        });
    } else {
      setPlanifications(null);
    }
  }, [type, airport, beaconName]);

  const flightEnter = (flight) => {
    flightService.setHovered(flight.callsign);
  };

  const flightLeave = () => {
    flightService.setHovered('');
  };

  const flightClick = (flight) => {
    if (flight.callsign === selectedCallsign) {
      flightService.setSelected('');
    } else {
      flightService.setSelected(flight.callsign);
    }
  };

  const timelineClick = () => {
    flightService.setSelected('');
  };

  return selectedTime ? (
    <Timeline
      name={beaconName}
      currentTime={currentTime}
      selectedTime={selectedTime}
      shift={shift !== null && shift !== undefined ? shift : 0}
      setSelectedTime={setSelectedTime}
      horizon={240}
      currentRunway={
        currentConfiguration ? currentConfiguration.replace('PO', '').replace('PG_', '') : null
      }
      currentCadency={currentCadency}
      load={currentLoad}
      FlightComponent={Flight}
      flightData={flights}
      hoveredFlightCallsign={hoveredCallsign}
      selectedFlightCallsign={selectedCallsign}
      flightEnter={flightEnter}
      flightLeave={flightLeave}
      flightClick={flightClick}
      timelineClick={timelineClick}
      planifications={planifications}
    />
  ) : (
    ''
  );
}

export default SmartTimeline;
