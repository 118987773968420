import React, {useRef, useState, useEffect, useReducer} from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {makeStyles} from '@material-ui/core/styles';

import RtgGraphsCard from './cards/rtg-graphs-card.js';
import FlightListRtgLATGOCard from './cards/flight-list-rtg-latgo-card.js';

import {beaconService} from '../models/beacon/beacon.service.ts';
import {dateService} from '../models/date/date.service.ts';
import {flightService} from '../models/flight/flight.service.ts';
import {capacityService} from '../models/capacity/capacity.service.ts';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawerContainer: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 48,
  },
  drawerTitle: {
    flexGrow: 1,
  },
  name: {
    textAlign: 'center',
    fontSize: '26px',
    fontWeight: 'bold',
  },
  scrollableContent: {
    height: 'calc(100% - 48px)',
    overflow: 'auto',
  },
}));

function RtgLATGODrawer({name}) {
  const classes = useStyles();

  // Subscriptions
  const minutesLeftSubscription = useRef(null);

  const loadLFPGARNSubscription = useRef(null);
  const loadLFPGARSSubscription = useRef(null);

  const beaconLFPGARNSubscription = useRef(null);
  const beaconLFPGARSSubscription = useRef(null);

  const capacityLFPGARNSubscription = useRef(null);
  const capacityLFPGARSSubscription = useRef(null);

  const flightsRtg1Subscription = useRef(null);
  const flightsRtg2Subscription = useRef(null);

  // State
  const [minutesLeft, setMinutesLeft] = useState(null);

  const [loadLFPGARN, setLoadLFPGARN] = useState(null);
  const [loadLFPGARS, setLoadLFPGARS] = useState(null);

  const beaconReducerFunction = (currentBeacon, beacon) =>
    beacon
      ? {
          ...beacon,
          defaultCapacity: beacon.defaultCapacity || 999,
          defaultDepartureCapacity: beacon.defaultDepartureCapacity || 999,
        }
      : null;
  const [beaconLFPGARN, setBeaconLFPGARN] = useReducer(beaconReducerFunction, null);
  const [beaconLFPGARS, setBeaconLFPGARS] = useReducer(beaconReducerFunction, null);

  const [capacitiesLFPGARN, setCapacitiesLFPGARN] = useState(null);
  const [capacitiesLFPGARS, setCapacitiesLFPGARS] = useState(null);

  const flightsRtgReducerFunction = (currentFlights, flights) =>
    flights.map((fl) => {
      let oceanicEntryPoint = null;
      if (fl && fl.dep && fl.dep === 'LFRB') {
        oceanicEntryPoint = 'LFRB';
      } else if (fl && fl.icaoRoute && fl.icaoRoute.includes('RATKA')) {
        oceanicEntryPoint = 'RATKA';
      } else if (fl && fl.icaoRoute && fl.icaoRoute.includes('TAKAS')) {
        oceanicEntryPoint = 'TAKAS';
      } else if (fl && fl.icaoRoute && fl.icaoRoute.includes('NAKID')) {
        oceanicEntryPoint = 'NAKID';
      } else if (fl && fl.icaoRoute && fl.icaoRoute.includes('DOLUR')) {
        oceanicEntryPoint = 'DOLUR';
      }

      return {
        callsign: fl.callsign,
        delay: fl && fl.delayMf !== null && fl.delayMf !== undefined ? fl.delayMf : null,
        sta: fl && fl.sta ? fl.sta : null,
        oceanicEntryPoint,
      };
    });
  const [flightsRtg1, setFlightsRtg1] = useReducer(flightsRtgReducerFunction, null);
  const [flightsRtg2, setFlightsRtg2] = useReducer(flightsRtgReducerFunction, null);

  // Au mount du composant
  useEffect(() => {
    minutesLeftSubscription.current = dateService.selectMinutesLeft().subscribe(setMinutesLeft);

    // Subscription aux loads
    loadLFPGARNSubscription.current = flightService
      .selectLoadForAirportAndRunway('LFPG', 'LFPGARN')
      .subscribe((load) => setLoadLFPGARN(load.loadArray));
    loadLFPGARSSubscription.current = flightService
      .selectLoadForAirportAndRunway('LFPG', 'LFPGARS')
      .subscribe((load) => setLoadLFPGARS(load.loadArray));

    // Subscription aux beacons
    beaconLFPGARNSubscription.current = beaconService
      .selectBeaconForAirport('LFPGARN', 'LFPG')
      .subscribe(setBeaconLFPGARN);
    beaconLFPGARSSubscription.current = beaconService
      .selectBeaconForAirport('LFPGARS', 'LFPG')
      .subscribe(setBeaconLFPGARS);

    // Subscription aux capacités
    capacityLFPGARNSubscription.current = capacityService
      .selectCapacityForTV('LFPGARN')
      .subscribe((capacities) =>
        capacities && capacities.capacities
          ? setCapacitiesLFPGARN(capacities.capacities)
          : setCapacitiesLFPGARN(null),
      );
    capacityLFPGARSSubscription.current = capacityService
      .selectCapacityForTV('LFPGARS')
      .subscribe((capacities) =>
        capacities && capacities.capacities
          ? setCapacitiesLFPGARS(capacities.capacities)
          : setCapacitiesLFPGARS(null),
      );

    // Subscriptions aux listes de vols
    flightsRtg1Subscription.current = flightService
      .selectFlightsForTvAndAirport('LFFRTG1', 'LFPG')
      .subscribe(setFlightsRtg1);
    flightsRtg2Subscription.current = flightService
      .selectFlightsForTvAndAirport('LFFRTG2', 'LFPG')
      .subscribe(setFlightsRtg2);

    // Au unmount du composant
    return () => {
      if (minutesLeftSubscription.current) {
        minutesLeftSubscription.current.unsubscribe();
      }
      if (loadLFPGARNSubscription.current) {
        loadLFPGARNSubscription.current.unsubscribe();
      }
      if (loadLFPGARSSubscription.current) {
        loadLFPGARSSubscription.current.unsubscribe();
      }
      if (beaconLFPGARNSubscription.current) {
        beaconLFPGARNSubscription.current.unsubscribe();
      }
      if (beaconLFPGARSSubscription.current) {
        beaconLFPGARSSubscription.current.unsubscribe();
      }
      if (capacityLFPGARNSubscription.current) {
        capacityLFPGARNSubscription.current.unsubscribe();
      }
      if (capacityLFPGARSSubscription.current) {
        capacityLFPGARSSubscription.current.unsubscribe();
      }
      if (flightsRtg1Subscription.current) {
        flightsRtg1Subscription.current.unsubscribe();
      }
      if (flightsRtg2Subscription.current) {
        flightsRtg2Subscription.current.unsubscribe();
      }
    };
  }, []);

  // Event handlers
  const handleClick = () => {
    beaconService.updateSelectedBeacon('');
  };

  return (
    <div className={classes.drawerContainer}>
      <div className={classes.drawerHeader}>
        <div className={classes.drawerTitle}>
          <Typography className={classes.name}>{name}</Typography>
        </div>
        <IconButton onClick={handleClick}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <div className={classes.scrollableContent}>
        <RtgGraphsCard
          beaconSelected={name}
          loadName1="LFPGARN"
          load1={loadLFPGARN}
          capacities1={capacitiesLFPGARN}
          defaultCapacity1={beaconLFPGARN ? beaconLFPGARN.defaultCapacity : null}
          loadName2="LFPGARS"
          load2={loadLFPGARS}
          capacities2={capacitiesLFPGARS}
          defaultCapacity2={beaconLFPGARS ? beaconLFPGARS.defaultCapacity : null}
          minutesLeft={minutesLeft}
        />
        <FlightListRtgLATGOCard tv="RTG1" flights={flightsRtg1} />
        <FlightListRtgLATGOCard tv="RTG2" flights={flightsRtg2} />
      </div>
    </div>
  );
}

export default RtgLATGODrawer;
