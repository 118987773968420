import {Store, StoreConfig} from '@datorama/akita';
import {Planifications} from './planification.model';

export function createInitialState(): Planifications {
  return {
    LFPO: {
      configurations: [],
      cadencies: {
        '02': [],
        '06': [],
        '07': [],
        '24': [],
        '25': [],
      },
      closures: {
        '02': [],
        '06': [],
        '07': [],
        '24': [],
        '25': [],
      },
    },
    LFPG: {
      configurations: [],
      cadencies: {
        '09L': [],
        '27R': [],
        '08R': [],
        '26L': [],
      },
      closures: {
        '09L': [],
        '27R': [],
        '08R': [],
        '26L': [],
      },
    },
  };
}

@StoreConfig({name: 'planification'})
export class PlanificationStore extends Store<Planifications> {
  constructor() {
    super(createInitialState());
  }
}

export const planificationStore = new PlanificationStore();
