import React, {useEffect, useState, useRef, useReducer} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import {red, green, blue} from '@material-ui/core/colors';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import DoneIcon from '@material-ui/icons/Done';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import {notificationService} from '../models/notification/notification.service';
import {flightService} from '../models/flight/flight.service';

const useStyles = makeStyles((theme) => ({
  list: {
    width: '420px',
    maxHeight: '350px',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  notificationCenterHeader: {
    width: '420px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  notificationCenterTitle: {
    fontSize: 20,
    fontWeight: 500,
    margin: theme.spacing(2),
    display: 'inline-block',
  },
  notificationCenterClear: {
    marginRight: theme.spacing(1),
    marginLeft: 'auto',
  },
  notificationTitle: {
    fontWeight: 500,
    fontSize: 19,
  },
  notificationContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  notificationText: {
    display: 'block',
  },
  notificationDateAirport: {
    marginTop: 8,
    display: 'flex',
    flexDirection: 'row',
  },
  notificationDate: {
    fontSize: 12,
  },
  notificationAirport: {
    marginRight: 8,
    marginLeft: 'auto',
    fontSize: 12,
  },
}));

function NotificationCenter({className}) {
  const classes = useStyles();

  // States
  const [menuOpen, setMenuOpen] = useState(false);
  const [notifications, setNotifications] = useReducer(
    (state, newValue) => newValue.sort((a, b) => b.time.getTime() - a.time.getTime()),
    [],
  );

  // Ref du bouton d'ouverture du menu
  const button = useRef(null);

  // Mémorisation des subscription
  const notificationsSubscription = useRef(null);

  useEffect(() => {
    // Abonnement au model au 'mount' du composant
    notificationsSubscription.current = notificationService
      .selectNotifications()
      .subscribe(setNotifications);

    return () => {
      // Desabonnement au model au 'unmount' du composant
      if (notificationsSubscription.current) {
        notificationsSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Ouverture du menu
  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  // Fermeture du menu
  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  // Affichage du drawer vol correspondant au vol de la notification
  const handleCallsignClick = (callsign) => {
    if (callsign) {
      flightService.setSelected(callsign);
    }
  };

  // Fermeture d'une notification
  const handleNotificationClose = (notification) => {
    notificationService.removeNotification(notification.id);
  };

  // Fermeture de toutes les notifications
  const handleClear = () => {
    notificationService.removeAllNotifications();
  };

  return (
    <>
      <IconButton aria-label="settings" className={className} onClick={handleMenuOpen} ref={button}>
        <Badge badgeContent={notifications.length} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Popover
        open={menuOpen}
        anchorEl={button.current}
        onClose={handleMenuClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}>
        <div className={classes.notificationCenterHeader}>
          <Typography className={classes.notificationCenterTitle}>
            {notifications.length > 1
              ? `${notifications.length} notifications`
              : notifications.length === 1
              ? `${notifications.length} notification`
              : 'Aucune notification'}
          </Typography>
          <IconButton
            className={classes.notificationCenterClear}
            aria-label="clear"
            onClick={() => handleClear()}
            disabled={notifications.length === 0}>
            <DeleteIcon />
          </IconButton>
        </div>
        {notifications.length > 0 ? (
          <>
            <Divider />
            <List className={classes.list}>
              {notifications.map((notification, index) => (
                <React.Fragment key={notification.id}>
                  <ListItem
                    button={!!notification.callsign}
                    onClick={
                      notification.callsign
                        ? () => handleCallsignClick(notification.callsign)
                        : undefined
                    }>
                    <ListItemAvatar>
                      {notification.icon === 'info' ? (
                        <InfoOutlinedIcon style={{color: blue[500]}} />
                      ) : notification.icon === 'tick' ? (
                        <DoneIcon style={{color: green[500]}} />
                      ) : notification.icon === 'warning' ? (
                        <ReportProblemOutlinedIcon style={{color: red[500]}} />
                      ) : (
                        <NotificationsNoneIcon />
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Typography className={classes.notificationTitle} component="span">
                          {notification.title}
                        </Typography>
                      }
                      secondary={
                        <span className={classes.notificationContainer}>
                          {notification.text.split('\n').map((textPart, index) => (
                            <Typography
                              key={`textLine-${index}`}
                              className={classes.notificationText}
                              component="span">
                              {textPart}
                            </Typography>
                          ))}
                          <Typography className={classes.notificationDateAirport} component="span">
                            <Typography className={classes.notificationDate} component="span">
                              {notification.time
                                ? notification.time.toLocaleString('fr-FR', {
                                    timeZone: 'UTC',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                  })
                                : '--:--:--'}
                            </Typography>
                            <Typography className={classes.notificationAirport} component="span">
                              {notification.airport ? `${notification.airport}` : ''}
                            </Typography>
                          </Typography>
                        </span>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="close"
                        onClick={() => handleNotificationClose(notification)}>
                        <CloseIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                  {index < notifications.length - 1 ? <Divider /> : ''}
                </React.Fragment>
              ))}
            </List>
          </>
        ) : (
          ''
        )}
      </Popover>
    </>
  );
}

export default NotificationCenter;
