import React, {useEffect, useState, useRef, useReducer} from 'react';

import {makeStyles} from '@material-ui/core';

import {ReactComponent as SvgLightning} from '../../svg-icons/lightning.svg';

import {meteoService} from '../../models/meteo/meteo.service';
import {displayService} from '../../models/display/display.service';
import {dateService} from '../../models/date/date.service';

const useStyles = makeStyles((theme) => ({
  meteoEvents: {
    strokeWidth: 1,
    strokeLinejoin: 'round',
    strokeOpacity: 0.6,
    fillOpacity: 0.3,
  },
  turbulences: {
    stroke: 'RGB(144,177,128)',
    fill: 'RGB(144,177,128)',
  },
}));

function MeteoLayer({x, y, k}) {
  const classes = useStyles();

  // State & reducers
  const [turbulencesDisplayed, setTurbulencesDisplayed] = useState(false);
  const [convectionsDisplayed, setConvectionsDisplayed] = useState(false);
  const [lightningsDisplayed, setLightningsDisplayed] = useState(false);

  const [turbulences, setTurbulences] = useReducer((state, newValue) => {
    const projection = meteoService.getProjection();
    return newValue && projection
      ? newValue.map((turbulence) => ({
          ...turbulence,
          properties: {
            ...turbulence.properties,
            contourList: turbulence.properties.contourList.map((coordinate) =>
              projection([coordinate.lon, coordinate.lat]),
            ),
          },
        }))
      : state;
  }, []);
  const [convections, setConvections] = useReducer((state, newValue) => {
    const projection = meteoService.getProjection();
    return newValue && projection
      ? newValue.map((convection) => ({
          ...convection,
          properties: {
            ...convection.properties,
            contourList: convection.properties.contourList.map((coordinate) =>
              projection([coordinate.lon, coordinate.lat]),
            ),
          },
        }))
      : state;
  }, []);
  const [lightnings, setLightnings] = useReducer((state, newValue) => {
    const projection = meteoService.getProjection();
    return newValue && projection
      ? newValue.map((lightning) => ({
          ...lightning,
          properties: {
            ...lightning.properties,
            center: projection([lightning.properties.center.lon, lightning.properties.center.lat]),
          },
        }))
      : state;
  }, []);

  const [date, setDate] = useReducer((state, newValue) => {
    const newDate = dateService.getDate();
    return newDate || state;
  }, dateService.getDate());
  const [timeFilter, setTimeFilter] = useState(0);

  // Refs des subscriptions
  const turbulencesDisplayedSubscription = useRef(null);
  const convectionsDisplayedSubscription = useRef(null);
  const lightningsDisplayedSubscription = useRef(null);

  const turbulencesSubscription = useRef(null);
  const convectionsSubscription = useRef(null);
  const lightningsSubscription = useRef(null);

  const dateSubscription = useRef(null);
  const timeFilterSubscription = useRef(null);

  // Au mount du composant, on s'abonne aux modèles
  useEffect(() => {
    turbulencesDisplayedSubscription.current = displayService
      .areMeteoTurbulencesDisplayed()
      .subscribe(setTurbulencesDisplayed);
    convectionsDisplayedSubscription.current = displayService
      .areMeteoConvectionsDisplayed()
      .subscribe(setConvectionsDisplayed);
    lightningsDisplayedSubscription.current = displayService
      .areMeteoLightningsDisplayed()
      .subscribe(setLightningsDisplayed);

    turbulencesSubscription.current = meteoService.selectAllTurbulences().subscribe(setTurbulences);
    convectionsSubscription.current = meteoService.selectAllConvections().subscribe(setConvections);
    lightningsSubscription.current = meteoService.selectAllLightnings().subscribe(setLightnings);

    dateSubscription.current = dateService.selectMinute().subscribe(setDate);
    timeFilterSubscription.current = displayService
      .whatIsMeteoTimeFilter()
      .subscribe(setTimeFilter);

    // Au unmount du composant, on se désabonne des modèles
    return () => {
      if (turbulencesDisplayedSubscription.current) {
        turbulencesDisplayedSubscription.current.unsubscribe();
      }
      if (convectionsDisplayedSubscription.current) {
        convectionsDisplayedSubscription.current.unsubscribe();
      }
      if (lightningsDisplayedSubscription.current) {
        lightningsDisplayedSubscription.current.unsubscribe();
      }

      if (turbulencesSubscription.current) {
        turbulencesSubscription.current.unsubscribe();
      }
      if (convectionsSubscription.current) {
        convectionsSubscription.current.unsubscribe();
      }
      if (lightningsSubscription.current) {
        lightningsSubscription.current.unsubscribe();
      }

      if (dateSubscription.current) {
        dateSubscription.current.unsubscribe();
      }
      if (timeFilterSubscription.current) {
        timeFilterSubscription.current.unsubscribe();
      }
    };
  }, []);

  return (
    <>
      <g
        className={`${classes.meteoEvents} ${classes.turbulences}`}
        transform={`translate(${x} ${y}) scale(${k})`}>
        {turbulencesDisplayed
          ? turbulences.map((turbulence) =>
              turbulence.properties.start.getTime() <= date.getTime() + timeFilter * 60000 &&
              date.getTime() + timeFilter * 60000 < turbulence.properties.end.getTime() ? (
                <polygon
                  points={turbulence.properties.contourList}
                  vectorEffect="non-scaling-stroke"
                  key={turbulence.id}>
                  <title>TURB</title>
                </polygon>
              ) : (
                ''
              ),
            )
          : ''}
      </g>
      <g className={classes.meteoEvents} transform={`translate(${x} ${y}) scale(${k})`}>
        {convectionsDisplayed
          ? convections.map((convection) =>
              convection.properties.start.getTime() <= date.getTime() + timeFilter * 60000 &&
              date.getTime() + timeFilter * 60000 < convection.properties.end.getTime() ? (
                <polygon
                  points={convection.properties.contourList}
                  vectorEffect="non-scaling-stroke"
                  key={convection.id}
                  stroke={
                    convection.properties.intensity === 'MODERATE'
                      ? 'RGB(249,166,47)'
                      : convection.properties.intensity === 'SEVERE'
                      ? 'RGB(249,87,47)'
                      : 'black'
                  }
                  fill={
                    convection.properties.intensity === 'MODERATE'
                      ? 'RGB(249,166,47)'
                      : convection.properties.intensity === 'SEVERE'
                      ? 'RGB(249,87,47)'
                      : 'black'
                  }>
                  <title>
                    {convection.properties.top
                      ? `CB (${convection.properties.intensity})\nTop : FL${Math.round(
                          (convection.properties.top * 3.28084) / 100,
                        )}`
                      : `CB (${convection.properties.intensity})`}
                  </title>
                </polygon>
              ) : (
                ''
              ),
            )
          : ''}
      </g>
      <g>
        {lightningsDisplayed
          ? lightnings.map((lightning) => (
              <SvgLightning
                viewBox="0 0 1000 1000"
                x={lightning.properties.center[0] * k + x - 8}
                y={lightning.properties.center[1] * k + y - 8}
                height="16"
                width="16"
                key={lightning.id}
              />
            ))
          : ''}
      </g>
    </>
  );
}

export default MeteoLayer;
