import React, {useEffect, useState, useRef} from 'react';
import './planifications-graph.css';

import AddIcon from '@material-ui/icons/Add';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {Popover} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import {withStyles, makeStyles} from '@material-ui/core/styles';

import Brightness2Icon from '@material-ui/icons/Brightness2';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {planificationService} from '../../../models/planification/planification.service.ts';
import {dateService} from '../../../models/date/date.service.ts';

// Personnalisation des Accordions
const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .09)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 48,
    '&$expanded': {
      minHeight: 48,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

// Styles des inputs
const useStyles = makeStyles((theme) => ({
  textField: {
    marginRight: 15,
    width: 250,
  },
  smallTextField: {
    width: 210,
  },
  select: {
    width: 230,
    marginRight: 15,
  },
  dialogText: {
    marginBottom: 5,
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

function PlanificationsGraphAdd({
  airport,
  runway,
  planifications,
  currentConfiguration,
  enabled,
  disabled,
  addConfigurationDisabled,
  onMenuOpen,
  onMenuClose,
}) {
  // Styles
  const classes = useStyles();

  // Subscription
  const dateSubscription = useRef(null);

  // Lors du mount
  useEffect(() => {
    dateSubscription.current = dateService.selectDate().subscribe(setCurrentDateTime);

    // Lors de l'unmount
    return () => {
      if (dateSubscription.current) {
        dateSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Date courante
  const [currentDateTime, setCurrentDateTime] = useState(dateService.getDate());

  // Ouverture de la Popover
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverOpen = Boolean(anchorEl);

  const openPopover = (event) => {
    // On initialise les valeurs des inputs
    const closureStart = new Date(Date.now() + 60 * 60000);
    const closureEnd = new Date(closureStart.getTime() + 10 * 60000);
    const newCadencyAt = new Date(Date.now() + 60 * 60000);
    const newConfStart = new Date(Date.now() + 60 * 60000);
    const newConfEnd = new Date(newConfStart.getTime() + 9 * 60000);

    // Inputs d'ajout de nouvelle config
    setConfigurationFrom(newConfStart);
    setConfigurationTo(newConfEnd);
    setDefineCadencyForNewConfig(false);
    setSelectedCadencyForNewConfig(90);

    // Inputs d'ajout de nouvelle cadence
    setCadencyAt(newCadencyAt);

    // Input d'ajout de fermeture
    setClosureType('REAL');
    setClosureFrom(closureStart);
    setClosureTo(closureEnd);

    // Selected new config
    setSelectedConfiguration(possibleConfiguration[0]);

    // A l'ouverture du Popover, aucun Acordion n'est ouvert
    setExpandedPanel('');

    // Ouverture de la popover
    setAnchorEl(event.currentTarget);

    // On prévient le parent que le menu est ouvert
    onMenuOpen();
  };

  const closePopover = () => {
    setAnchorEl(null);

    // On prévient le parent que le menu est fermé
    onMenuClose();
  };

  // Configurations possibles
  const [possibleConfiguration, setPossibleConfiguration] = useState([]);
  useEffect(() => {
    let possibleConfiguration = planificationService.getPossibleConfigForAirport(airport);
    possibleConfiguration = possibleConfiguration.filter((item) => item !== currentConfiguration);
    setPossibleConfiguration(possibleConfiguration);
  }, [currentConfiguration, airport]);

  // From/To de la nouvelle config planifiée
  const [configurationFrom, setConfigurationFrom] = useState(null);
  const [configurationTo, setConfigurationTo] = useState(null);

  // Configuration sélectionnée
  const [selectedConfiguration, setSelectedConfiguration] = useState(possibleConfiguration[0]);

  // Cadence prévue pour la nouvelle config selectionnée, au moment sélectionné
  const [cadencyAtTime, setCadencyAtTime] = useState(
    planificationService.getCadencyForQfuAtTimeForAirport(
      planificationService.getQfuForAirportAndConfig(airport, runway, selectedConfiguration),
      configurationTo,
      airport,
    ),
  );
  useEffect(() => {
    setCadencyAtTime(
      planificationService.getCadencyForQfuAtTimeForAirport(
        planificationService.getQfuForAirportAndConfig(airport, runway, selectedConfiguration),
        configurationTo,
        airport,
      ),
    );
  }, [selectedConfiguration, configurationTo, planifications, airport, runway]);

  // L'utilisateur veut ou non définir une nouvelle cadence
  const [defineCadencyForNewConfig, setDefineCadencyForNewConfig] = useState(false);
  const [selectedCadencyForNewConfig, setSelectedCadencyForNewConfig] = useState(90);

  // Ajout de la nouvelle configuration
  const setNewPlannedConfiguration = () => {
    if (
      currentConfiguration &&
      selectedConfiguration &&
      currentConfiguration.replace('_CVF', '') === selectedConfiguration.replace('_CVF', '')
    ) {
      planificationService.setNewPlannedConfigurationForAirport(
        selectedConfiguration,
        configurationTo,
        configurationTo,
        airport,
      );
      if (defineCadencyForNewConfig) {
        let qfu = planificationService.getQfuForAirportAndConfig(
          airport,
          runway,
          selectedConfiguration,
        );
        planificationService.setNewPlannedCadencyForQfuForAirport(
          selectedCadencyForNewConfig,
          qfu,
          configurationTo,
          airport,
        );
      }
    } else {
      planificationService.setNewPlannedConfigurationForAirport(
        selectedConfiguration,
        configurationFrom,
        configurationTo,
        airport,
      );
      if (defineCadencyForNewConfig) {
        let qfu = planificationService.getQfuForAirportAndConfig(
          airport,
          runway,
          selectedConfiguration,
        );
        planificationService.setNewPlannedCadencyForQfuForAirport(
          selectedCadencyForNewConfig,
          qfu,
          configurationTo,
          airport,
        );
      }
    }
    closePopover();
  };

  // Nouvelle cadence sélectionnée
  const [selectedCadency, setSelectedCadency] = useState(90);

  // Heure d'effet de la nouvelle cadence sélectionnée
  const [cadencyAt, setCadencyAt] = useState(null);

  // QFU prévu au moment du changement de cadence
  const [cadencyQfu, setCadencyQfu] = useState(
    planificationService.getQfuAtTimeForAirport(cadencyAt, airport, runway),
  );
  useEffect(() => {
    setCadencyQfu(planificationService.getQfuAtTimeForAirport(cadencyAt, airport, runway));
  }, [cadencyAt, planifications, airport, runway]);

  // Ajout de la nouvelle planification de cadence
  const setNewPlannedCadency = () => {
    const qfu = planificationService.getQfuAtTimeForAirport(cadencyAt, airport, runway);
    planificationService.setNewPlannedCadencyForQfuForAirport(
      selectedCadency,
      qfu,
      cadencyAt,
      airport,
    );
    closePopover();
  };

  // Fermeture : type / from / to
  const [closureType, setClosureType] = useState('REAL');
  const [closureFrom, setClosureFrom] = useState(null);
  const [closureTo, setClosureTo] = useState(null);

  // QFU ouvert au moment de la fermeture
  const [closureQfu, setClosureQfu] = useState(
    planificationService.getQfuAtTimeForAirport(closureFrom, airport, runway),
  );
  useEffect(() => {
    setClosureQfu(planificationService.getQfuAtTimeForAirport(closureFrom, airport, runway));
  }, [closureFrom, planifications, airport, runway]);

  // Ajout de la fermeture
  const setNewPlannedClosure = () => {
    if (closureType === 'REAL') {
      planificationService.setNewPlannedClosureForAirport(closureFrom, closureTo, airport, runway);
    } else if (closureType === 'GAP') {
      planificationService.setNewPlannedGapForAirport(closureFrom, closureTo, airport, runway);
    }
    closePopover();
  };

  // Fonction de validation de l'ajout de planification
  const validatePlanification = () => {
    switch (expandedPanel) {
      case 'panel-configuration':
        setNewPlannedConfiguration();
        break;
      case 'panel-cadency':
        setNewPlannedCadency();
        break;
      case 'panel-closure':
        setNewPlannedClosure();
        break;
      default:
        break;
    }

    // Fermeture des menus
    setDialogOpen(false);
    closePopover();
  };

  // Gestion des Acordions
  const [expandedPanel, setExpandedPanel] = useState('');

  const handlePanelChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : '');
  };

  // Refs des inputs pour validation des forms
  const configFromInput = useRef(null);
  const configToInput = useRef(null);
  const cadencyAtInput = useRef(null);
  const closureFromInput = useRef(null);
  const closureToInput = useRef(null);

  // Gestion du Dialog
  // Ouverture du Dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <button
        className={`edition-button edit-button ${enabled ? 'enabled-button' : ''}`}
        onClick={openPopover}
        disabled={disabled}>
        <AddIcon fontSize="small" />
      </button>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}>
        <Accordion
          expanded={expandedPanel === 'panel-configuration'}
          onChange={handlePanelChange('panel-configuration')}
          disabled={addConfigurationDisabled}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-configuration-content">
            <Typography>Planifier un changement de configuration</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-content">
              <div className="accordion-line">
                <FormControl color="secondary" className={classes.select}>
                  <InputLabel id="configuration-select-label">Configuration</InputLabel>
                  <Select
                    labelId="configuration-select-label"
                    id="demo-simple-select"
                    value={selectedConfiguration}
                    onChange={(event) => setSelectedConfiguration(event.target.value)}
                    style={{marginRight: '10px'}}>
                    {possibleConfiguration
                      .sort((a, b) => {
                        if (a.endsWith('_CVF') && !b.endsWith('_CVF')) {
                          return 1;
                        } else if (!a.endsWith('_CVF') && b.endsWith('_CVF')) {
                          return -1;
                        } else {
                          return b - a;
                        }
                      })
                      .map((config) => (
                        <MenuItem value={config} key={config}>
                          <span style={{width: '40px'}}>
                            {config.replace('PO', '').replace('PG_', '').replace('_CVF', '')}
                          </span>
                          <span>
                            {config.endsWith('_CVF') ? <Brightness2Icon fontSize="inherit" /> : ''}
                          </span>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </div>
              <div className="accordion-line">
                <TextField
                  className={classes.textField}
                  label="Début de fermeture"
                  type="datetime-local"
                  InputLabelProps={{shrink: true}}
                  color="secondary"
                  value={configurationFrom ? configurationFrom.toISOString().slice(0, -8) : ''}
                  onChange={(event) =>
                    setConfigurationFrom(new Date(`${event.target.value}:00.000Z`))
                  }
                  disabled={
                    currentConfiguration &&
                    selectedConfiguration &&
                    currentConfiguration.slice(0, 5) === selectedConfiguration.slice(0, 5)
                  }
                  inputRef={configFromInput}
                  inputProps={{
                    min: currentDateTime.toISOString().slice(0, -8),
                    max: new Date(currentDateTime.getTime() + 3 * 60 * 60000)
                      .toISOString()
                      .slice(0, -8),
                  }}
                  error={!configFromInput.current || !configFromInput.current.validity.valid}
                  helperText={
                    !configFromInput.current || !configFromInput.current.validity.valid
                      ? 'doit se situer dans les 3 heures à venir'
                      : ' '
                  }
                />
                <TextField
                  className={classes.textField}
                  label="Début de nouvelle config."
                  type="datetime-local"
                  InputLabelProps={{shrink: true}}
                  color="secondary"
                  value={configurationTo ? configurationTo.toISOString().slice(0, -8) : ''}
                  onChange={(event) =>
                    setConfigurationTo(new Date(`${event.target.value}:00.000Z`))
                  }
                  inputRef={configToInput}
                  inputProps={{
                    min: currentDateTime.toISOString().slice(0, -8),
                    max: new Date(currentDateTime.getTime() + 3 * 60 * 60000)
                      .toISOString()
                      .slice(0, -8),
                  }}
                  error={!configToInput.current || !configToInput.current.validity.valid}
                  helperText={
                    !configToInput.current || !configToInput.current.validity.valid
                      ? 'doit se situer dans les 3 heures à venir'
                      : ' '
                  }
                />
              </div>
              <div className="accordion-line">
                <InfoOutlinedIcon color="secondary" />
                <div style={{marginLeft: 8, color: '#696969'}}>
                  Cadence prévue pour la nouvelle configuration :{' '}
                  {cadencyAtTime ? cadencyAtTime : '--'} sec.
                </div>
              </div>
              <div className="accordion-line">
                <Checkbox
                  checked={defineCadencyForNewConfig}
                  onChange={(event) => setDefineCadencyForNewConfig(event.target.checked)}
                  name="checkedNewCadency"
                  color="default"
                  style={{marginLeft: -10, marginRight: 10}}
                />
                <TextField
                  className={classes.smallTextField}
                  label="Définir une nouvelle cadence"
                  type="number"
                  InputLabelProps={{shrink: true}}
                  color="secondary"
                  value={selectedCadencyForNewConfig}
                  onChange={(event) => setSelectedCadencyForNewConfig(event.target.value)}
                  InputProps={{
                    inputProps: {
                      min: 10,
                      max: 300,
                    },
                    endAdornment: <InputAdornment position="end">sec.</InputAdornment>,
                  }}
                  disabled={!defineCadencyForNewConfig}
                />
              </div>
              <div className="accordion-button-line">
                <Button
                  onClick={() => setDialogOpen(true)}
                  disabled={
                    !configFromInput.current ||
                    !configToInput.current ||
                    !configFromInput.current.validity.valid ||
                    !configToInput.current.validity.valid
                  }
                  color="secondary">
                  Confirmer
                </Button>
                <Button onClick={closePopover} color="secondary">
                  Annuler
                </Button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expandedPanel === 'panel-cadency'}
          onChange={handlePanelChange('panel-cadency')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-cadency-content">
            <Typography>Planifier un changement de cadence</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-content">
              <div className="accordion-line">
                <TextField
                  className={classes.textField}
                  label="Heure"
                  type="datetime-local"
                  InputLabelProps={{shrink: true}}
                  color="secondary"
                  value={cadencyAt ? cadencyAt.toISOString().slice(0, -8) : ''}
                  onChange={(event) => setCadencyAt(new Date(`${event.target.value}:00.000Z`))}
                  inputRef={cadencyAtInput}
                  inputProps={{
                    min: currentDateTime.toISOString().slice(0, -8),
                    max: new Date(currentDateTime.getTime() + 3 * 60 * 60000)
                      .toISOString()
                      .slice(0, -8),
                  }}
                  error={!cadencyAtInput.current || !cadencyAtInput.current.validity.valid}
                  helperText={
                    !cadencyAtInput.current || !cadencyAtInput.current.validity.valid
                      ? 'doit se situer dans les 3 heures à venir'
                      : ' '
                  }
                />
                <TextField
                  className={classes.textField}
                  label="Nouvelle cadence"
                  type="number"
                  InputLabelProps={{shrink: true}}
                  color="secondary"
                  value={selectedCadency}
                  onChange={(event) => setSelectedCadency(event.target.value)}
                  InputProps={{
                    inputProps: {
                      min: 10,
                      max: 300,
                    },
                    endAdornment: <InputAdornment position="end">sec.</InputAdornment>,
                  }}
                  helperText=" "
                />
              </div>
              <div className="accordion-line">
                <InfoOutlinedIcon color="secondary" />
                <div style={{marginLeft: 8, color: '#696969'}}>
                  QFU impacté par le changement de cadence : {cadencyQfu}
                </div>
              </div>
              <div className="accordion-button-line">
                <Button
                  onClick={() => setDialogOpen(true)}
                  disabled={!cadencyAtInput.current || !cadencyAtInput.current.validity.valid}
                  color="secondary">
                  Confirmer
                </Button>
                <Button onClick={closePopover} color="secondary">
                  Annuler
                </Button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expandedPanel === 'panel-closure'}
          onChange={handlePanelChange('panel-closure')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-closure-content">
            <Typography>Planifier une fermeture</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="accordion-content">
              <div className="accordion-line">
                <FormControl component="fieldset" color="secondary">
                  <FormLabel component="legend">Type</FormLabel>
                  <RadioGroup
                    aria-label="closure-type"
                    name="closure-type"
                    value={closureType}
                    onChange={(event) => setClosureType(event.target.value)}
                    row>
                    <FormControlLabel value="REAL" control={<Radio />} label="REAL" />
                    <FormControlLabel value="GAP" control={<Radio />} label="GAP" />
                  </RadioGroup>
                </FormControl>
              </div>
              <div className="accordion-line">
                <TextField
                  className={classes.textField}
                  label="Début de fermeture"
                  type="datetime-local"
                  InputLabelProps={{shrink: true}}
                  color="secondary"
                  value={closureFrom ? closureFrom.toISOString().slice(0, -8) : ''}
                  onChange={(event) => setClosureFrom(new Date(`${event.target.value}:00.000Z`))}
                  inputRef={closureFromInput}
                  inputProps={{
                    min: currentDateTime.toISOString().slice(0, -8),
                    max: new Date(currentDateTime.getTime() + 3 * 60 * 60000)
                      .toISOString()
                      .slice(0, -8),
                  }}
                  error={!closureFromInput.current || !closureFromInput.current.validity.valid}
                  helperText={
                    !closureFromInput.current || !closureFromInput.current.validity.valid
                      ? 'doit se situer dans les 3 heures à venir'
                      : ' '
                  }
                />
                <TextField
                  className={classes.textField}
                  label="Fin de fermeture"
                  type="datetime-local"
                  InputLabelProps={{shrink: true}}
                  color="secondary"
                  value={closureTo ? closureTo.toISOString().slice(0, -8) : ''}
                  onChange={(event) => setClosureTo(new Date(`${event.target.value}:00.000Z`))}
                  inputRef={closureToInput}
                  inputProps={{
                    min: currentDateTime.toISOString().slice(0, -8),
                    max: new Date(currentDateTime.getTime() + 3 * 60 * 60000)
                      .toISOString()
                      .slice(0, -8),
                  }}
                  error={!closureToInput.current || !closureToInput.current.validity.valid}
                  helperText={
                    !closureToInput.current || !closureToInput.current.validity.valid
                      ? 'doit se situer dans les 3 heures à venir'
                      : ' '
                  }
                />
              </div>
              <div className="accordion-line">
                <InfoOutlinedIcon color="secondary" />
                <div style={{marginLeft: 8, color: '#696969'}}>
                  QFU impacté par la fermeture : {closureQfu}
                </div>
              </div>
              <div className="accordion-button-line">
                <Button
                  onClick={() => setDialogOpen(true)}
                  disabled={
                    !closureFromInput.current ||
                    !closureToInput.current ||
                    !closureFromInput.current.validity.valid ||
                    !closureToInput.current.validity.valid
                  }
                  color="secondary">
                  Confirmer
                </Button>
                <Button onClick={closePopover} color="secondary">
                  Annuler
                </Button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </Popover>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>Confirmer la nouvelle planification ?</DialogTitle>
        <DialogContent>
          {expandedPanel === 'panel-configuration' ? (
            <div className={classes.dialogText}>
              <span>
                Configuration{' '}
                {selectedConfiguration.replace('PO', '').replace('PG_', '').replace('_CVF', '')}
                {selectedConfiguration.endsWith('_CVF') ? (
                  <Brightness2Icon fontSize="inherit" />
                ) : (
                  ''
                )}{' '}
                à{' '}
                {configurationTo.toLocaleTimeString('fr-FR', {
                  timeZone: 'UTC',
                  hour: '2-digit',
                  minute: '2-digit',
                })}{' '}
                (fermeture de{' '}
                {configurationFrom.toLocaleTimeString('fr-FR', {
                  timeZone: 'UTC',
                  hour: '2-digit',
                  minute: '2-digit',
                })}{' '}
                à{' '}
                {configurationTo.toLocaleTimeString('fr-FR', {
                  timeZone: 'UTC',
                  hour: '2-digit',
                  minute: '2-digit',
                })}
                )
              </span>
              {defineCadencyForNewConfig ? (
                <span> avec une cadence de {selectedCadencyForNewConfig}</span>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}
          {expandedPanel === 'panel-cadency' ? (
            <div className={classes.dialogText}>
              Nouvelle cadence du QFU {cadencyQfu} : {selectedCadency} à partir de{' '}
              {cadencyAt.toLocaleTimeString('fr-FR', {
                timeZone: 'UTC',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </div>
          ) : (
            ''
          )}
          {expandedPanel === 'panel-closure' ? (
            <div className={classes.dialogText}>
              Fermeture ({closureType}) sur le QFU {closureQfu} de{' '}
              {closureFrom.toLocaleTimeString('fr-FR', {
                timeZone: 'UTC',
                hour: '2-digit',
                minute: '2-digit',
              })}{' '}
              à{' '}
              {closureTo.toLocaleTimeString('fr-FR', {
                timeZone: 'UTC',
                hour: '2-digit',
                minute: '2-digit',
              })}
            </div>
          ) : (
            ''
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={validatePlanification} color="secondary" autoFocus>
            Confirmer
          </Button>
          <Button onClick={() => setDialogOpen(false)} color="secondary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PlanificationsGraphAdd;
