import React, {useEffect, useState, useRef, useReducer} from 'react';

import BeaconMf from '../map-components/beacon-mf.js';
import BeaconCop from '../map-components/beacon-cop.js';
import BeaconGeo from '../map-components/beacon-geo.js';
import BeaconIaf from '../map-components/beacon-iaf.js';
import BeaconAirport from '../map-components/beacon-airport.js';
import BeaconOtherAirport from '../map-components/beacon-other-airport.js';
import BeaconLim from '../map-components/beacon-lim.js';
import BeaconRtg from '../map-components/beacon-rtg.js';

import {beaconService} from '../../models/beacon/beacon.service.ts';
import {displayService} from '../../models/display/display.service.ts';
import {airportService} from '../../models/airport/airport.service.ts';

function BeaconsLayer({x, y, k}) {
  // State & reducers
  const [airport, setAirport] = useState('');
  const [geoBeaconsDisplayed, setGeoBeaconsDisplayed] = useState(true);
  const [otherAirportBeaconsDisplayed, setOtherAirportBeaconDisplayed] = useState(false);
  const [iafBeaconsDisplayed, setIafBeaconsDisplayed] = useState(true);
  const [mfBeaconsDisplayed, setMfBeaconsDisplayed] = useState(true);

  const [hoveredBeacon, setHoveredBeacon] = useState(null);
  const [selectedBeacon, setSelectedBeacon] = useState(null);

  const beaconReducer = (state, newValue) => {
    const projection = beaconService.getProjection();
    return newValue && projection
      ? newValue.map((beacon) => ({
          ...beacon,
          coordinates: projection(beacon.coordinates),
        }))
      : state;
  };
  const [beaconsGeo, setBeaconsGeo] = useReducer(beaconReducer, []);
  const [beaconsMf, setBeaconsMf] = useReducer(beaconReducer, []);
  const [beaconsCop, setBeaconsCop] = useReducer(beaconReducer, []);
  const [beaconsIaf, setBeaconsIaf] = useReducer(beaconReducer, []);
  const [beaconsAirport, setBeaconsAirport] = useReducer(beaconReducer, []);
  const [beaconsOtherAirport, setBeaconsOtherAirport] = useReducer(beaconReducer, []);
  const [beaconsLim, setBeaconsLim] = useReducer(beaconReducer, []);
  const [beaconsRtg, setBeaconsRtg] = useReducer(beaconReducer, []);

  // Refs des subscriptions
  const airportSubscription = useRef(null);
  const beaconsGeoDisplayedSubscription = useRef(null);
  const beaconsOtherAirportDisplayedSubscription = useRef(null);
  const beaconsIafDisplayedSubscription = useRef(null);
  const beaconsMfDisplayedSubscription = useRef(null);
  const beaconGeoSubscription = useRef(null);
  const beaconMfSubscription = useRef(null);
  const beaconCopSubscription = useRef(null);
  const beaconIafSubscription = useRef(null);
  const beaconAirportSubscription = useRef(null);
  const beaconOtherAirportSubscription = useRef(null);
  const beaconLimSubscription = useRef(null);
  const beaconRtgSubscription = useRef(null);
  const hoveredBeaconSubscription = useRef(null);
  const selectedBeaconSubscription = useRef(null);

  // Au mount du composant on s'abonne aux modèles d'aéroport, de display et des beacons survolées / selectionnées
  useEffect(() => {
    airportSubscription.current = airportService.selectIcaoAirport().subscribe(setAirport);

    beaconsGeoDisplayedSubscription.current = displayService
      .areBeaconsGeoDisplayed()
      .subscribe(setGeoBeaconsDisplayed);
    beaconsOtherAirportDisplayedSubscription.current = displayService
      .areBeaconsOtherAirportDisplayed()
      .subscribe(setOtherAirportBeaconDisplayed);
    beaconsIafDisplayedSubscription.current = displayService
      .areBeaconsIafDisplayed()
      .subscribe(setIafBeaconsDisplayed);
    beaconsMfDisplayedSubscription.current = displayService
      .areBeaconsMfDisplayed()
      .subscribe(setMfBeaconsDisplayed);

    hoveredBeaconSubscription.current = beaconService
      .selectHoveredBeacon()
      .subscribe(setHoveredBeacon);
    selectedBeaconSubscription.current = beaconService
      .selectSelectedBeacon()
      .subscribe(setSelectedBeacon);

    // Au unmount du composant, on se désabonne de tous les modèles
    return () => {
      if (airportSubscription.current) {
        airportSubscription.current.unsubscribe();
      }
      if (beaconsGeoDisplayedSubscription.current) {
        beaconsGeoDisplayedSubscription.current.unsubscribe();
      }
      if (beaconsOtherAirportDisplayedSubscription.current) {
        beaconsOtherAirportDisplayedSubscription.current.unsubscribe();
      }
      if (beaconsIafDisplayedSubscription.current) {
        beaconsIafDisplayedSubscription.current.unsubscribe();
      }
      if (beaconsMfDisplayedSubscription.current) {
        beaconsMfDisplayedSubscription.current.unsubscribe();
      }
      if (hoveredBeaconSubscription.current) {
        hoveredBeaconSubscription.current.unsubscribe();
      }
      if (selectedBeaconSubscription.current) {
        selectedBeaconSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Lors du changement d'aéroport, on sabonne aux listes de balises
  useEffect(() => {
    // On se déabonne des modèle pour le précédent aéroport sélectionné
    if (beaconGeoSubscription.current) {
      beaconGeoSubscription.current.unsubscribe();
    }
    if (beaconMfSubscription.current) {
      beaconMfSubscription.current.unsubscribe();
    }
    if (beaconCopSubscription.current) {
      beaconCopSubscription.current.unsubscribe();
    }
    if (beaconIafSubscription.current) {
      beaconIafSubscription.current.unsubscribe();
    }
    if (beaconAirportSubscription.current) {
      beaconAirportSubscription.current.unsubscribe();
    }
    if (beaconOtherAirportSubscription.current) {
      beaconOtherAirportSubscription.current.unsubscribe();
    }
    if (beaconLimSubscription.current) {
      beaconLimSubscription.current.unsubscribe();
    }
    if (beaconRtgSubscription.current) {
      beaconRtgSubscription.current.unsubscribe();
    }

    // On met vide les balises sélectionnées et survolées
    beaconService.updateSelectedBeacon('');
    beaconService.updateHoveredBeacon('');

    // On se réabonne aux modèles pour le nouvel aéroport sélectionné
    if (airport) {
      beaconGeoSubscription.current = beaconService
        .selectBeaconsGeoForAirport(airport)
        .subscribe(setBeaconsGeo);
      beaconMfSubscription.current = beaconService
        .selectBeaconsMfForAirport(airport)
        .subscribe(setBeaconsMf);
      beaconCopSubscription.current = beaconService
        .selectBeaconsCopForAirport(airport)
        .subscribe(setBeaconsCop);
      beaconIafSubscription.current = beaconService
        .selectBeaconsIafForAirport(airport)
        .subscribe(setBeaconsIaf);
      beaconAirportSubscription.current = beaconService
        .selectBeaconsAirportForAirport(airport)
        .subscribe(setBeaconsAirport);
      beaconOtherAirportSubscription.current = beaconService
        .selectBeaconsOtherAirportForAirport(airport)
        .subscribe(setBeaconsOtherAirport);
      beaconLimSubscription.current = beaconService
        .selectBeaconsLimForAirport(airport)
        .subscribe(setBeaconsLim);
      beaconRtgSubscription.current = beaconService
        .selectBeaconsRtgForAirport(airport)
        .subscribe(setBeaconsRtg);
    } else {
      setBeaconsGeo([]);
      setBeaconsMf([]);
      setBeaconsCop([]);
      setBeaconsIaf([]);
      setBeaconsAirport([]);
      setBeaconsOtherAirport([]);
      setBeaconsLim([]);
      setBeaconsRtg([]);
    }
  }, [airport]);

  return (
    <>
      <g className="beaconsMf">
        {mfBeaconsDisplayed
          ? beaconsMf.map((beacon) => (
              <BeaconMf
                key={beacon.name}
                name={beacon.name}
                x={beacon.coordinates[0] * k + x}
                y={beacon.coordinates[1] * k + y}
                isHovered={hoveredBeacon && hoveredBeacon.name === beacon.name}
                isSelected={selectedBeacon && selectedBeacon.name === beacon.name}
                defaultCapacity={beacon.defaultCapacity}
              />
            ))
          : ''}
      </g>
      <g className="beaconsCop">
        {beaconsCop.map((beacon) => (
          <BeaconCop
            key={beacon.name}
            name={beacon.name}
            x={beacon.coordinates[0] * k + x}
            y={beacon.coordinates[1] * k + y}
            isHovered={hoveredBeacon && hoveredBeacon.name === beacon.name}
            isSelected={selectedBeacon && selectedBeacon.name === beacon.name}
            defaultCapacity={beacon.defaultCapacity}
          />
        ))}
      </g>
      <g className="beaconsIaf">
        {iafBeaconsDisplayed
          ? beaconsIaf.map((beacon) => (
              <BeaconIaf
                key={beacon.name}
                name={beacon.name}
                x={beacon.coordinates[0] * k + x}
                y={beacon.coordinates[1] * k + y}
                isHovered={hoveredBeacon && hoveredBeacon.name === beacon.name}
                isSelected={selectedBeacon && selectedBeacon.name === beacon.name}
                defaultCapacity={beacon.defaultCapacity}
              />
            ))
          : ''}
      </g>
      <g className="beaconsGeo">
        {geoBeaconsDisplayed
          ? beaconsGeo.map((beacon) => (
              <BeaconGeo
                key={beacon.name}
                name={beacon.name}
                x={beacon.coordinates[0] * k + x}
                y={beacon.coordinates[1] * k + y}
              />
            ))
          : ''}
      </g>
      <g className="beaconsAirport">
        {beaconsAirport.map((beacon) => (
          <BeaconAirport
            key={beacon.name}
            name={beacon.name}
            x={beacon.coordinates[0] * k + x}
            y={
              beacon.coordinates[1] * k +
              y +
              (beacon.name === 'LFPGARN' ? -30 : beacon.name === 'LFPGARS' ? 30 : 0)
            }
            isHovered={hoveredBeacon && hoveredBeacon.name === beacon.name}
            isSelected={selectedBeacon && selectedBeacon.name === beacon.name}
            defaultCapacity={beacon.defaultCapacity}
          />
        ))}
      </g>
      <g className="beaconsOtherAirport">
        {otherAirportBeaconsDisplayed
          ? beaconsOtherAirport.map((beacon) => (
              <BeaconOtherAirport
                key={beacon.name}
                name={beacon.name}
                x={beacon.coordinates[0] * k + x}
                y={beacon.coordinates[1] * k + y}
                nbFlights={beacon.nbFlights}
              />
            ))
          : ''}
      </g>
      <g className="beaconsLim">
        {beaconsLim.map((beacon) => (
          <BeaconLim
            key={beacon.name}
            x={beacon.coordinates[0] * k + x}
            y={beacon.coordinates[1] * k + y}
          />
        ))}
      </g>
      <g className="beaconsRtg">
        {beaconsRtg.length > 0 ? (
          <rect
            x={beaconsRtg[0].coordinates[0] * k + x - 50}
            y={beaconsRtg[0].coordinates[1] * k + y - 142}
            rx="8"
            ry="8"
            height="50"
            width="100"
            fill="none"
            stroke="#ffffff80"
          />
        ) : (
          ''
        )}
        {beaconsRtg.map((beacon) => (
          <BeaconRtg
            key={beacon.name}
            name={beacon.name}
            x={
              beacon.coordinates[0] * k +
              x +
              (beacon.name === 'RTG LATGO' ? -25 : beacon.name === 'RTG TE-UJ' ? 25 : 0)
            }
            y={beacon.coordinates[1] * k + y - 110}
            isHovered={hoveredBeacon && hoveredBeacon.name === beacon.name}
            isSelected={selectedBeacon && selectedBeacon.name === beacon.name}
          />
        ))}
      </g>
    </>
  );
}

export default BeaconsLayer;
