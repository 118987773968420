import React, { useEffect, useState, useRef, useReducer } from 'react';

import * as d3 from 'd3';

import Flight from '../map-components/flight.js';

import { beaconService } from '../../models/beacon/beacon.service.ts';
import { displayService } from '../../models/display/display.service.ts';
import { airportService } from '../../models/airport/airport.service.ts';
import { flightService } from '../../models/flight/flight.service.ts';
import { sectorService } from '../../models/sector/sector.service.ts';
import { planificationService } from '../../models/planification/planification.service.ts';

function FlightsLayer({ x, y, k }) {
  // State & reducers
  const [airport, setAirport] = useState('');
  const [sectorEga, setSectorEga] = useState(null);
  const [flightLabelsDisplayed, setFlightLabelsDisplayed] = useState(true);
  const [rvrDisplayed, setRvrDisplayed] = useState(false);

  const [flights, setFlights] = useReducer(
    (state, newValue) =>
      newValue && newValue
        .map((flight) => {
          const projection = beaconService.getProjection();

          const adscRoute =
            flight.adscData && flight.adscData.route
              ? flight.adscData.route.map((point) => projection([point[0], point[1]]))
              : '';
          const adscTodPos =
            flight.adscData && flight.adscData.todPos.length > 0
              ? projection([flight.adscData.todPos[0], flight.adscData.todPos[1]])
              : '';
          const adscTocPos =
            flight.adscData && flight.adscData.tocPos.length > 0
              ? projection([flight.adscData.tocPos[0], flight.adscData.tocPos[1]])
              : '';
          const hasAdscData = flight.adscData ? true : false;

          if (flight.latRadar && flight.lonRadar && flight.groundSpeedRadar && flight.trackRadar) {
            const mach = flight.machRadar
              ? flight.machRadar < 1
                ? flight.machRadar.toFixed(2).substring(1)
                : flight.machRadar.toFixed(2)
              : '';

            return {
              callsign: flight.callsign,
              coordinates: [flight.lonRadar, flight.latRadar],
              pos: projection([flight.lonRadar, flight.latRadar]),
              track: flight.trackRadar,
              afl: flight.aflRadar,
              groundSpeed: Math.round(flight.groundSpeedRadar / 10.0),
              mach,
              speedConstraint: flight.speedConstraint,
              speedConstraintStatus: flight.speedConstraintStatus,
              delayMF: flight.delayMF,
              radarInfo: 'radar',
              rvr: flight.rvr ? flight.rvr : null,
              mpr: flight.mpr ? flight.mpr : null,
              sta: flight.sta ? flight.sta : null,
              adscRoute: adscRoute,
              adscTodPos: adscTodPos,
              adscTocPos: adscTocPos,
              hasAdscData: hasAdscData,
            };
          }
          if (flight.latAdsb && flight.lonAdsb && flight.groundSpeedAdsb && flight.trackAdsb) {
            return {
              callsign: flight.callsign,
              coordinates: [flight.lonAdsb, flight.latAdsb],
              pos: projection([flight.lonAdsb, flight.latAdsb]),
              track: flight.trackAdsb,
              afl: flight.aflAdsb,
              groundSpeed: Math.round(flight.groundSpeedAdsb / 10.0),
              mach: undefined,
              speedConstraint: flight.speedConstraint,
              speedConstraintStatus: flight.speedConstraintStatus,
              delayMF: flight.delayMF,
              radarInfo: 'adsb',
              rvr: flight.rvr ? flight.rvr : null,
              mpr: flight.mpr ? flight.mpr : null,
              sta: flight.sta ? flight.sta : null,
              adscRoute: adscRoute,
              adscTodPos: adscTodPos,
              adscTocPos: adscTocPos,
              hasAdscData: hasAdscData,
            };
          }
          if (flight.lat && flight.lon) {
            // si on a pas de données radar et qu'on doit se contenter des données nm, on recalcule la track en fonction du iaf
            const pos = projection([flight.lon, flight.lat]);

            const runway = planificationService.getRunwayForAirportAndQfu(flight.arr, flight.qfu);
            const airportBeacon = beaconService.getBeaconForAirport(runway, airport);
            const posAirport = airportBeacon ? projection(airportBeacon.coordinates) : '';

            let track = 0;
            // Le vol est orienté vers son airport
            if (airport) {
              if (posAirport[0] > pos[0] && posAirport[1] < pos[1]) {
                track =
                  (Math.atan((posAirport[0] - pos[0]) / (pos[1] - posAirport[1])) * 180) / Math.PI;
              } else if (posAirport[0] > pos[0] && posAirport[1] === pos[1]) {
                track = 90;
              } else if (posAirport[0] > pos[0] && posAirport[1] > pos[1]) {
                track =
                  90 +
                  (Math.atan((posAirport[1] - pos[1]) / (posAirport[0] - pos[0])) * 180) / Math.PI;
              } else if (posAirport[0] === pos[0] && posAirport[1] > pos[1]) {
                track = 180;
              } else if (posAirport[0] < pos[0] && posAirport[1] > pos[1]) {
                track =
                  180 +
                  (Math.atan((pos[0] - posAirport[0]) / (posAirport[1] - pos[1])) * 180) / Math.PI;
              } else if (posAirport[0] < pos[0] && posAirport[1] === pos[1]) {
                track = 270;
              } else if (posAirport[0] < pos[0] && posAirport[1] < pos[1]) {
                track =
                  270 +
                  (Math.atan((pos[1] - posAirport[1]) / (pos[0] - posAirport[0])) * 180) / Math.PI;
              } else if (posAirport[0] === pos[0] && posAirport[1] < pos[1]) {
                track = 0;
              } else if (posAirport[0] === pos[0] && posAirport[1] === pos[1]) {
                track = 0;
              }
              track = !isNaN(track) ? track : 0;
            }

            return {
              callsign: flight.callsign,
              coordinates: [flight.lon, flight.lat],
              pos,
              track,
              afl: flight.afl,
              groundSpeed: undefined,
              mach: undefined,
              speedConstraint: flight.speedConstraint,
              speedConstraintStatus: flight.speedConstraintStatus,
              delayMF: flight.delayMF,
              radarInfo: 'nm',
              rvr: flight.rvr ? flight.rvr : null,
              mpr: flight.mpr ? flight.mpr : null,
              sta: flight.sta ? flight.sta : null,
              adscRoute: adscRoute,
              adscTodPos: adscTodPos,
              adscTocPos: adscTocPos,
              hasAdscData: hasAdscData,
            };
          }
          return null;
        })
        .filter((flight) => flight),
    [],
  );

  // Refs des subscriptions
  const airportSubscription = useRef(null);
  const sectorEgaSubscription = useRef(null);
  const flightLabelsDisplayedSubscription = useRef(null);
  const rvrDisplayedSubscription = useRef(null);
  const flightsSubscription = useRef(null);

  // Au mount du composant on s'abonne aux modèles d'aéroport et de display
  useEffect(() => {
    airportSubscription.current = airportService.selectIcaoAirport().subscribe(setAirport);
    sectorEgaSubscription.current = sectorService.selectSectorEga().subscribe(setSectorEga);

    flightLabelsDisplayedSubscription.current = displayService
      .areLabelsDisplayed()
      .subscribe(setFlightLabelsDisplayed);
    rvrDisplayedSubscription.current = displayService
      .areMeteoRVRsDisplayed()
      .subscribe(setRvrDisplayed);

    // Au unmount du composant, on se désabonne de tous les modèles
    return () => {
      if (airportSubscription.current) {
        airportSubscription.current.unsubscribe();
      }
      if (sectorEgaSubscription.current) {
        sectorEgaSubscription.current.unsubscribe();
      }
      if (flightLabelsDisplayedSubscription.current) {
        flightLabelsDisplayedSubscription.current.unsubscribe();
      }
      if (rvrDisplayedSubscription.current) {
        rvrDisplayedSubscription.current.unsubscribe();
      }
      if (flightsSubscription.current) {
        flightsSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Lors du changement d'aéroport, on sabonne aux listes de vols
  useEffect(() => {
    // On se déabonne des vols pour le précédent aéroport sélectionné
    if (flightsSubscription.current) {
      flightsSubscription.current.unsubscribe();
    }

    // On met vide les balises sélectionnées et survolées
    flightService.setHovered('');
    flightService.setSelected('');

    // On se réabonne aux modèles pour le nouvel aéroport sélectionné
    if (airport) {
      flightsSubscription.current = flightService
        .selectAllFlightsForAirport(airport)
        .subscribe(setFlights);
    } else {
      setFlights([]);
    }
  }, [airport]);

  return (
    <g className="flights">
      {flights.map((flight) => {
        if (flight.afl <= 50) {
          return '';
        }
        if (
          sectorEga &&
          d3.geoContains(
            {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [sectorEga.coordinates],
              },
              properties: { name: 'EGA' },
            },
            flight.coordinates,
          )
        ) {
          return '';
        }

        return (
          <Flight
            key={flight.callsign}
            callsign={flight.callsign}// console.log("flight ==>>>>  ", flight.callsign, flight.delayMF);
            pos={[flight.pos[0] * k + x, flight.pos[1] * k + y]}
            track={flight.track}
            afl={flight.afl}
            mach={flight.mach}
            groundSpeed={flight.groundSpeed}
            speedConstraint={flight.speedConstraint}
            speedConstraintStatus={flight.speedConstraintStatus}
            delayMF={flight.delayMF}
            radarInfo={flight.radarInfo}
            rvr={flight.rvr}
            rvrAlert={flight.rvrAlert}
            labelDisplayed={flightLabelsDisplayed}
            rvrDisplayed={rvrDisplayed}
            adscRoute={
              flight && flight?.adscRoute !== ''
                ? flight?.adscRoute.map((projectedPos) => [
                  projectedPos[0] * k + x,
                  projectedPos[1] * k + y,
                ])
                : flight?.adscRoute
            }
            adscTodPos={
              flight && flight?.adscTodPos !== ''
                ? [flight?.adscTodPos[0] * k + x, flight?.adscTodPos[1] * k + y]
                : flight?.adscTodPos
            }
            adscTocPos={
              flight && flight?.adscTocPos !== ''
                ? [flight?.adscTocPos[0] * k + x, flight?.adscTocPos[1] * k + y]
                : flight?.adscTocPos
            }
            hasAdscData={flight?.hasAdscData}
          />
        );
      })}
    </g>
  );
}

export default FlightsLayer;
