import {Query} from '@datorama/akita';
import {DateState} from './date.model';
import {dateStore, DateStore} from './date.store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export class DateQuery extends Query<DateState> {
  constructor(protected store: DateStore) {
    super(store);
  }

  selectDate(): Observable<Date> {
    return this.select((state) => state.date);
  }

  selectDay(): Observable<number> {
    return this.select((state) => state.day);
  }

  selectHour(): Observable<number> {
    return this.select((state) => state.hour);
  }

  selectMinute(): Observable<number> {
    return this.select((state) => state.minute);
  }

  selectMinutesLeft(): Observable<number> {
    return this.select((state) => state.minute).pipe(map((minute) => (60 - minute) % 20));
  }

  selectTenSecond(): Observable<number> {
    return this.select((state) => state.tenSecond);
  }

  selectUpdatePeriod(): Observable<number> {
    return this.select((state) => state.updatePeriod);
  }

  selectSecond(): Observable<number> {
    return this.select((state) => state.second);
  }

  getDate(): Date {
    return this.getValue().date;
  }

  getHour(): number {
    return this.getValue().hour;
  }

  getMinute(): number {
    return this.getValue().minute;
  }

  getMinutesLeft(): number {
    const minutes: number = this.getValue().minute;
    return (60 - minutes) % 20;
  }
}

export const dateQuery = new DateQuery(dateStore);
