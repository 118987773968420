import {webSocket} from '../../utils/serverWebSocket';
import {Socket} from 'socket.io-client';

import {militaryAreaStore, MilitaryAreaStore} from './military-area.store';
import {MilitaryArea} from './military-area.model';
import {militaryAreaQuery} from './military-area.query';

export class MilitaryAreaService {
  private socket?: Socket;

  constructor(private militaryAreaStore: MilitaryAreaStore) {}

  /**
   * Méthode permettant d'ouvrir la connexion avec le serveur via une webSocket et de s'abonner aux différents types de messages émis par celui ci
   */
  async start() {
    // On s'assure que le store est vide
    militaryAreaStore.remove();

    // On s'abonne au WS
    this.socket = webSocket().getSocket();
    this.onMessageReceived = this.onMessageReceived.bind(this);
    this.socket.on('message', this.onMessageReceived);
  }

  /**
   * Méthode permettant se désabonner du serveur et de vider le store, lorsque celui ci n'est plus utilisé
   * La socket n'est pas fermée car elle peut être utilisée par un autre modèle
   */
  destroy(): void {
    // Déconnexion du serveur
    if (this.socket) {
      this.socket.off('message', this.onMessageReceived);
      this.socket = undefined;
    }

    // On vide le store
    militaryAreaStore.remove();
  }

  /**
   * Listener pour les messages reçus du serveur
   * @param message message reçu
   */
  onMessageReceived(message: any) {
    const json = JSON.parse(message);

    if (json.typeMessage === 'militaryAreaUpdated') {
      this.addOrUpdateMilitaryArea({
        ...json.data,
        start: new Date(json.data.start),
        end: new Date(json.data.end),
      });
    }
  }

  /**
   * Méthode permettant d'ajouter ou mettre à jour une zone militaire du store
   * @param militaryArea Zone militaire à ajouter ou mettre à jour dans le store
   */
  addOrUpdateMilitaryArea(militaryArea: MilitaryArea) {
    militaryAreaStore.upsert(militaryArea.name, militaryArea);
  }

  /**
   * Méthode permettant de s'abonner aux changements des zones militaires
   */
  selectMilitaryAreas() {
    return militaryAreaQuery.selectMilitaryAreas();
  }

  /**
   * Méthode permettant d'obtenir ponctuellement l'ensemble des zones militaires
   */
  getMilitaryAreas() {
    return militaryAreaQuery.getMilitaryAreas();
  }
}

export const militaryAreaService = new MilitaryAreaService(militaryAreaStore);
