import {map} from 'rxjs/operators';

import {QueryEntity} from '@datorama/akita';
import {RegulationState, RegulationStore, regulationStore} from './regulation.store';

export class RegulationQuery extends QueryEntity<RegulationState> {
  constructor(protected store: RegulationStore) {
    super(store);
  }

  selectRegulation(regulationId: string) {
    return this.selectAll({filterBy: (entity) => entity.regulationId === regulationId}).pipe(
      map((entity) => entity[0] || null),
    );
  }
}

export const regulationQuery = new RegulationQuery(regulationStore);
