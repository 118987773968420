import {webSocket} from '../../utils/serverWebSocket';
import {Socket} from 'socket.io-client';

import {departureCountStore, DepartureCountStore} from './departure-count.store';
import {DepartureCounts} from './departure-count.model';
import {departureCountQuery} from './departure-count.query';

export class DepartureCountService {
  private socket?: Socket;

  constructor(private departureCountStore: DepartureCountStore) {}

  /**
   * Méthode permettant d'ouvrir la connexion avec le serveur via une webSocket et de s'abonner aux différents types de messages émis par celui ci
   */
  async start() {
    // On s'assure que le store est vide
    departureCountStore.remove();

    // On s'abonne au WS
    this.socket = webSocket().getSocket();
    this.onMessageReceived = this.onMessageReceived.bind(this);
    this.socket.on('message', this.onMessageReceived);
  }

  /**
   * Méthode permettant se désabonner du serveur et de vider le store, lorsque celui ci n'est plus utilisé
   * La socket n'est pas fermée car elle peut être utilisée par un autre modèle
   */
  destroy(): void {
    // Déconnexion du serveur
    if (this.socket) {
      this.socket.off('message', this.onMessageReceived);
      this.socket = undefined;
    }

    // On vide le store
    departureCountStore.remove();
  }

  /**
   * Listener pour les messages reçus du serveur
   * @param message message reçu
   */
  onMessageReceived(message: any) {
    const json = JSON.parse(message);

    if (json.typeMessage === 'departureCountUpdated') {
      this.addOrUpdateDepartureCount(json.data.tv, {
        ...json.data,
        departureCount: json.data.departureCount.map((dc: any) => ({
          ...dc,
          start: new Date(dc.start),
          end: new Date(dc.end),
        })),
      });
    }
  }

  /**
   * Méthode permettant d'ajouter ou mettre à jour les départs d'un TV
   * @param tv TV pour lequel mettre à jour les départs
   * @param departureCount Départs à jour pour le TV
   */
  addOrUpdateDepartureCount(tv: string, departureCount: DepartureCounts) {
    departureCountStore.upsert(tv, departureCount);
  }

  /**
   * Méthode permettant de s'abonner aux départs d'un TV
   * @param tv TV pour lequel on veut s'abonner aux départs
   */
  selectDepartureCountsForTV(tv: string) {
    return departureCountQuery.selectDepartureCountsForTV(tv);
  }
}

export const departureCountService = new DepartureCountService(departureCountStore);
