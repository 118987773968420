import React, {useState, useEffect} from 'react';

import * as d3 from 'd3';

import BeaconsLayer from './map-layers/beacons-layer.js';
import SectorsLayer from './map-layers/sectors-layer.js';
import FlowsLayer from './map-layers/flows-layer.js';
import MeteoLayer from './map-layers/meteo-layer.js';
import FlightLayer from './map-layers/flights-layer.js';
import DistanceLayer from './map-layers/distance-layer.js';

import {flightService} from '../models/flight/flight.service.ts';
import {beaconService} from '../models/beacon/beacon.service.ts';

const BACKGROUND_WIDTH = 3840;
const BACKGROUND_HEIGHT = 2160;
const BACKGROUND_X = -1000;
const BACKGROUND_Y = -750;

function Map() {
  // State
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [k, setK] = useState(1);

  // At mount, set pan & zoom
  useEffect(() => {
    // récupération du svg retourné dans la méthode render()
    const svg = d3.select('#svg');

    // récupération de la carte
    const map = svg.select('.map');

    // ajout du pan & zoom
    const zoom = d3
      .zoom()
      .scaleExtent([1, 16])
      .on('zoom', (event) => {
        // Pour ne pas sortir des limites du rectangle de fond
        let newX = event.transform.x;
        let newY = event.transform.y;
        const newK = event.transform.k;

        if (newX > -BACKGROUND_X * newK) {
          newX = -BACKGROUND_X * newK;
        } else if (newX < -(BACKGROUND_WIDTH + BACKGROUND_X) * newK + window.innerWidth) {
          newX = -(BACKGROUND_WIDTH + BACKGROUND_X) * newK + window.innerWidth;
        }

        if (newY > -BACKGROUND_Y * newK) {
          newY = -BACKGROUND_Y * newK;
        } else if (newY < -(BACKGROUND_HEIGHT + BACKGROUND_Y) * newK + window.innerHeight) {
          newY = -(BACKGROUND_HEIGHT + BACKGROUND_Y) * newK + window.innerHeight;
        }

        // Mise à jour des coordonnées et echelles
        setX(newX);
        setY(newY);
        setK(newK);
      });

    svg.call(zoom).on('dblclick.zoom', null);

    // récupération et ajout du fond de carte
    const path = d3.geoPath(beaconService.getProjection());
    d3.json('./europe.geojson').then((data) => {
      map
        .append('path')
        .attr('d', path(data))
        .attr('fill', '#777f96')
        .attr('stroke', 'black')
        .attr('stroke-width', 0.6)
        .attr('vector-effect', 'non-scaling-stroke');
    });
  }, []);

  // Event handler
  const handleClick = (event) => {
    flightService.setSelected('');
    beaconService.updateSelectedBeacon('');
  };

  return (
    <svg id="svg" width="100%" height="100%">
      <g className="background" onClick={handleClick}>
        <rect width={BACKGROUND_WIDTH} height={BACKGROUND_HEIGHT} fill="#868EA8" />
      </g>
      <g className="map" onClick={handleClick} transform={`translate(${x} ${y}) scale(${k})`} />
      <SectorsLayer x={x} y={y} k={k} />
      <DistanceLayer x={x} y={y} k={k} />
      <MeteoLayer x={x} y={y} k={k} />
      <FlowsLayer x={x} y={y} k={k} />
      <BeaconsLayer x={x} y={y} k={k} />
      <FlightLayer x={x} y={y} k={k} />
    </svg>
  );
}

export default Map;
