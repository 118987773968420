import React, {FC, useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {useStyles} from './ChartsCardStyle';
import withChartsCardData from './withChartsCardData';
import RepartitionGraph from '../../../drawers/graphs/repartition-graph';
import DeparturesArrivalsGraph from '../../../drawers/graphs/departures-arrivals-graph';
import {IChartsCard} from './ChartsCard.type';
import IntervalsBarChart from '../../charts/intervalsBarChart/IntervalsBarChart';

const ChartsCard = (props: IChartsCard) => {
  const {beaconSelected, loadWithCapacities, minutesLeft, type} = props;

  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      {loadWithCapacities && (
        <CardContent>
          <Typography className={classes.cardTitle}>Graphiques</Typography>
          <IntervalsBarChart
            beaconSelected={beaconSelected}
            loadWithCapacities={loadWithCapacities}
            minutesLeft={minutesLeft}
            max={[16, 18]}
          />
          {type === 'airport' && (
            <>
              <br />
              <RepartitionGraph
                beaconSelected={beaconSelected}
                loadWithCapacities={loadWithCapacities}
                minutesLeft={minutesLeft}
                max={[16, 18]}
              />
            </>
          )}
          {type === 'airport' && beaconSelected === 'LFPO' && (
            <>
              <br />
              <DeparturesArrivalsGraph
                beaconSelected={beaconSelected}
                loadWithCapacities={loadWithCapacities}
                minutesLeft={minutesLeft}
                max={[16, 18]}
              />
            </>
          )}
        </CardContent>
      )}
    </Card>
  );
};

export default withChartsCardData(ChartsCard);
