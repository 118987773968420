import {io, Socket} from 'socket.io-client';

interface WebSocket {
  getSocket: () => Socket;
}

export const webSocket = (): WebSocket => {
  let _socket: Socket;

  return {
    getSocket(): Socket {
      if (!process.env.REACT_APP_SERVER_ADDR) throw new Error('Server address not defined');
      if (!_socket) {
        _socket = io(process.env.REACT_APP_SERVER_ADDR as string, {
          path: '/data/',
        });
      }
      return _socket;
    },
  };
};
