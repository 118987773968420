import Switch from '@material-ui/core/Switch';
import {withStyles} from '@material-ui/styles';

const AirportSwitch = withStyles({
  switchBase: {
    color: '#607d8b',
    '&$checked': {
      color: '#607d8b',
    },
    '&$checked + $track': {
      backgroundColor: '#607d8b',
      opacity: 0.5,
    },
    '& + $track': {
      backgroundColor: '#607d8b',
      opacity: 0.5,
    },
  },
  checked: {},
  track: {},
})(Switch);

export default AirportSwitch;
