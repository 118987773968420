import {displayStore, DisplayStore} from './display.store';
import {displayQuery} from './display.query';

export class DisplayService {
  constructor(private displayStore: DisplayStore) {}

  /**
   * Méthode permettant de démarrer le service (pas d'effet ici, la méthode est uniquement là pour la cohérence entre tous les modèles)
   */
  start() {
    console.log('Display service started');
  }

  /**
   * Méthode permettant d'arrêter le service (pas d'effet ici, la méthode est uniquement là pour la cohérence entre tous les modèles)
   */
  destroy() {
    console.log('Display service stopped');
  }

  /**
   * Méthode permettant de définir si les balises Geo doivent être affichées ou non
   * @param areBeaconsGeoDisplayed affichage des balises Geo
   */
  updateBeaconsGeoDisplayed(areBeaconsGeoDisplayed: boolean) {
    displayStore.update({
      areBeaconsGeoDisplayed: areBeaconsGeoDisplayed,
    });
  }

  /**
   * Méthode permettant de définir si les balises Other Airport doivent être affichées ou non
   * @param areBeaconsOtherAirportDisplayed affichage des balises Other Airport
   */
  updateBeaconsOtherAirportDisplayed(areBeaconsOtherAirportDisplayed: boolean) {
    displayStore.update({
      areBeaconsOtherAirportDisplayed: areBeaconsOtherAirportDisplayed,
    });
  }

  /**
   * Méthode permettant de définir si les balises Iaf doivent être affichées ou non
   * @param areBeaconsIafDisplayed affichage des balises Iaf
   */
  updateBeaconsIafDisplayed(areBeaconsIafDisplayed: boolean) {
    displayStore.update({
      areBeaconsIafDisplayed: areBeaconsIafDisplayed,
    });
  }

  /**
   * Méthode permettant de définir si les balises Mf doivent être affichées ou non
   * @param areBeaconsMfDisplayed affichage des balises Mf
   */
  updateBeaconsMfDisplayed(areBeaconsMfDisplayed: boolean) {
    displayStore.update({
      areBeaconsMfDisplayed: areBeaconsMfDisplayed,
    });
  }

  /**
   * Méthode permettant de définir si les secteurs LFBB doivent être affichés ou non
   * @param areSectorLfbbDisplayed affichage des secteurs LFBB
   */
  updateSectorLfbbDisplayed(areSectorLfbbDisplayed: boolean) {
    displayStore.update({
      areSectorLfbbDisplayed: areSectorLfbbDisplayed,
    });
  }

  /**
   * Méthode permettant de définir si les secteurs EGA doivent être affichés ou non
   * @param areSectorEgaDisplayed affichage des secteurs EGA
   */
  updateSectorEgaDisplayed(areSectorEgaDisplayed: boolean) {
    displayStore.update({
      areSectorEgaDisplayed: areSectorEgaDisplayed,
    });
  }

  /**
   * Méthode permettant de définir si les secteurs UIR doivent être affichés ou non
   * @param areSectorUirDisplayed affichage des secteurs UIR
   */
  updateSectorUirDisplayed(areSectorUirDisplayed: boolean) {
    displayStore.update({
      areSectorUirDisplayed: areSectorUirDisplayed,
    });
  }

  /**
   * Méthode permettant de définir si les secteurs NW doivent être affichés ou non
   * @param areSectorSectoNwDisplayed affichage des secteurs NW
   */
  updateSectorSectoNwDisplayed(areSectorSectoNwDisplayed: boolean) {
    displayStore.update({
      areSectorSectoNwDisplayed: areSectorSectoNwDisplayed,
    });
  }

  /**
   * Méthode permettant de définir si les secteurs MUAC KUAC doivent être affichés ou non
   * @param areSectorMuacKuacDisplayed affichage des secteurs MUAC KUAC
   */
  updateSectorMuacKuacDisplayed(areSectorMuacKuacDisplayed: boolean) {
    displayStore.update({
      areSectorMuacKuacDisplayed: areSectorMuacKuacDisplayed,
    });
  }

  /**
   * Méthode permettant de définir si les secteurs SE doivent être affichés ou non
   * @param areSectorSectoSeDisplayed affichage des secteurs SE
   */
  updateSectorSectoSeDisplayed(areSectorSectoSeDisplayed: boolean) {
    displayStore.update({
      areSectorSectoSeDisplayed: areSectorSectoSeDisplayed,
    });
  }

  /**
   * Méthode permettant de définir si les étiquètes de vols doivent être affichés en permanence ou non
   * @param areLabelsDisplayed affichage des étiquètes de vols
   */
  updateLabelsDisplayed(areLabelsDisplayed: boolean) {
    displayStore.update({
      areLabelsDisplayed: areLabelsDisplayed,
    });
  }

  /**
   * Méthode permettant de définir si les turbulences doivent être affichées ou non
   * @param areTurbulencesDisplayed affichage des turbulences
   */
  updateMeteoTurbulencesDisplayed(areTurbulencesDisplayed: boolean) {
    displayStore.update({
      areMeteoTurbulencesDisplayed: areTurbulencesDisplayed,
    });
  }

  /**
   * Méthode permettant de définir si les convections doivent être affichées ou non
   * @param areConvectionsDisplayed affichage des convections
   */
  updateMeteoConvectionsDisplayed(areConvectionsDisplayed: boolean) {
    displayStore.update({
      areMeteoConvectionsDisplayed: areConvectionsDisplayed,
    });
  }

  /**
   * Méthode permettant de définir si les lightnings doivent être affichées ou non
   * @param areLightningsDisplayed affichage des lightnings
   */
  updateMeteoLightningsDisplayed(areLightningsDisplayed: boolean) {
    displayStore.update({
      areMeteoLightningsDisplayed: areLightningsDisplayed,
    });
  }

  /**
   * Méthode permettant de définir si les RVRs doivent être affichées ou non
   * @param areRVRsDisplayed affichage des RVRs
   */
  updateMeteoRVRsDisplayed(areRVRsDisplayed: boolean) {
    displayStore.update({
      areMeteoRVRsDisplayed: areRVRsDisplayed,
    });
  }

  /**
   * Méthode permettant de définir le filtre temporel d'affichage des données météo en minutes
   * @param timeFilter filtre temporel en minutes (0, 15, 30, 45, ou 60)
   */
  updateMeteoTimeFilter(timeFilter: number) {
    displayStore.update({
      meteoTimeFilter: timeFilter,
    });
  }

  /**
   * Méthode permettant de définir si les marqueurs de distances doivent être affichés ou non
   * @param areDistanceMarksDisplayed affichage des marqueurs de distance
   */
  updateDistanceMarksDisplayed(areDistanceMarksDisplayed: boolean) {
    displayStore.update({
      areDistanceMarksDisplayed: areDistanceMarksDisplayed,
    });
  }

  /**
   * Méthode permettant de s'abonner au changement d'affichage des balises Geo
   */
  areBeaconsGeoDisplayed() {
    return displayQuery.areBeaconsGeoDisplayed();
  }

  /**
   * Méthode permettant de s'abonner au changement d'affichage des balises Other Airport
   */
  areBeaconsOtherAirportDisplayed() {
    return displayQuery.areBeaconsOtherAirportDisplayed();
  }

  /**
   * Méthode permettant de s'abonner au changement d'affichage des balises Iaf
   */
  areBeaconsIafDisplayed() {
    return displayQuery.areBeaconsIafDisplayed();
  }

  /**
   * Méthode permettant de s'abonner au changement d'affichage des balises Mf
   */
  areBeaconsMfDisplayed() {
    return displayQuery.areBeaconsMfDisplayed();
  }

  /**
   * Méthode permettant de s'abonner au changement d'affichage des secteurs LFBB
   */
  areSectorLfbbDisplayed() {
    return displayQuery.areSectorLfbbDisplayed();
  }

  /**
   * Méthode permettant de s'abonner au changement d'affichage des secteurs EGA
   */
  areSectorEgaDisplayed() {
    return displayQuery.areSectorEgaDisplayed();
  }

  /**
   * Méthode permettant de s'abonner au changement d'affichage des secteurs UIR
   */
  areSectorUirDisplayed() {
    return displayQuery.areSectorUirDisplayed();
  }

  /**
   * Methode permettant de s'abonner au changement d'affichage des secteurs NW
   */
  areSectorSectoNwDisplayed() {
    return displayQuery.areSectorSectoNWDisplayed();
  }

  /**
   * Methode permettant de s'abonner au changement d'affichage des secteurs MUAC KUAC
   */
  areSectorMuacKuacDisplayed() {
    return displayQuery.areSectorMUACKUACDisplayed();
  }

  /**
   * Methode permettant de s'abonner au changement d'affichage des secteurs SE
   */
  areSectorSectoSeDisplayed() {
    return displayQuery.areSectorSectoSEDisplayed();
  }

  /**
   * Méthode permettant de s'abonner au changement d'affichage permanent des étiquètes de vols
   */
  areLabelsDisplayed() {
    return displayQuery.areLabelsDisplayed();
  }

  /**
   * Méthode permettant de s'abonner au changement d'affichage des balises
   */
  areBeaconsDisplayed() {
    return displayQuery.areBeaconsDisplayed();
  }

  /**
   * Méthode permettant de s'abonner au changement d'affichage des secteurs
   */
  areSectorsDisplayed() {
    return displayQuery.areSectorsDisplayed();
  }

  /**
   * Méthode permettant de s'abonner au changement d'affichage des turbulences
   */
  areMeteoTurbulencesDisplayed() {
    return displayQuery.areMeteoTurbulencesDisplayed();
  }

  /**
   * Méthode permettant de s'abonner au changement d'affichage des convections
   */
  areMeteoConvectionsDisplayed() {
    return displayQuery.areMeteoConvectionsDisplayed();
  }

  /**
   * Méthode permettant de s'abonner au changement d'affichage des lightnings
   */
  areMeteoLightningsDisplayed() {
    return displayQuery.areMeteoLightningsDisplayed();
  }

  /**
   * Méthode permettant de s'abonner au changement d'affichage des RVRs
   */
  areMeteoRVRsDisplayed() {
    return displayQuery.areMeteoRVRsDisplayed();
  }

  /**
   * Méthode permettant de s'abonner au changement de filtre météo temporel
   */
  whatIsMeteoTimeFilter() {
    return displayQuery.meteoTimeFilter();
  }

  /**
   * Méthode permettant de s'abonner au changement d'affichage des marqueurs de distance
   */
  areDistanceMarksDisplayed() {
    return displayQuery.areDistanceMarksDisplayed();
  }
}

export const displayService = new DisplayService(displayStore);
