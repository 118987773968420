import {switchMap, map} from 'rxjs/operators';
import {of} from 'rxjs';

import {QueryEntity} from '@datorama/akita';
import {FlightState, FlightStore, flightStore} from './flight.store';
import {Flight} from './flight.model';

import {dateService} from '../date/date.service';
import {beaconService} from '../beacon/beacon.service';
import {planificationService} from '../planification/planification.service';

export class FlightQuery extends QueryEntity<FlightState> {
  constructor(protected store: FlightStore) {
    super(store);
  }

  selectAllFlights() {
    return this.selectAll();
  }

  selectAllFlightsForAirport(airport: 'LFPO' | 'LFPG') {
    // return this.selectAll({
    //     filterBy: entity => entity.arr === airport
    // });
    return dateService.selectUpdatePeriod().pipe(
      map((period) => {
        return this.getAll({
          filterBy: (entity) => entity.arr === airport,
        });
      }),
    );
  }

  selectFlight(callsign: string) {
    return this.selectEntity((entity) => entity.callsign === callsign);
  }

  selectHovered() {
    return this.select('hovered');
  }

  selectSelected() {
    return this.select('selected');
  }

  selectHoveredFlight() {
    return this.selectHovered().pipe(
      switchMap((hovered) =>
        hovered ? this.selectEntity((entity) => entity.callsign === hovered) : of(null),
      ),
    );
  }

  selectSelectedFlight() {
    return this.selectSelected().pipe(
      switchMap((selected) =>
        selected ? this.selectEntity((entity) => entity.callsign === selected) : of(null),
      ),
    );
  }

  selectLoadForMfAndAirport(mf: string, airport: 'LFPO' | 'LFPG') {
    return dateService.selectUpdatePeriod().pipe(
      map((period: number) => {
        const flights = this.getFlightsForMfAndAirport(mf, airport);

        // On récupère l'heure du modèle
        const currentDate: Date = new Date(dateService.getDate().getTime());
        currentDate.setUTCSeconds(0);
        currentDate.setUTCMilliseconds(0);
        const step = 20;

        // On filtre et on trie la liste de vols sur la balise (flightsOnBeacon)
        const flightsOnBeacon = flights
          .filter(
            (flight: Flight) =>
              flight &&
              flight.etaMF &&
              flight.etaMF.getTime() >= currentDate.getTime() &&
              flight.etaMF.getTime() < currentDate.getTime() + 3 * 3600000,
          ) // entre maintenant et +3h
          .sort((a, b) => (a.etaMF && b.etaMF ? a.etaMF.getTime() - b.etaMF.getTime() : 0));

        // On calcule le mfLoadArray
        const mfLoadArray = [];

        // Initialisation de la date de début (Heure courante si les minutes sont 00, 20 ou 40, sinon on arrondit au multiple de 20 inférieur (ex : 12:54:00 sera arrondi 12:40:00))
        let cursorDate: Date = new Date(currentDate);
        cursorDate.setMinutes(currentDate.getMinutes() - (currentDate.getMinutes() % step));

        // Calcul de la dernière date (3h après le début)
        const endDate: Date = new Date(currentDate.getTime() + 3 * 3600000);

        do {
          // Création d'une entrée du mfLoadArray
          const loadArrayItem: any = {};

          // La date de début est la date curseur
          loadArrayItem.from = cursorDate;

          // La date courante est incrémentée de 20 minutes (step)
          cursorDate = new Date(cursorDate.getTime() + step * 60000);

          // La date de fin est la nouvelle date curseur
          loadArrayItem.to = cursorDate;

          // On récupère les vols pour la période courante
          loadArrayItem.flights = flights
            .filter(
              (flight) =>
                flight.etaMF &&
                flight.etaMF.getTime() >= loadArrayItem.from.getTime() &&
                flight.etaMF.getTime() < loadArrayItem.to.getTime(),
            ) // Vols dans le créneau de 20 minutes
            .sort((a, b) => (a.etaMF && b.etaMF ? a.etaMF.getTime() - b.etaMF.getTime() : 0));

          // On calcule le load pour la période
          loadArrayItem.load = loadArrayItem.flights.length;

          // L'item est ajouté au mfLoadArray
          mfLoadArray.push(loadArrayItem);
        } while (cursorDate.getTime() < endDate.getTime());

        // Correction de l'heure de début du premier créneau, et de l'heure de fin du dernier créneau (afin de ne pas avoir en dehors des 3 prochaines heures)
        mfLoadArray[0].from = currentDate;
        mfLoadArray[mfLoadArray.length - 1].to = new Date(currentDate.getTime() + 3 * 3600000);

        return {
          mf: mf,
          loadArray: mfLoadArray,
          currentDate: currentDate,
          flightsOnBeacon: flightsOnBeacon,
        };
      }),
    );
  }

  selectLoadForCopAndAirport(cop: string, airport: 'LFPO' | 'LFPG') {
    return dateService.selectUpdatePeriod().pipe(
      map((period: number) => {
        const flights = this.getFlightsForCopAndAirport(cop, airport);

        // On récupère l'heure du modèle
        const currentDate: Date = new Date(dateService.getDate().getTime());
        currentDate.setUTCSeconds(0);
        currentDate.setUTCMilliseconds(0);
        const step = 20;

        // On filtre et on trie la liste de vols sur la balise (flightsOnBeacon)
        const flightsOnBeacon = flights
          .filter(
            (flight: Flight) =>
              flight &&
              flight.etaMF &&
              flight.etaMF.getTime() >= currentDate.getTime() &&
              flight.etaMF.getTime() < currentDate.getTime() + 3 * 3600000,
          ) // entre maintenant et +3h
          .sort((a, b) => (a.etaMF && b.etaMF ? a.etaMF.getTime() - b.etaMF.getTime() : 0));

        // On calcule le copLoadArray
        const copLoadArray = [];

        // Initialisation de la date de début (Heure courante si les minutes sont 00, 20 ou 40, sinon on arrondit au multiple de 20 inférieur (ex : 12:54:00 sera arrondi 12:40:00))
        let cursorDate: Date = new Date(currentDate);
        cursorDate.setMinutes(currentDate.getMinutes() - (currentDate.getMinutes() % step));

        // Calcul de la dernière date (3h après le début)
        const endDate: Date = new Date(currentDate.getTime() + 3 * 3600000);

        do {
          // Création d'une entrée du copLoadArray
          const loadArrayItem: any = {};

          // La date de début est la date curseur
          loadArrayItem.from = cursorDate;

          // La date courante est incrémentée de 20 minutes (step)
          cursorDate = new Date(cursorDate.getTime() + step * 60000);

          // La date de fin est la nouvelle date curseur
          loadArrayItem.to = cursorDate;

          // On récupère les vols pour la période courante
          loadArrayItem.flights = flights
            .filter(
              (flight) =>
                flight.etaMF &&
                flight.etaMF.getTime() >= loadArrayItem.from.getTime() &&
                flight.etaMF.getTime() < loadArrayItem.to.getTime(),
            ) // Vols dans le créneau de 20 minutes
            .sort((a, b) => (a.etaMF && b.etaMF ? a.etaMF.getTime() - b.etaMF.getTime() : 0));

          // On calcule le load pour la période
          loadArrayItem.load = loadArrayItem.flights.length;

          // L'item est ajouté au copLoadArray
          copLoadArray.push(loadArrayItem);
        } while (cursorDate.getTime() < endDate.getTime());

        // Correction de l'heure de début du premier créneau, et de l'heure de fin du dernier créneau (afin de ne pas avoir en dehors des 3 prochaines heures)
        copLoadArray[0].from = currentDate;
        copLoadArray[copLoadArray.length - 1].to = new Date(currentDate.getTime() + 3 * 3600000);

        return {
          cop: cop,
          loadArray: copLoadArray,
          currentDate: currentDate,
          flightsOnBeacon: flightsOnBeacon,
        };
      }),
    );
  }

  selectLoadForIafAndAirport(iaf: string, airport: 'LFPO' | 'LFPG') {
    return dateService.selectUpdatePeriod().pipe(
      map((period: number) => {
        const flights = this.getFlightsForIafAndAirport(iaf, airport);

        // On récupère l'heure du modèle
        const currentDate: Date = new Date(dateService.getDate().getTime());
        currentDate.setUTCSeconds(0);
        currentDate.setUTCMilliseconds(0);
        const step = 20;

        // On filtre et on trie la liste de vols sur la balise (flightsOnBeacon)
        const flightsOnBeacon = flights
          .filter(
            (flight: Flight) =>
              flight &&
              flight.etaFF &&
              flight.etaFF.getTime() >= currentDate.getTime() &&
              flight.etaFF.getTime() < currentDate.getTime() + 3 * 3600000,
          ) // entre maintenant et +3h
          .sort((a, b) => (a.etaFF && b.etaFF ? a.etaFF.getTime() - b.etaFF.getTime() : 0));

        // On calcule le iafLoadArray
        const iafLoadArray = [];

        // Initialisation de la date de début (Heure courante si les minutes sont 00, 20 ou 40, sinon on arrondit au multiple de 20 inférieur (ex : 12:54:00 sera arrondi 12:40:00))
        let cursorDate: Date = new Date(currentDate);
        cursorDate.setMinutes(currentDate.getMinutes() - (currentDate.getMinutes() % step));

        // Calcul de la dernière date (3h après le début)
        const endDate: Date = new Date(currentDate.getTime() + 3 * 60 * 60000);

        do {
          // Création d'une entrée du iafLoadArray
          const loadArrayItem: any = {};

          // La date de début est la date curseur
          loadArrayItem.from = cursorDate;

          // La date courante est incrémentée de 20 minutes (step)
          cursorDate = new Date(cursorDate.getTime() + step * 60000);

          // La date de fin est la nouvelle date curseur
          loadArrayItem.to = cursorDate;

          // On récupère les vols pour la période courante
          loadArrayItem.flights = flights
            .filter(
              (flight: Flight) =>
                flight &&
                flight.etaFF &&
                flight.etaFF.getTime() >= loadArrayItem.from.getTime() &&
                flight.etaFF.getTime() < loadArrayItem.to.getTime(),
            ) // Vols dans le créneau de 20 minutes
            .sort((a, b) => (a.etaFF && b.etaFF ? a.etaFF.getTime() - b.etaFF.getTime() : 0));

          // On calcule le load pour la période
          loadArrayItem.load = loadArrayItem.flights.length;

          // L'item est ajouté au iafLoadArray
          iafLoadArray.push(loadArrayItem);
        } while (cursorDate.getTime() < endDate.getTime());

        // Correction de l'heure de début du premier créneau, et de l'heure de fin du dernier créneau (afin de ne pas avoir en dehors des 3 prochaines heures)
        iafLoadArray[0].from = currentDate;
        iafLoadArray[iafLoadArray.length - 1].to = new Date(currentDate.getTime() + 3 * 3600000);

        return {
          iaf: iaf,
          loadArray: iafLoadArray,
          currentDate: currentDate,
          flightsOnBeacon: flightsOnBeacon,
        };
      }),
    );
  }

  selectLoadForAirportAndRunway(airport: 'LFPO' | 'LFPG', runway: 'LFPO' | 'LFPGARS' | 'LFPGARN') {
    return dateService.selectUpdatePeriod().pipe(
      map((tenSecond: number) => {
        const flights = this.getFlightsForAirportAndRunway(airport, runway);

        // let departureCounts = airport === 'LFPO' && subairport === 'LFPO' ? this.getValue().departureCounts : null;

        // On récupère l'heure du modèle
        const currentDate: Date = new Date(dateService.getDate().getTime());
        currentDate.setUTCSeconds(0);
        currentDate.setUTCMilliseconds(0);
        const step = 20;

        // On filtre et on trie la liste de vols sur l'aeroport (flightsOnAirport)
        const flightsOnAirport = flights
          .filter(
            (flight: Flight) =>
              flight &&
              flight.eta &&
              flight.eta.getTime() >= currentDate.getTime() &&
              flight.eta.getTime() < currentDate.getTime() + 3 * 3600000,
          ) // entre maintenant et +3h
          .sort((a, b) => (a.eta && b.eta ? a.eta.getTime() - b.eta.getTime() : 0));

        // On calcule le loadArray
        const loadArray = [];

        // Initialisation de la date de début (Heure courante si les minutes sont 00, 20 ou 40, sinon on arrondit au multiple de 20 inférieur (ex : 12:54:00 sera arrondi 12:40:00))
        let cursorDate: Date = new Date(currentDate);
        cursorDate.setMinutes(currentDate.getMinutes() - (currentDate.getMinutes() % step));

        // Calcul de la dernière date (3h après le début)
        const endDate: Date = new Date(currentDate.getTime() + 3 * 3600000);

        do {
          // Création d'une entrée du mfLoadArray
          const loadArrayItem: any = {};

          // La date de début est la date curseur
          loadArrayItem.from = cursorDate;

          // La date courante est incrémentée de 20 minutes (step)
          cursorDate = new Date(cursorDate.getTime() + step * 60000);

          // La date de fin est la nouvelle date curseur
          loadArrayItem.to = cursorDate;

          // On récupère les vols pour la période courante
          loadArrayItem.flights = flights
            .filter(
              (flight: Flight) =>
                flight &&
                flight.eta &&
                flight.eta.getTime() >= loadArrayItem.from.getTime() &&
                flight.eta.getTime() < loadArrayItem.to.getTime(),
            ) // Vols dans le créneau de 20 minutes
            .sort((a, b) => (a.eta && b.eta ? a.eta.getTime() - b.eta.getTime() : 0));

          // On calcule le load pour la période
          loadArrayItem.load = loadArrayItem.flights.length;

          // On calcule la répartition des vols par iaf
          const iafs = beaconService.getIafForAirport(airport);
          loadArrayItem.flightRepartition = {};
          iafs.forEach((iaf) => {
            loadArrayItem.flightRepartition[iaf] = loadArrayItem.flights.reduce(
              (accumulator: number, currentItem: Flight) =>
                currentItem && currentItem.iaf && currentItem.iaf === iaf
                  ? accumulator + 1
                  : accumulator,
              0,
            );
          });

          // L'item est ajouté au loadArray
          loadArray.push(loadArrayItem);
        } while (cursorDate.getTime() < endDate.getTime());

        // Correction de l'heure de début du premier créneau, et de l'heure de fin du dernier créneau (afin de ne pas avoir en dehors des 3 prochaines heures)
        loadArray[0].from = currentDate;
        loadArray[loadArray.length - 1].to = new Date(currentDate.getTime() + 3 * 3600000);

        return {
          airportName: airport,
          loadArray: loadArray,
          date: currentDate,
          flightsOnAirport: flightsOnAirport,
        };
      }),
    );
  }

  selectLoadForAirportAndQfu(airport: 'LFPO' | 'LFPG', qfu: string) {
    return dateService.selectUpdatePeriod().pipe(
      map((tenSecond: number) => {
        const flights = this.getFlightsForAirportAndQfu(airport, qfu);

        // On récupère l'heure du modèle
        const currentDate: Date = new Date(dateService.getDate().getTime());
        currentDate.setUTCSeconds(0);
        currentDate.setUTCMilliseconds(0);
        const step = 20;

        // On filtre et on trie la liste de vols sur l'aeroport (flightsOnAirport)
        const flightsOnAirport = flights
          .filter(
            (flight: Flight) =>
              flight &&
              flight.eta &&
              flight.eta.getTime() >= currentDate.getTime() &&
              flight.eta.getTime() < currentDate.getTime() + 3 * 3600000,
          ) // entre maintenant et +3h
          .sort((a, b) => (a.eta && b.eta ? a.eta.getTime() - b.eta.getTime() : 0));

        // On calcule le loadArray
        const loadArray = [];

        // Initialisation de la date de début (Heure courante si les minutes sont 00, 20 ou 40, sinon on arrondit au multiple de 20 inférieur (ex : 12:54:00 sera arrondi 12:40:00))
        let cursorDate: Date = new Date(currentDate);
        cursorDate.setMinutes(currentDate.getMinutes() - (currentDate.getMinutes() % step));

        // Calcul de la dernière date (3h après le début)
        const endDate: Date = new Date(currentDate.getTime() + 3 * 60 * 60000);

        do {
          // Création d'une entrée du mfLoadArray
          const loadArrayItem: any = {};

          // La date de début est la date curseur
          loadArrayItem.from = cursorDate;

          // La date courante est incrémentée de 20 minutes (step)
          cursorDate = new Date(cursorDate.getTime() + step * 60000);

          // La date de fin est la nouvelle date curseur
          loadArrayItem.to = cursorDate;

          // On récupère les vols pour la période courante
          loadArrayItem.flights = flights
            .filter(
              (flight: Flight) =>
                flight &&
                flight.eta &&
                flight.eta.getTime() >= loadArrayItem.from.getTime() &&
                flight.eta.getTime() < loadArrayItem.to.getTime(),
            ) // Vols dans le créneau de 20 minutes
            .sort((a, b) => (a.eta && b.eta ? a.eta.getTime() - b.eta.getTime() : 0));

          // On calcule le load pour la période
          loadArrayItem.load = loadArrayItem.flights.length;

          // On calcule la répartition des vols par iaf
          const iafs = beaconService.getIafForAirport(airport);
          loadArrayItem.flightRepartition = {};
          iafs.forEach((iaf) => {
            loadArrayItem.flightRepartition[iaf] = loadArrayItem.flights.reduce(
              (accumulator: number, currentItem: Flight) =>
                currentItem && currentItem.iaf && currentItem.iaf === iaf
                  ? accumulator + 1
                  : accumulator,
              0,
            );
          });

          // L'item est ajouté au loadArray
          loadArray.push(loadArrayItem);
        } while (cursorDate.getTime() < endDate.getTime());

        // Correction de l'heure de début du premier créneau, et de l'heure de fin du dernier créneau (afin de ne pas avoir en dehors des 3 prochaines heures)
        loadArray[0].from = currentDate;
        loadArray[loadArray.length - 1].to = new Date(currentDate.getTime() + 3 * 3600000);

        return {
          airportName: airport,
          qfu: qfu,
          loadArray: loadArray,
          date: currentDate,
          flightsOnAirport: flightsOnAirport,
        };
      }),
    );
  }

  selectLoadForTv(tv: string) {
    return dateService.selectUpdatePeriod().pipe(
      map((tenSecond: number) => {
        const flights = this.getFlightsForTv(tv);

        // On récupère l'heure du modèle
        const currentDate: Date = new Date(dateService.getDate().getTime());
        currentDate.setUTCSeconds(0);
        currentDate.setUTCMilliseconds(0);
        const step = 20;

        // On calcule le loadArray
        const loadArray = [];

        // Initialisation de la date de début (Heure courante si les minutes sont 00, 20 ou 40, sinon on arrondit au multiple de 20 inférieur (ex : 12:54:00 sera arrondi 12:40:00))
        let cursorDate: Date = new Date(currentDate);
        cursorDate.setMinutes(currentDate.getMinutes() - (currentDate.getMinutes() % step));

        // Calcul de la dernière date (3h après le début)
        const endDate: Date = new Date(currentDate.getTime() + 3 * 60 * 60000);

        do {
          // Création d'une entrée du mfLoadArray
          const loadArrayItem: any = {};

          // La date de début est la date curseur
          loadArrayItem.from = cursorDate;

          // La date courante est incrémentée de 20 minutes (step)
          cursorDate = new Date(cursorDate.getTime() + step * 60000);

          // La date de fin est la nouvelle date curseur
          loadArrayItem.to = cursorDate;

          // On récupère les vols pour la période courante
          loadArrayItem.flights = flights
            .filter((flight: Flight) => {
              // Vols dans le créneau de 20 minutes
              const tvInformations =
                flight &&
                flight.inFlightlists &&
                flight.inFlightlists.some((tvInformations) => tvInformations.tv === tv)
                  ? flight.inFlightlists.find((tvInformations) => tvInformations.tv === tv)
                  : null;

              return (
                tvInformations &&
                tvInformations.entryTime &&
                tvInformations.entryTime.getTime() >= loadArrayItem.from.getTime() &&
                tvInformations.entryTime.getTime() < loadArrayItem.to.getTime()
              );
            })
            .sort((a, b) => {
              const aTvInformations = a.inFlightlists
                ? a.inFlightlists.find((tvInformations) => tvInformations.tv === tv)
                : null;
              const bTvInformations = b.inFlightlists
                ? b.inFlightlists.find((tvInformations) => tvInformations.tv === tv)
                : null;
              if (
                aTvInformations &&
                aTvInformations.entryTime &&
                bTvInformations &&
                bTvInformations.entryTime
              ) {
                return aTvInformations.entryTime.getTime() - bTvInformations.entryTime.getTime();
              }
              return 0;
            });

          // On calcule le load pour la période
          loadArrayItem.load = loadArrayItem.flights.length;

          // L'item est ajouté au loadArray
          loadArray.push(loadArrayItem);
        } while (cursorDate.getTime() < endDate.getTime());

        return {
          tv: tv,
          loadArray: loadArray,
          date: currentDate,
        };
      }),
    );
  }

  selectFlightsForAirportAndRunway(
    airport: 'LFPO' | 'LFPG',
    runway: 'LFPO' | 'LFPGARS' | 'LFPGARN',
  ) {
    const possibleQfus = planificationService.getPossibleQfuForAirport(airport, runway);
    // return this.selectAll({
    //     filterBy: entity => entity.arr === airport && entity.qfu !== null && entity.qfu !== undefined && possibleQfus.includes(entity.qfu)
    // });
    return dateService.selectUpdatePeriod().pipe(
      map((tenSecond) =>
        this.getAll({
          filterBy: (entity) =>
            entity.arr === airport &&
            entity.qfu !== null &&
            entity.qfu !== undefined &&
            possibleQfus.includes(entity.qfu),
        }),
      ),
    );
  }

  selectFlightsForAirportAndCop(airport: 'LFPO' | 'LFPG', beaconCopName: string) {
    // return this.selectAll({
    //     filterBy: entity => entity.arr === airport && entity.cop !== null && entity.cop !== undefined && entity.cop === beaconCopName
    // });
    return dateService.selectUpdatePeriod().pipe(
      map((tenSecond) =>
        this.getAll({
          filterBy: (entity) =>
            entity.arr === airport &&
            entity.cop !== null &&
            entity.cop !== undefined &&
            entity.cop === beaconCopName,
        }),
      ),
    );
  }

  selectFlightsForTvAndAirport(tv: string, airport: 'LFPO' | 'LFPG') {
    // return this.selectAll({
    //     filterBy: entity =>  !!entity.arr && entity.arr === airport && !!entity.inFlightlists && entity.inFlightlists.some(tvInformations => tvInformations.tv === tv)
    // });
    return dateService.selectUpdatePeriod().pipe(
      map((period) => {
        return this.getFlightsForTv(tv).filter(
          (flight) =>
            !!flight.arr &&
            flight.arr === airport &&
            !!flight.inFlightlists &&
            flight.inFlightlists.some((tvInformations) => tvInformations.tv === tv),
        );
      }),
    );
  }

  selectTEReroutableFlights() {
    return dateService.selectUpdatePeriod().pipe(
      map((tenSecond: number) => {
        // On prend tous les vols TE3
        let flights = this.getFlightsForTv('LFFTE3');

        // On filtre sur l'ADES
        flights = flights.filter(
          (flight) =>
            flight.inFlightlists &&
            flight.inFlightlists.some((tvInformations) => tvInformations.ades === 'LFPG'),
        );

        // On filtre sur l'ADEP
        flights = flights.filter((flight) => {
          const flightTvInformations = flight.inFlightlists
            ? flight.inFlightlists.find((tvInformations) => tvInformations.tv === 'LFFTE3')
            : null;

          return (
            flightTvInformations &&
            flightTvInformations.adep &&
            [
              /^EDDM$/,
              /^EDDS$/,
              /^LO[A-Z]{2}$/,
              /^LR[A-Z]{2}$/,
              /^LYBE$/,
              /^LHBP$/,
              /^LDZA$/,
              /^LC[A-Z]{2}$/,
              /^LT[A-Z]{2}$/,
              /^HECA$/,
            ].some((regex) => !!flightTvInformations.adep && regex.test(flightTvInformations.adep))
          );
        });

        // On filtre les vols qui ne sont pas au sol
        flights = flights.filter(
          (flight) => flight.airborneStatus && flight.airborneStatus === 'ON_GROUND',
        );

        // On récupère l'heure du modèle, pour le filtrage EOBT et STA
        const currentDate: Date = dateService.getDate();

        // On filtre les vols dont l'EOBT est dans moins de 30min
        flights = flights.filter((flight) => {
          const tvInformations = flight.inFlightlists
            ? flight.inFlightlists.find((tvInformations) => tvInformations.tv === 'LFFTE3')
            : null;

          return (
            tvInformations &&
            tvInformations.eobt &&
            tvInformations.eobt.getTime() >= currentDate.getTime() + 1800000
          ); // dans + de 30 minutes
        });

        // On filtre les vols dont la STA n'est pas comprise entre +90min et +240min
        flights = flights.filter(
          (flight) =>
            flight.sta &&
            flight.sta.getTime() >= currentDate.getTime() + 90 * 60000 &&
            flight.sta.getTime() <= currentDate.getTime() + 240 * 60000,
        );

        return flights;
      }),
    );
  }

  selectUJReroutableFlights() {
    return dateService.selectUpdatePeriod().pipe(
      map((tenSecond: number) => {
        // On prend tous les vols UJ
        let flights = this.getFlightsForTv('LFFUJ');

        // On filtre sur l'ADES
        flights = flights.filter(
          (flight) =>
            flight.inFlightlists &&
            flight.inFlightlists.some((tvInformations) => tvInformations.ades === 'LFPG'),
        );

        // On filtre sur l'ADEP
        flights = flights.filter((flight) => {
          const flightTvInformations = flight.inFlightlists
            ? flight.inFlightlists.find((tvInformations) => tvInformations.tv === 'LFFUJ')
            : null;

          return (
            flightTvInformations &&
            flightTvInformations.adep &&
            [
              /^EDDM$/,
              /^EDDS$/,
              /^LO[A-Z]{2}$/,
              /^LR[A-Z]{2}$/,
              /^LYBE$/,
              /^LHBP$/,
              /^LDZA$/,
              /^LC[A-Z]{2}$/,
              /^LT[A-Z]{2}$/,
              /^HECA$/,
            ].some((regex) => !!flightTvInformations.adep && regex.test(flightTvInformations.adep))
          );
        });

        // On filtre les vols qui ne sont pas au sol
        flights = flights.filter(
          (flight) => flight.airborneStatus && flight.airborneStatus === 'ON_GROUND',
        );

        // On récupère l'heure du modèle, pour le filtrage EOBT et STA
        const currentDate: Date = dateService.getDate();

        // On filtre les vols dont l'EOBT est dans moins de 30min
        flights = flights.filter((flight) => {
          const tvInformations = flight.inFlightlists
            ? flight.inFlightlists.find((tvInformations) => tvInformations.tv === 'LFFUJ')
            : null;

          return (
            tvInformations &&
            tvInformations.eobt &&
            tvInformations.eobt.getTime() >= currentDate.getTime() + 1800000
          ); // dans + de 30 minutes
        });

        // On filtre les vols dont la STA n'est pas comprise entre +90min et +240min
        flights = flights.filter(
          (flight) =>
            flight.sta &&
            flight.sta.getTime() >= currentDate.getTime() + 90 * 60000 &&
            flight.sta.getTime() <= currentDate.getTime() + 240 * 60000,
        );

        return flights;
      }),
    );
  }

  getFlights() {
    return this.getAll();
  }

  getFlightsForMfAndAirport(mf: string, airport: 'LFPO' | 'LFPG') {
    return this.getAll({
      filterBy: (entity) => entity.mf === mf && entity.arr === airport,
    });
  }

  getFlightsForIafAndAirport(iaf: string, airport: 'LFPO' | 'LFPG') {
    return this.getAll({
      filterBy: (entity) => entity.iaf === iaf && entity.arr === airport,
    });
  }

  getFlightsForCopAndAirport(cop: string, airport: 'LFPO' | 'LFPG') {
    return this.getAll({
      filterBy: (entity) => entity.cop === cop && entity.arr === airport,
    });
  }

  getFlightsForAirportAndRunway(airport: 'LFPO' | 'LFPG', runway: 'LFPO' | 'LFPGARN' | 'LFPGARS') {
    const possibleQfus = planificationService.getPossibleQfuForAirport(airport, runway);
    return this.getAll({
      filterBy: (entity) =>
        entity.arr === airport &&
        entity.qfu !== null &&
        entity.qfu !== undefined &&
        possibleQfus.includes(entity.qfu),
    });
  }

  getFlightsForAirportAndQfu(airport: 'LFPO' | 'LFPG', qfu: string) {
    return this.getAll({
      filterBy: (entity) => entity.arr === airport && entity.qfu === qfu,
    });
  }

  getFlightsForTv(tv: string) {
    return this.getAll({
      filterBy: (entity) =>
        entity.inFlightlists !== null &&
        entity.inFlightlists !== undefined &&
        entity.inFlightlists.some((tvInformations) => tvInformations.tv === tv),
    });
  }
}

export const flightQuery = new FlightQuery(flightStore);
