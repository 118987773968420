import React, {useEffect, useState} from 'react';
import './planifications-graph.css';

import {makeStyles} from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import Brightness2Icon from '@material-ui/icons/Brightness2';
import {Popover} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputAdornment from '@material-ui/core/InputAdornment';

import {planificationService} from '../../../models/planification/planification.service';

// Styles des inputs
const useStyles = makeStyles((theme) => ({
  dialogText: {
    marginBottom: 5,
    color: 'rgba(0, 0, 0, 0.54)',
  },
}));

function PlanificationsGraphEditCurrent({
  airport,
  runway,
  currentConfiguration,
  currentCadency,
  enabled,
  disabled,
  changeConfigurationDisabled,
  onMenuOpen,
  onMenuClose,
}) {
  const classes = useStyles();

  // Configurations possibles
  const [possibleConfiguration, setPossibleConfiguration] = useState([]);

  useEffect(() => {
    setPossibleConfiguration(planificationService.getPossibleConfigForAirport(airport));
  }, [airport]);

  // Paramètres sélectionnés dans les inputs lors de l'édition de la configuration courante
  const [selectedConfiguration, setSelectedConfiguration] = useState('');
  const [selectedCadency, setSelectedCadency] = useState(0);

  // Ouverture de la Popover
  const [anchorEl, setAnchorEl] = useState(null);
  const popoverOpen = Boolean(anchorEl);

  // Ouverture du Dialog
  const [dialogOpen, setDialogOpen] = useState(false);

  const openPopover = (event) => {
    // On initialise les valeurs des inputs
    setSelectedConfiguration(currentConfiguration || possibleConfiguration[0]);
    setSelectedCadency(currentCadency || 90);

    // Ouverture de la Popover
    setAnchorEl(event.currentTarget);

    // On prévient le parent que le menu est ouvert
    onMenuOpen();
  };

  const closePopover = () => {
    setAnchorEl(null);

    // On prévient le parent que le menu est fermé
    onMenuClose();
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const setNewConfigurationAndCadency = () => {
    if (selectedConfiguration !== currentConfiguration) {
      // Nouvelle configuration
      planificationService.setNewConfigurationForAirport(selectedConfiguration, airport);

      // QFU associé
      const qfu = planificationService.getQfuForAirportAndConfig(
        airport,
        runway,
        selectedConfiguration,
      );

      // Nouvelle cadence pour le nouveau QFU
      planificationService.setNewCadencyForAirport(selectedCadency, qfu, airport);
    } else if (selectedCadency !== currentCadency) {
      // QFU de la configuration courante
      const qfu = planificationService.getQfuForAirportAndConfig(
        airport,
        runway,
        currentConfiguration,
      );

      // Nouvelle cadence
      planificationService.setNewCadencyForAirport(selectedCadency, qfu, airport);
    }
    closePopover();
    closeDialog();
  };

  return (
    <>
      <button
        className={`edition-button edit-button ${enabled ? 'enabled-button' : ''}`}
        onClick={openPopover}
        disabled={disabled}>
        <EditIcon fontSize="small" />
      </button>
      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'right'}}>
        <div className="popover-content">
          <div className="popover-content-line">
            <FormControl color="secondary" style={{width: '150px'}}>
              <InputLabel id="configuration-select-label">Configuration</InputLabel>
              <Select
                labelId="configuration-select-label"
                id="demo-simple-select"
                value={selectedConfiguration}
                onChange={(event) => setSelectedConfiguration(event.target.value)}
                disabled={changeConfigurationDisabled}
                style={{marginRight: '10px'}}>
                {possibleConfiguration
                  .sort((a, b) => {
                    if (a.endsWith('_CVF') && !b.endsWith('_CVF')) {
                      return 1;
                    }
                    if (!a.endsWith('_CVF') && b.endsWith('_CVF')) {
                      return -1;
                    }
                    return b - a;
                  })
                  .map((config) => (
                    <MenuItem value={config} key={config}>
                      <span style={{width: '40px'}}>
                        {config.replace('PG_', '').replace('PO', '').replace('_CVF', '')}
                      </span>
                      <span>
                        {config.endsWith('_CVF') ? <Brightness2Icon fontSize="inherit" /> : ''}
                      </span>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl color="secondary" style={{width: '100px'}}>
              <InputLabel id="cadency-input-label">Cadence</InputLabel>
              <Input
                type="number"
                value={selectedCadency}
                onChange={(event) => setSelectedCadency(parseInt(event.target.value))}
                inputProps={{min: 10, max: 300}}
                endAdornment={<InputAdornment position="end">sec.</InputAdornment>}
              />
            </FormControl>
          </div>
          <div className="popover-content-line popover-content-button-line">
            <Button
              color="secondary"
              disabled={
                currentConfiguration === selectedConfiguration && currentCadency === selectedCadency
              }
              onClick={openDialog}>
              Confirmer
            </Button>
            <Button color="secondary" onClick={closePopover}>
              Annuler
            </Button>
          </div>
        </div>
      </Popover>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle>Confirmer les modifications ?</DialogTitle>
        <DialogContent>
          {currentConfiguration !== selectedConfiguration ? (
            <div className={classes.dialogText}>
              Configuration :{' '}
              {selectedConfiguration.replace('PG_', '').replace('PO', '').replace('_CVF', '')}{' '}
              {selectedConfiguration.endsWith('_CVF') ? <Brightness2Icon fontSize="inherit" /> : ''}
            </div>
          ) : (
            ''
          )}
          <div className={classes.dialogText}>Cadence : {selectedCadency}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={setNewConfigurationAndCadency} color="secondary" autoFocus>
            Confirmer
          </Button>
          <Button onClick={closeDialog} color="secondary">
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PlanificationsGraphEditCurrent;
