import {Query} from '@datorama/akita';
import {DisplayState} from './display.model';
import {displayStore, DisplayStore} from './display.store';

export class DisplayQuery extends Query<DisplayState> {
  constructor(protected store: DisplayStore) {
    super(store);
  }

  areBeaconsGeoDisplayed() {
    return this.select((state) => state.areBeaconsGeoDisplayed);
  }

  areBeaconsOtherAirportDisplayed() {
    return this.select((state) => state.areBeaconsOtherAirportDisplayed);
  }

  areBeaconsIafDisplayed() {
    return this.select((state) => state.areBeaconsIafDisplayed);
  }

  areBeaconsMfDisplayed() {
    return this.select((state) => state.areBeaconsMfDisplayed);
  }

  areSectorLfbbDisplayed() {
    return this.select((state) => state.areSectorLfbbDisplayed);
  }

  areSectorEgaDisplayed() {
    return this.select((state) => state.areSectorEgaDisplayed);
  }

  areSectorUirDisplayed() {
    return this.select((state) => state.areSectorUirDisplayed);
  }

  areSectorSectoNWDisplayed() {
    return this.select((state) => state.areSectorSectoNwDisplayed);
  }

  areSectorMUACKUACDisplayed() {
    return this.select((state) => state.areSectorMuacKuacDisplayed);
  }

  areSectorSectoSEDisplayed() {
    return this.select((state) => state.areSectorSectoSeDisplayed);
  }

  areLabelsDisplayed() {
    return this.select((state) => state.areLabelsDisplayed);
  }

  areBeaconsDisplayed() {
    return this.select([
      'areBeaconsGeoDisplayed',
      'areBeaconsIafDisplayed',
      'areBeaconsMfDisplayed',
      'areBeaconsOtherAirportDisplayed',
    ]);
  }

  areSectorsDisplayed() {
    return this.select([
      'areSectorLfbbDisplayed',
      'areSectorEgaDisplayed',
      'areSectorUirDisplayed',
    ]);
  }

  areMeteoTurbulencesDisplayed() {
    return this.select((state) => state.areMeteoTurbulencesDisplayed);
  }

  areMeteoConvectionsDisplayed() {
    return this.select((state) => state.areMeteoConvectionsDisplayed);
  }

  areMeteoLightningsDisplayed() {
    return this.select((state) => state.areMeteoLightningsDisplayed);
  }

  areMeteoRVRsDisplayed() {
    return this.select((state) => state.areMeteoRVRsDisplayed);
  }

  meteoTimeFilter() {
    return this.select((state) => state.meteoTimeFilter);
  }

  areDistanceMarksDisplayed() {
    return this.select((state) => state.areDistanceMarksDisplayed);
  }
}

export const displayQuery = new DisplayQuery(displayStore);
