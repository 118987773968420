import {QueryEntity} from '@datorama/akita';
import {FlowState, FlowStore, flowStore} from './flow.store';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

export class FlowQuery extends QueryEntity<FlowState> {
  constructor(protected store: FlowStore) {
    super(store);
  }

  selectFlows() {
    return this.selectLoading().pipe(switchMap((loading) => (loading ? of([]) : this.selectAll())));
  }

  selectFlowsForAirportAndConfig(airport: string, config: string) {
    return this.selectLoading().pipe(
      switchMap((loading) => {
        return loading
          ? of([])
          : this.selectAll({
              filterBy: (entity) =>
                entity.forAirport === airport && entity.forConfig.includes(config),
            });
      }),
    );
  }

  getFlows() {
    return this.getAll();
  }

  getFlowsForAirportAndConfig(airport: string, config: string) {
    return this.getAll({
      filterBy: (entity) => entity.forAirport === airport && entity.forConfig.includes(config),
    });
  }
}

export const flowQuery = new FlowQuery(flowStore);
