import React, {ComponentType, FC, useMemo, useEffect} from 'react';
import {IChartsCard} from './ChartsCard.type';
import {useStyles} from './ChartsCardStyle';

const withChartsCardData = (WrappedComponent) => {
  function WithChartsCardData({
    type,
    beaconSelected,
    minutesLeft,
    load,
    capacities,
    defaultCapacity,
    departureCapacities,
    defaultDepartureCapacity,
    departureCounts,
  }) {
    const classes = useStyles();

    const loadWithCapacities = useMemo(
      () => findCapacityAndDepartureCount(load),
      [
        load,
        capacities,
        defaultCapacity,
        departureCapacities,
        defaultDepartureCapacity,
        departureCounts,
      ],
    );

    // This function finds the matching capacity range and returns the most constraining one.
    function findCapacity(dataSet, item, defaultValue) {
      const beginCapacity = dataSet.find(
        (el) => el.start.getTime() <= item.from.getTime() && el.end.getTime() > item.from.getTime(),
      );
      const endCapacity = dataSet.find(
        (el) => el.start.getTime() <= item.to.getTime() && el.end.getTime() > item.to.getTime(),
      );

      if (beginCapacity && endCapacity)
        return Math.min(beginCapacity.capacity, endCapacity.capacity);
      if (beginCapacity) return beginCapacity.capacity;
      if (endCapacity) return endCapacity.capacity;
      return defaultValue;
    }

    // This function counts departures
    function countDepartures(departureCounts, load, loadItem, index) {
      if (index === 0)
        return departureCounts?.find(
          (departureCount) => departureCount.end.getTime() === loadItem.to.getTime(),
        );
      if (index === load.length - 1)
        return departureCounts?.find(
          (departureCount) => departureCount.start.getTime() === loadItem.from.getTime(),
        );
      return departureCounts?.find(
        (departureCount) =>
          departureCount.start.getTime() === loadItem.from.getTime() &&
          departureCount.end.getTime() === loadItem.to.getTime(),
      );
    }

    function findCapacityAndDepartureCount(loadData) {
      if (loadData) {
        return loadData.map((loadItem, index) => {
          const loadItemCapacity =
            capacities && findCapacity(capacities, loadItem, defaultCapacity);
          const loadItemDepartureCapacity =
            departureCapacities &&
            findCapacity(departureCapacities, loadItem, defaultDepartureCapacity);
          const departureCount =
            departureCounts && countDepartures(departureCounts, load, loadItem, index);
          return {
            ...loadItem,
            capacity: loadItemCapacity ?? defaultCapacity,
            departureCapacity: loadItemDepartureCapacity ?? defaultDepartureCapacity,
            departureCount: departureCount?.count ?? 0,
          };
        });
      }
      return null;
    }

    return (
      <WrappedComponent
        type={type}
        beaconSelected={beaconSelected}
        loadWithCapacities={loadWithCapacities}
        minutesLeft={minutesLeft}
        max={[16, 18]}
      />
    );
  }

  return WithChartsCardData;
};

export default withChartsCardData;
