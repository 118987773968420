import React, {useState, useEffect, useRef} from 'react';

import {Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import {dateService} from '../models/date/date.service.ts';

const useStyles = makeStyles((theme) => ({
  clock: {
    fontSize: '20px',
    fontWeight: '500',
  },
}));

function Clock(props) {
  const moisAnnee = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre',
  ];
  const joursSemaine = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

  const classes = useStyles();

  const [date, setDate] = useState(dateService.getDate());

  const dateSubscription = useRef(null);

  useEffect(() => {
    // Abonnement au modèle
    dateSubscription.current = dateService.selectDate().subscribe(setDate);

    return () => {
      // Désabonnement au modèle
      if (dateSubscription.current) {
        dateSubscription.current.unsubscribe();
      }
    };
  }, []);

  return (
    <Typography className={`${classes.clock} ${props.className}`}>
      {date ? joursSemaine[date.getUTCDay()] : ''} {date ? date.getUTCDate() : ''}{' '}
      {date ? moisAnnee[date.getUTCMonth()] : ''} {date ? date.getUTCFullYear() : ''} -{' '}
      {date ? (date.getUTCHours() < 10 ? `0${date.getUTCHours()}` : date.getUTCHours()) : ''}:
      {date ? (date.getUTCMinutes() < 10 ? `0${date.getUTCMinutes()}` : date.getUTCMinutes()) : ''}:
      {date ? (date.getUTCSeconds() < 10 ? `0${date.getUTCSeconds()}` : date.getUTCSeconds()) : ''}{' '}
      UTC
    </Typography>
  );
}

export default Clock;
