import React from 'react';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import {makeStyles} from '@material-ui/styles';

import FlightListCardItem from './flight-list-card-item.js';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#565656',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  card: {
    minHeight: '400',
    backgroundColor: 'RGB(247,247,247)',
    margin: theme.spacing(1),
  },
  paper: {
    backgroundColor: 'RGB(234,234,234)',
  },
  tableauHaut: {
    fontSize: '13px',
    textAlign: 'center',
    pointerEvents: 'none',
  },
}));

function FlightListCard({flights}) {
  const classes = useStyles();

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <Typography className={classes.cardTitle}>Liste de vols</Typography>
        <Grid container direction="column" justifyContent="center" alignItems="center" margin={5}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item xs={3}>
              <Box className={classes.paper} style={{maxHeight: '20px', minHeight: '20px'}}>
                <Typography className={classes.tableauHaut}>Callsign</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.paper} style={{maxHeight: '20px', minHeight: '20px'}}>
                <Typography className={classes.tableauHaut}>ADEP</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.paper} style={{maxHeight: '20px', minHeight: '20px'}}>
                <Typography className={classes.tableauHaut}>ATFCM</Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <Box className={classes.paper} style={{maxHeight: '20px', minHeight: '20px'}}>
                <Typography className={classes.tableauHaut}>Régul.</Typography>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box className={classes.paper} style={{maxHeight: '20px', minHeight: '20px'}}>
                <Typography className={classes.tableauHaut}>STA</Typography>
              </Box>
            </Grid>
          </Grid>
          {flights
            ? flights.map((flight) => (
                <FlightListCardItem
                  key={flight.callsign}
                  callsign={flight.callsign}
                  atfcmDelay={flight.atfcmDelay}
                  regulationStatus={flight.regulationStatus}
                  dep={flight.dep}
                  regulation={flight.mpr}
                  sta={flight.sta}
                />
              ))
            : ''}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default FlightListCard;
