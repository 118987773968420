import React, {useEffect, useState, useRef} from 'react';

import {makeStyles} from '@material-ui/core';

import {airportService} from '../../models/airport/airport.service.ts';
import {beaconService} from '../../models/beacon/beacon.service.ts';
import {displayService} from '../../models/display/display.service';

const useStyles = makeStyles((theme) => ({
  circle: {
    strokeWidth: 1,
    stroke: '#B2B2B2',
    fill: 'none',
    strokeDasharray: '3 3',
    vectorEffect: 'non-scaling-stroke',
  },
}));

const distances = [
  {
    airport: 'LFPO',
    beacon: 'CAD',
    center: [1.3886666666666667, 48.0605],
    nb: 10,
    startAngle: 180,
    spanAngle: 170,
  },
  {
    airport: 'LFPO',
    beacon: 'OKRIX',
    center: [3.5668333333333333, 47.96633333333333],
    nb: 10,
    startAngle: 55,
    spanAngle: 130,
  },
  {
    airport: 'LFPO',
    beacon: 'GIMER',
    center: [3.9741666666666666, 49.36516666666667],
    nb: 5,
    startAngle: -30,
    spanAngle: 119,
  },
  {
    airport: 'LFPG',
    beacon: 'LORNI',
    center: [3.45129, 49.4174],
    nb: 13,
    startAngle: -5,
    spanAngle: 95,
  },
  {
    airport: 'LFPG',
    beacon: 'OKIPA',
    center: [3.5635, 48.6107],
    nb: 18,
    startAngle: 85,
    spanAngle: 100,
  },
  {
    airport: 'LFPG',
    beacon: 'MOPAR',
    center: [1.75728, 49.2912],
    nb: 10,
    startAngle: 260,
    spanAngle: 80,
  },
  {
    airport: 'LFPG',
    beacon: 'BANOX',
    center: [1.5055, 48.5736],
    nb: 10,
    startAngle: 185,
    spanAngle: 90,
  },
];

function DistanceLayer({x, y, k}) {
  const classes = useStyles();

  // State
  const [airport, setAirport] = useState(null);
  const [distanceMarksDisplayed, setDistanceMarksDisplayed] = useState(false);
  const [airportDistances, setAirportDistances] = useState([]);

  // Refs des subscriptions
  const airportSubscription = useRef(null);
  const distanceMarksDisplayedSubscription = useRef(null);

  // Au mount du composant, on s'abonne à l'aéroport courant et au display model
  useEffect(() => {
    airportSubscription.current = airportService.selectIcaoAirport().subscribe(setAirport);
    distanceMarksDisplayedSubscription.current = displayService
      .areDistanceMarksDisplayed()
      .subscribe(setDistanceMarksDisplayed);

    // Au unmount du composant, on se désabonne des modèles
    return () => {
      if (airportSubscription.current) {
        airportSubscription.current.unsubscribe();
      }
      if (distanceMarksDisplayedSubscription.current) {
        distanceMarksDisplayedSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Lors du changement d'aéroport, on modifie les données pour afficher les distances concernant l'aéroport
  useEffect(() => {
    if (airport) {
      setAirportDistances(distances.filter((distance) => distance.airport === airport));
    } else {
      setAirportDistances([]);
    }
  }, [airport]);

  // Fonction permettant de générer les arcs de cercle
  const generatePaths = (distance) => {
    const paths = [];
    const projection = beaconService.getProjection();

    let distanceNM = 0;
    for (let i = 0; i < distance.nb; i++) {
      // On ajoute 20 nm à la distance
      distanceNM += 20 / 60;

      // On calcule le point de départ de l'arc
      const startPoint = projection([distance.center[0], distance.center[1] + distanceNM]);

      // On calcule le point d'arrivée de l'arc
      const angleInRadians = (-distance.spanAngle * Math.PI) / 180;
      const beaconPoint = projection(distance.center);
      const relativeCoordinates = [startPoint[0] - beaconPoint[0], startPoint[1] - beaconPoint[1]];
      const endPoint = [
        relativeCoordinates[0] * Math.cos(angleInRadians) +
          relativeCoordinates[1] * Math.sin(angleInRadians) +
          beaconPoint[0],
        -relativeCoordinates[0] * Math.sin(angleInRadians) +
          relativeCoordinates[1] * Math.cos(angleInRadians) +
          beaconPoint[1],
      ];
      paths.push(
        <path
          key={`${distance.beacon}-${distanceNM}`}
          transform={`rotate(${distance.startAngle} ${beaconPoint[0]} ${beaconPoint[1]})`}
          d={`M${startPoint[0]},${startPoint[1]} A ${startPoint[1] - beaconPoint[1]} ${
            startPoint[1] - beaconPoint[1]
          } 0 0 1 ${endPoint[0]} ${endPoint[1]}`}
          className={classes.circle}
        />,
      );
    }
    return paths;
  };

  return (
    <g transform={`translate(${x} ${y}) scale(${k})`}>
      {distanceMarksDisplayed ? airportDistances.map((distance) => generatePaths(distance)) : ''}
    </g>
  );
}

export default DistanceLayer;
