import React, {useState, useEffect} from 'react';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {makeStyles} from '@material-ui/core/styles';

// import IntervalsGraph from '../graphs/intervals-graph.js';
import IntervalsBarChart from '../../components/charts/intervalsBarChart/IntervalsBarChart';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    fontSize: '&6px',
    fontWeight: 'bold',
    color: '#565656',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  card: {
    minHeight: '400',
    backgroundColor: 'RGB(247,247,247)',
    margin: theme.spacing(1),
  },
  paper: {
    backgroundColor: 'RGB(234,234,234)',
  },
  graphTitle: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#565656',
    marginLeft: theme.spacing(1),
  },
}));

function RtgGraphsCard({
  beaconSelected,
  loadName1,
  load1,
  capacities1,
  defaultCapacity1,
  loadName2,
  load2,
  capacities2,
  defaultCapacity2,
  minutesLeft,
  militaryAreas1,
  militaryAreas2,
}) {
  const classes = useStyles();

  // State
  const [loadWithCapacities1, setLoadWithCapacities1] = useState(null);
  const [loadWithCapacities2, setLoadWithCapacities2] = useState(null);

  // Calcul du load avec capacités 1
  useEffect(() => {
    if (load1) {
      setLoadWithCapacities1(
        load1.map((loadItem, index, load) => {
          let loadItemCapacity = 999;
          if (capacities1) {
            // On trouve le ou les créneaux de capacités correspondants
            const beginCapacity = capacities1.find(
              (capa) =>
                capa.start.getTime() <= loadItem.from.getTime() &&
                capa.end.getTime() > loadItem.from.getTime(),
            );
            const endCapacity = capacities1.find(
              (capa) =>
                capa.start.getTime() <= loadItem.to.getTime() &&
                capa.end.getTime() > loadItem.to.getTime(),
            );
            if (beginCapacity && endCapacity) {
              // Si la capacité change pendant le créneau (beginCapacity !== endCapacity), on prend la plus contraignante
              loadItemCapacity = Math.min(beginCapacity.capacity, endCapacity.capacity);
            } else if (beginCapacity) {
              loadItemCapacity = beginCapacity.capacity;
            } else if (endCapacity) {
              loadItemCapacity = endCapacity.capacity;
            }
          } else if (defaultCapacity1) {
            loadItemCapacity = defaultCapacity1;
          }

          return {
            ...loadItem,
            capacity: loadItemCapacity,
          };
        }),
      );
    } else {
      setLoadWithCapacities1(null);
    }
  }, [load1, capacities1, defaultCapacity1]);

  // Calcul du load avec capacités 2
  useEffect(() => {
    if (load2) {
      setLoadWithCapacities2(
        load2.map((loadItem, index, load) => {
          let loadItemCapacity = 999;
          if (capacities2) {
            // On trouve le ou les créneaux de capacités correspondants
            const beginCapacity = capacities2.find(
              (capa) =>
                capa.start.getTime() <= loadItem.from.getTime() &&
                capa.end.getTime() > loadItem.from.getTime(),
            );
            const endCapacity = capacities2.find(
              (capa) =>
                capa.start.getTime() <= loadItem.to.getTime() &&
                capa.end.getTime() > loadItem.to.getTime(),
            );
            if (beginCapacity && endCapacity) {
              // Si la capacité change pendant le créneau (beginCapacity !== endCapacity), on prend la plus contraignante
              loadItemCapacity = Math.min(beginCapacity.capacity, endCapacity.capacity);
            } else if (beginCapacity) {
              loadItemCapacity = beginCapacity.capacity;
            } else if (endCapacity) {
              loadItemCapacity = endCapacity.capacity;
            }
          } else if (defaultCapacity2) {
            loadItemCapacity = defaultCapacity2;
          }

          return {
            ...loadItem,
            capacity: loadItemCapacity,
          };
        }),
      );
    } else {
      setLoadWithCapacities2(null);
    }
  }, [load2, capacities2, defaultCapacity2]);

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <Typography className={classes.cardTitle}>Graphiques</Typography>
        {/* <IntervalsGraph beaconSelected={beaconSelected}
                                loadWithCapacities={loadWithCapacities1}
                                minutesLeft={minutesLeft}
                                max={[16, 18]}
                                militaryAreas={militaryAreas1}
                /> */}
        <IntervalsBarChart
          beaconSelected={beaconSelected}
          loadWithCapacities={loadWithCapacities1}
          minutesLeft={minutesLeft}
          max={[16, 18]}
          militaryAreas={militaryAreas1}
        />
        <Typography className={classes.graphTitle}>{loadName1}</Typography>
        <br />
        {/* <IntervalsGraph beaconSelected={beaconSelected}
                                loadWithCapacities={loadWithCapacities2}
                                minutesLeft={minutesLeft}
                                max={[16, 18]}
                                militaryAreas={militaryAreas2}
                /> */}
        <IntervalsBarChart
          beaconSelected={beaconSelected}
          loadWithCapacities={loadWithCapacities2}
          minutesLeft={minutesLeft}
          max={[16, 18]}
          militaryAreas={militaryAreas2}
        />
        <Typography className={classes.graphTitle}>{loadName2}</Typography>
      </CardContent>
    </Card>
  );
}

export default RtgGraphsCard;
