import React from 'react';

import * as d3 from 'd3';
import {tip as d3Tip} from 'd3-v6-tip';

import './graphs-styles.css';

class DeparturesArrivalsGraph extends React.Component {
  // Dessin du graphe au démarrage
  componentDidMount() {
    this.chartRef = React.createRef();
    this.drawChart();
  }

  // Fonction permettant de supprimer le graphe
  removePreviousChart() {
    while (this.chartRef.current.firstChild) {
      this.chartRef.current.removeChild(this.chartRef.current.firstChild);
    }

    d3.selectAll('.d3-tip-dep-arr').remove();
  }

  /**
   * Méthode qui dessine le graphique, prend en argument le type de changement: si le changement est un changement d'heure, le graphique est redessiné directement,
   * si on change de balise, il y a une animation sur les barres
   * @param {string} changeType
   */
  drawChart(changeType) {
    if (this.chartRef.current) {
      d3.selection.prototype.conditionalTransition = function (cond1, duration, delay) {
        return cond1 ? d3.transition().duration(duration).delay(delay).ease(d3.easeBounce) : this;
      };

      const dataset = this.props.loadWithCapacities; // LoadArray
      const {max} = this.props; // Valeur maximale par défaut, fournie dans les props

      const datasetMax = dataset ? d3.max(dataset) : 0;
      const h = datasetMax > max[0] ? 170 + (datasetMax - max[0]) * 15 : 170;
      const barWidth = 30; // Largeur de barre
      const spacing = 1;
      const width0 = (this.props.minutesLeft * 3) / 2; // Largeur de la première barre
      const w = 320; // Width
      const margins = {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      };

      const mid = h / 2;

      const xScale = d3
        .scaleLinear()
        .domain([0, 9])
        .range([margins.left, w - margins.right]);
      const xAxis = d3
        .axisBottom()
        .scale(xScale)
        .tickFormat((d, i) =>
          dataset && dataset[i] && dataset[i].from && dataset[i].from.getUTCMinutes() === 0
            ? dataset[i].from.toLocaleTimeString('utc', {
                hour12: false,
                timeZone: 'UTC',
                timeStyle: 'short',
              })
            : '',
        ); // Axe des abscisses avec les valeurs tickLabels qui correspondent aux heures pleines

      const yScale = d3
        .scaleLinear()
        .domain([0, datasetMax > max[0] ? datasetMax + 2 : max[1]])
        .range([mid, 0]);

      const yScaleDep = d3
        .scaleLinear()
        .domain([0, datasetMax > max[0] ? datasetMax + 2 : max[1]])
        .range([mid, 2 * mid]);

      const yAxis = d3
        .axisLeft()
        .scale(yScale)
        .tickValues(
          dataset
            ? dataset
                .map((elem) => Math.floor(elem.capacity / 3))
                .filter((elem, index, array) => array.indexOf(elem) === index)
            : null,
        )
        .tickSizeOuter(0); // Axe des ordonnées avec les valeurs seuils

      const yAxisDep = d3
        .axisLeft()
        .scale(yScaleDep)
        .tickValues(
          dataset
            ? dataset
                .map((elem) => Math.floor(elem.departureCapacity / 3))
                .filter((elem, index, array) => array.indexOf(elem) === index)
            : null,
        )
        .tickSizeOuter(0);

      const tip = d3Tip()
        .attr('class', 'd3-tip-dep-arr')
        .offset([-10, 0])
        .html((event, d, i) => {
          // Description du tooltip
          const vols = d.load > 1 ? 'arrivées' : 'arrivée';
          const volsDep = d.departureCount > 1 ? 'départs' : 'départ';
          const nbVols = `<div style="justify-content: center; display: flex;">${d.load} ${vols}</div>`;
          const nbVolsDep = `<div style="justify-content: center; display: flex;">${d.departureCount} ${volsDep}</div>`;
          return `${nbVols}<hr>${nbVolsDep}`;
        });

      const svg = d3
        .select(this.chartRef.current)
        .append('svg')
        .attr('width', w)
        .attr('height', h)
        .attr('class', 'bar')
        .attr('marginLeft', 5);

      if (dataset) {
        // Ajout des barres arrivées
        svg
          .selectAll('barsArrivees')
          .data(dataset)
          .enter()
          .append('rect')
          .attr('fill', '#406279')
          .attr('class', 'sBar')
          .attr('x', (d, i) =>
            i === 0 ? margins.left : margins.left + width0 + spacing * i + barWidth * (i - 1),
          ) // Valeur x des barres
          .attr('width', (d, i) => {
            // Largeur des barres
            if (i === 0) {
              return width0;
            }
            if (i === dataset.length - 1) {
              return barWidth - width0;
            }
            return barWidth;
          })
          .on('mouseenter', tip.show)
          .on('mousemove', tip.show)
          .on('mouseout', tip.hide)
          .conditionalTransition(changeType !== 'timeChange', 1000, (d, i) => i * 100) // Si on est sur un changement de balise cliquée, on a une animation
          .attr('y', (value, i) => (value ? yScale(value.load) : 0))
          .attr('height', (value, i) => (value ? mid - yScale(value.load) : 0));

        // Ajout des barres départs
        svg
          .selectAll('barsDeparts')
          .data(dataset)
          .enter()
          .append('rect')
          .attr('fill', '#228BE6')
          .attr('class', 'sBar')
          .attr('x', (d, i) =>
            i === 0 ? margins.left : margins.left + width0 + spacing * i + barWidth * (i - 1),
          ) // Valeur x des barres
          .attr('width', (d, i) => {
            // Largeur des barres
            if (i === 0) {
              return width0;
            }
            if (i === dataset.length - 1) {
              return barWidth - width0;
            }
            return barWidth;
          })
          .on('mouseenter', tip.show)
          .on('mousemove', tip.show)
          .on('mouseout', tip.hide)
          .conditionalTransition(changeType !== 'timeChange', 1000, (d, i) => i * 100) // Si on est sur un changement de balise cliquée, on a une animation
          .attr('y', mid)
          .attr('height', (value) => (value ? yScaleDep(value.departureCount) - mid : 0));

        svg.call(tip);

        // Ajout des lignes affichant les valeurs de capacité (axe des arrivées)
        svg
          .selectAll('lines')
          .data(dataset)
          .enter()
          .append('line')
          .attr('class', 'capacityLine')
          .attr(
            'x1',
            (d, i) =>
              // X du départ de la ligne de capacité
              margins.left + width0 + spacing * i + barWidth * (i - 1),
          )
          .attr('y1', (d, i) =>
            // Y du départ de la ligne de capacité
            yScale(Math.floor(d.capacity / 3)),
          )
          .attr('x2', (d, i, array) =>
            // X de la fin de la ligne de capacité
            i < array.length - 1
              ? margins.left + width0 + spacing * i + barWidth * i
              : margins.left + spacing * i + barWidth * i,
          )
          .attr('y2', (d, i) =>
            // Y de la fin de la ligne de capacité
            yScale(Math.floor(d.capacity / 3)),
          );

        // Ajout des lignes affichant les valeurs de capacité (axe des départs)
        svg
          .selectAll('lines')
          .data(dataset)
          .enter()
          .append('line')
          .attr('class', 'capacityLine')
          .attr(
            'x1',
            (d, i) =>
              // X du départ de la ligne de capacité
              margins.left + width0 + spacing * i + barWidth * (i - 1),
          )
          .attr('y1', (d, i) =>
            // Y du départ de la ligne de capacité
            yScaleDep(Math.floor(d.departureCapacity / 3)),
          )
          .attr('x2', (d, i, array) =>
            // X de la fin de la ligne de capacité
            i < array.length - 1
              ? margins.left + width0 + spacing * i + barWidth * i
              : margins.left + spacing * i + barWidth * i,
          )
          .attr('y2', (d, i) =>
            // Y de la fin de la ligne de capacité
            yScaleDep(Math.floor(d.departureCapacity / 3)),
          );
      }

      // Ajout du rectangle tout à gauche du graphique
      svg
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', margins.left)
        .attr('height', h)
        .style('fill', 'grey');

      // Ajout de l'axe des x que l'on décale pour faire décaler en fonction des minutes
      svg
        .append('g')
        .call(xAxis)
        .attr(
          'transform',
          `translate(${this.props.minutesLeft !== 0 ? -(barWidth - width0 + spacing) : 0},${mid})`,
        )
        .attr('class', 'axisX');

      // Ajout d'une ligne sur l'axe des abscisses
      svg
        .append('line')
        .attr('x1', 0)
        .attr('x2', 300)
        .attr('y1', mid)
        .attr('y2', mid)
        .style('stroke', 'black')
        .style('strokeWidth', 1);

      // Ajout de l'axe des ordonnées
      svg
        .append('g')
        .call(yAxis)
        .attr('class', 'axisWhite')
        .attr('transform', `translate(${margins.right},0)`);

      // Ajout de l'axe des ordonnées DEP
      svg
        .append('g')
        .call(yAxisDep)
        .attr('class', 'axisWhite')
        .attr('transform', `translate(${margins.right},0)`);

      // Suppression du premier tick de l'axe x
      svg.selectAll('.tick').each(function (d) {
        if (d === 0) {
          this.remove();
        }
      });
    }
  }

  /**
   * Mise à jour du composant si les minutes restantes changent ou que l'on sélectionne une autre balise
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.beaconSelected !== this.props.beaconSelected) {
      this.removePreviousChart();
      this.drawChart('otherChange');
    } else {
      this.removePreviousChart(); // On enlève le graphique
      this.drawChart('timeChange'); // On dessine le graphique
    }
  }

  render() {
    const styles = {
      display: 'grid',
      justifyItems: 'center',
    };

    return <div ref={this.chartRef} style={styles} />;
  }
}

export default DeparturesArrivalsGraph;
