import React, {useRef, useState, useEffect} from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SvgIcon from '@material-ui/core/SvgIcon';

import {makeStyles} from '@material-ui/core/styles';

import {ReactComponent as PropellerSvg} from '../svg-icons/low_perf.svg';
import {ReactComponent as AirborneSvg} from '../svg-icons/airborne.svg';

import FlightPlanCard from './cards/flight-plan-card.js';
import AmanCard from './cards/aman-card.js';
import RadarCard from './cards/radar-card.js';
import ADSBCard from './cards/adsb-card.js';

import {flightService} from '../models/flight/flight.service.ts';
import ADSCCard from './cards/adsc-card';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawerContainer: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  flightDrawerHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 48,
  },
  flightDrawerTitle: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  airborneIcon: {
    marginLeft: theme.spacing(1),
    fontSize: 50,
  },
  name: {
    textAlign: 'center',
    fontSize: '26px',
    fontWeight: 'bold',
    display: 'inline-block',
  },
  type: {
    textAlign: 'center',
    fontSize: '15px',
    fontWeight: 'bold',
    display: 'inline-block',
    marginLeft: theme.spacing(2),
  },
  scrollableContent: {
    height: 'calc(100% - 48px)',
    overflow: 'auto',
  },
}));

function FlightDrawer() {
  const classes = useStyles();

  // Subscriptions
  const flightSubscription = useRef(null);

  // State
  const [flight, setFlight] = useState(null);

  // Event handlers
  const handleClick = () => {
    flightService.setSelected('');
  };

  useEffect(() => {
    // Au mount du composant, on s'abonne au vol sélectionné
    flightSubscription.current = flightService.selectSelectedFlight().subscribe(setFlight);

    // Au unmount du composant, on se désabonne des modèles
    return () => {
      if (flightSubscription.current) {
        flightSubscription.current.unsubscribe();
      }
    };
  }, []);

  return flight ? (
    <div className={classes.drawerContainer}>
      <div className={classes.flightDrawerHeader}>
        {flight.airborneStatus === 'AIRBORNE' ? (
          <SvgIcon
            component={AirborneSvg}
            viewBox="0 0 64 42.667"
            className={classes.airborneIcon}
          />
        ) : (
          ''
        )}
        <div className={classes.flightDrawerTitle}>
          <Typography className={classes.name} variant="h1">
            {flight.callsign}
          </Typography>
          <Typography className={classes.type} variant="h2">
            {flight.type}
          </Typography>
          {flight.lowPerf ? <SvgIcon component={PropellerSvg} /> : ''}
        </div>
        <IconButton onClick={handleClick}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <div className={classes.scrollableContent}>
        <FlightPlanCard flight={flight} />
        <AmanCard flight={flight} />
        {flight && flight.latRadar && flight.lonRadar ? (
          <RadarCard flight={flight} />
        ) : flight && flight.lonAdsb && flight.latAdsb ? (
          <ADSBCard flight={flight} />
        ) : (
          ''
        )}
        {flight && flight?.adscData ? <ADSCCard flight={flight} /> : ''}
      </div>
    </div>
  ) : (
    ''
  );
}

export default FlightDrawer;
