import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {ReactComponent as SvgProgress} from '../svg-icons/state_inprogress.svg';
import {ReactComponent as SvgOk} from '../svg-icons/state_ok.svg';
import {ReactComponent as SvgNok} from '../svg-icons/state_nok.svg';
import {ReactComponent as SvgCvf} from '../svg-icons/CVF.svg';
import {ReactComponent as SvgMoved} from '../svg-icons/moved.svg';
import {ReactComponent as SvgSwapped} from '../svg-icons/swapped.svg';
import {ReactComponent as SvgForced} from '../svg-icons/forced.svg';

const useStyles = makeStyles((theme) => ({
  sta: {
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontWeight: 'bold',
    fontSize: 14,
  },
  callsign: {
    dominantBaseline: 'central',
    fontSize: 14,
    fontWeight: 'bold',
  },
  dep: {
    dominantBaseline: 'central',
    fontSize: 12,
    fontWeight: 'bold',
  },
  speedConstraint: {
    dominantBaseline: 'central',
    fontSize: 14,
    fontWeight: 'bold',
  },
  speedConstraintDelete: {
    dominantBaseline: 'central',
    fontSize: 14,
    fontWeight: 'bold',
    textDecoration: 'solid line-through',
  },
  speedConstaintIcon: {
    height: 16,
    width: 16,
  },
  localRegulation: {
    width: '20px',
    height: '18px',
    fill: 'white',
    stroke: 'RGB(224, 78, 178)',
    strokeWidth: '1.5px',
  },
  externalRegulation: {
    width: '20px',
    height: '18px',
    fill: 'RGB(224, 78, 178)',
    stroke: 'white',
    strokeWidth: '1.5px',
  },
  localRegulationText: {
    fontSize: '13px',
    fontWeight: 'bold',
    fill: 'RGB(224, 78, 178)',
  },
  externalRegulationText: {
    fontSize: '13px',
    fontWeight: 'bold',
    fill: 'white',
  },
}));

function Flight({flight, time, delay, orientation, hovered, selected}) {
  // CSS
  const classes = useStyles();

  // Couleur d'affichage du vol (AIRBORNE -> white, TAXIING -> #9DA3B1, ON_GROUND -> black)
  const flightColor =
    flight.airborneStatus === 'AIRBORNE'
      ? 'white'
      : flight.airborneStatus === 'TAXIING'
      ? '#9DA3B1'
      : flight.airborneStatus === 'ON_GROUND'
      ? 'black'
      : '';

  return (
    <>
      <rect
        height="34"
        width="100%"
        x="-50%"
        y="-17"
        fill={selected ? '#1150A1BB' : hovered ? '#3277d1BB' : '#50607701'}
      />
      {orientation === 'e' ? (
        <>
          {
            // Speed constraint status
            flight.speedConstraintStatus === 'PROPOSED' ? (
              <svg viewBox="0 0 16 16" height="16" width="16" x="-116" y="-7">
                <SvgProgress />
              </svg>
            ) : flight.speedConstraintStatus === 'IMPLEMENTED' ? (
              <svg viewBox="0 0 24 24" height="16" width="16" x="-116" y="-8">
                <SvgOk />
              </svg>
            ) : flight.speedConstraintStatus === 'REFUSED' ? (
              <svg viewBox="0 0 14.414 14.414" height="12" width="12" x="-116" y="-6">
                <SvgNok />
              </svg>
            ) : (
              ''
            )
          }
          {
            // Speed constraint
            flight.speedConstraint && flight.speedConstraint !== 'NONE' ? (
              <text
                x="-123"
                y="0"
                className={
                  flight.speedConstraint === 'SLW'
                    ? classes.speedConstraint
                    : flight.speedConstraint === 'DELETE'
                    ? classes.speedConstraintDelete
                    : ''
                }
                textAnchor="end"
                fill={flightColor}>
                SLW
              </text>
            ) : (
              ''
            )
          }
          {
            // CVF
            flight.trajectory === 'CVF' ? (
              <svg viewBox="0 0 24 24" height="16" width="16" x="-90" y="-8" fill={flightColor}>
                <SvgCvf />
              </svg>
            ) : (
              ''
            )
          }
          <text x="-30" y="0" className={classes.dep} textAnchor="end" fill={flightColor}>
            {flight.dep}
          </text>
          {
            // Dernière action réalisée
            flight.moveStatus && flight.moveStatus !== 'NONE' ? (
              <>
                <rect x="-22" y="-12" height="24" width="20" fill="white" stroke="white" />
                <svg viewBox="0 0 24 24" height="16" width="16" x="-20" y="-8">
                  {flight.moveStatus === 'MOVED' ? (
                    <SvgMoved />
                  ) : flight.moveStatus === 'STA_FORCED' ||
                    flight.moveStatus === 'STA_MF_FORCED' ? (
                    <SvgForced />
                  ) : flight.moveStatus === 'SWAPPED' ? (
                    <SvgSwapped />
                  ) : (
                    ''
                  )}
                </svg>
              </>
            ) : (
              ''
            )
          }
          <path d="M2 12 L21 12 L28 0 L21 -12 L2 -12 Z" stroke="white" fill="white" />
          <text x="13" y="0" className={classes.sta}>
            {
              time.getUTCMinutes() < 10
                ? `0${time.getUTCMinutes()}`
                : time.getUTCMinutes() /* STA (minute seulement) */
            }
          </text>
          <text
            x="40"
            y="0"
            className={classes.callsign}
            textAnchor="start"
            fill={flightColor}
            textDecoration={
              flight && flight.isReady && flight.airborneStatus === 'ON_GROUND' ? 'underline' : ''
            }>
            {flight.callsign}
            {
              // Si vol Low Perf -> Affichage d'un "*"
              flight.lowPerf ? '*' : ''
            }
          </text>
          {
            // Délai iAman
            delay && delay !== 0 ? (
              <text
                x="125"
                y="0"
                fontSize="14"
                fontWeight="bold"
                dominantBaseline="central"
                textAnchor="middle"
                fill={
                  delay < 0
                    ? 'RGB(156, 228, 8)'
                    : delay < 2 && delay >= 0
                    ? 'black'
                    : delay < 6 && delay >= 2
                    ? 'RGB(247,147,30)'
                    : delay >= 6
                    ? 'RGB(203,83,87)'
                    : ''
                }>
                {delay}
              </text>
            ) : (
              ''
            )
          }
          {
            // Délai ATFCM
            flight.atfcmDelay > 0 ? (
              <>
                <rect
                  className={
                    flight.regulationStatus === 'MPR_LOCAL_REGULATION'
                      ? classes.localRegulation
                      : flight.regulationStatus === 'MPR_EXTERNAL_REGULATION'
                      ? classes.externalRegulation
                      : ''
                  }
                  x="135"
                  y="-9"
                />
                <text
                  className={
                    flight.regulationStatus === 'MPR_LOCAL_REGULATION'
                      ? classes.localRegulationText
                      : flight.regulationStatus === 'MPR_EXTERNAL_REGULATION'
                      ? classes.externalRegulationText
                      : ''
                  }
                  dominantBaseline="central"
                  textAnchor="middle"
                  x="145"
                  y="0">
                  {flight.atfcmDelay}
                </text>
              </>
            ) : (
              ''
            )
          }
          {
            // MF
            flight.mfShortName ? (
              <text
                x="168"
                y="0"
                textAnchor="middle"
                dominantBaseline="central"
                fontWeight="bold"
                fontSize="14"
                fill="white">
                {flight.mfShortName}
              </text>
            ) : (
              ''
            )
          }
          {
            // Target time
            flight && flight.targetTime ? (
              <>
                <rect x="182" y="-10" height="20" width="20" stroke="white" fill="white" />
                <text
                  x="192"
                  y="0"
                  fontSize="14"
                  fontWeight="bold"
                  dominantBaseline="central"
                  textAnchor="middle"
                  fill="black">
                  {flight.targetTime ? flight.targetTime.getUTCMinutes() : ''}
                </text>
                <svg viewBox="0 0 24 24" height="12" width="12" x="199" y="5">
                  <SvgOk />
                </svg>
              </>
            ) : (
              ''
            )
          }
        </>
      ) : (
        <>
          {
            // Speed constraint status
            flight.speedConstraintStatus === 'PROPOSED' ? (
              <svg viewBox="0 0 16 16" height="16" width="16" x="135" y="-7">
                <SvgProgress />
              </svg>
            ) : flight.speedConstraintStatus === 'IMPLEMENTED' ? (
              <svg viewBox="0 0 24 24" height="16" width="16" x="135" y="-8">
                <SvgOk />
              </svg>
            ) : flight.speedConstraintStatus === 'REFUSED' ? (
              <svg viewBox="0 0 14.414 14.414" height="12" width="12" x="135" y="-6">
                <SvgNok />
              </svg>
            ) : (
              ''
            )
          }
          {
            // Speed constraint
            flight.speedConstraint && flight.speedConstraint !== 'NONE' ? (
              <text
                x="100"
                y="0"
                className={
                  flight.speedConstraint === 'SLW'
                    ? classes.speedConstraint
                    : flight.speedConstraint === 'DELETE'
                    ? classes.speedConstraintDelete
                    : ''
                }
                textAnchor="start"
                fill={flightColor}>
                SLW
              </text>
            ) : (
              ''
            )
          }
          {
            // CVF
            flight.trajectory === 'CVF' ? (
              <svg viewBox="0 0 24 24" height="16" width="16" x="70" y="-8" fill={flightColor}>
                <SvgCvf />
              </svg>
            ) : (
              ''
            )
          }
          <text x="30" y="0" className={classes.dep} textAnchor="start" fill={flightColor}>
            {flight.dep}
          </text>
          {
            // Dernière action réalisée
            flight.moveStatus && flight.moveStatus !== 'NONE' ? (
              <>
                <rect x="2" y="-12" height="24" width="20" fill="white" stroke="white" />
                <svg viewBox="0 0 24 24" height="16" width="16" x="4" y="-8">
                  {flight.moveStatus === 'MOVED' ? (
                    <SvgMoved />
                  ) : flight.moveStatus === 'STA_FORCED' ||
                    flight.moveStatus === 'STA_MF_FORCED' ? (
                    <SvgForced />
                  ) : flight.moveStatus === 'SWAPPED' ? (
                    <SvgSwapped />
                  ) : (
                    ''
                  )}
                </svg>
              </>
            ) : (
              ''
            )
          }
          <path d="M-2 12 L-21 12 L-28 0 L-21 -12 L-2 -12 Z" stroke="white" fill="white" />
          <text x="-13" y="0" className={classes.sta}>
            {time.getUTCMinutes() /* STA (minute seulement */}
          </text>
          <text
            x="-40"
            y="0"
            className={classes.callsign}
            textAnchor="end"
            fill={flightColor}
            textDecoration={
              flight && flight.isReady && flight.airborneStatus === 'ON_GROUND' ? 'underline' : ''
            }>
            {flight.callsign}
            {
              // Si vol Low Perf -> Affichage d'un "*"
              flight.lowPerf ? '*' : ''
            }
          </text>
          {
            // Délai iAman
            delay && delay !== 0 ? (
              <text
                x="-125"
                y="0"
                fontSize="14"
                fontWeight="bold"
                dominantBaseline="central"
                textAnchor="middle"
                fill={
                  delay < 0
                    ? 'RGB(156, 228, 8)'
                    : delay < 2 && delay >= 0
                    ? 'black'
                    : delay < 6 && delay >= 2
                    ? 'RGB(247,147,30)'
                    : delay >= 6
                    ? 'RGB(203,83,87)'
                    : ''
                }>
                {delay}
              </text>
            ) : (
              ''
            )
          }
          {
            // Délai ATFCM
            flight.atfcmDelay > 0 ? (
              <>
                <rect
                  className={
                    flight.regulationStatus === 'MPR_LOCAL_REGULATION'
                      ? classes.localRegulation
                      : flight.regulationStatus === 'MPR_EXTERNAL_REGULATION'
                      ? classes.externalRegulation
                      : ''
                  }
                  x="-155"
                  y="-9"
                />
                <text
                  className={
                    flight.regulationStatus === 'MPR_LOCAL_REGULATION'
                      ? classes.localRegulationText
                      : flight.regulationStatus === 'MPR_EXTERNAL_REGULATION'
                      ? classes.externalRegulationText
                      : ''
                  }
                  dominantBaseline="central"
                  textAnchor="middle"
                  x="-145"
                  y="0">
                  {flight.atfcmDelay}
                </text>
              </>
            ) : (
              ''
            )
          }
          {
            // MF
            flight.mfShortName ? (
              <text
                x="-168"
                y="0"
                textAnchor="middle"
                dominantBaseline="central"
                fontWeight="bold"
                fontSize="14"
                fill="white">
                {flight.mfShortName}
              </text>
            ) : (
              ''
            )
          }
          {
            // Target time
            flight && flight.targetTime ? (
              <>
                <rect x="-202" y="-10" height="20" width="20" stroke="white" fill="white" />
                <text
                  x="-192"
                  y="0"
                  fontSize="14"
                  fontWeight="bold"
                  dominantBaseline="central"
                  textAnchor="middle"
                  fill="black">
                  {flight.targetTime ? flight.targetTime.getUTCMinutes() : ''}
                </text>
                <svg viewBox="0 0 24 24" height="12" width="12" x="-185" y="5">
                  <SvgOk />
                </svg>
              </>
            ) : (
              ''
            )
          }
        </>
      )}
    </>
  );
}

export default Flight;
