import React, {useEffect, useState, useMemo} from 'react';

import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {makeStyles} from '@material-ui/core/styles';

// import IntervalsGraph from '../graphs/intervals-graph.js';
import RepartitionGraph from '../graphs/repartition-graph.js';
import DeparturesArrivalsGraph from '../graphs/departures-arrivals-graph.js';
import IntervalsBarChart from '../../components/charts/intervalsBarChart/IntervalsBarChart';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#565656',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  card: {
    minHeight: '400',
    backgroundColor: 'RGB(247,247,247)',
    margin: theme.spacing(1),
  },
  paper: {
    backgroundColor: 'RGB(234,234,234)',
  },
}));

function GraphsCard({
  type,
  beaconSelected,
  minutesLeft,
  load,
  capacities,
  defaultCapacity,
  departureCapacities,
  defaultDepartureCapacity,
  departureCounts,
}) {
  const classes = useStyles();

  const loadWithCapacities = useMemo(
    () => findCapacityAndDepartureCount(load),
    [
      load,
      capacities,
      defaultCapacity,
      departureCapacities,
      defaultDepartureCapacity,
      departureCounts,
    ],
  );

  // This function finds the matching capacity range and returns the most constraining one.
  function findCapacity(dataSet, item, defaultValue) {
    const beginCapacity = dataSet.find(
      (el) => el.start.getTime() <= item.from.getTime() && el.end.getTime() > item.from.getTime(),
    );
    const endCapacity = dataSet.find(
      (el) => el.start.getTime() <= item.to.getTime() && el.end.getTime() > item.to.getTime(),
    );
    if (beginCapacity && endCapacity) return Math.min(beginCapacity.capacity, endCapacity.capacity);
    if (beginCapacity) return beginCapacity.capacity;
    if (endCapacity) return endCapacity.capacity;
    return defaultValue;
  }

  // This function counts departures
  function countDepartures(departureCounts, load, loadItem, index) {
    if (index === 0)
      return departureCounts?.find(
        (departureCount) => departureCount.end.getTime() === loadItem.to.getTime(),
      );
    if (index === load.length - 1)
      return departureCounts?.find(
        (departureCount) => departureCount.start.getTime() === loadItem.from.getTime(),
      );
    return departureCounts?.find(
      (departureCount) =>
        departureCount.start.getTime() === loadItem.from.getTime() &&
        departureCount.end.getTime() === loadItem.to.getTime(),
    );
  }

  function findCapacityAndDepartureCount(loadData) {
    if (loadData) {
      return loadData.map((loadItem, index) => {
        const loadItemCapacity = capacities && findCapacity(capacities, loadItem, defaultCapacity);
        const loadItemDepartureCapacity =
          departureCapacities &&
          findCapacity(departureCapacities, loadItem, defaultDepartureCapacity);
        const departureCount =
          departureCounts && countDepartures(departureCounts, load, loadItem, index);
        return {
          ...loadItem,
          capacity: loadItemCapacity ?? defaultCapacity,
          departureCapacity: loadItemDepartureCapacity ?? defaultDepartureCapacity,
          departureCount: departureCount?.count ?? 0,
        };
      });
    }
    return null;
  }

  return (
    <Card className={classes.card} variant="outlined">
      {loadWithCapacities && (
        <CardContent>
          <Typography className={classes.cardTitle}>Graphiques</Typography>
          {/* <IntervalsGraph
            beaconSelected={beaconSelected}
            loadWithCapacities={loadWithCapacities}
            minutesLeft={minutesLeft}
            max={[16, 18]}
          /> */}
          <IntervalsBarChart
            beaconSelected={beaconSelected}
            loadWithCapacities={loadWithCapacities}
            minutesLeft={minutesLeft}
            max={[16, 18]}
          />
          {type === 'airport' && (
            <>
              <br />
              <RepartitionGraph
                beaconSelected={beaconSelected}
                loadWithCapacities={loadWithCapacities}
                minutesLeft={minutesLeft}
                max={[16, 18]}
              />
            </>
          )}
          {type === 'airport' && beaconSelected === 'LFPO' && (
            <>
              <br />
              <DeparturesArrivalsGraph
                beaconSelected={beaconSelected}
                loadWithCapacities={loadWithCapacities}
                minutesLeft={minutesLeft}
                max={[16, 18]}
              />
            </>
          )}
        </CardContent>
      )}
    </Card>
  );
}

export default GraphsCard;
