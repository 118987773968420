import React, {useEffect, useState} from 'react';

import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';

import Brightness2Icon from '@material-ui/icons/Brightness2';

import {ReactComponent as SvgFermeture} from '../svg-icons/fermeture_blanc.svg';
import {ReactComponent as SvgCvf} from '../svg-icons/CVF2.svg';
import {ReactComponent as SvgEndCvf} from '../svg-icons/No_CVF2.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    backgroundColor: '#506077',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  nameArea: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '30px',
    width: '100%',
    color: 'white',
  },
  wheelArea: {
    height: '95px',
    width: '100%',
  },
  timelineArea: {
    height: 'calc(100% - 125px)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  selectedTime: {
    height: '30px',
    width: '65px',
    border: '1px solid white',
    borderRadius: '15px',
    position: 'fixed',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'center',
    backgroundColor: 'black',
    cursor: 'pointer',
  },
  selectedCurrentTime: {
    backgroundColor: '#506077',
  },
  selectedHour: {
    lineHeight: '30px',
    verticalAlign: 'middle',
    color: 'white',
    fontSize: '14px',
    marginRight: '3px',
  },
  selectedMinute: {
    lineHeight: '30px',
    verticalAlign: 'middle',
    color: 'white',
    fontSize: '20px',
  },
  timeline: {
    marginTop: '15px',
    borderTop: '1px solid white',
    height: 'calc(100% - 15px)',
    width: '100%',
  },
  wheel: {
    height: '100%',
    width: '100%',
  },
  runwayLeft: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'absolute',
    marginTop: '87px',
    marginLeft: '6px',
    left: 0,
    fontSize: 16,
    color: 'white',
  },
  runwayRight: {
    display: 'inline-flex',
    alignItems: 'center',
    position: 'absolute',
    marginTop: '87px',
    marginLeft: 'auto',
    marginRight: '6px',
    right: 0,
    fontSize: 16,
    color: 'white',
  },
  animated: {
    transition: 'transform .2s ease-in-out',
  },
}));

// Constante : hauteur d'une minute sur la timeline
const minuteHeight = 40;
const timelineWidth = 420;
const wheelAreaHeight = 95;

function Timeline({
  name,
  currentTime,
  selectedTime,
  shift,
  setSelectedTime,
  horizon,
  currentRunway,
  currentCadency,
  load,
  FlightComponent,
  flightData,
  hoveredFlightCallsign,
  selectedFlightCallsign,
  flightEnter,
  flightLeave,
  flightClick,
  timelineClick,
  planifications,
}) {
  // CSS
  const classes = useStyles();

  // State
  const [selectedAngle, setSelectedAngle] = useState(0);
  const [shiftedCurrentTime, setShiftedCurrentTime] = useState(currentTime);
  const [shiftedSelectedTime, setShiftedSelectedTime] = useState(selectedTime);
  const [shiftedHorizon, setShiftedHorizon] = useState(horizon);

  // Au changement de currentTime ou de shift, on met à jour le shiftedCurrentTime
  useEffect(() => {
    if (currentTime && shift !== undefined && shift !== null) {
      setShiftedCurrentTime(new Date(currentTime.getTime() - shift * 60000));
    }
  }, [currentTime, shift]);

  // Au changement de selectedTime ou de shift, on met à jour le shiftedSelectedTime
  useEffect(() => {
    if (selectedTime && shift !== undefined && shift !== null) {
      setShiftedSelectedTime(new Date(selectedTime.getTime() - shift * 60000));
    }
  }, [selectedTime, shift]);

  // Au changement d'horizon ou de shift, on met à jour le shiftedHorizon
  useEffect(() => {
    setShiftedHorizon(horizon - shift);
  }, [horizon, shift]);

  // Angle du curseur de la roue crantée mémorisé
  useEffect(() => {
    let angle =
      (Math.floor((shiftedSelectedTime.getTime() - currentTime.getTime()) / 60000) * 360) /
      (3 * 60);
    angle = angle < 0 ? 0 : angle < 350 ? angle : 350;

    setSelectedAngle(angle);
  }, [shiftedSelectedTime, shiftedCurrentTime, currentTime]);

  // Si l'heure séléctionnée n'est plus dans la fenetre heure courante -> heure courante + horizon
  // on la remet à l'heure courante
  useEffect(() => {
    const limitTime = new Date(currentTime.getTime() + horizon * 60000);
    if (
      selectedTime.getTime() < currentTime.getTime() ||
      selectedTime.getTime() > limitTime.getTime()
    ) {
      setSelectedTime(currentTime);
    }
  }, [currentTime, selectedTime, horizon, setSelectedTime]);

  const increaseTime = (nbMinutes) => {
    // Heure limite selectionnable
    const limitTime = new Date(currentTime.getTime() + horizon * 60000);

    // On incrémente l'heure si la limite le permet
    const newTime = new Date(selectedTime.getTime() + nbMinutes * 60000);

    setSelectedTime(newTime.getTime() > limitTime.getTime() ? limitTime : newTime);
  };

  const decreaseTime = (nbMinutes) => {
    // On décrémente l'heure si la limite de l'heure courante le permet
    const newTime = new Date(selectedTime.getTime() - nbMinutes * 60000);

    setSelectedTime(newTime.getTime() < currentTime.getTime() ? currentTime : newTime);
  };

  // Utils for scroll
  const throttle = (callback, limit) => {
    let waiting = false;
    return function () {
      if (!waiting) {
        waiting = true;
        callback.apply(this, arguments);
        setTimeout(() => {
          waiting = false;
        }, limit);
      }
    };
  };

  // Event listener du scroll
  const wheel = (event) => {
    // event.deltaY negatif -> on remonte
    // event.deltaY positif -> on descend
    if (event.deltaY > 0) {
      increaseTime(Math.trunc(event.deltaY / 50));
    } else {
      decreaseTime(Math.abs(Math.trunc(event.deltaY / 50)));
    }
  };

  const selectCurrentTime = () => {
    const unshiftedCurrentTime = new Date(currentTime.getTime() + shift * 60000);
    setSelectedTime(unshiftedCurrentTime);
  };

  const createTicks = () => {
    const ticks = [];

    // Le premier tick est là pour éviter un clignotement lors du changement d'heure courante
    if (shift === 0) {
      ticks.push(
        <g key={`tick--1`} transform={`translate(0 ${-minuteHeight})`} className={classes.animated}>
          <line
            stroke="white"
            x1={-(minuteHeight / 2)}
            y1={-(minuteHeight / 2)}
            x2={-(minuteHeight / 2)}
            y2={minuteHeight / 2}
          />
          <line
            stroke="white"
            x1={-(minuteHeight / 2)}
            y1="0"
            x2={-(minuteHeight / 2) + 10}
            y2="0"
          />
          <line
            stroke="white"
            x1={minuteHeight / 2}
            y1={-(minuteHeight / 2)}
            x2={minuteHeight / 2}
            y2={minuteHeight / 2}
          />
          <line stroke="white" x1={minuteHeight / 2 - 10} y1="0" x2={minuteHeight / 2} y2="0" />
        </g>,
      );
    } else {
      ticks.push(
        <g key={`tick--1`} transform={`translate(0 ${-minuteHeight})`} className={classes.animated}>
          <rect
            fill="#6b7287"
            x={-(timelineWidth / 2)}
            y={-minuteHeight / 2 - 1}
            height={minuteHeight + 2}
            width={timelineWidth}
            stroke="0"
          />
          <line
            stroke="#ffffff30"
            x1={-(minuteHeight / 2)}
            y1={-(minuteHeight / 2)}
            x2={-(minuteHeight / 2)}
            y2={minuteHeight / 2}
          />
          <line
            stroke="#ffffff30"
            x1={-(minuteHeight / 2)}
            y1="0"
            x2={-(minuteHeight / 2) + 10}
            y2="0"
          />
          <line
            stroke="#ffffff30"
            x1={minuteHeight / 2}
            y1={-(minuteHeight / 2)}
            x2={minuteHeight / 2}
            y2={minuteHeight / 2}
          />
          <line stroke="#ffffff30" x1={minuteHeight / 2 - 10} y1="0" x2={minuteHeight / 2} y2="0" />
        </g>,
      );
    }

    let index = 0;
    let cursor = new Date(shiftedCurrentTime.getTime());
    const endTime = new Date(cursor.getTime() + (shiftedHorizon + 60) * 60000); // On ajoute 60 minutes pour que la timeline soit affichée meme lorsque on arrive à l'horizon
    while (cursor.getTime() <= endTime.getTime()) {
      const currentHourText =
        cursor.getUTCHours() < 10 ? `0${cursor.getUTCHours()}` : cursor.getUTCHours();
      const currentMinuteText =
        cursor.getUTCMinutes() < 10 ? `0${cursor.getUTCMinutes()}` : cursor.getUTCMinutes();

      if (index > shift || shift === 0) {
        ticks.push(
          <g
            key={`tick-${cursor.getUTCHours()}-${cursor.getUTCMinutes()}`}
            transform={`translate(0 ${index * minuteHeight})`}
            className={classes.animated}>
            <line
              stroke="white"
              x1={-(minuteHeight / 2)}
              y1={-(minuteHeight / 2)}
              x2={-(minuteHeight / 2)}
              y2={minuteHeight / 2}
            />
            <line
              stroke="white"
              x1={-(minuteHeight / 2)}
              y1="0"
              x2={-(minuteHeight / 2) + 10}
              y2="0"
            />
            <line
              stroke="white"
              x1={minuteHeight / 2}
              y1={-(minuteHeight / 2)}
              x2={minuteHeight / 2}
              y2={minuteHeight / 2}
            />
            <line stroke="white" x1={minuteHeight / 2 - 10} y1="0" x2={minuteHeight / 2} y2="0" />
            {cursor.getUTCMinutes() === 0 ? (
              <g>
                <text
                  x={-(timelineWidth / 2) + 3}
                  y="0"
                  fill="white"
                  dominantBaseline="central"
                  fontSize="12">
                  {currentHourText}:{currentMinuteText}
                </text>
                <line
                  x1={-(timelineWidth / 2) + 36}
                  y1="0"
                  x2={-(minuteHeight / 2)}
                  y2="0"
                  stroke="white"
                  strokeDasharray="4, 5"
                />
                <line
                  x1={minuteHeight / 2}
                  y1="0"
                  x2={timelineWidth / 2 - 36}
                  y2="0"
                  stroke="white"
                  strokeDasharray="4, 5"
                />
                <text
                  x={timelineWidth / 2 - 3}
                  y="0"
                  fill="white"
                  dominantBaseline="central"
                  textAnchor="end"
                  fontSize="12">
                  {currentHourText}:{currentMinuteText}
                </text>
              </g>
            ) : (
              ''
            )}
            {cursor.getUTCMinutes() % 5 === 0 ? (
              <text
                x="0"
                y="0"
                fill="white"
                dominantBaseline="central"
                textAnchor="middle"
                fontSize="12">
                {currentMinuteText}
              </text>
            ) : (
              ''
            )}
          </g>,
        );
      } else if (index === shift) {
        ticks.push(
          <g
            key={`tick-${cursor.getUTCHours()}-${cursor.getUTCMinutes()}`}
            transform={`translate(0 ${index * minuteHeight})`}
            className={classes.animated}>
            <rect
              fill="#6b7287"
              x={-(timelineWidth / 2)}
              y={-minuteHeight / 2 - 1}
              height={minuteHeight / 2 + 1}
              width={timelineWidth}
              stroke="0"
            />
            <line
              stroke="#ffffff30"
              x1={-(minuteHeight / 2)}
              y1={-(minuteHeight / 2)}
              x2={-(minuteHeight / 2)}
              y2="0"
            />
            <line
              stroke="white"
              x1={-(minuteHeight / 2)}
              y1="0"
              x2={-(minuteHeight / 2)}
              y2={minuteHeight / 2}
            />
            <line
              stroke="#ffffff30"
              x1={minuteHeight / 2}
              y1={-(minuteHeight / 2)}
              x2={minuteHeight / 2}
              y2="0"
            />
            <line
              stroke="white"
              x1={minuteHeight / 2}
              y1="0"
              x2={minuteHeight / 2}
              y2={minuteHeight / 2}
            />
            <g>
              <text
                x={-(timelineWidth / 2) + 3}
                y="0"
                fill="white"
                dominantBaseline="central"
                fontSize="12">
                {currentHourText}:{currentMinuteText}
              </text>
              <line
                x1={-(timelineWidth / 2) + 36}
                y1="0"
                x2={timelineWidth / 2 - 36}
                y2="0"
                stroke="white"
              />
              <text
                x={timelineWidth / 2 - 3}
                y="0"
                fill="white"
                dominantBaseline="central"
                textAnchor="end"
                fontSize="12">
                {currentHourText}:{currentMinuteText}
              </text>
            </g>
          </g>,
        );
      } else {
        ticks.push(
          <g
            key={`tick-${cursor.getUTCHours()}-${cursor.getUTCMinutes()}`}
            transform={`translate(0 ${index * minuteHeight})`}
            className={classes.animated}>
            <rect
              fill="#6b7287"
              x={-(timelineWidth / 2)}
              y={-minuteHeight / 2 - 1}
              height={minuteHeight + 2}
              width={timelineWidth}
              stroke="0"
            />
            <line
              stroke="#ffffff30"
              x1={-(minuteHeight / 2)}
              y1={-(minuteHeight / 2)}
              x2={-(minuteHeight / 2)}
              y2={minuteHeight / 2}
            />
            <line
              stroke="#ffffff30"
              x1={-(minuteHeight / 2)}
              y1="0"
              x2={-(minuteHeight / 2) + 10}
              y2="0"
            />
            <line
              stroke="#ffffff30"
              x1={minuteHeight / 2}
              y1={-(minuteHeight / 2)}
              x2={minuteHeight / 2}
              y2={minuteHeight / 2}
            />
            <line
              stroke="#ffffff30"
              x1={minuteHeight / 2 - 10}
              y1="0"
              x2={minuteHeight / 2}
              y2="0"
            />
            {cursor % 5 === 0 ? (
              <text
                x="0"
                y="0"
                fill="#ffffff30"
                dominantBaseline="central"
                textAnchor="middle"
                fontSize="12">
                {currentMinuteText}
              </text>
            ) : (
              ''
            )}
          </g>,
        );
      }

      cursor = new Date(cursor.getTime() + 60000);
      index++;
    }

    return ticks;
  };

  const createWheelTicks = () => {
    const ticks = [<line key="wheelTick--1" x1="0" y1="-18" x2="0" y2="-34" stroke="#a9a9a9" />];

    let cursor = currentTime;
    let limitTime = new Date(currentTime.getTime() + 3 * 3600000);
    let angle = 0;

    while (cursor.getTime() < limitTime.getTime() && angle <= 340) {
      if (cursor.getUTCMinutes() === 0) {
        // Si on est sur une heure pile, on ajoute un grand trait
        ticks.push(
          <line
            key={`wheelTick-${cursor.getUTCHours()}-${cursor.getUTCMinutes()}`}
            x1="0"
            y1="-18"
            x2="0"
            y2="-34"
            transform={`rotate(${angle})`}
            stroke="#a9a9a9"
          />,
        );
      } else if (cursor.getUTCMinutes() % 20 === 0) {
        // Si on tombe sur :20 ou :40, on ajoute un petit trait
        ticks.push(
          <line
            key={`wheelTick-${cursor.getUTCHours()}-${cursor.getUTCMinutes()}`}
            x1="0"
            y1="-26"
            x2="0"
            y2="-30"
            transform={`rotate(${angle})`}
            stroke="#a9a9a9"
          />,
        );
      }

      // Une minute correspond à 2 degrés
      cursor = new Date(cursor.getTime() + 60000);
      angle += 2;
    }

    return ticks;
  };

  const createLoadAlerts = () => {
    const alerts = [];

    if (load) {
      // On trie les load par date
      const sortedLoad = load.sort(
        (loadItem1, loadItem2) => loadItem1.from.getTime() - loadItem2.from.getTime(),
      );

      // On se positionne sur le premier loadItem
      let currentLoad = sortedLoad[0] || null;

      // Temps restant dans la première période
      const minutesLeftInPeriod = currentLoad
        ? Math.floor((currentLoad.to.getTime() - currentLoad.from.getTime()) / 60000)
        : null;

      // Load alert du premier créneau
      if (currentLoad && currentLoad.alertLevel > 0) {
        alerts.push(
          <path
            key={`alert-${currentLoad.from.getUTCHours()}-${currentLoad.from.getUTCMinutes()}`}
            d={`
                            M${18 * Math.sin(2 * minutesLeftInPeriod * (Math.PI / 180))} ${
              -18 * Math.cos(2 * minutesLeftInPeriod * (Math.PI / 180))
            }
                            A18 18 0 0 0 0 -18
                            L0 -28
                            A28 28 0 0 1 ${
                              28 * Math.sin(2 * minutesLeftInPeriod * (Math.PI / 180))
                            } ${-28 * Math.cos(2 * minutesLeftInPeriod * (Math.PI / 180))}
                            Z
                          `}
            stroke="none"
            fill={
              currentLoad.alertLevel === 1
                ? '#D9A62F'
                : currentLoad.alertLevel === 2
                ? '#CA5256'
                : 'white'
            }
          />,
        );
      }

      // Créneaux suivants
      let rotation = minutesLeftInPeriod * 2;
      let index = 1;
      do {
        currentLoad = sortedLoad[index];
        if (currentLoad && currentLoad.alertLevel > 0) {
          if (rotation + 40 < 360) {
            alerts.push(
              <path
                key={`alert-${currentLoad.from.getUTCHours()}-${currentLoad.from.getUTCMinutes()}`}
                d="M11.5702 -13.7888 A18 18 0 0 0 0 -18 L0 -28 A28 28 0 0 1 17.9981 -21.4492 Z"
                stroke="none"
                fill={
                  currentLoad.alertLevel === 1
                    ? '#D9A62F'
                    : currentLoad.alertLevel === 2
                    ? '#CA5256'
                    : 'white'
                }
                transform={`rotate(${rotation})`}
              />,
            );
          } else {
            // Dernier créneau (= 20 minutes - temps restant dans la première période)
            alerts.push(
              <path
                key={`alert-${currentLoad.from.getUTCHours()}-${currentLoad.from.getUTCMinutes()}`}
                d={`
                                    M${
                                      18 *
                                      Math.sin(2 * (20 - minutesLeftInPeriod) * (Math.PI / 180))
                                    } ${
                  -18 * Math.cos(2 * (20 - minutesLeftInPeriod) * (Math.PI / 180))
                }
                                    A18 18 0 0 0 0 -18
                                    L0 -28
                                    A28 28 0 0 1 ${
                                      28 *
                                      Math.sin(2 * (20 - minutesLeftInPeriod) * (Math.PI / 180))
                                    } ${
                  -28 * Math.cos(2 * (20 - minutesLeftInPeriod) * (Math.PI / 180))
                }
                                    Z
                                  `}
                stroke="none"
                fill={
                  currentLoad.alertLevel === 1
                    ? '#D9A62F'
                    : currentLoad.alertLevel === 2
                    ? '#CA5256'
                    : 'white'
                }
                transform={`rotate(${rotation})`}
              />,
            );
          }
        }
        rotation += 40;
        index++;
      } while (rotation < 360);
    }

    return alerts;
  };

  return (
    <div className={classes.root} onClick={timelineClick}>
      <div className={classes.nameArea}>
        <Typography>{name}</Typography>
      </div>
      <div className={classes.wheelArea}>
        <svg
          className={classes.wheel}
          viewBox={`${-(timelineWidth / 2)}, ${-(
            wheelAreaHeight / 2
          )}, ${timelineWidth}, ${wheelAreaHeight}`}>
          <g>{createLoadAlerts()}</g>
          <g>
            <circle cx="0" cy="0" r="18" fill="black" stroke="white" />
            <text
              x="0"
              y="0"
              fill="white"
              dominantBaseline="central"
              textAnchor="middle"
              fontSize="12"
              fontWeight="bold">
              {currentCadency ? `${currentCadency}s` : ''}
            </text>
          </g>
          <g>
            <path d="M0,-28 A 28 28 0 1 1 -9.5766,-26.3114" fill="none" stroke="#a9a9a9" />
            {createWheelTicks()}
          </g>
          <g>
            <rect
              fill="black"
              stroke="white"
              x="-2"
              y="-38"
              width="4"
              height="10"
              transform={`rotate(${selectedAngle})`}
            />
          </g>
        </svg>
        {currentRunway ? (
          <>
            <div className={classes.runwayLeft}>
              <span>{currentRunway.replace('_CVF', '')}</span>
              {currentRunway.endsWith('_CVF') ? <Brightness2Icon fontSize="inherit" /> : ''}
            </div>
            <span className={classes.runwayRight}>
              {currentRunway.replace('_CVF', '')}
              {currentRunway.endsWith('_CVF') ? <Brightness2Icon fontSize="inherit" /> : ''}
            </span>
          </>
        ) : (
          ''
        )}
      </div>
      <div className={classes.timelineArea} onWheel={throttle(wheel, 0.25)}>
        <div
          className={
            Math.floor(shiftedSelectedTime.getTime() / 60000) ===
            Math.floor(currentTime.getTime() / 60000)
              ? `${classes.selectedTime} ${classes.selectedCurrentTime}`
              : classes.selectedTime
          }
          onClick={(event) => {
            event.stopPropagation();
            selectCurrentTime();
          }}>
          <div className={classes.selectedHour}>
            {shiftedSelectedTime.getUTCHours() < 10
              ? `0${shiftedSelectedTime.getUTCHours()}`
              : shiftedSelectedTime.getUTCHours()}
          </div>
          <div className={classes.selectedMinute}>
            {shiftedSelectedTime.getUTCMinutes() < 10
              ? `0${shiftedSelectedTime.getUTCMinutes()}`
              : shiftedSelectedTime.getUTCMinutes()}
          </div>
        </div>
        <svg
          className={classes.timeline}
          preserveAspectRatio="xMidYMin slice"
          viewBox={`${-(timelineWidth / 2)}, 0, ${timelineWidth}, 5000`}>
          <g
            transform={`translate(0, ${
              -Math.floor((shiftedSelectedTime.getTime() - shiftedCurrentTime.getTime()) / 60000) *
              minuteHeight
            })`}
            className={classes.animated}>
            <g>{createTicks()}</g>
            <g>
              {flightData.map((flight) => {
                if (flight.at.getTime() >= currentTime.getTime()) {
                  return (
                    <g
                      transform={`translate(0 ${
                        (flight.at.getTime() / 60000 - shiftedCurrentTime.getTime() / 60000) *
                        minuteHeight
                      })`}
                      className={classes.animated}
                      key={flight.callsign}
                      onMouseEnter={() => flightEnter(flight.properties)}
                      onMouseLeave={() => flightLeave(flight.properties)}
                      onClick={(event) => {
                        event.stopPropagation();
                        flightClick(flight.properties);
                      }}
                      cursor="default">
                      <FlightComponent
                        flight={flight.properties}
                        time={flight.at}
                        delay={flight.delay}
                        orientation={flight.orientation}
                        hovered={flight.properties.callsign === hoveredFlightCallsign}
                        selected={flight.properties.callsign === selectedFlightCallsign}
                      />
                    </g>
                  );
                } else {
                  return '';
                }
              })}
            </g>
            <g>
              {
                // Changements de configuration
                planifications && planifications.configChangesClosures
                  ? planifications.configChangesClosures.map((closure) => {
                      const closureDuration = Math.ceil(
                        (closure.closureTo.getTime() - closure.closureFrom.getTime()) / 60000,
                      );

                      return (
                        <g
                          key={`config-${closure.closureFrom.getTime()}`}
                          transform={`translate(0 ${
                            ((closure.closureFrom.getTime() -
                              (closure.closureFrom.getTime() % 60000) -
                              shiftedCurrentTime.getTime()) /
                              60000) *
                            minuteHeight
                          })`}
                          className={classes.animated}>
                          <title>
                            Configuration {closure.config.replace('PO', '').replace('PG_', '')} à{' '}
                            {closure.closureTo.getUTCHours()}:
                            {closure.closureTo.getUTCMinutes() < 10
                              ? `0${closure.closureTo.getUTCMinutes()}`
                              : closure.closureTo.getUTCMinutes()}
                            {'\n'}
                            Fermeture {closure.closureFrom.getUTCHours()}:
                            {closure.closureFrom.getUTCMinutes() < 10
                              ? `0${closure.closureFrom.getUTCMinutes()}`
                              : closure.closureFrom.getUTCMinutes()}{' '}
                            - {closure.closureTo.getUTCHours()}:
                            {closure.closureTo.getUTCMinutes() < 10
                              ? `0${closure.closureTo.getUTCMinutes()}`
                              : closure.closureTo.getUTCMinutes()}
                            {'\n'}
                            Cadence {closure.cadency}s
                          </title>
                          <rect
                            x="-20"
                            y="-10"
                            height={closureDuration * minuteHeight + 20}
                            width="40"
                            rx="9"
                            ry="9"
                            fill="#E3B963"
                          />
                          <g>
                            <text
                              x="-5"
                              y="9"
                              textAnchor="end"
                              fill="white"
                              fontSize="11"
                              fontWeight="bold">
                              {closure.closureFrom.getUTCHours() < 10
                                ? `0${closure.closureFrom.getUTCHours()}`
                                : closure.closureFrom.getUTCHours()}
                            </text>
                            <text
                              x="-3"
                              y="9"
                              textAnchor="start"
                              fill="white"
                              fontSize="18"
                              fontWeight="bold">
                              {closure.closureFrom.getUTCMinutes() < 10
                                ? `0${closure.closureFrom.getUTCMinutes()}`
                                : closure.closureFrom.getUTCMinutes()}
                            </text>
                          </g>
                          <svg
                            viewBox="0 0 24 24"
                            height="30"
                            width="30"
                            x="-15"
                            y={(closureDuration * minuteHeight) / 2 - 55}>
                            <SvgFermeture />
                          </svg>
                          <g>
                            <circle
                              cx="0"
                              cy={(closureDuration * minuteHeight) / 2}
                              r="18"
                              fill="white"
                            />
                            <text
                              x="0"
                              y={(closureDuration * minuteHeight) / 2}
                              textAnchor="middle"
                              dominantBaseline="central"
                              fill="black"
                              fontSize="14"
                              fontWeight="bold">
                              {closure.config
                                .replace('PO', '')
                                .replace('PG_', '')
                                .replace('_CVF', '')}
                            </text>
                          </g>
                          {closure.isCvfConfig ? (
                            <svg
                              viewBox="0 0 26.41 26.371"
                              x="-12"
                              y={(closureDuration * minuteHeight) / 2 + 25}
                              height="24"
                              width="24">
                              <SvgCvf />
                            </svg>
                          ) : (
                            ''
                          )}
                          <g>
                            <circle
                              cx="0"
                              cy={
                                closure.isCvfConfig
                                  ? (closureDuration * minuteHeight) / 2 + 73
                                  : (closureDuration * minuteHeight) / 2 + 46
                              }
                              r="18"
                              fill="black"
                              stroke="white"
                            />
                            <text
                              x="0"
                              y={
                                closure.isCvfConfig
                                  ? (closureDuration * minuteHeight) / 2 + 73
                                  : (closureDuration * minuteHeight) / 2 + 46
                              }
                              textAnchor="middle"
                              dominantBaseline="central"
                              fontSize="12"
                              fontWeight="bold"
                              fill="white">
                              {closure.cadency ? `${closure.cadency}s` : '--'}
                            </text>
                          </g>
                          <g>
                            <text
                              x="-5"
                              y={closureDuration * minuteHeight + 3}
                              textAnchor="end"
                              fill="white"
                              fontSize="11"
                              fontWeight="bold">
                              {closure.closureTo.getUTCHours() < 10
                                ? `0${closure.closureTo.getUTCHours()}`
                                : closure.closureTo.getUTCHours()}
                            </text>
                            <text
                              x="-3"
                              y={closureDuration * minuteHeight + 3}
                              textAnchor="start"
                              fill="white"
                              fontSize="18"
                              fontWeight="bold">
                              {closure.closureTo.getUTCMinutes() < 10
                                ? `0${closure.closureTo.getUTCMinutes()}`
                                : closure.closureTo.getUTCMinutes()}
                            </text>
                          </g>
                        </g>
                      );
                    })
                  : ''
              }
            </g>
            <g>
              {planifications && planifications.closures
                ? planifications.closures.map((closure) => {
                    const closureDuration = Math.ceil(
                      (closure.to.getTime() - closure.from.getTime()) / (1000 * 60),
                    );

                    return (
                      <g
                        key={`closure-${closure.from.getTime()}`}
                        transform={`translate(0 ${
                          ((closure.from.getTime() -
                            (closure.from.getTime() % 60000) -
                            shiftedCurrentTime.getTime()) /
                            60000) *
                          minuteHeight
                        })`}
                        className={classes.animated}>
                        <title>
                          {closure.type === 'REAL'
                            ? `Fermeture (${closure.from.getUTCHours()}:${
                                closure.from.getUTCMinutes() < 10
                                  ? `0${closure.from.getUTCMinutes()}`
                                  : closure.from.getUTCMinutes()
                              } - ${closure.to.getUTCHours()}:${
                                closure.to.getUTCMinutes() < 10
                                  ? `0${closure.to.getUTCMinutes()}`
                                  : closure.to.getUTCMinutes()
                              })`
                            : closure.type === 'GAP'
                            ? `Gap (${closure.from.getUTCHours()}:${
                                closure.from.getUTCMinutes() < 10
                                  ? `0${closure.from.getUTCMinutes()}`
                                  : closure.from.getUTCMinutes()
                              } - ${closure.to.getUTCHours()}:${
                                closure.to.getUTCMinutes() < 10
                                  ? `0${closure.to.getUTCMinutes()}`
                                  : closure.to.getUTCMinutes()
                              })`
                            : ''}
                        </title>
                        <rect
                          x="-20"
                          y="-10"
                          height={closureDuration * minuteHeight + 20}
                          width="40"
                          rx="9"
                          ry="9"
                          fill={
                            closure.type === 'REAL'
                              ? '#C9623D'
                              : closure.type === 'GAP'
                              ? '#000000'
                              : ''
                          }
                        />
                        <g>
                          <text
                            x="-5"
                            y="9"
                            textAnchor="end"
                            fill="white"
                            fontSize="11"
                            fontWeight="bold">
                            {closure.from.getUTCHours() < 10
                              ? `0${closure.from.getUTCHours()}`
                              : closure.from.getUTCHours()}
                          </text>
                          <text
                            x="-3"
                            y="9"
                            textAnchor="start"
                            fill="white"
                            fontSize="18"
                            fontWeight="bold">
                            {closure.from.getUTCMinutes() < 10
                              ? `0${closure.from.getUTCMinutes()}`
                              : closure.from.getUTCMinutes()}
                          </text>
                        </g>
                        <svg
                          viewBox="0 0 24 24"
                          height="30"
                          width="30"
                          x="-15"
                          y={(closureDuration * minuteHeight) / 2}>
                          <SvgFermeture />
                        </svg>
                        <g>
                          <text
                            x="-5"
                            y={closureDuration * minuteHeight + 3}
                            textAnchor="end"
                            fill="white"
                            fontSize="11"
                            fontWeight="bold">
                            {closure.to.getUTCHours() < 10
                              ? `0${closure.to.getUTCHours()}`
                              : closure.to.getUTCHours()}
                          </text>
                          <text
                            x="-3"
                            y={closureDuration * minuteHeight + 3}
                            textAnchor="start"
                            fill="white"
                            fontSize="18"
                            fontWeight="bold">
                            {closure.to.getUTCMinutes() < 10
                              ? `0${closure.to.getUTCMinutes()}`
                              : closure.to.getUTCMinutes()}
                          </text>
                        </g>
                      </g>
                    );
                  })
                : ''}
            </g>
            <g>
              {planifications && planifications.cadencyChanges
                ? planifications.cadencyChanges.map((change) => {
                    return (
                      <g
                        key={`cadency-${change.at.getTime()}`}
                        transform={`translate(0 ${
                          ((change.at.getTime() -
                            (change.at.getTime() % 60000) -
                            shiftedCurrentTime.getTime()) /
                            60000) *
                          minuteHeight
                        })`}
                        className={classes.animated}>
                        <text
                          x={-(timelineWidth / 2) + 3}
                          y="0"
                          fill="white"
                          dominantBaseline="central"
                          fontSize="12">
                          {change.at.getUTCHours()}:{change.at.getUTCMinutes()}
                        </text>
                        <line
                          x1={-(timelineWidth / 2) + 36}
                          y1="0"
                          x2={-(minuteHeight / 2)}
                          y2="0"
                          stroke="white"
                          strokeDasharray="4, 5"
                        />
                        <circle cx="0" cy="0" r="19" fill="black" stroke="white" />
                        <text
                          x="0"
                          y="0"
                          fill="white"
                          fontSize="12"
                          fontWeight="bold"
                          textAnchor="middle"
                          dominantBaseline="central">
                          {`${change.newCadency}s`}
                        </text>
                        <line
                          x1={minuteHeight / 2}
                          y1="0"
                          x2={timelineWidth / 2 - 36}
                          y2="0"
                          stroke="white"
                          strokeDasharray="4, 5"
                        />
                        <text
                          x={timelineWidth / 2 - 3}
                          y="0"
                          fill="white"
                          dominantBaseline="central"
                          textAnchor="end"
                          fontSize="12">
                          {change.at.getUTCHours()}:{change.at.getUTCMinutes()}
                        </text>
                      </g>
                    );
                  })
                : ''}
            </g>
            <g>
              {planifications && planifications.cvfChanges
                ? planifications.cvfChanges.map((change) => {
                    return (
                      <g
                        key={`cvf-${change.at.getTime()}`}
                        transform={`translate(0 ${
                          ((change.at.closureFrom.getTime() -
                            (change.at.getTime() % 60000) -
                            shiftedCurrentTime.getTime()) /
                            60000) *
                          minuteHeight
                        })`}
                        className={classes.animated}>
                        <text
                          x={-(timelineWidth / 2) + 3}
                          y="0"
                          fill="white"
                          dominantBaseline="central"
                          fontSize="12">
                          {change.at.getUTCHours()}:{change.at.getUTCMinutes()}
                        </text>
                        <line
                          x1={-(timelineWidth / 2) + 36}
                          y1="0"
                          x2={-(minuteHeight / 2)}
                          y2="0"
                          stroke="white"
                          strokeDasharray="4, 5"
                        />
                        <circle cx="0" cy="0" r="19" fill="#E3B963" stroke="#707070" />
                        {change.notCvfToCvf ? (
                          <svg viewBox="0 0 26.41 26.371" x="-10" y="-10" height="20" width="20">
                            <SvgCvf />
                          </svg>
                        ) : (
                          <svg viewBox="0 0 30.071 17.899" x="-12" y="-12" height="24" width="24">
                            <SvgEndCvf />
                          </svg>
                        )}
                        <line
                          x1={minuteHeight / 2}
                          y1="0"
                          x2={timelineWidth / 2 - 36}
                          y2="0"
                          stroke="white"
                          strokeDasharray="4, 5"
                        />
                        <text
                          x={timelineWidth / 2 - 3}
                          y="0"
                          fill="white"
                          dominantBaseline="central"
                          textAnchor="end"
                          fontSize="12">
                          {change.at.getUTCHours()}:{change.at.getUTCMinutes()}
                        </text>
                      </g>
                    );
                  })
                : ''}
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
}

export default Timeline;
