import React, { useEffect, useRef, useReducer, useState, useMemo } from 'react';

import * as d3 from 'd3';

import { ReactComponent as SvgInProgress } from '../../svg-icons/state_inprogress.svg';
import { ReactComponent as SvgOk } from '../../svg-icons/state_ok.svg';
import { ReactComponent as SvgNok } from '../../svg-icons/state_nok.svg';

import { flightService } from '../../models/flight/flight.service.ts';
import { regulationService } from '../../models/regulation/regulation.service.ts';

const hoverColor = '#3277d1';
const selectColor = '#1150a1';
const negativeDelayColor = 'RGB(156,228,8)';
const delayColor = 'RGB(247,147,30)';
const importantDelayColor = 'RGB(202,82,86)';
const adscColor = 'rgb(0,255,136)';

function Flight({
  callsign,
  pos,
  track,
  afl,
  mach,
  groundSpeed,
  speedConstraint,
  speedConstraintStatus,
  delayMF,
  radarInfo,
  rvr,
  labelDisplayed,
  rvrDisplayed,
  mpr,
  sta,
  adscRoute,
  adscTodPos,
  adscTocPos,
  hasAdscData,
}) {

  // State & reducers
  const [labelPositionShiftX, setLabelPositionShiftX] = useReducer(
    (state, newValue) => state + newValue,
    30,
  );
  const [labelPositionShiftY, setLabelPositionShiftY] = useReducer(
    (state, newValue) => state + newValue,
    -30,
  );
  const [mpRegulation, setMpRegulation] = useState(null);
  const [rvrAlert, setRvrAlert] = useState(false);
  const [hovered, setHovered] = useReducer((state, newValue) => newValue === callsign, false);
  const [selected, setSelected] = useReducer((state, newValue) => newValue === callsign, false);

  const lineSecondPoint = useMemo(() => {
    const wLabel = rvrDisplayed ? 100 : 90;
    const hLabel = speedConstraint === 'SLW' || speedConstraint === 'DELETE' ? 90 : 74;

    if (
      labelPositionShiftX >= -wLabel &&
      labelPositionShiftX <= 0 &&
      labelPositionShiftY >= -hLabel &&
      labelPositionShiftY <= 0
    ) {
      return [0, 0];
    }
    let lineSecondPoint;

    // Détermination du 2ème point
    let edge = 'none';

    const hh =
      ((labelPositionShiftY + hLabel / 2) * (wLabel / 2)) / (labelPositionShiftX + wLabel / 2);
    if (-(hLabel / 2) <= hh && hh <= hLabel / 2) {
      edge = labelPositionShiftX > 0 ? 'left' : 'right';
      if (edge === 'left') {
        lineSecondPoint = {
          x: labelPositionShiftX,
          y: labelPositionShiftY + hLabel / 2 - hh,
        };
      } else if (edge === 'right') {
        lineSecondPoint = {
          x: labelPositionShiftX + wLabel,
          y: labelPositionShiftY + hLabel / 2 + hh,
        };
      }
    } else {
      const ww =
        ((labelPositionShiftX + wLabel / 2) * (hLabel / 2)) / (labelPositionShiftY + hLabel / 2);
      if (-(wLabel / 2) <= ww && ww <= wLabel / 2) {
        edge = labelPositionShiftY > 0 ? 'bottom' : 'top';
        if (edge === 'bottom') {
          lineSecondPoint = {
            x: labelPositionShiftX + wLabel / 2 - ww,
            y: labelPositionShiftY,
          };
        } else if (edge === 'top') {
          lineSecondPoint = {
            x: labelPositionShiftX + wLabel / 2 + ww,
            y: labelPositionShiftY + hLabel,
          };
        }
      }
    }

    return lineSecondPoint;
  }, [rvrDisplayed, speedConstraint, labelPositionShiftX, labelPositionShiftY]);

  // Refs des subscriptions
  const hoveredFlightSubscription = useRef(null);
  const selectedFlightSubscription = useRef(null);

  // Abonnement à la MPR
  const mpRegulationSubscription = useRef(null);

  // Ref de l'etiquette pour le drag & drop
  const flightLabel = useRef(null);

  // Au mount du composant, on s'abonne au vol survolé / selectionné
  useEffect(() => {
    hoveredFlightSubscription.current = flightService.selectHovered().subscribe(setHovered);
    selectedFlightSubscription.current = flightService.selectSelected().subscribe(setSelected);

    // Au unmount, on se désabonne des modèles
    return () => {
      if (hoveredFlightSubscription.current) {
        hoveredFlightSubscription.current.unsubscribe();
      }
      if (selectedFlightSubscription.current) {
        selectedFlightSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Ajout du drag & drop
  useEffect(() => {
    if ((labelDisplayed || hovered || selected) && flightLabel.current) {
      d3.select(flightLabel.current).call(
        d3.drag().on('drag', (event) => {
          setLabelPositionShiftX(event.dx);
          setLabelPositionShiftY(event.dy);
        }),
      );
    }
  }, [labelDisplayed, hovered, selected]);

  // Lors du changement de MPR du vol, on s'bonne à la régulation correspondante
  useEffect(() => {
    // Désabonnement de la MPR précédente si besoin
    if (mpRegulationSubscription.current) {
      mpRegulationSubscription.current.unsubscribe();
    }

    // Abonnement à la nouvelle MPR si besoin
    if (mpr) {
      regulationService.selectRegulation(mpr).subscribe(setMpRegulation);
    }
  }, [mpr]);

  // Lors du changement de mpRegulation et/ou de STA, on calcule s'il y a une alerte RVR
  useEffect(() => {
    if (sta && mpRegulation && mpRegulation.rvrs) {
      const matchingRVR = mpRegulation.rvrs.find(
        (rvr) => rvr.start.getTime() <= sta.getTime() && rvr.end.getTime() > sta.getTime(),
      );
      setRvrAlert(!!matchingRVR && (!rvr || matchingRVR.rvr < rvr));
    } else {
      setRvrAlert(false);
    }
  }, [mpRegulation, sta, rvr]);

  // Event handlers
  const handleClick = (event) => {
    if (selected) {
      flightService.setSelected('');
    } else {
      flightService.setSelected(callsign);
    }
  };

  const handleMouseEnter = (event) => {
    flightService.setHovered(callsign);
  };

  const handleMouseLeave = (event) => {
    flightService.setHovered('');
  };

  const path = (pos, adscRoute) => {
    let path;
    if (adscRoute) {
      path = `M${pos[0]},${pos[1]}`;
      adscRoute.forEach((point) => (path += `L${point[0]},${point[1]}`));
    }
    return path || null;
  };

  return (
    <>
      {(hovered || selected) && adscRoute && adscRoute !== '' ? (
        <path
          d={path(pos, adscRoute)}
          stroke={adscColor}
          strokeWidth="4"
          strokeOpacity="0.5"
          fill="none"
          vectorEffect="non-scaling-stroke"
        />
      ) : (
        ''
      )}
      <g
        className="flight"
        transform={`translate(${pos[0]} ${pos[1]})`}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        {labelDisplayed ||
          hovered ||
          selected ||
          speedConstraint === 'SLW' ||
          speedConstraint === 'DELETE' ? (
          <>
            <line
              x1="0"
              y1="0"
              x2={lineSecondPoint.x}
              y2={lineSecondPoint.y}
              stroke="#00000001"
              strokeWidth="10"
            />
            <line
              x1="0"
              y1="0"
              x2={lineSecondPoint.x}
              y2={lineSecondPoint.y}
              stroke={selected || hovered ? selectColor : 'white'}
            />
          </>
        ) : (
          ''
        )}
        <path
          fill={
            selected
              ? selectColor
              : hovered
                ? hoverColor
                : delayMF < 0
                  ? negativeDelayColor
                  : delayMF >= 6
                    ? importantDelayColor
                    : delayMF >= 2
                      ? delayColor
                      : 'black'
          }
          stroke={selected || hovered ? 'white' : 'none'}
          d="M19,14V12L11,7V1.5a1.5,1.5,0,0,0-3,0V7L0,12v2l8-2.5V17L6,18.5V20l3.5-1L13,20V18.5L11,17V11.5Z"
          transform={`translate(-9.5 -10) rotate(${track} 9.5 10)`}
        />
        <circle
          cx="0"
          cy="0"
          r="12"
          fill="#ffffff01"
          stroke={
            radarInfo !== 'radar' && radarInfo !== 'adsb'
              ? selected || hovered
                ? selectColor
                : delayMF < 0
                  ? negativeDelayColor
                  : delayMF >= 6
                    ? importantDelayColor
                    : delayMF >= 2
                      ? delayColor
                      : 'black'
              : 'none'
          }
        />

        {radarInfo === 'adsb' ? (
          <text
            x="12"
            y="12"
            alignmentBaseline="middle"
            fill={
              selected || hovered
                ? selectColor
                : delayMF < 0
                  ? negativeDelayColor
                  : delayMF >= 6
                    ? importantDelayColor
                    : delayMF >= 2
                      ? delayColor
                      : 'white'
            }
            style={{ fontSize: '12px', fontWeight: 'bold' }}>
            B
          </text>
        ) : (
          ''
        )}

        {labelDisplayed ||
          hovered ||
          selected ||
          speedConstraint === 'SLW' ||
          speedConstraint === 'DELETE' ? (
          <g
            transform={`translate(${labelPositionShiftX} ${labelPositionShiftY})`}
            style={{ cursor: 'default' }}
            ref={flightLabel}>
            <rect
              fill={selected ? selectColor : hovered ? hoverColor : 'RGB(77,83,97)'}
              style={{ opacity: 0.6 }}
              width={rvrDisplayed || rvrAlert ? 100 : 90}
              height={speedConstraint === 'SLW' || speedConstraint === 'DELETE' ? 90 : 74}
              rx="9"
            />
            <text x="5" y="18" fill="white">
              {groundSpeed || '-'}
            </text>
            <text x="5" y="34" fill="white">
              {callsign}
            </text>
            {hasAdscData ? <circle cx="80" cy="29" r="5" fill={adscColor} /> : ''}
            <text x="5" y="50" fill="white">
              {mach ? `m${mach}` : '-'}/{afl && !isNaN(afl) ? `FL${afl}` : '-'}
            </text>
            <text
              x="5"
              y="66"
              fill={
                delayMF < 0
                  ? negativeDelayColor
                  : delayMF >= 6
                    ? importantDelayColor
                    : delayMF >= 2
                      ? delayColor
                      : 'white'
              }>
              {delayMF || '-'}
            </text>
            {rvrDisplayed || rvrAlert ? (
              <text x="42" y="66" fill={rvrAlert ? 'RGB(247,147,30)' : 'white'}>
                {rvr ? `RVR${rvr}` : rvrAlert ? 'RVR -' : ''}
              </text>
            ) : (
              ''
            )}

            <text
              x="5"
              y="82"
              fill="white"
              style={{
                textDecoration: speedConstraint === 'DELETE' ? 'line-through' : '',
              }}>
              {speedConstraint === 'SLW' || speedConstraint === 'DELETE' ? 'SLW' : ''}
            </text>
            {speedConstraintStatus === 'PROPOSED' ? (
              <SvgInProgress x="37" y="70" viewBox="0 0 13 13" />
            ) : speedConstraintStatus === 'REFUSED' ? (
              <SvgNok x="37" y="70" viewBox="-2 -2 19 19" />
            ) : speedConstraintStatus === 'IMPLEMENTED' ? (
              <SvgOk x="37" y="70" viewBox="3 3 35 35" />
            ) : (
              ''
            )}
          </g>
        ) : (
          ''
        )}
      </g>

      {(hovered || selected) && adscTodPos && adscTodPos !== '' ? (
        <>
          {' '}
          <circle cx={adscTodPos[0]} cy={adscTodPos[1]} r="5" fill={adscColor} />
        </>
      ) : (
        ''
      )}
      {(hovered || selected) && adscTocPos && adscTocPos !== '' ? (
        <>
          {' '}
          <circle cx={adscTocPos[0]} cy={adscTocPos[1]} r="5" fill={adscColor} />
        </>
      ) : (
        ''
      )}
    </>
  );
}

export default Flight;
