import React, {useEffect, useRef, useState} from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import {makeStyles} from '@material-ui/core';

import PlanificationsGraph from '../graphs/planifications-graph/planifications-graph.js';

import {planificationService} from '../../models/planification/planification.service.ts';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#565656',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  card: {
    minHeight: '400',
    backgroundColor: 'RGB(247,247,247)',
    margin: theme.spacing(1),
  },
}));

function PlanificationsCard({airport, runway}) {
  const classes = useStyles();

  // Subscriptions
  const planificationsSubscription = useRef(null);

  // State
  const [planifications, setPlanifications] = useState(null);

  // Au mount, on ne fait rien
  useEffect(
    () =>
      // Au unmount, on se désabonne des modèles
      () => {
        if (planificationsSubscription.current) {
          planificationsSubscription.current.unsubscribe();
        }
      },
    [],
  );

  // Lors du changement d'aéroport et/ou de configuration, on met à jour la subscription aux planifications
  useEffect(() => {
    if (planificationsSubscription.current) {
      planificationsSubscription.current.unsubscribe();
    }

    if (airport && runway) {
      planificationsSubscription.current = planificationService
        .selectPlanificationForGraphForAirport(airport, runway)
        .subscribe(setPlanifications);
    } else {
      setPlanifications(null);
    }
  }, [airport, runway]);

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <Typography className={classes.cardTitle}>Planifications</Typography>
        <PlanificationsGraph airport={airport} runway={runway} planifications={planifications} />
      </CardContent>
    </Card>
  );
}

export default PlanificationsCard;
