import React, {useState, useEffect, useRef, useReducer} from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {makeStyles} from '@material-ui/core/styles';

import PlanificationsCard from './cards/planifications-card.js';

import FlightListCard from './cards/flight-list-card.js';
import GraphsCard from './cards/graphs-card.js';
import ChartsCard from '../components/cards/chartsCard/ChartsCard';

import {flightService} from '../models/flight/flight.service';
import {airportService} from '../models/airport/airport.service';
import {beaconService} from '../models/beacon/beacon.service';
import {dateService} from '../models/date/date.service';
import {capacityService} from '../models/capacity/capacity.service';
import {departureCountService} from '../models/departure-count/departure-count.service';

const useStyles = makeStyles((theme) => ({
  drawerContainer: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 48,
  },
  drawerTitle: {
    flexGrow: 1,
  },
  name: {
    textAlign: 'center',
    fontSize: '26px',
    fontWeight: 'bold',
  },
  scrollableContent: {
    height: 'calc(100% - 48px)',
    overflow: 'auto',
  },
}));

function AirportDrawer({name}) {
  const classes = useStyles();

  // Subscriptions
  const minutesLeftSubscription = useRef(null);
  const airportSubscription = useRef(null);
  const loadSubscription = useRef(null);
  const beaconSubscription = useRef(null);
  const capacitySubscription = useRef(null);
  const departureCapacitySubscription = useRef(null);
  const departureCountsSubscription = useRef(null);

  // State
  const [minutesLeft, setMinutesLeft] = useState(null);
  const [airport, setAirport] = useState(null);

  const [load, setLoad] = useState(null);
  const [flightsOnAirport, setFlightsOnAirport] = useState(null);

  const [beacon, setBeacon] = useReducer(
    (currentBeacon, beacon) =>
      beacon
        ? {
            ...beacon,
            defaultCapacity: beacon.defaultCapacity || 999,
            defaultDepartureCapacity: beacon.defaultDepartureCapacity || 999,
          }
        : null,
    null,
  );

  const [capacities, setCapacities] = useState(null);
  const [departureCapacities, setDepartureCapacities] = useState(null);

  const [departureCounts, setDepartureCounts] = useState(null);

  // Au mount du composant
  useEffect(() => {
    minutesLeftSubscription.current = dateService.selectMinutesLeft().subscribe(setMinutesLeft);
    airportSubscription.current = airportService.selectIcaoAirport().subscribe(setAirport);

    // Au unmount du composant
    return () => {
      if (minutesLeftSubscription.current) {
        minutesLeftSubscription.current.unsubscribe();
      }
      if (airportSubscription.current) {
        airportSubscription.current.unsubscribe();
      }
      if (loadSubscription.current) {
        loadSubscription.current.unsubscribe();
      }
      if (beaconSubscription.current) {
        beaconSubscription.current.unsubscribe();
      }
      if (capacitySubscription.current) {
        capacitySubscription.current.unsubscribe();
      }
      if (departureCapacitySubscription.current) {
        departureCapacitySubscription.current.unsubscribe();
      }
      if (departureCountsSubscription.current) {
        departureCountsSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Lors du changement d'aéroport ou de la balise aeroport, on met à jour la subscription au load et à la balise (contenant les valeurs de capacité par défaut)
  useEffect(() => {
    if (loadSubscription.current) {
      loadSubscription.current.unsubscribe();
    }
    if (beaconSubscription.current) {
      beaconSubscription.current.unsubscribe();
    }

    if (name && airport) {
      loadSubscription.current = flightService
        .selectLoadForAirportAndRunway(airport, name)
        .subscribe((load) => {
          setLoad(load.loadArray);
          setFlightsOnAirport(load.flightsOnAirport);
        });

      beaconSubscription.current = beaconService
        .selectBeaconForAirport(name, airport)
        .subscribe(setBeacon);
    } else {
      setLoad(null);
      setFlightsOnAirport(null);
      setBeacon(null);
    }
  }, [name, airport]);

  // Lors du changement de balise, on s'abonne aux capacités associées
  useEffect(() => {
    if (capacitySubscription.current) {
      capacitySubscription.current.unsubscribe();
    }
    if (departureCapacitySubscription.current) {
      departureCapacitySubscription.current.unsubscribe();
    }
    if (departureCountsSubscription.current) {
      departureCountsSubscription.current.unsubscribe();
    }

    if (beacon && beacon.tvForCapacity) {
      capacitySubscription.current = capacityService
        .selectCapacityForTV(beacon.tvForCapacity)
        .subscribe((capacities) =>
          capacities && capacities.capacities
            ? setCapacities(capacities.capacities)
            : setCapacities(null),
        );
    } else {
      setCapacities(null);
    }

    if (beacon && beacon.tvForDepartureCapacity) {
      departureCapacitySubscription.current = capacityService
        .selectCapacityForTV(beacon.tvForDepartureCapacity)
        .subscribe((departureCapacities) =>
          departureCapacities && departureCapacities.capacities
            ? setDepartureCapacities(departureCapacities.capacities)
            : setDepartureCapacities(null),
        );
    } else {
      setDepartureCapacities(null);
    }

    // On s'abonne aussi aux départs pour LFPO
    if (beacon && beacon.tvForDepartureCapacity) {
      departureCountsSubscription.current = departureCountService
        .selectDepartureCountsForTV(beacon.tvForDepartureCapacity)
        .subscribe((departureCounts) => {
          if (departureCounts && departureCounts.departureCount) {
            setDepartureCounts(departureCounts.departureCount);
          } else {
            setDepartureCounts(null);
          }
        });
    } else {
      setDepartureCounts(null);
    }
  }, [beacon]);

  // Event handlers
  const handleClick = () => {
    beaconService.updateSelectedBeacon('');
  };

  return (
    <div className={classes.drawerContainer}>
      <div className={classes.drawerHeader}>
        <div className={classes.drawerTitle}>
          <Typography className={classes.name}>{name}</Typography>
        </div>
        <IconButton onClick={handleClick}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <div className={classes.scrollableContent}>
        <PlanificationsCard airport={airport} runway={name} />
        {/* <GraphsCard type="airport"
                            beaconSelected={name}
                            minutesLeft={minutesLeft}
                            load={load}
                            capacities={capacities}
                            defaultCapacity={beacon ? beacon.defaultCapacity : null}
                            departureCapacities={departureCapacities}
                            defaultDepartureCapacity={beacon ? beacon.defaultDepartureCapacity : null}
                            departureCounts={departureCounts}
                /> */}
        <ChartsCard
          type="airport"
          beaconSelected={name}
          minutesLeft={minutesLeft}
          load={load}
          capacities={capacities}
          defaultCapacity={beacon ? beacon.defaultCapacity : null}
          departureCapacities={departureCapacities}
          defaultDepartureCapacity={beacon ? beacon.defaultDepartureCapacity : null}
          departureCounts={departureCounts}
        />
        <FlightListCard flights={flightsOnAirport} />
      </div>
    </div>
  );
}

export default AirportDrawer;
