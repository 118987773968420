import {QueryEntity} from '@datorama/akita';
import {SectorState, SectorStore, sectorStore} from './sector.store';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

export class SectorQuery extends QueryEntity<SectorState> {
  constructor(protected store: SectorStore) {
    super(store);
  }

  selectSectors() {
    return this.selectLoading().pipe(
      switchMap((loading) => {
        return loading ? of([]) : this.selectAll();
      }),
    );
  }

  selectSectorEga() {
    return this.selectLoading().pipe(
      switchMap((loading) => {
        return loading ? of(null) : this.selectEntity((sector) => sector && sector.name === 'EGA');
      }),
    );
  }
}

export const sectorQuery = new SectorQuery(sectorStore);
