import React, {useEffect, useState, useRef, useReducer} from 'react';

import {makeStyles} from '@material-ui/core';
import {sectorService} from '../../models/sector/sector.service.ts';
import {displayService} from '../../models/display/display.service.ts';

const useStyles = makeStyles((theme) => ({
  sector: {
    stroke: 'black',
    fill: 'none',
    strokeWidth: 0.7,
    strokeDasharray: '5,5',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    strokeOpacity: 0.5,
  },
}));

function SectorsLayer({x, y, k}) {
  const classes = useStyles();

  // State
  const [sectorLfbbDisplayed, setSectorLfbbDisplayed] = useState(false);
  const [sectorEgaDisplayed, setSectorEgaDisplayed] = useState(false);
  const [sectorUirDisplayed, setSectorUirDisplayed] = useState(false);
  const [sectorSectoNwDisplayed, setSectorSectoNwDisplayed] = useState(false);
  const [sectorMuacKuacDisplayed, setSectorMuacKuacDisplayed] = useState(false);
  const [sectorSectoSeDisplayed, setSectorSectoSeDisplayed] = useState(false);
  const [sectors, setSectors] = useReducer((state, newValue) => {
    const projection = sectorService.getProjection();
    return newValue && projection
      ? newValue.map((sector) => ({
          ...sector,
          coordinates: sector.coordinates.map((coordinate) => projection(coordinate)),
        }))
      : state;
  }, []);

  // Refs des subscriptions
  const sectorLfbbDisplayedSubscription = useRef(null);
  const sectorEgaDisplayedSubscription = useRef(null);
  const sectorUirDisplayedSubscription = useRef(null);
  const sectorSectoNwDisplayedSubscription = useRef(null);
  const sectorMuacKuacDisplayedSubscription = useRef(null);
  const sectorSectoSeDisplayedSubscription = useRef(null);
  const sectorsSubscription = useRef(null);

  // Au mount du composant, on s'abonne aux modèles
  useEffect(() => {
    sectorLfbbDisplayedSubscription.current = displayService
      .areSectorLfbbDisplayed()
      .subscribe(setSectorLfbbDisplayed);
    sectorEgaDisplayedSubscription.current = displayService
      .areSectorEgaDisplayed()
      .subscribe(setSectorEgaDisplayed);
    sectorUirDisplayedSubscription.current = displayService
      .areSectorUirDisplayed()
      .subscribe(setSectorUirDisplayed);
    sectorSectoNwDisplayedSubscription.current = displayService
      .areSectorSectoNwDisplayed()
      .subscribe(setSectorSectoNwDisplayed);
    sectorMuacKuacDisplayedSubscription.current = displayService
      .areSectorMuacKuacDisplayed()
      .subscribe(setSectorMuacKuacDisplayed);
    sectorSectoSeDisplayedSubscription.current = displayService
      .areSectorSectoSeDisplayed()
      .subscribe(setSectorSectoSeDisplayed);

    sectorsSubscription.current = sectorService.selectSectors().subscribe(setSectors);

    // Au unmount du composant, on se désabonne des modèles
    return () => {
      if (sectorLfbbDisplayedSubscription.current) {
        sectorLfbbDisplayedSubscription.current.unsubscribe();
      }
      if (sectorEgaDisplayedSubscription.current) {
        sectorEgaDisplayedSubscription.current.unsubscribe();
      }
      if (sectorUirDisplayedSubscription.current) {
        sectorUirDisplayedSubscription.current.unsubscribe();
      }
      if (sectorSectoNwDisplayedSubscription.current) {
        sectorSectoNwDisplayedSubscription.current.unsubscribe();
      }
      if (sectorMuacKuacDisplayedSubscription.current) {
        sectorMuacKuacDisplayedSubscription.current.unsubscribe();
      }
      if (sectorSectoSeDisplayedSubscription.current) {
        sectorSectoSeDisplayedSubscription.current.unsubscribe();
      }

      if (sectorsSubscription.current) {
        sectorsSubscription.current.unsubscribe();
      }
    };
  }, []);

  return (
    <g transform={`translate(${x} ${y}) scale(${k})`}>
      <g className={classes.sector}>
        {sectorLfbbDisplayed
          ? sectors
              .filter((sector) => sector.belong === 'LFBB-LFRR')
              .map((sector) => (
                <polygon
                  points={sector.coordinates}
                  vectorEffect="non-scaling-stroke"
                  key={sector.name}
                />
              ))
          : ''}
      </g>
      <g className={classes.sector}>
        {sectorUirDisplayed
          ? sectors
              .filter((sector) => sector.belong === 'UIR')
              .map((sector) => (
                <polygon
                  points={sector.coordinates}
                  vectorEffect="non-scaling-stroke"
                  key={sector.name}
                />
              ))
          : ''}
      </g>
      <g className={classes.sector}>
        {sectorEgaDisplayed
          ? sectors
              .filter((sector) => sector.belong === 'EGA')
              .map((sector) => (
                <polygon
                  points={sector.coordinates}
                  vectorEffect="non-scaling-stroke"
                  key={sector.name}
                />
              ))
          : ''}
      </g>
      <g className={classes.sector}>
        {sectorSectoNwDisplayed
          ? sectors
              .filter((sector) => sector.belong === 'Secto NW')
              .map((sector) => (
                <polygon
                  points={sector.coordinates}
                  vectorEffect="non-scaling-stroke"
                  key={sector.name}
                />
              ))
          : ''}
      </g>
      <g className={classes.sector}>
        {sectorMuacKuacDisplayed
          ? sectors
              .filter((sector) => sector.belong === 'MUAC-KUAC')
              .map((sector) => (
                <polygon
                  points={sector.coordinates}
                  vectorEffect="non-scaling-stroke"
                  key={sector.name}
                />
              ))
          : ''}
      </g>
      <g className={classes.sector}>
        {sectorSectoSeDisplayed
          ? sectors
              .filter((sector) => sector.belong === 'Secto SE')
              .map((sector) => (
                <polygon
                  points={sector.coordinates}
                  vectorEffect="non-scaling-stroke"
                  key={sector.name}
                />
              ))
          : ''}
      </g>
    </g>
  );
}

export default SectorsLayer;
