import {Observable} from 'rxjs';
import {dateStore, DateStore} from './date.store';
import {dateQuery} from './date.query';

export class DateService {
  /**
   * Mémorisation de l'intervalle qui met à jour la date toutes les secondes, afin de pouvoir l'arrêter dans la méthode destroy()
   */
  private updateInterval: NodeJS.Timeout | null = null;

  constructor(private dateStore: DateStore) {}

  /**
   * Méthode permettant de démarrer la mise à jour automatique toutes les secondes de la date du modèle
   */
  start(): void {
    if (!this.updateInterval) {
      this.updateInterval = setInterval(() => this.updateDate(new Date()), 1000);
    }
  }

  /**
   * Méthode permettant d'arrêter la mise à jour automatique de la date du modèle
   */
  destroy(): void {
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }
  }

  /**
   * Méthode permettant de définir la nouvelle date contenue par le modèle
   * @param date nouvelle date à insérer dans le store
   */
  updateDate(date: Date): void {
    dateStore.update({
      date: date,
      day: date.getUTCDay(),
      hour: date.getUTCHours(),
      minute: date.getUTCMinutes(),
      tenSecond: Math.trunc(date.getUTCSeconds() / 10),
      updatePeriod: Math.trunc(date.getUTCSeconds() / 4),
      second: date.getUTCSeconds(),
    });
  }

  /**
   * Méthode permettant de s'abonner au changement de la date du modèle (donc toutes les secondes)
   */
  selectDate(): Observable<Date> {
    return dateQuery.selectDate();
  }

  /**
   * Méthode permettant de s'abonner au changement de jour (ex: lundi 23:59 -> mardi 00:00)
   */
  selectDay(): Observable<number> {
    return dateQuery.selectDay();
  }

  /**
   * Méthode permettant de s'abonner au changement d'heure (ex: 09:59 -> 10:00)
   */
  selectHour(): Observable<number> {
    return dateQuery.selectHour();
  }

  /**
   * Méthode permettant de s'abonner au changement de minute (ex: 12:12 -> 12:13)
   */
  selectMinute(): Observable<number> {
    return dateQuery.selectMinute();
  }

  /**
   * Méthode permettant de s'abonner au changement de minutes restantes dans le créneau de 20 minutes en cours
   */
  selectMinutesLeft(): Observable<number> {
    return dateQuery.selectMinutesLeft();
  }

  /**
   * Méthode permettant de s'abonner au changement de dizaine de secondes
   */
  selectTenSecond(): Observable<number> {
    return dateQuery.selectTenSecond();
  }

  /**
   * Méthode permettant de s'abonner au changement de periode de mise à jour (fixée à 4 secondes)
   */
  selectUpdatePeriod(): Observable<number> {
    return dateQuery.selectUpdatePeriod();
  }

  /**
   * Méthode permettant de s'abonner au changement de seconde (ex: 08:37:15 -> 08:37:16)
   */
  selectSecond(): Observable<number> {
    return dateQuery.selectSecond();
  }

  /**
   * Méthode permettant de récupérer ponctuellement la date courante
   */
  getDate(): Date {
    return dateQuery.getDate();
  }

  /**
   * Méthode permettant de récupérer ponctuellement l'heure courante
   */
  getHour(): number {
    return dateQuery.getHour();
  }

  /**
   * Méthode permettant de récupérer ponctuellement la minute courante
   */
  getMinute(): number {
    return dateQuery.getMinute();
  }

  /**
   * Méthode permettant de récupérer ponctuellement le nombre de minutes restantes dans le créneau de 20 minutes en cours
   */
  getMinutesLeft(): number {
    return dateQuery.getMinutesLeft();
  }
}

export const dateService = new DateService(dateStore);
