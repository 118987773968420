import React, {useState} from 'react';

import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  sliceOfWheel: {
    stroke: 'none',
    '&:hover': {
      strokeWidth: 2,
      stroke: 'white',
    },
  },
}));

function Wheel({beaconRadius, beaconLoadWithCapacities, currentDateTime}) {
  const classes = useStyles();

  // State
  const [hoveredSliceOfWheel, setHoveredSliceOfWheel] = useState(null);

  const createWheelTicks = () => {
    const ticks = [
      <line key="wheelTick--1" x1="0" y1={-beaconRadius} x2="0" y2="-35" stroke="#a9a9a9" />,
    ];

    let cursor = currentDateTime.getUTCHours() * 60 + currentDateTime.getUTCMinutes();
    let angle = 0;

    while (
      cursor < currentDateTime.getUTCHours() * 60 + currentDateTime.getUTCMinutes() + 3 * 60 &&
      angle <= 340
    ) {
      if (cursor % 60 === 0) {
        // Si on est sur une heure pile, on ajoute un grand trait
        ticks.push(
          <line
            key={`wheelTick-${cursor}`}
            x1="0"
            y1={-beaconRadius}
            x2="0"
            y2="-28"
            transform={`rotate(${angle})`}
            stroke="#a9a9a9"
          />,
        );
      } else if (cursor % 20 === 0) {
        // Si on tombe sur :20 ou :40, on ajoute un petit trait
        ticks.push(
          <line
            key={`wheelTick-${cursor}`}
            x1="0"
            y1="-22"
            x2="0"
            y2="-28"
            transform={`rotate(${angle})`}
            stroke="#a9a9a9"
          />,
        );
      }

      // Une minute correspond à 2 degrés
      cursor++;
      angle += 2;
    }

    return ticks;
  };

  const createLoadAlerts = () => {
    const alerts = [];

    if (beaconLoadWithCapacities) {
      const startDateTime = beaconLoadWithCapacities[0].from;
      beaconLoadWithCapacities.forEach((loadWithCapacities) => {
        const minutesInPeriod = Math.trunc(
          (loadWithCapacities.to.getTime() - loadWithCapacities.from.getTime()) / 60000,
        );
        alerts.push(
          <path
            key={`alert-${loadWithCapacities.from.toString()}`}
            className={classes.sliceOfWheel}
            d={`
                            M${beaconRadius * Math.sin(2 * minutesInPeriod * (Math.PI / 180))} ${
              -beaconRadius * Math.cos(2 * minutesInPeriod * (Math.PI / 180))
            }
                            A${beaconRadius} ${beaconRadius} 0 0 0 0 ${-beaconRadius}
                            L0 -28
                            A28 28 0 0 1 ${28 * Math.sin(2 * minutesInPeriod * (Math.PI / 180))} ${
              -28 * Math.cos(2 * minutesInPeriod * (Math.PI / 180))
            }
                            Z
                          `}
            fill={
              loadWithCapacities.load >
              Math.floor(1.2 * Math.floor(loadWithCapacities.capacity / 3))
                ? '#CA5256'
                : loadWithCapacities.load > Math.floor(loadWithCapacities.capacity / 3)
                ? '#D9A62F'
                : '#00000001'
            }
            transform={`rotate(${
              Math.trunc((loadWithCapacities.from.getTime() - startDateTime.getTime()) / 60000) * 2
            })`}
            onMouseEnter={() => setHoveredSliceOfWheel(loadWithCapacities)}
          />,
        );
      });
    }

    return alerts;
  };

  return (
    <g onMouseLeave={() => setHoveredSliceOfWheel(null)}>
      <g>
        <path d="M0,-28 A 28 28 0 1 1 -9.5766,-26.3114" fill="none" stroke="#a9a9a9" />
        {createWheelTicks()}
      </g>
      <g>{createLoadAlerts()}</g>
      {hoveredSliceOfWheel ? (
        <g>
          <g>
            <circle
              cx="45"
              cy="0"
              r={hoveredSliceOfWheel.load >= 10 ? 12 : 10}
              fill={
                hoveredSliceOfWheel.load <= Math.floor(hoveredSliceOfWheel.capacity / 3)
                  ? '#406279'
                  : hoveredSliceOfWheel.load <=
                    Math.floor(1.2 * Math.floor(hoveredSliceOfWheel.capacity / 3))
                  ? '#D9A62F'
                  : '#CA5256'
              }
            />
            <text
              x="45"
              y="0"
              fill={
                hoveredSliceOfWheel.load >
                Math.floor(1.2 * Math.floor(hoveredSliceOfWheel.capacity / 3))
                  ? 'white'
                  : 'black'
              }
              fontWeight="bold"
              textAnchor="middle"
              dominantBaseline="central">
              {hoveredSliceOfWheel.load}
            </text>
          </g>
          <g>
            <circle
              cx="65"
              cy="0"
              r={Math.floor(1.2 * Math.floor(hoveredSliceOfWheel.capacity / 3)) >= 10 ? 12 : 10}
              fill="white"
              stroke="red"
              strokeWidth="2"
            />
            <text
              x="65"
              y="0"
              fill="red"
              fontWeight="bold"
              textAnchor="middle"
              dominantBaseline="central">
              {Math.floor(1.2 * Math.floor(hoveredSliceOfWheel.capacity / 3))}
            </text>
          </g>
          <text x="85" y="0" fill="white" fontWeight="bold" dominantBaseline="central">
            {hoveredSliceOfWheel.from.toLocaleTimeString('utc', {
              hour12: false,
              timeZone: 'UTC',
              timeStyle: 'short',
            })}
            -
            {hoveredSliceOfWheel.to.toLocaleTimeString('utc', {
              hour12: false,
              timeZone: 'UTC',
              timeStyle: 'short',
            })}
          </text>
        </g>
      ) : (
        ''
      )}
    </g>
  );
}

export default Wheel;
