import React, {useEffect, useState, useRef} from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import {flightService} from '../models/flight/flight.service.ts';

function FlightSearch({className, airport}) {
  // State
  const [flights, setFlights] = useState([]);
  const [value] = useState(null);
  const [inputValue, setInputValue] = useState('');

  // Refs des subscriptions
  const flightSubscription = useRef(null);

  // Au mount du composant, on s'abonne à la liste des vols
  useEffect(() => {
    flightSubscription.current = flightService.selectAllFlights().subscribe(setFlights);

    return () => {
      if (flightSubscription.current) {
        flightSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Event handlers
  const handleChange = (event, value, reason) => {
    // Lorsqu'on sélectionne un vol, on le marque comme sélectionné dans le modèle
    if (value) {
      setInputValue(value.callsign);
      flightService.setSelected(value.callsign);
    }
  };

  const handleInputChange = (event, inputValue, reason) => {
    // Lorsqu'on tape, on met uniquement à jour la valeur de l'input (nécessaire car le composant est en mode "controlled")
    if (reason !== 'reset') {
      setInputValue(inputValue.toUpperCase());
    }
  };

  const handleFocus = () => {
    // Lors du focus, on efface la précédente valeur et la précédente valeur de l'input
    setInputValue('');
  };

  return (
    <Autocomplete
      className={className}
      options={flights.sort((a, b) => (a.arr === airport ? -1 : 1))}
      groupBy={(option) => option.arr}
      getOptionLabel={(option) => option.callsign}
      getOptionDisabled={(option) => option.arr !== airport}
      getOptionSelected={(option, value) => option.callsign === value.callsign}
      noOptionsText="Aucun vol trouvé"
      disableClearable
      blurOnSelect
      value={value}
      onFocus={handleFocus}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      style={{width: 220}}
      renderInput={(params) => (
        <TextField
          {...params}
          color="secondary"
          label="Trouver un vol"
          variant="outlined"
          size="small"
        />
      )}
    />
  );
}

export default FlightSearch;
