import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  card: {
    minHeight: '400',
    minWidth: '400',
    maxWidth: '400',
    backgroundColor: 'RGB(247,247,247)',
    margin: theme.spacing(1),
  },
  cardTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#565656',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  flightParameter: {
    fontSize: '13px',
  },
  flightParameterLow: {
    fontSize: '9px',
    color: 'grey',
  },
  flightData: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  paper: {
    backgroundColor: 'RGB(234,234,234)',
  },
  spaceAfter: {
    marginBottom: 5,
  },
}));

function ADSBCard({flight}) {
  const classes = useStyles();

  return flight ? (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <Typography className={classes.cardTitle}>ADS-B</Typography>
        <Grid container direction="row" justifyContent="flex-start" className={classes.spaceAfter}>
          <Grid item xs={4}>
            <div style={{display: 'flex', alignItems: 'baseline'}}>
              <Typography className={classes.flightParameter}>Route&nbsp;</Typography>
              <Typography className={classes.flightParameterLow}>(géographique)&nbsp;</Typography>
              <Typography className={classes.flightParameter}>:&nbsp;</Typography>
            </div>
          </Grid>
          <Grid item xs={3}>
            {flight && flight.trackAdsb && !isNaN(flight.trackAdsb) ? (
              <Typography className={classes.flightData}>{flight.trackAdsb}°</Typography>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.flightParameter}>GS:</Typography>
          </Grid>
          <Grid item xs={3}>
            {flight &&
            flight.groundSpeedAdsb &&
            flight.groundSpeedAdsb !== 0 &&
            !isNaN(flight.groundSpeedAdsb) ? (
              <Typography className={classes.flightData}>{flight.groundSpeedAdsb}kts</Typography>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" className={classes.spaceAfter}>
          <Grid item xs={4}>
            <div style={{display: 'flex', alignItems: 'baseline'}}>
              <Typography className={classes.flightParameter}>Niveau Actuel :&nbsp;</Typography>
            </div>
          </Grid>
          <Grid item xs={8}>
            {flight && flight.aflAdsb && flight.aflAdsb !== 0 && !isNaN(flight.aflAdsb) ? (
              <Typography className={classes.flightData}>
                {' '}
                FL
                {flight.aflAdsb < 10
                  ? `00${flight.aflAdsb}`
                  : flight.aflAdsb < 100
                  ? `0${flight.aflAdsb}`
                  : flight.aflAdsb}
              </Typography>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ) : (
    ''
  );
}

export default ADSBCard;
