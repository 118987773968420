import {webSocket} from '../../utils/serverWebSocket';
import {Socket} from 'socket.io-client';

import {notificationStore, NotificationStore} from './notification.store';
import {Notification} from './notification.model';
import {notificationQuery} from './notification.query';

import {flightService} from '../flight/flight.service';
import {dateService} from '../date/date.service';

export class NotificationService {
  private socket?: Socket;

  constructor(private notificationStore: NotificationStore) {}

  /**
   * Méthode permettant d'ouvrir la connexion avec le serveur via une webSocket et de s'abonner aux différents types de messages émis par celui ci
   */
  async start() {
    // On s'assure que le store est vide
    notificationStore.remove();

    // On s'abonne au WS
    this.socket = webSocket().getSocket();
    this.onMessageReceived = this.onMessageReceived.bind(this);
    this.socket.on('message', this.onMessageReceived);

    // setInterval(() => {
    //     this.addNotification({
    //         id: Math.random().toString(),
    //         time: new Date(),
    //         title: `AFR1234 RTG LATGO`,
    //         text: '',
    //         callsign: 'AFR1234',
    //         airport: 'LFPO',
    //         icon: 'tick'
    //     });
    // }, 10000);
  }

  /**
   * Méthode permettant se désabonner du serveur et de vider le store, lorsque celui ci n'est plus utilisé
   * La socket n'est pas fermée car elle peut être utilisée par un autre modèle
   */
  destroy(): void {
    // Déconnexion du serveur
    if (this.socket) {
      this.socket.off('message', this.onMessageReceived);
      this.socket = undefined;
    }

    // On vide le store
    notificationStore.remove();
  }

  /**
   * Listener pour les messages reçus du serveur
   * @param message message reçu
   */
  onMessageReceived(message: any) {
    const json = JSON.parse(message);

    if (json.typeMessage === 'notification') {
      let callsign, flight, airport;
      switch (json.data.type) {
        case 'reroutingLATGO':
          // On récupère les infos nécéssaires
          callsign = json.data.callsign ? json.data.callsign : null;
          flight = callsign ? flightService.getFlight(callsign) : null;
          airport = flight && flight.arr ? flight.arr : null;

          this.addNotification({
            id: json.data.id,
            time: new Date(json.data.time),
            title: `${callsign} : RTG LATGO`,
            text: '',
            callsign: callsign,
            airport: airport,
            icon: 'tick',
          });
          break;
        case 'targetTime':
          // On récupère les infos nécéssaires
          callsign = json.data.callsign;
          flight = callsign ? flightService.getFlight(callsign) : null;
          airport = flight && flight.arr ? flight.arr : null;

          this.addNotification({
            id: json.data.id,
            time: new Date(json.data.time),
            title: `${callsign} : ${new Date(json.data.targetTime).toLocaleTimeString('fr-FR', {
              timeZone: 'UTC',
              hour: '2-digit',
              minute: '2-digit',
            })}@${json.data.targetTimePoint}`,
            text: '',
            callsign: callsign,
            airport: airport,
            icon: 'tick',
          });
          break;
        case 'mcpConfirmation':
          this.addNotification({
            id: json.data.id,
            time: new Date(json.data.time),
            title: `MCP ${json.data.mcpId} implémentée par NM`,
            text: '',
            callsign: null,
            airport: null,
            icon: 'tick',
          });
          break;
        case 'runwayUpdate':
          this.addNotification({
            id: json.data.id,
            time: new Date(json.data.time),
            title: 'Planification piste mise à jour',
            text: json.data.configurations.reduce((accumulator: string, currentValue: any) => {
              const fromDate = new Date(currentValue.from);
              const toDate = new Date(currentValue.to);
              const now = dateService.getDate();
              return toDate.getTime() < now.getTime()
                ? accumulator
                : accumulator +
                    `${fromDate.toLocaleTimeString('fr-FR', {
                      timeZone: 'UTC',
                      hour: '2-digit',
                      minute: '2-digit',
                    })} à ${toDate.toLocaleTimeString('fr-FR', {
                      timeZone: 'UTC',
                      hour: '2-digit',
                      minute: '2-digit',
                    })} : Dep. ${currentValue.dep} / Arr. ${currentValue.arr}\n`;
            }, ''),
            callsign: null,
            airport: 'LFPO',
            icon: 'tick',
          });
          break;
        case 'endCvf':
          this.addNotification({
            id: json.data.id,
            time: new Date(json.data.time),
            title: 'Pensez à changer la configuration',
            text: '',
            callsign: null,
            airport: 'LFPO',
            icon: 'info',
          });
          break;
        case 'planifyCvf':
          this.addNotification({
            id: json.data.id,
            time: new Date(json.data.time),
            title: 'Pensez à changer la configuration (CVF)',
            text: '',
            callsign: null,
            airport: 'LFPO',
            icon: 'info',
          });
          break;
        case 'nearCvfFlight':
          callsign = json.data.callsign;
          flight = callsign ? flightService.getFlight(callsign) : null;
          airport = flight && flight.arr ? flight.arr : null;

          this.addNotification({
            id: json.data.id,
            time: new Date(json.data.time),
            title: `${callsign} prévu proche du CVF`,
            text: '',
            callsign: callsign,
            airport: airport,
            icon: 'info',
          });
          break;
        case 'cvfFlight':
          callsign = json.data.callsign;
          flight = callsign ? flightService.getFlight(callsign) : null;
          airport = flight && flight.arr ? flight.arr : null;

          this.addNotification({
            id: json.data.id,
            time: new Date(json.data.time),
            title: `${callsign} prévu pendant le CVF`,
            text: '',
            callsign: callsign,
            airport: airport,
            icon: 'info',
          });
          break;
        case 'cvfFlightWarn':
          callsign = json.data.callsign;
          flight = callsign ? flightService.getFlight(callsign) : null;
          airport = flight && flight.arr ? flight.arr : null;

          this.addNotification({
            id: json.data.id,
            time: new Date(json.data.time),
            title: `${callsign} prévu pendant le CVF`,
            text: '',
            callsign: callsign,
            airport: airport,
            icon: 'warning',
          });
          break;
        case 'noCvfFlights':
          this.addNotification({
            id: json.data.id,
            time: new Date(json.data.time),
            title: 'Aucun vol prévu proche du CVF',
            text: '',
            callsign: null,
            airport: 'LFPO',
            icon: 'info',
          });
          break;
        case 'flightSlowImplemented':
          callsign = json.data.callsign;
          flight = callsign ? flightService.getFlight(callsign) : null;
          airport = flight && flight.arr ? flight.arr : null;

          this.addNotification({
            id: json.data.id,
            time: new Date(json.data.time),
            title: `${callsign} SLW implémenté`,
            text: '',
            callsign: callsign,
            airport: airport,
            icon: 'tick',
          });
          break;
        case 'flightSlowRefused':
          callsign = json.data.callsign;
          flight = callsign ? flightService.getFlight(callsign) : null;
          airport = flight && flight.arr ? flight.arr : null;

          this.addNotification({
            id: json.data.id,
            time: new Date(json.data.time),
            title: `${callsign} SLW refusé`,
            text: '',
            callsign: callsign,
            airport: airport,
            icon: 'warning',
          });
          break;
        case 'flightReduced':
          callsign = json.data.callsign;
          flight = callsign ? flightService.getFlight(callsign) : null;
          airport = flight && flight.arr ? flight.arr : null;

          this.addNotification({
            id: json.data.id,
            time: new Date(json.data.time),
            title: `${callsign} réduit`,
            text: '',
            callsign: callsign,
            airport: airport,
            icon: 'tick',
          });
          break;
        case 'flightNotReduced':
          callsign = json.data.callsign;
          flight = callsign ? flightService.getFlight(callsign) : null;
          airport = flight && flight.arr ? flight.arr : null;

          this.addNotification({
            id: json.data.id,
            time: new Date(json.data.time),
            title: `${callsign} non réduit`,
            text: '',
            callsign: callsign,
            airport: airport,
            icon: 'warning',
          });
          break;
      }
    } else if (json.typeMessage === 'notificationDelete') {
      this.removeNotification(json.data);
    }
  }

  /**
   * Méthode permettant d'ajouter une notification au store
   * @param notification Notification à ajouter au store
   */
  addNotification(notification: Notification) {
    notificationStore.add(notification);
  }

  /**
   * Méthode permettant de supprimer une notification du store
   * @param id ID de la notification à supprimer du store
   */
  removeNotification(id: string) {
    notificationStore.remove(id);
  }

  /**
   * Méthode permettant de supprimer toutes les notifications du store
   */
  removeAllNotifications() {
    notificationStore.remove();
  }

  /**
   * Méthode permettant de s'abonner aux changements des notifications
   */
  selectNotifications() {
    return notificationQuery.selectNotifications();
  }

  /**
   * Méthode permettant d'obtenir ponctuellement l'ensemble des notifications
   */
  getNotifications() {
    return notificationQuery.getNotifications();
  }
}

export const notificationService = new NotificationService(notificationStore);
