import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Flight} from './flight.model';

export interface FlightState extends EntityState<Flight, string> {
  selected: string;
  hovered: string;
}

@StoreConfig({name: 'flight', idKey: 'callsign'})
export class FlightStore extends EntityStore<FlightState, Flight> {
  constructor() {
    super({
      selected: '',
      hovered: '',
    });
  }
}

export const flightStore = new FlightStore();
