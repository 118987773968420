import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {Beacon} from './beacon.model';

export interface BeaconState extends EntityState<Beacon, string> {
  selected: string;
  hovered: string;
}

@StoreConfig({name: 'beacon', idKey: '_id'})
export class BeaconStore extends EntityStore<BeaconState, Beacon> {
  constructor() {
    super({selected: '', hovered: ''});
  }

  akitaPreAddEntity(newEntity: Beacon): Beacon & {_id: string} {
    return {
      ...newEntity,
      _id: `${newEntity.typeBeacon}-${newEntity.name}-${newEntity.forAirport}`,
    };
  }
}

export const beaconStore = new BeaconStore();
