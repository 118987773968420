import React, {useRef, useState, useEffect, useReducer} from 'react';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {makeStyles} from '@material-ui/core/styles';

import GraphsCard from './cards/graphs-card';
import FlightListCard from './cards/flight-list-card';

import {beaconService} from '../models/beacon/beacon.service';
import {dateService} from '../models/date/date.service';
import {airportService} from '../models/airport/airport.service';
import {flightService} from '../models/flight/flight.service';
import ChartsCard from '../components/cards/chartsCard/ChartsCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawerContainer: {
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 48,
  },
  drawerTitle: {
    flexGrow: 1,
  },
  name: {
    textAlign: 'center',
    fontSize: '26px',
    fontWeight: 'bold',
  },
  scrollableContent: {
    height: 'calc(100% - 48px)',
    overflow: 'auto',
  },
}));

function BeaconCopDrawer({name}) {
  const classes = useStyles();

  // Subscriptions
  const minutesLeftSubscription = useRef(null);
  const airportSubscription = useRef(null);
  const loadSubscription = useRef(null);
  const beaconSubscription = useRef(null);

  // State
  const [minutesLeft, setMinutesLeft] = useState(null);
  const [airport, setAirport] = useState(null);
  const [load, setLoad] = useState(null);
  const [flightsOnBeacon, setFlightsOnBeacon] = useState(null);
  const [beacon, setBeacon] = useReducer(
    (currentBeacon, beacon) =>
      beacon
        ? {
            ...beacon,
            defaultCapacity: beacon.defaultCapacity || 999,
            defaultDepartureCapacity: beacon.defaultDepartureCapacity || 999,
          }
        : null,
    null,
  );

  // Au mount du composant
  useEffect(() => {
    minutesLeftSubscription.current = dateService.selectMinutesLeft().subscribe(setMinutesLeft);
    airportSubscription.current = airportService.selectIcaoAirport().subscribe(setAirport);

    // Au unmount du composant
    return () => {
      if (minutesLeftSubscription.current) {
        minutesLeftSubscription.current.unsubscribe();
      }
      if (airportSubscription.current) {
        airportSubscription.current.unsubscribe();
      }
      if (loadSubscription.current) {
        loadSubscription.current.unsubscribe();
      }
      if (beaconSubscription.current) {
        beaconSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Lors du changement d'aéroport ou de la balise MF, on met à jour la subscription au load et aux valeurs seuils
  useEffect(() => {
    if (loadSubscription.current) {
      loadSubscription.current.unsubscribe();
    }
    if (beaconSubscription.current) {
      beaconSubscription.current.unsubscribe();
    }

    if (name && airport) {
      loadSubscription.current = flightService
        .selectLoadForCopAndAirport(name, airport)
        .subscribe((load) => {
          setLoad(load.loadArray);
          setFlightsOnBeacon(load.flightsOnBeacon);
        });

      beaconSubscription.current = beaconService
        .selectBeaconForAirport(name, airport)
        .subscribe(setBeacon);
    } else {
      setLoad(null);
      setFlightsOnBeacon(null);
      setBeacon(null);
    }
  }, [name, airport]);

  // Event handlers
  const handleClick = () => {
    beaconService.updateSelectedBeacon('');
  };

  return (
    <div className={classes.drawerContainer}>
      <div className={classes.drawerHeader}>
        <div className={classes.drawerTitle}>
          <Typography className={classes.name}>{name}</Typography>
        </div>
        <IconButton onClick={handleClick}>
          <CloseIcon />
        </IconButton>
      </div>
      <Divider />
      <div className={classes.scrollableContent}>
        {/* <GraphsCard type="cop"
                            beaconSelected={name}
                            minutesLeft={minutesLeft}
                            load={load}
                            defaultCapacity={beacon ? beacon.defaultCapacity : null}
                /> */}
        <ChartsCard
          type="cop"
          beaconSelected={name}
          minutesLeft={minutesLeft}
          load={load}
          defaultCapacity={beacon ? beacon.defaultCapacity : null}
        />
        <FlightListCard flights={flightsOnBeacon} />
      </div>
    </div>
  );
}

export default BeaconCopDrawer;
