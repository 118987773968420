import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  card: {
    minHeight: '400',
    minWidth: '400',
    maxWidth: '400',
    backgroundColor: 'RGB(247,247,247)',
    margin: theme.spacing(1),
  },
  cardTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#565656',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  flightParameter: {
    fontSize: '13px',
  },
  flightParameterLow: {
    fontSize: '9px',
    color: 'grey',
  },
  flightData: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  paper: {
    backgroundColor: 'RGB(234,234,234)',
  },
  spaceAfter: {
    marginBottom: 5,
  },
}));

function RadarCard({flight}) {
  const classes = useStyles();

  return flight ? (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <Typography className={classes.cardTitle}>RADAR</Typography>
        <Grid container direction="row" justifyContent="flex-start" className={classes.spaceAfter}>
          <Grid item xs={4}>
            <div style={{display: 'flex', alignItems: 'baseline'}}>
              <Typography className={classes.flightParameter}>Route&nbsp;</Typography>
              <Typography className={classes.flightParameterLow}>(géographique)&nbsp;</Typography>
              <Typography className={classes.flightParameter}>:&nbsp;</Typography>
            </div>
          </Grid>
          <Grid item xs={3}>
            {flight && flight.trackRadar && !isNaN(flight.trackRadar) ? (
              <Typography className={classes.flightData}>{flight.trackRadar}°</Typography>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.flightParameter}>GS:</Typography>
          </Grid>
          <Grid item xs={3}>
            {flight &&
            flight.groundSpeedRadar &&
            flight.groundSpeedRadar !== '0' &&
            !isNaN(flight.groundSpeedRadar) ? (
              <Typography className={classes.flightData}>{flight.groundSpeedRadar}kts</Typography>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" className={classes.spaceAfter}>
          <Grid item xs={4}>
            <div style={{display: 'flex', alignItems: 'baseline'}}>
              <Typography className={classes.flightParameter}>Niveau Actuel :&nbsp;</Typography>
            </div>
          </Grid>
          <Grid item xs={3}>
            {flight && flight.aflRadar && flight.aflRadar !== 0 && !isNaN(flight.aflRadar) ? (
              <Typography className={classes.flightData}>
                {' '}
                FL
                {flight.aflRadar < 10
                  ? `00${flight.aflRadar}`
                  : flight.aflRadar < 100
                  ? `0${flight.aflRadar}`
                  : flight.aflRadar}
              </Typography>
            ) : (
              ''
            )}
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.flightParameter}>Mach :</Typography>
          </Grid>
          <Grid item xs={3}>
            {flight && flight.machRadar ? (
              <Typography className={classes.flightData}>
                {flight.machRadar < 1
                  ? flight.machRadar.toFixed(2).substring(1)
                  : flight.machRadar.toFixed(2)}
              </Typography>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ) : (
    ''
  );
}

export default RadarCard;
