import React, {useEffect, useState, useRef, useReducer} from 'react';

import * as d3 from 'd3';

import {makeStyles} from '@material-ui/core';

import {beaconService} from '../../models/beacon/beacon.service';
import {airportService} from '../../models/airport/airport.service';
import {planificationService} from '../../models/planification/planification.service';
import {flowService} from '../../models/flow/flow.service';

const useStyles = makeStyles((theme) => ({
  flow: {
    stroke: 'white',
    strokeOpacity: 0.5,
    fill: 'none',
    strokeWidth: 1,
    strokeDasharray: '1,1',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },
}));

function FlowsLayer({x, y, k}) {
  const classes = useStyles();

  // State
  const [airport, setAirport] = useState('');
  const [configuration, setConfiguration] = useState('');
  const [flows, setFlows] = useReducer((state, newValue) => {
    const path = d3.geoPath(beaconService.getProjection());
    return newValue && path
      ? newValue.map((flow) => ({
          ...flow,
          path: path({
            geometry: {coordinates: flow.coordinates, type: 'LineString'},
            properties: {name: flow.name},
            type: 'Feature',
          }),
        }))
      : state;
  }, []);

  // Refs des subscriptions
  const airportSubscription = useRef(null);
  const configurationSubscription = useRef(null);
  const flowsSubscription = useRef(null);

  // Au mount du composant, on s'abonne à l'aéroport sélectionné et on mémorise l'objet path
  useEffect(() => {
    airportSubscription.current = airportService.selectIcaoAirport().subscribe(setAirport);

    // Au unmount du composant, on se désabonne des modèles
    return () => {
      if (airportSubscription.current) {
        airportSubscription.current.unsubscribe();
      }
      if (configurationSubscription.current) {
        configurationSubscription.current.unsubscribe();
      }
      if (flowsSubscription.current) {
        flowsSubscription.current.unsubscribe();
      }
    };
  }, []);

  // Lors du changement d'aéroport sélectionné, on met à jour la subscription à la configuration
  useEffect(() => {
    if (configurationSubscription.current) {
      configurationSubscription.current.unsubscribe();
    }

    if (airport) {
      configurationSubscription.current = planificationService
        .selectCurrentConfigurationForAirport(airport)
        .subscribe(setConfiguration);
    } else {
      setConfiguration('');
    }
  }, [airport]);

  // Lors du changement d'airport ou de config, on met à jour la subscription aux flows
  useEffect(() => {
    if (flowsSubscription.current) {
      flowsSubscription.current.unsubscribe();
    }

    if (airport && configuration) {
      flowsSubscription.current = flowService
        .selectFlowsForAirportAndConfig(airport, configuration)
        .subscribe(setFlows);
    } else {
      setFlows([]);
    }
  }, [airport, configuration]);

  return (
    <g className={classes.flow} transform={`translate(${x} ${y}) scale(${k})`}>
      {flows.map((flow) => (
        <path d={flow.path} key={flow.name} vectorEffect="non-scaling-stroke" />
      ))}
    </g>
  );
}

export default FlowsLayer;
