import {Query} from '@datorama/akita';
import {
  Configuration,
  Planifications,
  Cadency,
  Closure,
  PlanificationLFPO,
  PlanificationLFPG,
} from './planification.model';
import {planificationStore, PlanificationStore} from './planification.store';
import {dateService} from '../date/date.service';
import {map, switchMap} from 'rxjs/operators';

export class PlanificationQuery extends Query<Planifications> {
  private airportConfigQfuMatching: {
    airport: 'LFPO' | 'LFPG';
    runway: 'LFPO' | 'LFPGARN' | 'LFPGARS';
    config: string;
    qfu: string;
  }[] = [
    {airport: 'LFPO', runway: 'LFPO', config: 'POE06', qfu: '06'},
    {airport: 'LFPO', runway: 'LFPO', config: 'POE06_CVF', qfu: '06'},
    {airport: 'LFPO', runway: 'LFPO', config: 'POE07', qfu: '07'},
    {airport: 'LFPO', runway: 'LFPO', config: 'POE07_CVF', qfu: '07'},
    {airport: 'LFPO', runway: 'LFPO', config: 'POW24', qfu: '24'},
    {airport: 'LFPO', runway: 'LFPO', config: 'POW24_CVF', qfu: '24'},
    {airport: 'LFPO', runway: 'LFPO', config: 'POW25', qfu: '25'},
    {airport: 'LFPO', runway: 'LFPO', config: 'POW25_CVF', qfu: '25'},
    {airport: 'LFPG', runway: 'LFPGARN', config: 'PG_W', qfu: '27R'},
    {airport: 'LFPG', runway: 'LFPGARN', config: 'PG_E', qfu: '09L'},
    {airport: 'LFPG', runway: 'LFPGARS', config: 'PG_W', qfu: '26L'},
    {airport: 'LFPG', runway: 'LFPGARS', config: 'PG_E', qfu: '08R'},
  ];

  constructor(protected store: PlanificationStore) {
    super(store);
  }

  getQfuForAirportAndConfig(
    airport: 'LFPO' | 'LFPG',
    runway: 'LFPO' | 'LFPGARN' | 'LFPGARS',
    config: string,
  ) {
    const foundElement = this.airportConfigQfuMatching.find(
      (element) =>
        element.airport === airport && element.runway === runway && element.config === config,
    );
    return foundElement ? foundElement.qfu : null;
  }

  selectPlanificationForAirport(airport: 'LFPO' | 'LFPG') {
    return this.select((state) => state[airport]);
  }

  getPlanificationForAirport(airport: 'LFPO' | 'LFPG') {
    return this.getValue()[airport];
  }

  selectCurrentCadencyForAirportAndQfu(airport: 'LFPO' | 'LFPG', qfu: string) {
    return dateService.selectUpdatePeriod().pipe(
      switchMap(() => this.selectPlanificationForAirport(airport)),
      map((planifications: PlanificationLFPO | PlanificationLFPG) => {
        const currentDate = dateService.getDate();

        // On cherche la cadence pour le QFU voulu
        let currentCadency: Cadency | undefined;
        if (qfu) {
          // @ts-ignore
          currentCadency = planifications.cadencies[qfu].reduce((accumulator, value) => {
            if (
              (value.from.getTime() <= currentDate.getTime() &&
                value.to &&
                value.to.getTime() >= currentDate.getTime()) ||
              (value.from.getTime() <= currentDate.getTime() && value.to === null)
            ) {
              return value;
            } else {
              return accumulator;
            }
          }, null);
        }

        return currentCadency ? currentCadency.cadency : null;
      }),
    );
  }

  selectPlanificationForGraphForAirport(
    airport: 'LFPO' | 'LFPG',
    runway: 'LFPO' | 'LFPGARS' | 'LFPGARN',
  ) {
    // SwitchMap permet de mettre à jour les données toutes les 4 secondes (grace au dateService.selectUpdatePeriod()) et lorsque les planifications ont changé (grace au this.selectPlanification())
    // Cela permet d'avoir un graphe de planifications toujours à jour
    return dateService.selectUpdatePeriod().pipe(
      switchMap(() => this.selectPlanificationForAirport(airport)),
      map((planifications: PlanificationLFPO | PlanificationLFPG) => {
        const currentDate = dateService.getDate();

        // On cherche la config courante
        const currentConfig: Configuration = planifications.configurations.reduce(
          (accumulator: any, value: Configuration) => {
            if (
              (value.from.getTime() <= currentDate.getTime() &&
                value.next &&
                value.next.getTime() >= currentDate.getTime()) ||
              (value.from.getTime() <= currentDate.getTime() && value.next === null)
            ) {
              return value;
            } else {
              return accumulator;
            }
          },
          null,
        );

        // On trouve le QFU correspondant
        let currentQfu: string | undefined | null;
        if (currentConfig && currentConfig.configuration) {
          currentQfu = this.getQfuForAirportAndConfig(airport, runway, currentConfig.configuration);
        }

        // On cherche la cadence courante
        let currentCadency: Cadency | undefined;
        if (currentQfu) {
          // @ts-ignore
          currentCadency = planifications.cadencies[currentQfu].reduce((accumulator, value) => {
            if (
              (value.from.getTime() <= currentDate.getTime() &&
                value.to &&
                value.to.getTime() >= currentDate.getTime()) ||
              (value.from.getTime() <= currentDate.getTime() && value.to === null)
            ) {
              return value;
            } else {
              return accumulator;
            }
          }, null);
        }

        // On determines les fermetures liées aux changements de config
        const configChangesClosures = planifications.configurations
          .filter(
            (config) =>
              config.to &&
              config.next &&
              config.to.getTime() < config.next.getTime() &&
              config.next.getTime() > currentDate.getTime(),
          )
          .map((config) => {
            const nextConfig = planifications.configurations.find(
              (conf) => conf.from.getTime() === config.next?.getTime(),
            );
            // @ts-ignore config.to est forcément défini, puisqu'on filtre les données juste avant
            const closureFrom = config.to;
            // @ts-ignore config.to est forcément défini, puisqu'on filtre les données juste avant
            const closureTo = config.next;
            const configuration = nextConfig ? nextConfig.configuration : '';
            const qfu =
              nextConfig && nextConfig.configuration
                ? this.getQfuForAirportAndConfig(airport, runway, nextConfig.configuration)
                : '';
            const cadency = this.getCadencyForQfuAtTimeForAirport(qfu, closureTo, airport);
            const isCvfConfig = nextConfig ? nextConfig.configuration.endsWith('_CVF') : false;

            return {
              closureFrom: closureFrom,
              closureTo: closureTo,
              config: configuration,
              qfu: qfu,
              cadency: cadency,
              isCvfConfig: isCvfConfig,
            };
          });

        // Changements de cadences qui concerne les configurations planifiées
        const cadencyChanges: any[] = [];
        planifications.configurations
          .filter(
            (config) =>
              config.next === null ||
              (config.next && config.next.getTime() >= currentDate.getTime()),
          )
          .forEach((config) => {
            const qfu = this.getQfuForAirportAndConfig(airport, runway, config.configuration);
            if (qfu) {
              // @ts-ignore
              planifications.cadencies[qfu].forEach((cad: Cadency) => {
                if (
                  cad.from.getTime() >= currentDate.getTime() &&
                  cad.from.getTime() >= config.from.getTime() &&
                  (config.next === null ||
                    (config.next && config.next.getTime() > cad.from.getTime()))
                ) {
                  cadencyChanges.push({
                    at: cad.from,
                    newCadency: cad.cadency,
                  });
                }
              });
            }
          });

        // Changements de config : non CVF -> CVF (ou inversement)
        const cvfChanges: any[] = [];
        planifications.configurations
          .filter(
            (config) =>
              config.next === null ||
              (config.next && config.next.getTime() > currentDate.getTime()),
          )
          .forEach((configuration, index, configurations) => {
            if (
              configuration.to &&
              configuration.next &&
              configuration.to.getTime() === configuration.next.getTime()
            ) {
              const nextConfiguration = configurations.find(
                (conf) => conf.from.getTime() === configuration.next?.getTime(),
              );
              if (nextConfiguration) {
                if (nextConfiguration.configuration === configuration.configuration + '_CVF') {
                  cvfChanges.push({
                    at: configuration.next,
                    notCvfToCvf: true,
                  });
                } else if (
                  nextConfiguration.configuration + '_CVF' ===
                  configuration.configuration
                ) {
                  cvfChanges.push({
                    at: configuration.next,
                    notCvfToCvf: false,
                  });
                }
              }
            }
          });

        // Autres fermetures
        const closures: any[] = [];
        planifications.configurations
          .filter(
            (config) =>
              config.to === null || (config.to && config.to.getTime() > currentDate.getTime()),
          )
          .forEach((config) => {
            const qfu = this.getQfuForAirportAndConfig(airport, runway, config.configuration);
            if (qfu) {
              // @ts-ignore
              planifications.closures[qfu].forEach((clos: Closure) => {
                if (clos.type !== 'CONFIGURATION') {
                  if (config.to === null) {
                    // config.to === null -> la config n'a pas de date de fin
                    if (
                      clos.from.getTime() < config.from.getTime() &&
                      clos.to.getTime() > config.from.getTime()
                    ) {
                      closures.push({type: clos.type, from: config.from, to: clos.to});
                    } else if (
                      clos.from.getTime() > config.from.getTime() &&
                      clos.to.getTime() > config.from.getTime()
                    ) {
                      closures.push({type: clos.type, from: clos.from, to: clos.to});
                    }
                  } else {
                    // config.to !== null -> la config a une date de fin
                    if (
                      clos.from.getTime() < config.from.getTime() &&
                      clos.to.getTime() > config.from.getTime() &&
                      clos.to.getTime() < config.to.getTime()
                    ) {
                      closures.push({type: clos.type, from: config.from, to: clos.to});
                    } else if (
                      clos.from.getTime() > config.from.getTime() &&
                      clos.from.getTime() < config.to.getTime() &&
                      clos.to.getTime() > config.from.getTime() &&
                      clos.to.getTime() < config.to.getTime()
                    ) {
                      closures.push({type: clos.type, from: clos.from, to: clos.to});
                    } else if (
                      clos.from.getTime() > config.from.getTime() &&
                      clos.from.getTime() < config.to.getTime() &&
                      clos.to.getTime() > config.to.getTime()
                    ) {
                      closures.push({type: clos.type, from: clos.from, to: config.to});
                    } else if (
                      clos.from.getTime() < config.from.getTime() &&
                      clos.to.getTime() > config.to.getTime()
                    ) {
                      closures.push({type: clos.type, from: config.from, to: config.to});
                    }
                  }
                }
              });
            }
          });

        return {
          currentQFU: currentQfu ? currentQfu : null, // QFU actuel
          currentConfiguration: currentConfig ? currentConfig.configuration : null, // Configuration actuelle
          currentCadency: currentCadency ? currentCadency.cadency : null, // Cadence actuelle
          configChangesClosures: configChangesClosures, // Fermetures liées à un changement de QFU
          cadencyChanges: cadencyChanges, // Changement de cadence pour la configuration en place à cet instant
          cvfChanges: cvfChanges, // Changements de configuration de type cvf -> non cvf (ou inversement)
          closures: closures, // Autres fermetures
        };
      }),
    );
  }

  getQfuAtTimeForAirport(
    time: Date,
    airport: 'LFPO' | 'LFPG',
    runway: 'LFPO' | 'LFPGARN' | 'LFPGARS',
  ) {
    const planifications = this.getPlanificationForAirport(airport);

    if (planifications && time) {
      // On cherche la config associée
      const config: Configuration = planifications.configurations.reduce(
        (accumulator: any, value: Configuration) => {
          if (
            (value.from.getTime() <= time.getTime() &&
              value.next &&
              value.next.getTime() >= time.getTime()) ||
            (value.from.getTime() <= time.getTime() && value.next === null)
          ) {
            return value;
          } else {
            return accumulator;
          }
        },
        null,
      );

      // On trouve le QFU associé
      const qfu =
        config && config.configuration
          ? this.getQfuForAirportAndConfig(airport, runway, config.configuration)
          : null;

      return qfu || '--';
    } else {
      return '--';
    }
  }

  getPossibleConfigForAirport(airport: 'LFPO' | 'LFPG') {
    return this.airportConfigQfuMatching
      .filter((element) => element.airport === airport) // On ne garde que les configs de l'aeroport concerné
      .map((element) => element.config) // On ne garde que la config
      .filter((element, index, array) => array.indexOf(element) === index); // On élimine les doublons
  }

  getPossibleQfuForAirport(airport: 'LFPO' | 'LFPG', runway: 'LFPO' | 'LFPGARS' | 'LFPGARN') {
    return this.airportConfigQfuMatching
      .filter((element) => element.airport === airport && element.runway === runway) // On ne garde que les QFU pour l'aeroport voulu
      .map((element) => element.qfu) // On ne garde que le QFU
      .filter((element, index, array) => array.indexOf(element) === index); // On élimine les doublons
  }

  getCadencyForQfuAtTimeForAirport(
    qfu: string | undefined | null,
    time: Date | null,
    airport: 'LFPO' | 'LFPG',
  ) {
    if (qfu && time) {
      const planifications = this.getPlanificationForAirport(airport);

      // On cherche la cadence correspondante
      // @ts-ignore
      const cadency = planifications.cadencies[qfu].reduce((accumulator, value) => {
        if (
          (value.from.getTime() <= time.getTime() &&
            value.to &&
            value.to.getTime() >= time.getTime()) ||
          (value.from.getTime() <= time.getTime() && value.to === null)
        ) {
          return value;
        } else {
          return accumulator;
        }
      }, null);

      return cadency ? cadency.cadency : undefined;
    } else {
      return undefined;
    }
  }

  selectCurrentConfigurationForAirport(airport: 'LFPO' | 'LFPG') {
    return this.selectPlanificationForAirport(airport).pipe(
      map((planifications) => {
        const currentDateTime = dateService.getDate();

        // On cherche la config associée
        const config: Configuration = planifications.configurations.reduce(
          (accumulator: any, value: Configuration) => {
            if (
              (value.from.getTime() <= currentDateTime.getTime() &&
                value.next &&
                value.next.getTime() >= currentDateTime.getTime()) ||
              (value.from.getTime() <= currentDateTime.getTime() && value.next === null)
            ) {
              return value;
            } else {
              return accumulator;
            }
          },
          null,
        );

        return config ? config.configuration : '--';
      }),
    );
  }

  getCurrentConfigForAirport(airport: 'LFPO' | 'LFPG') {
    const planifications = this.getPlanificationForAirport(airport);

    const currentDateTime = dateService.getDate();

    // On cherche la config associée
    const config: Configuration = planifications.configurations.reduce(
      (accumulator: any, value: Configuration) => {
        if (
          (value.from.getTime() <= currentDateTime.getTime() &&
            value.next &&
            value.next.getTime() >= currentDateTime.getTime()) ||
          (value.from.getTime() <= currentDateTime.getTime() && value.next === null)
        ) {
          return value;
        } else {
          return accumulator;
        }
      },
      null,
    );

    return config ? config.configuration : '--';
  }

  getRunwayForAirportAndQfu(airport: 'LFPO' | 'LFPG', qfu: string) {
    const entries = this.airportConfigQfuMatching.filter(
      (entity) => entity.airport === airport && entity.qfu === qfu,
    );
    return entries.length > 0 ? entries[0].runway : null;
  }

  getAllRunwaysForAirport(airport: 'LFPO' | 'LFPG') {
    const results: ('LFPO' | 'LFPGARN' | 'LFPGARS')[] = this.airportConfigQfuMatching
      .filter((entry) => entry.airport === airport) // Filtrage sur l'aéroport
      .map((entry) => entry.runway) // On ne garde que les pistes
      .filter((entry, index, array) => array.indexOf(entry) === index); // On filtre les doublons

    return results;
  }
}

export const planificationQuery = new PlanificationQuery(planificationStore);
