import React from 'react';

import './beacons-styles.css';

import {ReactComponent as SvgRtg} from '../../svg-icons/sync.svg';

import {beaconService} from '../../models/beacon/beacon.service.ts';

function BeaconRtg({name, x, y, isSelected, isHovered}) {
  // Event handlers
  const handleClick = (event) => {
    if (isSelected) {
      beaconService.updateSelectedBeacon('');
    } else {
      beaconService.updateSelectedBeacon(name);
    }
  };

  const handleMouseEnter = (event) => {
    if (event.buttons % 2 !== 1) {
      beaconService.updateHoveredBeacon(name);
    }
  };

  const handleMouseLeave = (event) => {
    if (event.buttons % 2 !== 1) {
      beaconService.updateHoveredBeacon('');
    }
  };

  return (
    <g
      className={`beaconRtg${isSelected ? ' selected' : isHovered ? ' hovered' : ''}`}
      transform={`translate (${x} ${y})`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <g>
        <rect className="nameArea" x="-23.5" y="-29.5" rx="5" ry="5" width="46" height="14" />
        <text className="name" x="0" y="-18" textAnchor="middle">
          {name.replace('RTG ', '')}
        </text>
      </g>
      <circle className="beaconCircle" cx="0" cy="0" r="12" />
      <SvgRtg
        className="rtgIcon"
        x="-10"
        y="-10"
        height="20"
        width="20"
        viewBox="0 0 768.051 1024"
      />
    </g>
  );
}

export default BeaconRtg;
