import React, {useEffect} from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import {makeStyles} from '@material-ui/core/styles';
import {Divider} from '@material-ui/core';
import SpeedSection from './components/SpeedSection';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  card: {
    minHeight: '400',
    minWidth: '400',
    maxWidth: '400',
    backgroundColor: 'RGB(247,247,247)',
    margin: theme.spacing(1),
  },
  cardTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#565656',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  flightParameter: {
    fontSize: '13px',
  },
  flightParameterLow: {
    fontSize: '9px',
    color: 'grey',
  },
  flightData: {
    fontSize: '15px',
    fontWeight: 'bold',
  },
  tableauHaut: {
    fontSize: '13px',
    textAlign: 'center',
    height: 25,
    lineHeight: '25px',
  },
  tableauData: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    height: 25,
    lineHeight: '25px',
  },

  subtitle: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 'bold',
    color: 'grey',
    height: 25,
    lineHeight: '25px',
    marginBottom: 5,
  },
  paper: {
    backgroundColor: 'RGB(234,234,234)',
  },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

function ADSCCard({flight}) {
  const classes = useStyles();

  return flight ? (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <Typography className={classes.cardTitle}>ADS-C</Typography>

        {flight.adscData.xmanMach && flight.adscData.xmanMinMach && flight.adscData.xmanMaxMach && (
          <SpeedSection
            tocMach={flight.adscData.tocMach}
            tocMinMach={flight.adscData.tocMinMach}
            tocMaxMach={flight.adscData.tocMaxMach}
            xmanMach={flight.adscData.xmanMach}
            xmanMinMach={flight.adscData.xmanMinMach}
            xmanMaxMach={flight.adscData.xmanMaxMach}
            todMach={flight.adscData.todMach}
            todIas={flight.adscData.todIas}
          />
        )}

        <Divider className={classes.divider}></Divider>

        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauData}>&nbsp;</Typography>
          </Grid>
          <Grid item xs={3} className={classes.paper}>
            <Typography className={classes.tableauHaut}>ETO</Typography>
          </Grid>
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauData}>&nbsp;</Typography>
          </Grid>
          <Grid item xs={5} className={classes.paper}>
            <Typography className={classes.tableauHaut}>TOA Range</Typography>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauHaut}>TOC</Typography>
          </Grid>
          <Grid item xs={3}>
            {flight.adscData && flight.adscData.etoToc? (
              <Typography className={classes.tableauData}>{flight.adscData.etoToc}</Typography>
            ) : (
              <Typography className={classes.tableauData}>∅</Typography>
            )}
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.tableauData}>&nbsp;</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.tableauData}></Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauHaut}>TOD</Typography>
          </Grid>
          <Grid item xs={3}>
            {flight.adscData && flight.adscData.etoTod ? (
              <Typography className={classes.tableauData}>{flight.adscData.etoTod}</Typography>
            ) : (
              <Typography className={classes.tableauData}>∅</Typography>
            )}
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.tableauData}>&nbsp;</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.tableauData}>{''}</Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauHaut}>{flight.mf}</Typography>
          </Grid>
          <Grid item xs={3}>
            {flight.adscData && flight.adscData.etoMf ? (
              <Typography className={classes.tableauData}>{flight.adscData.etoMf}</Typography>
            ) : (
              <Typography className={classes.tableauData}>∅</Typography>
            )}
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.tableauData}>&nbsp;</Typography>
          </Grid>
          <Grid item xs={5}>
            {flight.adscData && flight.adscData.toaRange &&
            flight.adscData.etoMf ? (
              <Typography className={classes.tableauData}>
                {flight.adscData.toaRange.start} - {flight.adscData.toaRange.end}
              </Typography>
            ) : (
              <Typography className={classes.tableauData}>∅</Typography>
            )}
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauHaut}>{flight.iaf}</Typography>
          </Grid>
          <Grid item xs={3}>
            {flight.adscData && flight.adscData.etoIaf ? (
              <Typography className={classes.tableauData}>{flight.adscData.etoIaf}</Typography>
            ) : (
              <Typography className={classes.tableauData}>∅</Typography>
            )}
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.tableauData}>&nbsp;</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.tableauData}>{''}</Typography>
          </Grid>
        </Grid>

        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={2} className={classes.paper}>
            <Typography className={classes.tableauHaut}>{flight.arr}</Typography>
          </Grid>
          <Grid item xs={3}>
            {flight.adscData && flight.adscData.eta ? (
              <Typography className={classes.tableauData}>{flight.adscData.eta}</Typography>
            ) : (
              <Typography className={classes.tableauData}>∅</Typography>
            )}
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.tableauData}>&nbsp;</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.tableauData}>{''}</Typography>
          </Grid>
        </Grid>

        <Divider className={classes.divider}></Divider>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Grid item>
            <Typography className={classes.flightParameter}>Vent : </Typography>
          </Grid>
          <Grid item>
            {flight.adscData && flight.adscData.windDirection && flight.adscData.windSpeed ? (
              <Typography className={classes.flightData}>
                {flight.adscData.windDirection < 10
                  ? '00'.concat(String(flight.adscData.windDirection))
                  : flight.adscData.windDirection < 100
                  ? '0'.concat(String(flight.adscData.windDirection))
                  : flight.adscData.windDirection}
                ° {flight.adscData.windSpeed}kt
              </Typography>
            ) : (
              <Typography className={classes.flightData}>&nbsp;</Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  ) : (
    ''
  );
}

export default ADSCCard;
