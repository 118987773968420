import {QueryEntity} from '@datorama/akita';
import {
  DepartureCountState,
  DepartureCountStore,
  departureCountStore,
} from './departure-count.store';

export class DepartureCountQuery extends QueryEntity<DepartureCountState> {
  constructor(protected store: DepartureCountStore) {
    super(store);
  }

  selectDepartureCountsForTV(tv: string) {
    return this.selectEntity((entity) => entity.tv === tv);
  }
}

export const departureCountQuery = new DepartureCountQuery(departureCountStore);
