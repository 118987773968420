import {QueryEntity} from '@datorama/akita';
import {NotificationState, NotificationStore, notificationStore} from './notification.store';

export class NotificationQuery extends QueryEntity<NotificationState> {
  constructor(protected store: NotificationStore) {
    super(store);
  }

  selectNotifications() {
    return this.selectAll();
  }

  getNotifications() {
    return this.getAll();
  }
}

export const notificationQuery = new NotificationQuery(notificationStore);
