import {webSocket} from '../../utils/serverWebSocket';
import {Socket} from 'socket.io-client';

import {flightStore, FlightStore} from './flight.store';
import {Flight, FlightlistInformations} from './flight.model';
import {flightQuery} from './flight.query';

export class FlightService {
  private socket?: Socket;

  constructor(private flightStore: FlightStore) {}

  /**
   * Méthode permettant d'ouvrir la connexion avec le serveur via une webSocket et de s'abonner aux différents types de messages émis par celui ci
   */
  start() {
    // On s'assure que le store est vide
    flightStore.remove();

    // On s'abonne au WS
    this.socket = webSocket().getSocket();

    this.onMessageReceived = this.onMessageReceived.bind(this);

    this.socket.on('message', this.onMessageReceived);
  }

  /**
   * Méthode permettant se désabonner du serveur et de vider le store, lorsque celui ci n'est plus utilisé
   * La socket n'est pas fermée car elle peut être utilisée par un autre modèle
   */
  destroy(): void {
    // Déconnexion du serveur
    if (this.socket) {
      this.socket.off('message', this.onMessageReceived);
      this.socket = undefined;
    }

    // On vide le store
    flightStore.remove();
  }

  /**
   * Méthode permettant de mettre à jour un vol du store, ou de  l'ajouter s'il n'existe pas
   * @param callsign Callsign du vol à mettre à jour / ajouter
   * @param flight Vol à mettre à jour / ajouter
   */
  upsertFlight(callsign: string, flight: Flight) {
    // Prevents unrelevant persisting data (use case: computer turns to sleep mode, 'flightDeleted' message is not received)
    flightStore.remove((el) => {
     return new Date(el.eta) < new Date();
    })
    flightStore.upsert(callsign, flight);
  }

  /**
   * Méthode permettant de définir quel vol est sélectionné dans l'IHM
   * @param callsign Callsign du vol sélectionné dans l'IHM
   */
  setSelected(callsign: string) {
    flightStore.update({selected: callsign});
  }

  /**
   * Méthode permettant de définir quel vole est survolé dans l'IHM
   * @param callsign Callsign du vol survolé dans l'IHM
   */
  setHovered(callsign: string) {
    flightStore.update({hovered: callsign});
  }

  /**
   * Listener pour les messages reçus du serveur
   * @param message message reçu
   */
  onMessageReceived(message: any) {
    // Formattage des données reçues
    const receivedObject: any = JSON.parse(message);

    switch (receivedObject.typeMessage) {
      case 'flightUpdated':
        this.onFlightUpdated(receivedObject.data);
        break;
      case 'flightDeleted':
        this.onFlightDeleted(receivedObject.data);
        break;
    }
  }

  /**
   * Méthode permettant de mettre à jour (ou d'ajouter) un vol du store avec de nouvelles données reçues du serveur
   * @param data nouvelles données reçues du serveur à mettre à jour
   */
  onFlightUpdated(data: any) {
    // Instanciation des objects Date si les propriétés sont présentes dans data
    const flight: Flight = {
      ...data,
      ...(!!data.eta && {eta: new Date(data.eta)}),
      ...(!!data.etaFF && {etaFF: new Date(data.etaFF)}),
      ...(!!data.etaMF && {etaMF: new Date(data.etaMF)}),
      ...(!!data.sta && {sta: new Date(data.sta)}),
      ...(!!data.staFF && {staFF: new Date(data.staFF)}),
      ...(!!data.staMF && {staMF: new Date(data.staMF)}),
      ...(!!data.cta && {cta: new Date(data.cta)}),
      ...(!!data.earliestTT && {earliestTT: new Date(data.earliestTT)}),
      ...(!!data.latestTT && {latestTT: new Date(data.latestTT)}),
      ...(!!data.ctot && {ctot: new Date(data.ctot)}),
      ...(!!data.cto && {cto: new Date(data.cto)}),
      ...(!!data.targetTime && {targetTime: new Date(data.targetTime)}),
      ...(!!data.inFlightlists && {
        inFlightlists: data.inFlightlists.map((flightlistInformations: FlightlistInformations) => ({
          ...flightlistInformations,
          ...(!!flightlistInformations.eobt && {eobt: new Date(flightlistInformations.eobt)}),
          ...(!!flightlistInformations.entryTime && {
            entryTime: new Date(flightlistInformations.entryTime),
          }),
        })),
      }),
    };

    // Calcul des informations manquantes
    if (flight.staMF && flight.etaMF) {
      flight.delayMF = Math.trunc(
        Math.abs((flight.staMF.getTime() - flight.etaMF.getTime()) / 60000),
      );
    }
    if (flight.staFF && flight.etaFF) {
      flight.delayFF = Math.trunc(
        Math.abs((flight.staFF.getTime() - flight.etaFF.getTime()) / 60000),
      );
    }
    if (flight.sta && flight.eta) {
      flight.delayRNW = Math.trunc(Math.abs((flight.sta.getTime() - flight.eta.getTime()) / 60000));
    }

    this.upsertFlight(flight.callsign, flight);
  }

  /**
   * Méthode permettant de supprimer un vol du store lorsqu'il a atteri
   * @param data données du vol à supprimer (callsign)
   */
  onFlightDeleted(data: any) {
    const flight: Flight = {
      callsign: data.callsign,
    };

    flightStore.remove(flight.callsign);
  }

  /**
   * Méthode permettant de s'abonner aux mises à jours de tous les vols
   */
  selectAllFlights() {
    return flightQuery.selectAllFlights();
  }

  /**
   * Méthode permettant de s'abonner aux mises à jours de tous les vols pour un aeroport précisé
   * @param airport aeroport dont on veut récupérer les vols
   */
  selectAllFlightsForAirport(airport: 'LFPO' | 'LFPG') {
    return flightQuery.selectAllFlightsForAirport(airport);
  }

  /**
   * Méthode permettant de s'abonner aux mises à jour d'un vol en particulier
   * @param callsign Callsign du vol auquel on veut s'abonner
   */
  selectFlight(callsign: string) {
    return flightQuery.selectFlight(callsign);
  }

  /**
   * Méthode permettant de s'abonner au changement de vol survolé (callsign uniquement)
   */
  selectHovered() {
    return flightQuery.selectHovered();
  }

  /**
   * Méthode permettant de s'abonner au changement de vol sélectionné (callsign uniquement)
   */
  selectSelected() {
    return flightQuery.selectSelected();
  }

  /**
   * Méthode permettant de s'abonner au changement de vol survolé (toutes les informations du vol)
   */
  selectHoveredFlight() {
    return flightQuery.selectHoveredFlight();
  }

  /**
   * Méthode permettant de s'abonner au changement de vol sélectionné (toutes les informations du vol)
   */
  selectSelectedFlight() {
    return flightQuery.selectSelectedFlight();
  }

  /**
   * Méthode permettant de s'abonner aux changements de la charge d'un MF pour un aeroport précisé
   * @param mf MF auquel on veut s'abonner
   * @param airport aeroport selectionné
   */
  selectLoadForMfAndAirport(mf: string, airport: 'LFPO' | 'LFPG') {
    return flightQuery.selectLoadForMfAndAirport(mf, airport);
  }

  /**
   * Méthode permettant de s'abonner aux changement de la charge d'un COP pour un aeroport précisé
   * @param cop COP auquel on veut s'abonner
   */
  selectLoadForCopAndAirport(cop: string, airport: 'LFPO' | 'LFPG') {
    return flightQuery.selectLoadForCopAndAirport(cop, airport);
  }

  /**
   * Méthode permettant de s'abonner aux changements de la charge d'un IAF pour un aeroport précisé
   * @param iaf IAF auquel on veut s'abonner
   * @param airport aeroport sélectionné
   */
  selectLoadForIafAndAirport(iaf: string, airport: 'LFPO' | 'LFPG') {
    return flightQuery.selectLoadForIafAndAirport(iaf, airport);
  }

  /**
   * Méthode permettant de s'abonner aux changement de la charge d'un aeroport + qfu
   * @param airport Airport auquel on veut s'abonner
   */
  selectLoadForAirportAndQfu(airport: 'LFPO' | 'LFPG', qfu: string) {
    return flightQuery.selectLoadForAirportAndQfu(airport, qfu);
  }

  /**
   * Méthode permettant de s'abonner aux changements de la charge s'un aeroport + piste
   * @param airport Aeroport auquel on veut s'abonner
   * @param runway piste voulue (pour LFPG -> LFPGARS ou LFPGARN)
   */
  selectLoadForAirportAndRunway(airport: 'LFPO' | 'LFPG', runway: 'LFPO' | 'LFPGARN' | 'LFPGARS') {
    return flightQuery.selectLoadForAirportAndRunway(airport, runway);
  }

  /**
   * Méthode permettant de s'abonner aux vols pour un aéroport + piste
   * @param airport Airport auquel on veut s'abonner
   * @param runway Piste voulu (pour LFPG -> LFPGARS ou LFPGARN)
   */
  selectFlightsForAirportAndRunway(
    airport: 'LFPO' | 'LFPG',
    runway: 'LFPO' | 'LFPGARN' | 'LFPGARS',
  ) {
    return flightQuery.selectFlightsForAirportAndRunway(airport, runway);
  }

  /**
   * Méthode permettant de s'abonner aux vols pour un aéroport + cop
   * @param airport Airport auquel on veut s'abonner
   * @param beaconName COP voulu (pour LFPG -> COP TE ou COP UJ)
   */
  selectFlightsForAirportAndCop(airport: 'LFPO' | 'LFPG', beaconName: string) {
    return flightQuery.selectFlightsForAirportAndCop(airport, beaconName);
  }

  /**
   * Méthode permettant d'obtenir la liste des vols pour un TV et un aéroport donné
   * @param tv TV auquel on veut s'abonner
   * @param airport Aéroport pour lequel on veut s'abonner
   */
  selectFlightsForTvAndAirport(tv: string, airport: 'LFPO' | 'LFPG') {
    return flightQuery.selectFlightsForTvAndAirport(tv, airport);
  }

  /**
   * Méthode permettant d'obtenir la load pour un TV
   * @param tv TV pour lequel on veut la load
   */
  selectLoadForTv(tv: string) {
    return flightQuery.selectLoadForTv(tv);
  }

  /**
   * Méthode permettant d'obtenir la liste des vols reroutables TE -> UJ
   */
  selectTEReroutableFlights() {
    return flightQuery.selectTEReroutableFlights();
  }

  /**
   * Méthode permettant d'obtenir la liste des vols reroutables UJ -> TE
   */
  selectUJReroutableFlights() {
    return flightQuery.selectUJReroutableFlights();
  }

  /**
   * Méthode permettant de récupérer ponctuellement les informations d'un vol
   * @param callsign Callsign du vol dont on veut récupérer les informations
   */
  getFlight(callsign: string) {
    return flightQuery.getEntity(callsign);
  }
}

export const flightService = new FlightService(flightStore);
